import { Modal } from '@orascom/common-components';
import ContactUsForm from '../contact-us-form/contact-us-form';
import { useTranslation } from 'react-i18next';
import callIcon from '../../../assets/icons/call-recieve.svg';

/* eslint-disable-next-line */
export interface TalkToSalesModalProps {
  show: boolean;
  onClose: () => void;
}

export function TalkToSalesModal({ show, onClose }: TalkToSalesModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={t('talkToSales')}
      sideModal
      headerExtraChildren={
        <a href="tel:16565">
          <img src={callIcon} alt="call-icon" />
          {t('callUs')}
        </a>
      }
    >
      <ContactUsForm
        title={t('leaveYourContactInfo')}
        onCloseModal={onClose}
        talkToSalesForm
      />
    </Modal>
  );
}

export default TalkToSalesModal;
