import { ImageTextOverlay, CardsSlider } from '@orascom/common-components';
import { useTranslation } from 'react-i18next';
import { DestinationFacilities as DestinationFacilitiesInterface } from '@orascom/api-interfaces';
import styles from './destination-facilities.module.scss';

export interface DestinationFacilitiesProps {
  title: string;
  subtitle: string;
  image: string;
  destinationFacilities: DestinationFacilitiesInterface[];
}

export function DestinationFacilities(
  props: Readonly<DestinationFacilitiesProps>
) {
  const { t } = useTranslation();

  const { title, subtitle, image, destinationFacilities } = props;

  return (
    <div className={styles['facilities']}>
      {image?.length ? (
        <div className={styles['facilities__banner']}>
          <ImageTextOverlay
            title={title}
            subTitle={subtitle}
            image={image}
            maxContentWidth={window.innerWidth > 769 ? 600 : 311}
          />
        </div>
      ) : null}
      {destinationFacilities?.length ? (
        <div className={styles['facilities__cards-wrapper']}>
          <h3 className={styles['facilities__title']}>
            {t('facilitiesAmenities')}
          </h3>
          <div className={styles['facilities__cards']}>
            <CardsSlider>
              {destinationFacilities.map((fac) => (
                <div key={fac.title} className={styles['facilities__card']}>
                  <img
                    src={fac.icon}
                    alt=""
                    role="presentation"
                    loading="lazy"
                  />
                  <h5>{fac.title}</h5>
                  <p>{fac.description}</p>
                </div>
              ))}
            </CardsSlider>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default DestinationFacilities;
