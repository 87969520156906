import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../api/routes';
import styles from './unit-details.module.scss';
import { UnitDestination } from '@orascom/api-interfaces';

const UnitBreadcrumbs = ({ destination }: { destination: UnitDestination }) => {
  const { t } = useTranslation();
  return (
    <div className={styles['header-details']}>
      <p>{t('destinations')}</p>
      <span>/</span>
      <Link to={ROUTES.Destination.getPath?.(destination.slug)}>
        {destination.name}
      </Link>
      <span>/</span>
      <p>{t('unitDetails')}</p>
    </div>
  );
};

export default UnitBreadcrumbs;
