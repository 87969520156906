import styles from './destination-testimonial.module.scss';
import quoteIcon from '../../assets/icons/quote.svg';

/* eslint-disable-next-line */
export interface DestinationTestimonialProps {
  id: string;
  name: string;
  image: string;
  position: string;
  review: string;
}

export function DestinationTestimonial(
  props: Readonly<DestinationTestimonialProps>
) {
  const { name, image, position, review } = props;
  return (
    <div className={styles['destination-testimonial']}>
      <div className={styles['destination-testimonial__person']}>
        <div className={styles['destination-testimonial__person-image']}>
          <img src={image} alt={name} loading="lazy" />
        </div>
        <div className={styles['destination-testimonial__person-info']}>
          <p className={styles['destination-testimonial__person-name']}>
            {name}
          </p>
          <p className={styles['destination-testimonial__person-position']}>
            {position}
          </p>
        </div>
      </div>
      <div className={styles['destination-testimonial__review']}>
        <img
          src={quoteIcon}
          alt="quote-icon"
          className={styles['destination-testimonial__review-quote-icon']}
          loading="lazy"
        />
        <p>{review}</p>
      </div>
    </div>
  );
}

export default DestinationTestimonial;
