/* eslint-disable import/no-extraneous-dependencies */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import cashIcon from '../../assets/icons/cash.svg';
import callIcon from '../../assets/icons/call.svg';
import styles from './reservation-reserve-successful.module.scss';
import greenTick from '../../assets/icons/green-tick.svg';
import GallerySlider from '../common/gallery-slider/gallery-slider';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { RESERVATION_CONTEXT } from 'libs/common-components/src/contexts/reservation.context';
import { v4 as uuidv4 } from 'uuid';
import { USER_CONTEXT } from '../../contexts/user-context';

export function ReservationReserveSuccessful() {
  const { t } = useTranslation();
  const { reservationUnitDetails } = useContext(RESERVATION_CONTEXT);
  const { user } = useContext(USER_CONTEXT);

  return (
    <div className={styles['wrapper']}>
      <div className={styles['banner-wrapper']}>
        <div className={styles['banner']}>
          <h5 className={styles['title']}>
            <img src={greenTick} alt="" role="presentation" />

            {t('thanksForCompletingThePurchase')}
          </h5>

          <p className={styles['body']}>
            {t('congratulationsOnFinishingFirstStep')}{' '}
            <strong>{`${reservationUnitDetails?.unit_type.name} - ${reservationUnitDetails?.project.destination.name} - ${reservationUnitDetails?.project.name}`}</strong>{' '}
            {t('yourUnitNumber')}{' '}
            <strong>{reservationUnitDetails?.unit_number}</strong>
          </p>
          <p className={styles['body']}>
            {t('weHaveSentAnEmailReceiptTo')} <strong>{user?.email}</strong>{' '}
            {t('acknowledgingThePaymentForTheReservationFee')}
          </p>
        </div>

        {/* <div className={styles['banner-info']}>
          <p>{t('purchaseDetails')}</p>
          <dl>
            <dt>{t('paidReservationFee')}</dt>
            <dd>
              {sale?.reservation_amount.toLocaleString()}{' '}
              <span className="currency">{selectedPaymentTerms?.currency}</span>{' '}
              <img src={greenTick} alt="" role="presentation" />
            </dd>
            <dt>{t('downPaymentDue')}</dt>
            <dd>
              {' '}
              {selectedPaymentTerms?.downpayment_amount.toLocaleString()}{' '}
              <span className="currency">{selectedPaymentTerms?.currency}</span>{' '}
            </dd>
            <dt>{t('estimatedDeliveryTime')}</dt>
            <dd>Q3 2023</dd>
          </dl>
        </div> */}
        <div className={styles['banner-gallery']}>
          {reservationUnitDetails &&
            reservationUnitDetails?.gallery.length > 0 && (
              <GallerySlider pagination autoPlay>
                {reservationUnitDetails?.gallery.map((galleryImageUrl) => (
                  <img src={galleryImageUrl} alt="gallery" key={uuidv4()} />
                ))}
              </GallerySlider>
            )}
        </div>
      </div>
      <h5 className={styles['title']}>{t('whatNext')}</h5>
      <p>{t('pleaseReviewProcedure')}</p>
      <div className={styles['steps']}>
        <div className={styles['step']}>
          <img src={callIcon} alt="" role="presentation" />
          <h6>{t('receiveCallFromOurSalesTeam')}</h6>
          <p>{t('ourSalesWillGetInTouchSoon')}</p>
        </div>
        <div className={styles['step']}>
          <img src={cashIcon} alt="" role="presentation" />
          <h6>{t('payTheRestOfTheDownpayment')}</h6>
          <p>{t('thisReservationIsValidTwoWeeks')}</p>
        </div>
      </div>
      <h5 className={styles['title']}>{t('whenToExpectCall')}</h5>
      <p>
        <strong>{t('withinTwoDays')}</strong>
        {t('averagePeriod')}
      </p>
    </div>
  );
}

export default ReservationReserveSuccessful;
