import { UnitPaymentTerms } from '@orascom/api-interfaces';
import Numeral from 'numeral';
import { useContext } from 'react';
import { UserReservationSale } from '../../definitions/interfaces/common.interface';
import { CurrencyContext } from '@orascom/utils';

export const usePaymentSummary = (
  paymentTerms: UnitPaymentTerms,
  sale: UserReservationSale | undefined
) => {
  const { rates, currency, isLoading } = useContext(CurrencyContext);
  const originalCurrency = paymentTerms.currency;
  let rate = 1;

  if (currency !== originalCurrency && rates !== null) {
    rate = rates[`${currency}${originalCurrency}`];
  }

  const totalNumberOfInstallments = paymentTerms.total_number_of_installments;
  const installmentAmount = Numeral(
    (paymentTerms.installment_amount ?? 0) / rate
  ).format('0,0');
  const installmentsFrequency = paymentTerms.installment_frequency;

  const beforeDeliveryInstallmentsCount =
    paymentTerms.total_number_of_installments_before_delivery;
  const beforeDeliveryInstallmentAmount = Numeral(
    (paymentTerms.before_delivery_installment_amount ?? 0) / rate
  ).format('0,0');

  const afterDeliveryInstallmentsCount =
    paymentTerms.total_number_of_installments_after_delivery;
  const afterDeliveryInstallmentAmount = Numeral(
    (paymentTerms.after_delivery_installment_amount ?? 0) / rate
  ).format('0,0');
  const originalUnitPrice = Numeral(
    (paymentTerms.unit_price ?? 0) / rate
  ).format('0,0');
  const finalUnitPrice = Numeral(
    (paymentTerms.total_unit_price ?? 0) / rate
  ).format('0,0');
  const discountAmount = Numeral(
    (paymentTerms.discount_amount ?? 0) / rate
  ).format('0,0');
  const unitPriceAfterDiscount = Numeral(
    (paymentTerms.price_after_discount ?? 0) / rate
  ).format('0,0');
  const discountPercent = paymentTerms.discount_percent;
  const durationInYears = paymentTerms.installment_durationin_years;
  const clubHouseFees = Numeral(
    (paymentTerms.clubhouse_fees ?? 0) / rate
  ).format('0,0');
  const clubHouseInstallmentCount =
    paymentTerms.clubhouse_fees_installment_count;
  const clubHouseInstallmentAmount = Numeral(
    (paymentTerms.clubhouse_fees_installment_amount ?? 0) / rate
  ).format('0,0');
  const maintenanceFees = Numeral(
    (paymentTerms.maintenance_fees ?? 0) / rate
  ).format('0,0');
  const downPayment = Numeral(
    (paymentTerms.downpayment_amount ?? 0) / rate
  ).format('0,0');

  const deliveryPercent = paymentTerms.delivery_percent;

  const deliveryAmount = Numeral(
    (paymentTerms.delivery_amount ?? 0) / rate
  ).format('0,0');

  const reservationAmount = Numeral(
    (sale?.reservation_amount ?? 0) / rate
  ).format('0,0');
  
  return {
    totalNumberOfInstallments,
    installmentAmount,
    installmentsFrequency,
    beforeDeliveryInstallmentsCount,
    beforeDeliveryInstallmentAmount,
    afterDeliveryInstallmentsCount,
    afterDeliveryInstallmentAmount,
    originalUnitPrice,
    finalUnitPrice,
    discountAmount,
    unitPriceAfterDiscount,
    discountPercent,
    currency: isLoading ? originalCurrency : currency,
    durationInYears,
    clubHouseFees,
    clubHouseInstallmentCount,
    clubHouseInstallmentAmount,
    maintenanceFees,
    downPayment,
    reservationAmount,
    deliveryPercent,
    deliveryAmount,
  };
};
