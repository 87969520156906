/* eslint-disable @nx/enforce-module-boundaries */
/**
 * @author Salma Hefnawy
 * @date 2022-11-16
 * @description implementation of unit related API calls.
 * @filename unit.ts
 */
import { Network } from '../network';
import { PRE_DELIVERY_ENDPOINTS } from '../endpoints';

import {
  PaginationPayload,
  Payload,
  ResaleFormData,
  UnitAddOn,
} from '@orascom/api-interfaces';
import {
  OwnerCard,
  IssueType,
  UnitPaymentsDetails,
  PaymentPlan,
  CurrencyInterface,
} from '../../definitions/interfaces/pre-delivery.interface';
import { ChangeContactDetailsData } from '../../components/pre-delivery/change-contact-details-form/change-contact-details-form';
import { NewsListingInterface } from '../../definitions/interfaces/home.interface';
import { RequestAddonFormData } from '../../components/pre-delivery/request-addon-form/request-addon-form';
import {
  ServiceRequest,
  ServiceRequestFormData,
} from '../../definitions/interfaces/service-requests.interface';

/**
 * implement pre-delivery user related calls.
 */
export class PreDeliveryUser {
  /**
   * get all owner cards data from API.
   *
   * @static
   * @returns {Promise<<Payload<OwnerCard[]>>}
   * @memberof User
   */
  public static getOwnerCards(): Promise<Payload<OwnerCard[]>> {
    return Network.fetch(
      `${process.env.NX__PRE_DELIVERY_API_ENDPOINT}${PRE_DELIVERY_ENDPOINTS.ownerCards.path}`,
      {
        method: PRE_DELIVERY_ENDPOINTS.ownerCards.method,
      }
    );
  }

  /**
   * get all issue types from API.
   *
   * @static
   * @returns {Promise<<Payload<IssueType[]>>}
   * @memberof User
   */
  public static getIssueTypes(): Promise<Payload<IssueType[]>> {
    return Network.fetch(
      `${process.env.NX__PRE_DELIVERY_API_ENDPOINT}${PRE_DELIVERY_ENDPOINTS.getIssueTypes.path}`,
      {
        method: PRE_DELIVERY_ENDPOINTS.getIssueTypes.method,
      }
    );
  }

  /**
   * submit assistance request
   *
   * @static
   * @param {any} [formData]
   * @returns {Promise<<Payload<string>>}
   * @memberof User
   */
  public static submitAssistanceRequest(
    formData: any
  ): Promise<Payload<string>> {
    return Network.fetch(
      `${process.env.NX__PRE_DELIVERY_API_ENDPOINT}${PRE_DELIVERY_ENDPOINTS.submitAssistanceRequest.path}`,
      {
        method: PRE_DELIVERY_ENDPOINTS.submitAssistanceRequest.method,
        body: JSON.stringify(formData),
      }
    );
  }

  /**
   * get all unit payment details data from API.
   *
   * @static
   * @param {string} unitId
   * @returns {Promise<Payload<UnitDetails>>}
   * @memberof User
   */
  public static getUnitPayments(
    unitId: string
  ): Promise<Payload<UnitPaymentsDetails>> {
    return Network.fetch(
      `${
        process.env.NX__PRE_DELIVERY_API_ENDPOINT
      }${PRE_DELIVERY_ENDPOINTS.getUnitPayments.url(unitId)}`,
      {
        method: PRE_DELIVERY_ENDPOINTS.getUnitPayments.method,
      }
    );
  }

  /**
   * submit service request
   *
   * @static
   * @param {any} [formData]
   * @returns {Promise<<Payload<string>>}
   * @memberof User
   */
  public static submitServiceRequest(
    unitId: string,
    formData: ServiceRequestFormData
  ): Promise<Payload<string>> {
    return Network.fetch(
      `${
        process.env.NX__PRE_DELIVERY_API_ENDPOINT
      }${PRE_DELIVERY_ENDPOINTS.submitServiceRequest.url(unitId)}`,
      {
        method: PRE_DELIVERY_ENDPOINTS.submitServiceRequest.method,
        body: JSON.stringify(formData),
      }
    );
  }

  /**
   * submit resale request
   *
   * @static
   * @param {ResaleFormData} [formData]
   * @returns {Promise<<Payload<string>>}
   * @memberof User
   */
  public static submitResaleRequest(
    unitId: string,
    formData: ResaleFormData
  ): Promise<Payload<string>> {
    return Network.fetch(
      `${
        process.env.NX__PRE_DELIVERY_API_ENDPOINT
      }${PRE_DELIVERY_ENDPOINTS.submitResaleRequest.url(unitId)}`,
      {
        method: PRE_DELIVERY_ENDPOINTS.submitResaleRequest.method,
        body: JSON.stringify(formData),
      }
    );
  }

  /**
   * get all unit payment breakdown data from API.
   *
   * @static
   * @param {string} unitId
   * @returns {Promise<Payload<PaginationPayload<PaymentPlan[]>>>}
   * @memberof Unit
   */
  public static getUnitPaymentBreakdown(
    unitId: string
  ): Promise<Payload<PaginationPayload<PaymentPlan[]>>> {
    return Network.fetch(
      `${
        process.env.NX__PRE_DELIVERY_API_ENDPOINT
      }${PRE_DELIVERY_ENDPOINTS.getUnitPaymentBreakdown.url(unitId)}`,
      {
        method: PRE_DELIVERY_ENDPOINTS.getUnitPaymentBreakdown.method,
      }
    );
  }

  /**
   * get list of pre-delivery service requests
   *
   * @static
   * @param {number} page
   * @param {number} perPage
   * @returns {Promise<Payload<PaginationPayload<ServiceRequest[]>>>}
   *
   * @memberOf Offer
   */
  public static getServiceRequests(
    countryId: number,
    customerSourceId: string,
    unitId: string
  ): Promise<Payload<ServiceRequest[]>> {
    const url = new URL(
      `${process.env.NX__PRE_DELIVERY_API_ENDPOINT}${PRE_DELIVERY_ENDPOINTS.getServiceRequests.path}`
    );
    url.searchParams.append('country_id', String(countryId));
    url.searchParams.append('customer_source_id', String(customerSourceId));
    url.searchParams.append('unit_id', String(unitId));

    return Network.fetch(url.href, {
      method: PRE_DELIVERY_ENDPOINTS.getServiceRequests.method,
    });
  }

  /**
   * change ContactDetails Request
   *
   * @static
   * @param {any} [formData]
   * @returns {Promise<<Payload<string>>}
   * @memberof User
   */
  public static changeContactDetailsRequest(
    formData: ChangeContactDetailsData
  ): Promise<Payload<string>> {
    return Network.fetch(
      `${process.env.NX__PRE_DELIVERY_API_ENDPOINT}${PRE_DELIVERY_ENDPOINTS.changeContactDetailsRequest.path}`,
      {
        method: PRE_DELIVERY_ENDPOINTS.changeContactDetailsRequest.method,
        body: JSON.stringify(formData),
      }
    );
  }

  /**
   * get user latest news.
   *
   * @static
   * @param {string} [page=1]
   * @param {string} [per_page=10]
   * @param {boolean} [is_event=false]
   * @param {boolean} [is_featured=false]
   * @returns {Promise<Payload<PaginationPayload<NewsListingInterface[]>>>}
   * @memberof Home
   */
  public static getNews(
    page: string,
    per_page: string,
    is_event: boolean,
    is_featured: boolean
  ): Promise<Payload<PaginationPayload<NewsListingInterface[]>>> {
    const url = new URL(
      `${process.env.NX__PRE_DELIVERY_API_ENDPOINT}${PRE_DELIVERY_ENDPOINTS.getNews.path}`
    );
    url.searchParams.append('page', page);
    url.searchParams.append('per_page', per_page);
    url.searchParams.append('is_event', `${is_event ? 1 : 0}`);
    url.searchParams.append('is_featured', `${is_featured ? 1 : 0}`);

    return Network.fetch(url.toString(), {
      method: PRE_DELIVERY_ENDPOINTS.getNews.method,
    });
  }

  /**
   * get all addon data from API.
   *
   * @static
   * @param {string} addonId
   * @returns {Promise<<Payload<UnitAddOn>>}
   * @memberof User
   */
  public static getAddon(addonId: string): Promise<Payload<UnitAddOn>> {
    return Network.fetch(
      `${
        process.env.NX__PRE_DELIVERY_API_ENDPOINT
      }${PRE_DELIVERY_ENDPOINTS.getAddon.url(addonId)}`,
      {
        method: PRE_DELIVERY_ENDPOINTS.getAddon.method,
      }
    );
  }

  /**
   * get all unit addons data from API.
   *
   * @static
   * @param {string} addonId
   * @returns {Promise<<Payload<UnitAddOn>>}
   * @memberof User
   */
  public static getUnitAddons(unitId: string): Promise<Payload<UnitAddOn[]>> {
    return Network.fetch(
      `${
        process.env.NX__PRE_DELIVERY_API_ENDPOINT
      }${PRE_DELIVERY_ENDPOINTS.getUnitAddons.url(unitId)}`,
      {
        method: PRE_DELIVERY_ENDPOINTS.getUnitAddons.method,
      }
    );
  }

  /**
   * submit addon request
   *
   * @static
   * @param {any} [formData]
   * @returns {Promise<<Payload<string>>}
   * @memberof User
   */
  public static submitAddonRequest(
    unitId: string,
    formData: RequestAddonFormData
  ): Promise<Payload<string>> {
    return Network.fetch(
      `${
        process.env.NX__PRE_DELIVERY_API_ENDPOINT
      }${PRE_DELIVERY_ENDPOINTS.submitAddonRequest.url(unitId)}`,
      {
        method: PRE_DELIVERY_ENDPOINTS.submitAddonRequest.method,
        body: JSON.stringify(formData),
      }
    );
  }

  /**
   * get all currencues data from API.
   *
   * @static
   * @returns {Promise<Payload<CurrencyInterface[]>>}
   * @memberof Unit
   */
  public static getCurrencies(
    countryId: string
  ): Promise<Payload<CurrencyInterface[]>> {
    const url = new URL(
      `${process.env.NX__PRE_DELIVERY_API_ENDPOINT}${PRE_DELIVERY_ENDPOINTS.getCurrencies.path}`
    );

    url.searchParams.append('country_id', countryId);

    return Network.fetch(url.href, {
      method: PRE_DELIVERY_ENDPOINTS.getCurrencies.method,
    });
  }
}
