/* eslint-disable @nx/enforce-module-boundaries */
import styles from './account-settings.module.scss';
import { useTranslation } from 'react-i18next';
import {
  useContext,
  // useState
} from 'react';
import { USER_CONTEXT } from '../../../contexts/user-context';
import {
  Loader,
  // NavyButton
} from '@orascom/common-components';
// import Modal from 'libs/common-components/src/lib/modal/modal';
// import ChangeContactDetailsForm from '../../../components/pre-delivery/change-contact-details-form/change-contact-details-form';
// import { ServiceRequestTypeEnum } from 'apps/orascom-shopper-app/src/definitions/interfaces/service-requests.interface';

export function AccountSettings() {
  const { t } = useTranslation();
  // const [showContactModal, setShowContactModal] = useState(false);
  const userContext = useContext(USER_CONTEXT);

  // const handleShowContactModal = () => {
  //   setShowContactModal(true);
  //   document.body.style.overflow = 'hidden';
  // };
  // const handleCloseContactModal = () => {
  //   setShowContactModal(false);
  //   document.body.style.overflow = '';
  // };
  if (!userContext?.user) {
    return <Loader />;
  }

  return (
    <div className={`${styles['settings']} pre-delivery-container`}>
      <h1 className="orascom__sub-title ">{t('accountSettings')}</h1>
      <div className={styles['info']}>
        <div>
          <label>{t('fullName')}</label>
          <p className="name">{userContext.user.name}</p>
        </div>
        <div>
          <label>{t('email')}</label>
          <p>{userContext.user.email}</p>
        </div>
        <div>
          <label>{t('phoneNumber')}</label>
          <p>{userContext.user.phone}</p>
        </div>
      </div>
      {/* <div className={styles['note']}>
        <p>{t('accountSettingsNote')}</p>
      </div>
      <NavyButton onClick={() => handleShowContactModal()}>
        <div>{t('changeAccountInfo')}</div>
      </NavyButton>
      <Modal
        show={showContactModal}
        onClose={handleCloseContactModal}
        title={t('changeContactDetailsRequest')}
      >
        <ChangeContactDetailsForm
          unitId={14025}
          serviceType={ServiceRequestTypeEnum.CHANGE_CONTACT_DETAILS}
          customerId="6fdaa699-7581-ee11-8179-000d3aad37a1"
          onClose={handleCloseContactModal}
        />
      </Modal> */}
    </div>
  );
}

export default AccountSettings;
