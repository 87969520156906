/**
 * @author Ahmed Serag
 * @date 2022-12-20
 * @description implementation of Authentication related API calls.
 * @filename authentication.ts
 */
import { Network } from './network';
import { Payload } from '@orascom/api-interfaces';
import { RegistrationInput } from '../definitions/interfaces/authentication.interface';
import { ENDPOINTS } from './endpoints';
import { UserReservationDetailsInterface } from '../definitions/interfaces/common.interface';

/**
 * implement authentication related calls.
 */
export class Authentication {
  /**
   * Get user Token from the Backend to authenticate the API calls with.
   *
   * @static
   * @param {string} phone User Phone number.
   * @param {string} firebaseToken User's Firebase access token.
   * @return {*}  {Promise<Payload<unknown>>} Promise to return user details.
   * @memberof Authentication
   */
  public static login(
    phone: string,
    firebaseToken: string
  ): Promise<Payload<unknown>> {
    let promise = Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.login.path}`,
      {
        method: ENDPOINTS.login.method,
        body: JSON.stringify({
          phone,
          token: firebaseToken,
        }),
      }
    );

    promise = promise.then((payload) => {
      if (payload?.data?.token) {
        localStorage.setItem(
          process.env['NX_SHOPPER_ACCESS_TOKEN_KEY'] ?? '',
          payload.data.token
        );
      }

      return payload;
    });

    return promise;
  }

  /**
   * Register a user in the backend and get user Token from the Backend
   * to authenticate the API calls with.
   *
   * @static
   * @param {RegistrationInput} input
   * @return {*}  {Promise<Payload<unknown>>}
   */
  public static register(input: RegistrationInput): Promise<Payload<unknown>> {
    let promise = Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.register.path}`,
      {
        method: ENDPOINTS.register.method,
        body: JSON.stringify(input),
      }
    );

    promise = promise.then((payload) => {
      if (payload?.data?.token) {
        localStorage.setItem(
          process.env['NX_SHOPPER_ACCESS_TOKEN_KEY'] ?? '',
          payload.data.token
        );
      }

      return payload;
    });

    return promise;
  }

  public static logout(): Promise<unknown> {
    localStorage.removeItem(
      process.env['NX_SHOPPER_ACCESS_TOKEN_KEY'] as string
    );
    return Promise.resolve();
  }

  /**
   * Check if either email or phone are already used in the backend
   *
   * @static
   * @param {string} phone
   * @param {string} email
   * @return {*}  {Promise<Payload<unknown>>}
   */
  public static checkUserDetails(
    phone: string,
    email?: string
  ): Promise<Payload<unknown>> {
    const url = new URL(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.checkUserDetails.path}`
    );
    url.searchParams.append('phone', String(phone));
    if (email) {
      url.searchParams.append('email', String(email));
    }

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.checkUserDetails.method,
    });
  }

  public static getCurrentUser(): Promise<Payload<unknown>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getCurrentUser.path}`,
      {
        method: ENDPOINTS.getCurrentUser.method,
      }
    );
  }

  public static getUserReservationDetails(): Promise<
    Payload<UserReservationDetailsInterface>
  > {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getUserReservationDetails.path}`,
      {
        method: ENDPOINTS.getUserReservationDetails.method,
      }
    );
  }
}
