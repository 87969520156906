import { Link, useParams } from 'react-router-dom';
import styles from './news-and-events-details.module.scss';
import { NewsDetailsInterface } from '../../definitions/interfaces/home.interface';
import { Home as HomeUtilities } from '../../utils/home.utils';
import { useEffect, useState } from 'react';
import { Loader } from '@orascom/common-components';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { ROUTES } from '../../api/routes';
import { Helmet } from 'react-helmet-async';
import getDocTitle from '../../utils/getDocTitle';

export function NewsAndEventsDetails() {
  const { t } = useTranslation();
  const { newsSlug } = useParams();
  const [news, setNews] = useState<NewsDetailsInterface>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (newsSlug) {
      HomeUtilities.getNewsDetails(newsSlug)
        .then((res) => {
          setNews(res);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [newsSlug]);

  if (loading) {
    return <Loader />;
  }
  const eventDocTitle = getDocTitle(news?.title ?? 'News & Events');
  return (
    <div className={styles['wrapper']}>
      <Helmet>
        <title>{eventDocTitle}</title>
        <meta property="og:title" content={eventDocTitle} />
        <meta name="description" content={news?.short_description} />
        <meta property="og:description" content={news?.short_description} />
      </Helmet>
      <div className={styles['header']}>
        <div>
          <p className="orascom__title">{t('news')}</p>
          <p className="orascom__sub-title">{news?.title}</p>
        </div>
        <div className={styles['breadcrumbs']}>
          <Link to={ROUTES.NewsEvents.path}>{t('newsAndEvents')}</Link>
          <span>/</span>
          <span>{t('newsArticle')}</span>
        </div>
      </div>

      <img
        src={news?.image}
        alt={news?.title}
        className={styles['image']}
        loading="lazy"
      />
      <div className="container">{parse(news?.content as string)}</div>
    </div>
  );
}

export default NewsAndEventsDetails;
