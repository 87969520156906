/* eslint-disable @nx/enforce-module-boundaries */
import styles from './serivices-requests.module.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@orascom/common-components';
import { UnitDetails } from '@orascom/api-interfaces';
import {
  ServiceRequest,
  ServiceRequestStatusEnum,
} from '../../../definitions/interfaces/service-requests.interface';
import { PreDeliveryUser as PreDeliveryUserUtils } from '../../../utils/pre-delivery/user.utils';

/* eslint-disable-next-line */
export interface ServicesRequestsProps {
  selectedUnit?: UnitDetails;
  customerId: string;
}

export function ServicesRequests(props: Readonly<ServicesRequestsProps>) {
  const { t } = useTranslation();
  const [loadingServices, setLoadingServices] = useState(false);
  const [serviceRequests, setServiceRequests] = useState<ServiceRequest[]>([]);

  useEffect(() => {
    setLoadingServices(true);

    if (props.customerId && props.selectedUnit) {
      PreDeliveryUserUtils.getServiceRequests(
        props.selectedUnit.project.destination.country.id,
        props.customerId,
        props.selectedUnit.id.toString()
      )
        .then((res) => {
          setServiceRequests(res);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoadingServices(false);
        });
    }
  }, [props.customerId, props.selectedUnit]);

  const serviceRequestStatus = (
    serviceRequestStatusState: ServiceRequestStatusEnum
  ) => {
    const serviceRequestNotOnGoing =
      serviceRequestStatusState === ServiceRequestStatusEnum.CANCELLED ? (
        <p>
          <span className={`${styles['status']} ${styles['folded']}`}></span>
          {''}
          Folded
        </p>
      ) : (
        <p>
          <span
            className={`${styles['status']} ${styles['closed-deal']}`}
          ></span>
          {''}
          Done
        </p>
      );

    return serviceRequestStatusState === ServiceRequestStatusEnum.IN_PROGRESS ||
      serviceRequestStatusState === ServiceRequestStatusEnum.ON_HOLD ||
      serviceRequestStatusState ===
        ServiceRequestStatusEnum.WAITING_FOR_DETAILS ||
      serviceRequestStatusState === ServiceRequestStatusEnum.RESEARCHING ||
      serviceRequestStatusState === ServiceRequestStatusEnum.PENDING ? (
      <p>
        <span className={`${styles['status']} ${styles['ongoing']}`}></span>
        {''}
        Ongoing
      </p>
    ) : (
      serviceRequestNotOnGoing
    );
  };

  if (loadingServices) {
    return <Loader />;
  }
  return (
    <div className={styles['services-requests']}>
      <div className={styles['details']}>
        {serviceRequests.length ? (
          serviceRequests.map((serviceRequest) => (
            <div className={styles['details-item']} key={serviceRequest.id}>
              <h1 className="orascom__title">
                {props.selectedUnit?.project.name} -{' '}
                {props.selectedUnit?.project.destination.name}
              </h1>
              <h2 className="orascom__sub-title">
                {props.selectedUnit?.unit_type.name}
              </h2>
              <div className={styles['details-item-info']}>
                <div>
                  <label>{t('Service Requested')}</label>
                  <p>{serviceRequest.category.name}</p>
                </div>
                <div>
                  <label>{t('requestSubmissionDate')}</label>
                  <p>
                    {new Date(
                      serviceRequest.request_created_at * 1000
                    ).toLocaleDateString('en-GB', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </p>
                </div>
                <div>
                  <label>{t('status')}</label>

                  {serviceRequestStatus(serviceRequest.status)}
                </div>
              </div>
            </div>
          ))
        ) : (
          <h1 className="orascom__title">no requests found for this unit</h1>
        )}
      </div>
    </div>
  );
}

export default ServicesRequests;
