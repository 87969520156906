import { Contact as ContactAPI } from '../api/contact.api';
import { getPayloadData, handleError } from '@orascom/utils';
import { CountryOffice } from '../components/common/country-office-card/country-office-card';
import {
  ContactUsFormData,
  SubmitLeadData,
} from '../definitions/interfaces/contact.interface';
import { Payload } from '@orascom/api-interfaces';

/**
 * group of contact helpers functionalities.
 */
export class Contact {
  /**
   * submit contact us form
   *
   * @static
   * @param {ContactUsFormData} [formData]
   * @returns {Promise<string>}
   *
   * @memberOf Filter
   */
  public static submitContactForm(
    formData: ContactUsFormData
  ): Promise<string> {
    return ContactAPI.submitContactForm(formData)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   * get sales contacts
   *
   * @static
   * @returns {Promise<CountryOffice[]>}
   *
   * @memberOf Filter
   */
  public static getSalesContacts(): Promise<CountryOffice[]> {
    return ContactAPI.getSalesContacts()
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   * submit contact us form
   *
   * @static
   * @param {ContactUsFormData} [formData]
   * @returns {Promise<string>}
   *
   * @memberOf Filter
   */
  public static submitReferralForm(
    formData: ContactUsFormData
  ): Promise<string> {
    return ContactAPI.submitReferralForm(formData)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   * submit contact us form
   *
   * @static
   * @param {SubmitLeadData} [formData]
   * @returns {Promise<string>}
   *
   * @memberOf Contact
   */
  public static submitShopperLead(formData: SubmitLeadData): Promise<
    Payload<{
      lead_source_id: string;
      customer_source_id: string;
    }>
  > {
    return ContactAPI.submitShopperLead(formData)
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
