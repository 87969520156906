import { useEffect, useRef, useState } from 'react';
import { PriceSlider, PriceValue } from '@orascom/api-interfaces';
import { PriceRangeSlider } from '@orascom/common-components';
import './min-max-dropdown.scss';

interface MinMaxDropdownProps {
  priceSlider: PriceSlider;
  setPriceValue: (arg: PriceValue) => void;
  disabled?: boolean;
  className?: string;
  keyValue?: number | string;
}

export function MinMaxDropdown(props: Readonly<MinMaxDropdownProps>) {
  const [isOpen, setIsOpen] = useState(false);
  const { priceSlider, setPriceValue, disabled, className, keyValue } = props;

  const dropdownRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const closeDropdown = (event: MouseEvent) => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', closeDropdown);
    return () => {
      document.removeEventListener('mousedown', closeDropdown);
    };
  }, [dropdownRef, isOpen]);

  const toggleIsOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div key={keyValue} className={`${className}`}>
      <button className="minmax" onClick={toggleIsOpen} ref={dropdownRef}>
        <span className="minmax__label">Price range</span>
        <p>
          <i className="minmax__arrow"></i>
        </p>
        {isOpen && (
          <button
            className="minmax__dropdown"
            onClick={(e) => e.stopPropagation()}
          >
            <PriceRangeSlider
              disabled={disabled}
              priceSlider={priceSlider}
              onChange={setPriceValue}
            />
          </button>
        )}
      </button>
    </div>
  );
}
