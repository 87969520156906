/* eslint-disable @nx/enforce-module-boundaries */
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './referrals.module.scss';
import { GoldButton, NavyButton } from '@orascom/common-components';
import referralsWelcomeImage from '../../../assets/images/refferal-one.webp';
import referralsFormImage from '../../../assets/images/referral-form.webp';
import referralsPerksImage from '../../../assets/images/referal-perks.webp';
import CommunityIcon from '../../../assets/icons/community.svg?react';
import CashIcon from '../../../assets/icons/cash.svg?react';
import RewardsIcon from '../../../assets/icons/rewards.svg?react';
import { ROUTES } from '../../../api/routes';
import ContactUsForm from '../../../components/common/contact-us-form/contact-us-form';

export function Referrals() {
  const { t } = useTranslation();
  const referForm = useRef<null | HTMLDivElement>(null);

  const executeScroll = () => {
    referForm.current?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className={`${styles['referrals']} pre-delivery-container`}>
      <div className={styles['welcome']}>
        <div className={styles['welcome-content']}>
          <h1 className="orascom__sub-title">{t('referralTitle')}</h1>
          <p>{t('referralDesc')}</p>
          <div className={styles['welcome-content-buttons']}>
            <GoldButton onClick={executeScroll}>{t('referAFriend')}</GoldButton>
            {/* Todo: these will redirect to my referral page after implementing it */}
            <NavyButton asLink to={ROUTES.Pre_Delivery_My_Referrals.path}>
              {t('myReferrals')}
            </NavyButton>
          </div>
        </div>
        <div className={styles['welcome-image-container']}>
          <h1
            className={`${styles['welcome-title--responsive']} orascom__sub-title`}
          >
            {t('referralTitle')}
          </h1>
          <div className={styles['welcome-image']}>
            <img
              src={referralsWelcomeImage}
              alt="referrals-welcome"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className={styles['steps']}>
        <h3 className="orascom__title">{t('referralProcess')}</h3>
        <h2 className="orascom__sub-title">{t('referralStepsTitle')}</h2>
        <div className={styles['steps-cards']}>
          <div className={styles['steps-card']}>
            <div>
              <CommunityIcon />
              <h4>{t('shareReferral')}</h4>
            </div>
            <p>{t('shareReferralDesc')}</p>
          </div>
          <div className={styles['steps-card']}>
            <div>
              <CashIcon />
              <h4>{t('awaitPurchase')}</h4>
            </div>
            <p>{t('awaitPurchaseDesc')}</p>
          </div>
          <div className={styles['steps-card']}>
            <div>
              <RewardsIcon />
              <h4>{t('getRewarded')}</h4>
            </div>
            <p>{t('getRewardedDesc')}</p>
          </div>
        </div>
      </div>

      <div className={styles['perks']}>
        <div className={styles['perks-background']} />
        <h1 className="orascom__title">{t('reduceInstallmentFees')}</h1>
        <h2 className="orascom__sub-title">{t('minimizeInstallments')}</h2>
        <div className={styles['perks-details']}>
          <div className={styles['perks-details-image']}>
            <img
              src={referralsPerksImage}
              alt="referral-perks"
              loading="lazy"
            />
          </div>
          <p>{t('referralPerksDesc')}</p>
        </div>
      </div>

      <div className={styles['form']} ref={referForm}>
        <div className={styles['form-content']}>
          <h3 className="orascom__title">{t('submitReferralRequest')}</h3>
          <h4 className="orascom__sub-title">{t('referYourFriend')}</h4>
          <ContactUsForm title={t('referralFormDesc')} referralForm />
        </div>
        <div className={styles['form-image']}>
          <img src={referralsFormImage} alt="" loading="lazy" />
        </div>
      </div>
    </div>
  );
}

export default Referrals;
