import styles from './interactive-masterplan.module.scss';

/* eslint-disable-next-line */
export interface InteractiveMasterplanProps {}

export function InteractiveMasterplan(props: InteractiveMasterplanProps) {
  return (
    <div className={styles['wrapper']}>
      <iframe
        src="https://navigator5.beyonity.com/?id=80552DC7&language=en"
        title="Virtual Navigator Iframe"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
}

export default InteractiveMasterplan;
