import queryString from 'query-string';
import { UserReservationSale } from '../definitions/interfaces/common.interface';

export function getPaymentDetailsFromPaymobParams(searchParams: string): {
  success: string | undefined;
  amount: string | undefined;
  currency: string | undefined;
  saleID: string | undefined;
} {
  const parsedFeaturedSearchParams = queryString.parse(searchParams);
  const merchangOrderId = parsedFeaturedSearchParams.merchant_order_id;

  return {
    success: parsedFeaturedSearchParams.success as string,
    amount: parsedFeaturedSearchParams.amount_cents as string,
    currency: parsedFeaturedSearchParams.currency as string,
    saleID: (merchangOrderId as string)?.split('_')[0],
  };
}

export function unitIdFromUserSales(
  sales: UserReservationSale[],
  saleID: number | undefined
): number | undefined {
  if (!saleID) return undefined;

  const sale = sales.find((s) => s.id === saleID);
  return sale?.unit.id;
}
