import { Trans, useTranslation } from 'react-i18next';
import OtpInput from 'react18-input-otp';
import { useState } from 'react';
import styles from './phone-verification.module.scss';
import ErrorIcon from '../../assets/icons/error.svg';
import SuccessIcon from '../../assets/icons/success.svg';
import { SignUpFormInterface } from '../../definitions/interfaces/authentication.interface';

export interface PhoneVerificationProps {
  values: SignUpFormInterface | undefined;
  onChangeNumber: () => void;
  onSubmit: (otp: string) => Promise<unknown>;
  login?: boolean;
  onResendCode: () => void;
}

export function PhoneVerification(props: Readonly<PhoneVerificationProps>) {
  const { t } = useTranslation();
  const [OTP, setOTP] = useState('');
  const [wrongOTP, setWrongOTP] = useState(false);
  const [isValidatingOTP, setIsValidatingOTP] = useState(false);
  const [validOTP, setValidOTP] = useState(false);

  const inValidOTP = props.login
    ? t('insertVerificationCode')
    : t('verifyPhoneNumber');

  const OTPValidationResult = validOTP ? t('phoneNumberVerified') : inValidOTP;

  function handleChange(updatedOTP: string) {
    setOTP(updatedOTP);
    if (updatedOTP.length === 6) {
      setIsValidatingOTP(true);
      props
        .onSubmit(updatedOTP)
        .then(() => {
          setValidOTP(true);
        })
        .catch(() => {
          setIsValidatingOTP(false);
          setWrongOTP(true);
        });
    }
  }
  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>
        {OTPValidationResult}
        {validOTP && (
          <img
            src={SuccessIcon}
            role="presentation"
            alt=""
            className={styles['success-icon']}
          />
        )}
      </h1>
      <p className={styles['subtitle']}>
        {validOTP ? (
          <Trans i18nKey="phoneVerifiedSuccessfully" />
        ) : (
          t('OTPSentToPhoneNumber', {
            number: `+${props.values?.code}${props.values?.phone}`,
          })
        )}
      </p>
      {!validOTP && (
        <p className={styles['change-number']}>
          <span>{t('notYourPhoneNumber')}</span>
          <button type="button" onClick={props.onChangeNumber}>
            {t('changeIt')}
          </button>
        </p>
      )}

      <p className={styles['otp-label']}>{t('verificationCode')}</p>
      <OtpInput
        value={OTP}
        onChange={handleChange}
        numInputs={6}
        containerStyle={styles['otp-container']}
        inputStyle={
          validOTP
            ? `${styles['otp-input']} ${styles['otp-success']}`
            : styles['otp-input']
        }
        errorStyle={styles['otp-error']}
        hasErrored={wrongOTP && !isValidatingOTP}
        isDisabled={isValidatingOTP}
        shouldAutoFocus
        isInputNum
        autoComplete="one-time-code"
      />
      {wrongOTP && !isValidatingOTP && (
        <p className="error">
          <img src={ErrorIcon} role="presentation" alt="" />
          {t('invalidOTP')}
        </p>
      )}

      {!validOTP && (
        <p className={styles['resend-code']}>
          <span>{t('didNotReceiveCode')}</span>
          <button type="button" onClick={() => props.onResendCode()}>
            {t('resendCode')}
          </button>
        </p>
      )}
    </div>
  );
}

export default PhoneVerification;
