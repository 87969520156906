import { UnitPaymentTerms } from '@orascom/api-interfaces';

export const extractUniqueDurations = (
  paymentPlans: UnitPaymentTerms[]
): number[] => {
  const installmentDurations = paymentPlans.map(
    (plan) => plan.installment_durationin_years
  );

  // Remove duplicate values
  const uniqueDurations = [...new Set(installmentDurations)];

  // Sort in ascending order
  uniqueDurations.sort((a, b) => a - b);

  return uniqueDurations;
};
