import { createContext, Context } from 'react';
import {
  ReferralDetails,
  ReservationStateEnum,
  UnitPaymentTerms,
  UnitReservationDetails,
  UserReservationIds,
  UserReservationSale,
} from '@orascom/api-interfaces';

interface ReservationContextInterface {
  /**
   * state for the unit reservation form
   *
   * @type {ReservationStateEnum}
   * @memberOf UnitsCompareContextInterface
   */
  reservationState?: ReservationStateEnum;

  // selectedPaymentPlan?: PaymentPlanInterface;
  selectedPaymentTerms?: UnitPaymentTerms;

  sale?: UserReservationSale;

  reservationUnitDetails?: UnitReservationDetails;

  reservationIds?: UserReservationIds;

  referral?: ReferralDetails;

  setReferral?: (arg: ReferralDetails) => void;

  // setSelectedPaymentPlan?: (arg: PaymentPlanInterface) => void;
  setSelectedPaymentTerms?: (arg: UnitPaymentTerms) => void;

  setReservationState?: (arg: ReservationStateEnum) => void;

  setReservationUnitDetails?: (arg: UnitReservationDetails) => void;

  setReservationIds?: (arg: UserReservationIds) => void;

  setSale?: (arg: UserReservationSale) => void;
}

export const RESERVATION_CONTEXT: Context<ReservationContextInterface> =
  createContext({});
