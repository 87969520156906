/* eslint-disable @nx/enforce-module-boundaries */
import { Common, FilterValue } from '@orascom/api-interfaces';

export interface UnitCountry extends FilterValue {
  slug: string;
}
export interface UnitDestination extends FilterValue {
  slug: string;
  brochure: string;
  country: UnitCountry;
}

export interface UnitProject extends FilterValue {
  slug: string;
  destination: UnitDestination;
}

export interface UnitAccessCard extends Common {
  image: string;
}
export interface UnitConstructionUpdate extends Common {
  title: string;
  image: string;
  date: number;
}

export interface UnitPayment {
  next_installment: number;
  next_installment_due: number;
  receipt: string;
  contract: string;
  purchase_status: string;
  payment_progress: PaymentProgress;
}


export interface OwnerCard {
  image: string;
  destination_name: string;
  project_name: string;
  id: number;
}

export interface IssueType {
  id: number;
  name: string;
}

export interface InstallmentProgress {
  total_installments: number;
  paid_installments: number;
}
export interface PaymentProgress {
  down_payment_paid: number;
  total_installments_paid: number;
  total_unpaid: number;
}
export interface PaymentPlan {
  number: number;
  due_date: number;
  installment: number;
  rates_percent: number;
  clubhouse_fees: number;
  maintenance: number;
  total: number;
}
export interface UnitPaymentsDetails {
  next_installment_amount: number;
  next_installment_due: number;
  installment_progress: InstallmentProgress;
  unit_image: string;
  project_name: string;
  destination_name: string;
  unit_type: string;
  unit_price: number;
  unit_number: string;
  maintenance_fees: number;
  clubhouse_fees: number;
  installments_duration: string;
  purchase_date: number;
  delivery_year: number;
  payment_progress: PaymentProgress;
}
export interface OfferInterface extends Common {
  cover_image: string;
  title: string;
  description: string;
}

export interface ReferralInterface {
  name: string;
  phone_number: string;
  request_submission_date: number;
  status: string;
}

export enum ContactTypeEnum {
  VIRTUAL_MEETING = '1',
  MOBILE = '2',
  EMAIL = '3',
  WHATS_APP = '4',
}

export interface CurrencyInterface {
  id: number;
  country: UnitCountry;
  source_id: string;
  code: string;
  name: string;
  rate: string;
}
