/* eslint-disable @nx/enforce-module-boundaries */
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import styles from './my-units.module.scss';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Loader } from '@orascom/common-components';
import MyUnitCard from '../../../components/pre-delivery/my-unit-card/my-unit-card';

import { useGetMyUnits } from '../hooks/use-get-my-units';

export function MyUnits() {
  const { t } = useTranslation();

  const [myUnits, loadingMyUnits] = useGetMyUnits();

  if (loadingMyUnits) {
    return <Loader />;
  }
  if (!myUnits.length) {
    return (
      <div className={`${styles['wrapper']} pre-delivery-container`}>
        <h1 className="orascom__sub-title">No units found</h1>
      </div>
    );
  }

  return (
    <div className={`${styles['wrapper']} pre-delivery-container`}>
      <h1> {t('myUnits')}</h1>

      {myUnits.map((unit) => (
        <MyUnitCard key={uuidv4()} unitDetails={unit} unitPage="my-units" />
      ))}
    </div>
  );
}

export default MyUnits;
