import { FilterValue, User, UnitDetails } from '@orascom/api-interfaces';

export interface SectionTag {
  name: string;
  id: number;
}

export interface FilterValues {
  destinations: FilterValue[];
  unit_types: FilterValue[];
}

export enum UnitsEnum {
  ACRE = 'acre',
  METER_SQUARE = 'msq',
  METER = 'meter',
  PERCENT = 'percent',
  PLUS = 'plus',
  SECONDS = 'second',
  MINUTES = 'minute',
  HOURS = 'hours',
}
export interface UserReservationSale {
  crm_status?: string | null; //TODO: Use Sale Status ENUM
  customer_id: string;
  destination: {
    brochure?: string;
    id: number;
    name: string;
    slug: string;
    welcome_description?: string;
  };
  id: number;
  payment_method?: string;
  paymentplan_source_id: string;
  portal_sale: boolean;
  reservation_amount: number;
  sale_id: string;
  sale_source_id: string;
  status: string;
  unit: UnitDetails;
  unit_id: number;
}

export interface UserReservationDestination {
  id: number;
  name: string;
  slug: string;
  welcome_description?: string;
  brochure: string;
}

export interface UserReservationLead {
  lead_id: string;
  lead_name: string;
  country?: string;
  destination_id?: string;
  destination?: UserReservationDestination;
}

export interface UserReservationCustomer {
  customer_id: string;
  customer_name: string;
  country?: string;
  destination_id?: string;
  destination?: UserReservationDestination;
}
export interface UserReservationDetailsInterface {
  leads: UserReservationLead[];
  customers: UserReservationCustomer[];
  sales: UserReservationSale[];
}

export interface DraftSaleResponse {
  id: number;
  sale_source_id: string;
  status: string;
  broker?: Record<string, unknown>;
  user: User;
  unit: UnitDetails;
  customer_source_id: string;
  lead_source_id?: string;
  paymentplan_source_id: string;
  referral_number?: string;
  referral_unit_name?: string;
  reservation_amount: number;
}
