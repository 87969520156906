import { FunctionComponent } from 'react';

export interface ConditionalWrapperProps {
  condition: boolean;
  wrap: Function;
  children: React.ReactNode;
}

// conditional wrapper component to wrap other component with a component based on condition
const ConditionalWrapper: FunctionComponent<ConditionalWrapperProps> = ({
  condition,
  wrap,
  children,
}) => (condition ? wrap(children) : <>{children}</>);

export default ConditionalWrapper;
