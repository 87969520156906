import styles from './sales-offer-payment-calculator.module.scss';
import { UnitPaymentTerms } from '@orascom/api-interfaces';

function SalesOfferPaymentMontengroCalculator({
  selectedPaymentTerms,
}: Readonly<{
  selectedPaymentTerms: UnitPaymentTerms;
}>) {
  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>PAYMENT PLAN</h1>
      <p className={styles['details']}>
        Detailed breakdown of unit pricing, discounts, and flexible payment
        structures. Your path to owning a Orascom Development property made
        simple.
      </p>
      <br></br>
      <p className={styles['details']}>
        Visit Your Unit on our website for additional details.
      </p>
      <div className={styles['payment-calculator-card']}>
        <p className={styles['header']}>Payment Calculator</p>
        <p className={styles['plan-title']}>PERSONALIZED PLAN OVERVIEW</p>
        <div className={styles['details-card']}>
          <p className={styles['details-card__title']}>UNIT FEES</p>
          <dl>
            <dt>Original Unit Price</dt>
            <dd>
              {selectedPaymentTerms.total_unit_price.toLocaleString()}
              <span className={styles['currency']}>
                {selectedPaymentTerms.currency}
              </span>
            </dd>
            <dt>Discount</dt>
            <dd>
              <span className={styles['discount']}>
                -{selectedPaymentTerms.discount_amount.toLocaleString()}{' '}
              </span>
              <span className={styles['currency']}>
                {selectedPaymentTerms.currency}
              </span>
            </dd>

            <dt>Price After Discount</dt>
            <dd>
              {selectedPaymentTerms.price_after_discount.toLocaleString()}
              <span className={styles['currency']}>
                {selectedPaymentTerms.currency}
              </span>
            </dd>
            <dt>Total Unit Price</dt>
            <dd>
              <span className={`${styles['total__price']}`}>
                {selectedPaymentTerms.price_after_discount.toLocaleString()}
              </span>
              <span className={styles['currency']}>
                {selectedPaymentTerms.currency}
              </span>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default SalesOfferPaymentMontengroCalculator;
