import React, { Suspense } from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { I18nextProvider } from 'react-i18next';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import App from './app/app';
import i18next from '../../../i18n';

const sentryEnv = process.env['NX_SENTRY_ENV'];

Sentry.init({
  dsn: 'https://f63a5ecc483e4cf58fb94d8d4f8ff72e@sentry.robustastudio.com/41',
  enabled: sentryEnv !== 'development',
  tracesSampleRate: 0.5,
  environment: sentryEnv,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const language = i18next.language;

//TODO: Add an env variable for the different environments and use it for both sentry and react helmet.

root.render(
  <Suspense>
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <HelmetProvider>
          {sentryEnv !== 'production' && (
            <Helmet>
              <meta name="robots" content="none" />
            </Helmet>
          )}
          <App />
        </HelmetProvider>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop
          closeOnClick
          rtl={language === 'ar'}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </I18nextProvider>
    </BrowserRouter>
  </Suspense>
);
