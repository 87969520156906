import { useTranslation } from 'react-i18next';
import styles from './similar-units.module.scss';
import { useEffect, useState } from 'react';
import { PaginationPayload, UnitInterface } from '@orascom/api-interfaces';
import { Unit as UnitUtilities } from '../../utils/unit.utils';
import { toast } from 'react-toastify';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CardsSlider, Loader } from '@orascom/common-components';
import SimilarUnitCard from './similar-unit-card';
import { Link } from 'react-router-dom';
import arrowIcon from '../../assets/icons/right-nav-arrow.svg';
import { ROUTES } from '../../api/routes';
export interface SimilarUnitsProps {
  unitId: number;
  price: number;
  designType: string;
}
export function SimilarUnits(props: Readonly<SimilarUnitsProps>) {
  const { t } = useTranslation();
  const [units, setUnits] = useState<PaginationPayload<UnitInterface[]>>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    UnitUtilities.getSimilarUnits(
      props.unitId.toString(),
      props.price,
      props.designType
    )
      .then((res) => {
        setUnits(res);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.unitId]);
  if (loading) {
    return <Loader />;
  }
  if (units?.data.length === 0) {
    return null;
  }
  return (
    <div className={styles['similar-units-container']}>
      <div className={styles['similar-units-container-heading']}>
        <h2 className={styles['similar-units-container-heading__title']}>
          {t('similarUnits')}
        </h2>
        <Link
          to={ROUTES['SimilarUnits'].getPath?.(
            props.unitId.toString(),
            props.price
          )}
          className={styles['view-all']}
        >
          <span className="anchor">{t('viewAll')}</span>
          <img
            className={styles['arrow-right']}
            src={arrowIcon}
            alt=""
            role="presentation"
          />
        </Link>
      </div>
      {units && (
        <div className={styles['units-container']}>
          <CardsSlider className="similar-units-card">
            {units.data.map((unit) => (
              <SimilarUnitCard key={unit.id} unit={unit} />
            ))}
          </CardsSlider>
        </div>
      )}
    </div>
  );
}
export default SimilarUnits;
