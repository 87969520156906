import { CurrencyContext } from '@orascom/utils';
import { useContext } from 'react';

export const useCurrencyContext = () => {
  const currencyContext = useContext(CurrencyContext);

  if (!currencyContext) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }

  return currencyContext;
};
