/* eslint-disable @nx/enforce-module-boundaries */
import styles from './resell-assistance.module.scss';
import {
  GoldButton,
  Loader,
  SelectDropdown,
  Spinner,
} from '@orascom/common-components';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '../../../assets/icons/error.svg';
import successIcon from '../../../assets/icons/success.svg';
import errorSubmitIcon from '../../../assets/icons/error-2.svg';
import ContactUsImage from '../../../assets/images/get-help.webp';
import OrascomLogo from '../../../assets/icons/logo.svg';
import * as Yup from 'yup';
import { PreDeliveryUser as PreDeliveryUserUtilities } from '../../../utils/pre-delivery/user.utils';
import { OptionValue, ResaleFormData } from '@orascom/api-interfaces';
import { useContext, useEffect, useState } from 'react';
import { ContactTypeEnum } from '../../../definitions/interfaces/pre-delivery.interface';
import { USER_CONTEXT } from '../../../../src/contexts/user-context';
import { getCustomerId } from '../../../../src/utils/common.utils';
import { useGetMyUnits } from '../hooks/use-get-my-units';
import { UserReservationDetailsInterface } from 'apps/orascom-shopper-app/src/definitions/interfaces/common.interface';
import unitImagePlaceHolder from '../../../assets/images/uni-details.webp';

interface FormInitialData {
  unitId: string | null;
  contactType: OptionValue | null;
  resalePrice: string;
  message: string;
  currency: string;
}

/* eslint-disable-next-line */
export interface ResellAssistanceProps {}

export function ResellAssistance() {
  const { t } = useTranslation();
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [unitId, setUnitId] = useState<number>();
  const [customerId, setCustomerId] = useState<string>('');
  const [currencyOptions, setCurrencyOptions] = useState<OptionValue[]>([]);
  const [unitCurrency, setUnitCurrency] = useState<string>();
  const [loadingCurrencies, setLoadingCurrencies] = useState(true);

  const [myUnits, loadingMyUnits] = useGetMyUnits();

  const ResellAssistanceFormSchema = Yup.object().shape({
    resalePrice: Yup.string(),
    contactType: Yup.object().nullable().required(t('fieldIsRequired')),
    message: Yup.string().max(250, t('maximumCharacters250')),
  });

  const userContext = useContext(USER_CONTEXT);

  useEffect(() => {
    if (myUnits.length) {
      setUnitId(myUnits[0].id);
      setUnitCurrency(myUnits[0].currency);
    }
  }, [myUnits]);

  useEffect(() => {
    if (unitId) {
      const foundCustomerId = getCustomerId(
        unitId,
        userContext.userReservationDetails as UserReservationDetailsInterface
      );
      if (foundCustomerId) {
        setCustomerId(foundCustomerId);
      }

      const unitCountryId = myUnits.find((unit) => unit.id === unitId)?.project
        .destination.country.id;

      if (unitCountryId) {
        PreDeliveryUserUtilities.getCurrencies(unitCountryId.toString())
          .then((res) => {
            const currencies = res.map((currency) => {
              return {
                label: currency.code,
                value: currency.source_id,
              };
            });
            setCurrencyOptions(currencies);
          })
          .catch((err) => console.log(err))
          .finally(() => setLoadingCurrencies(false));
      }
    }
  }, [unitId]);

  const handleSubmitForm = (
    formData: FormInitialData,
    resetForm: () => void
  ) => {
    if (formData.unitId) {
      const mappedData: ResaleFormData = {
        customer_source_id: customerId,
        asking_price: Number(formData.resalePrice),
        currency_source_id: formData.currency,
        portal_comments: formData.message,
        preferred_communication_method: String(formData.contactType?.value),
      };

      PreDeliveryUserUtilities.submitResaleRequest(formData.unitId, mappedData)
        .then(() => {
          setSuccessMsg(true);
        })
        .catch(() => setErrorMsg(true))
        .finally(() => resetForm());
    }
  };

  if (loadingMyUnits || loadingCurrencies) {
    return <Loader />;
  }

  const initialSelectedCurrency = currencyOptions.find(
    (currencyOption) => currencyOption.label === unitCurrency
  );

  return (
    <div className="pre-delivery-container">
      <h1 className={styles['page-title']}>{t('resellAssistance')}</h1>
      <p className={styles['page-subtitle']}>{t('resellAssistanceSubtitle')}</p>
      <div className={styles['wrapper']}>
        <div>
          {myUnits.length > 0 && (
            <Formik
              initialValues={{
                unitId: String(myUnits[0].id),
                resalePrice: '',
                contactType: null,
                message: '',
                currency: String(initialSelectedCurrency?.value),
              }}
              validationSchema={ResellAssistanceFormSchema}
              onSubmit={(values, { resetForm }) => {
                handleSubmitForm(values, resetForm);
              }}
            >
              {({
                errors,
                touched,
                setFieldValue,
                values,
                isSubmitting,
                resetForm,
              }) => (
                <Form>
                  <legend>{t('selectYourUnit')}</legend>

                  <div role="group" className={styles['units']}>
                    {myUnits.map((unit) => (
                      <label key={unit.id} className={styles['unit-wrapper']}>
                        <div className={styles['unit']}>
                          <img
                            src={unit.cover_image ?? unitImagePlaceHolder}
                            alt=""
                            loading="lazy"
                          />
                          <div className={styles['unit-info']}>
                            <div>
                              <p>
                                {`${unit?.project?.destination?.name} - ${unit.project?.name}`}
                              </p>
                              <p className={styles['unit-name']}>
                                {unit.unit_type?.name}
                              </p>
                            </div>
                            <Field
                              type="radio"
                              name="unitId"
                              value={unit.id.toString()}
                              onChange={() => {
                                setUnitId(unit.id);
                                setUnitCurrency(unit.currency);
                                resetForm();
                                setSuccessMsg(false);
                                setErrorMsg(false);
                                setFieldValue('unitId', unit.id.toString());
                              }}
                            />
                          </div>
                        </div>
                      </label>
                    ))}
                  </div>
                  <legend>{t('resaleForm')}</legend>
                  {isSubmitting && !errorMsg && !successMsg ? (
                    <div className={styles['submitting']}>
                      <Spinner />
                      <p>{t('sendingRequest')}</p>
                    </div>
                  ) : errorMsg ? (
                    <div>
                      <div className={styles['error']}>
                        <p className={styles['error-title']}>
                          <img
                            src={errorSubmitIcon}
                            role="presentation"
                            alt=""
                          />
                          <span>{t('formSubmitErrorTitle')}</span>
                        </p>
                        <p className={styles['error-message']}>
                          {t('formSubmitErrorMessage')}
                        </p>
                      </div>
                      <GoldButton onClick={() => setErrorMsg(false)}>
                        {t('Try again')}
                      </GoldButton>
                    </div>
                  ) : successMsg ? (
                    <div>
                      <div className={styles['success']}>
                        <p className={styles['success-title']}>
                          <img src={successIcon} role="presentation" alt="" />
                          <span>{t('formSubmitSuccessTitle')}</span>
                        </p>
                        <p className={styles['success-message']}>
                          {t('formSubmitSuccessMessage')}
                        </p>
                      </div>
                      <GoldButton onClick={() => setSuccessMsg(false)}>
                        {t('Submit another request')}
                      </GoldButton>
                    </div>
                  ) : (
                    <>
                      <div className={`input-wrapper ${styles['price-input']}`}>
                        <label htmlFor="contactType">
                          {`${t('estimatedResalePrice')} ${t('optional')}`}
                        </label>

                        <Field
                          name="resalePrice"
                          type="number"
                          placeholder={t('insertValue')}
                          className={
                            errors.resalePrice && touched.resalePrice
                              ? 'error'
                              : ''
                          }
                        />
                        <SelectDropdown
                          options={currencyOptions}
                          placeholder={String(initialSelectedCurrency?.label)}
                          defaultValue={initialSelectedCurrency}
                          onChange={(option) =>
                            setFieldValue('currency', option?.value)
                          }
                          isSearchable={false}
                          className="currency-select currency"
                        />
                        {errors.resalePrice && touched.resalePrice ? (
                          <p className="error">
                            <img src={ErrorIcon} role="presentation" alt="" />
                            {errors.resalePrice}
                          </p>
                        ) : null}
                      </div>
                      <div className="input-wrapper">
                        <label htmlFor="contactType">{t('contactType')}*</label>
                        <SelectDropdown
                          name="contactType"
                          className={`${styles['select']} ${
                            errors.contactType && touched.contactType
                              ? 'error'
                              : ''
                          }`}
                          placeholder={t('selectPlaceholder')}
                          onChange={(option) =>
                            setFieldValue('contactType', option)
                          }
                          options={[
                            {
                              value: ContactTypeEnum.MOBILE,
                              label: 'Phone call',
                            },
                            {
                              value: ContactTypeEnum.WHATS_APP,
                              label: 'Whatsapp',
                            },
                            { value: ContactTypeEnum.EMAIL, label: 'Email' },
                            {
                              value: ContactTypeEnum.VIRTUAL_MEETING,
                              label: 'virtual meeting',
                            },
                          ]}
                          selectedOption={values.contactType || null}
                        />
                        {errors.contactType && touched.contactType ? (
                          <p className="error">
                            <img src={ErrorIcon} role="presentation" alt="" />
                            {errors.contactType}
                          </p>
                        ) : null}
                      </div>

                      <div className="input-wrapper">
                        <label htmlFor="requestDetails">{`${t('message')} ${t(
                          'optional'
                        )}`}</label>
                        <Field
                          name="message"
                          as="textarea"
                          rows="6"
                          className={
                            errors.message && touched.message ? 'error' : ''
                          }
                          placeholder={t('messagePlaceHolder')}
                        />
                        {errors.message && touched.message ? (
                          <p className="error">
                            <img src={ErrorIcon} role="presentation" alt="" />
                            {errors.message}
                          </p>
                        ) : null}
                      </div>
                      <p className={styles['disclaimer']}>
                        {t('contactUsDisclaimer')}
                      </p>
                      <GoldButton
                        className={styles['contact-btn']}
                        type="submit"
                      >
                        {t('submitMessage')}
                      </GoldButton>
                    </>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </div>
        <div className={styles['image-wrapper']}>
          <img
            src={ContactUsImage}
            alt="contact us"
            className={styles['image-background']}
            loading="lazy"
          />
          <div className={styles['contact-us']}>
            <img src={OrascomLogo} alt="contact us" />
            <div className={styles['contact-info']}>
              <p>For Inquiries, you may reach us at</p>
              {/* TODO: Change phone number */}
              <a href="tel:19123">19123</a>
            </div>
            <p>2022 ODH. All right reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResellAssistance;
