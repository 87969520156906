import { GoldButton } from '@orascom/common-components';
import NotAvailableUnitHelmet from './not-available-unit-helmet';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@orascom/utils';
import styles from './not-available-unit.module.scss';
import TalkToSalesModal from '../../components/common/talk-to-sales-modal/talk-to-sales-modal';

const NotAvailableUnitPage = () => {
  const { t } = useTranslation();

  const { onOpen, isOpen, onClose } = useDisclosure();

  return (
    <>
      <NotAvailableUnitHelmet />
      <div className={`${styles['no-results']} container`}>
        <h4>{t('notAvailableUnit')}</h4>
        <p>{t('notAvailableUnitAction')}</p>

        <GoldButton onClick={onOpen}>{t('notifyMe')}</GoldButton>

        <TalkToSalesModal show={isOpen} onClose={onClose} />
      </div>
    </>
  );
};

export default NotAvailableUnitPage;
