import { PaginationInterface } from '@orascom/api-interfaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './offers.module.scss';
import { Offer as OfferUtils } from '../../../utils/pre-delivery/offer.utils';
import { OfferInterface } from '../../../definitions/interfaces/pre-delivery.interface';
import { Loader, NavyButton } from '@orascom/common-components';

export function Offers() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const [paginationData, setPaginationData] = useState<PaginationInterface>();
  const [loadingOffers, setLoadingOffers] = useState(false);
  const [searchingOffers, setSearchingOffers] = useState(false);
  const [offers, setOffers] = useState<OfferInterface[]>([]);

  const unitsCurrentPage = paginationData?.current_page
    ? paginationData.current_page + 1
    : 1;
  const unitsPage = (page?: number) => {
    return page ?? unitsCurrentPage;
  };

  const loadOffersHandler = (loadMore: boolean, page?: number) => {
    setLoadingOffers(true);
    if (!loadMore) {
      setSearchingOffers(true);
    }
    OfferUtils.getOffers(unitsPage(page), 9)
      .then((res) => {
        if (loadMore) {
          setOffers((prevState) => {
            return [...prevState, ...res.data];
          });
        } else {
          setOffers(res.data);
        }

        setPaginationData(res.meta);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setLoadingOffers(false);
        if (!loadMore) {
          setSearchingOffers(false);
        }
      });
  };

  useEffect(() => {
    loadOffersHandler(false, paginationData?.current_page);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={`${styles['offers']} pre-delivery-container`}>
      <h1 className="orascom__sub-title">{t('exclusiveOffer')}</h1>
      <p>{t('exclusiveOffersDesc')}</p>
      {searchingOffers && <Loader />}
      {!searchingOffers && (
        <div className={styles['cards']}>
          {offers.map((offer) => (
            <div className={styles['card']} key={offer.id}>
              <div className={styles['card-image-wrapper']}>
                <img
                  src={offer.cover_image}
                  alt={offer.title}
                  className={styles['card-image']}
                  loading="lazy"
                />
              </div>
              <div className={styles['card-content']}>
                <h2>{offer.title}</h2>
                <p>{offer.description}</p>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className={styles['count']}>
        <hr />
        <span>
          {t('showing')} {offers.length ?? 0} {t('dealsOutOf')}{' '}
          {paginationData?.total ?? 0}
        </span>
      </div>
      {paginationData?.last_page &&
        paginationData?.current_page &&
        paginationData?.last_page > paginationData?.current_page && (
          <div className={styles['load-more-button']}>
            <NavyButton
              onClick={() => loadOffersHandler(true)}
              disabled={loadingOffers}
            >
              {t('loadMore')}
            </NavyButton>
          </div>
        )}
    </div>
  );
}

export default Offers;
