import { t } from 'i18next';
import styles from './progress-bar.module.scss';

/* eslint-disable-next-line */
export interface ProgressBarProps {
  total: number;
  completed: number;
}

export function ProgressBar(props: Readonly<ProgressBarProps>) {
  return (
    <div className={styles['progress-bar']}>
      <div className={styles['bar']}>
        <div className={styles['bar-total']} />
        <div
          className={styles['bar-completed']}
          style={{
            width: `${Math.round((props.completed / props.total) * 100)}%`,
          }}
        />
      </div>
      <div className={styles['info']}>
        <p>{`${props.completed} ${t('installmentsPaid')}`}</p>
        <p>{`${t('outOf')} ${props.total} ${t('installments')}`}</p>
      </div>
    </div>
  );
}

export default ProgressBar;
