import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import { CountryDestination } from '../../definitions/interfaces/country.interface';
import styles from './other-destinations.module.scss';

export interface OtherDestinationsProps {
  destinations: CountryDestination[];
}

export function OtherDestinations({
  destinations,
}: Readonly<OtherDestinationsProps>) {
  const { t } = useTranslation();

  return (
    <div className={styles['other-destinations']}>
      <h6 className="orascom__title">{t('otherDestinations')}</h6>
      <h3 className="orascom__sub-title">
        {t('otherDestinationsYouMightBeInterestedIn')}
      </h3>
      <div className={styles['other-destinations__cards']}>
        {destinations.map((d) => (
          <Link
            to={ROUTES.Destination.getPath?.(d.slug)}
            key={d.id}
            className={styles['other-destinations__card']}
          >
            <img src={d.cover_image} alt={d.name} loading="lazy" />
            <h6>{d.name}</h6>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default OtherDestinations;
