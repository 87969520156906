import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { WishList as WishListAPI } from '../api/wishlist.api';
import { getPayloadData, handleError } from '@orascom/utils';
import { PaginationPayload, UnitInterface } from '@orascom/api-interfaces';

/**
 * group of wishlist helper functions.
 */
export class WishList {
  /**
   * get wishlist
   *
   * @static
   * @param {number} page
   * @param {number} perPage
   * @returns {Promise<PaginationPayload<UnitInterface[]>>}
   *
   * @memberOf WishList
   */
  public static getWishList(
    page: number,
    perPage: number
  ): Promise<PaginationPayload<UnitInterface[]>> {
    return WishListAPI.getWishList(page, perPage)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   * remove unit from wishlist
   *
   * @static
   * @param {number} unitId
   * @returns {Promise<Payload<UnitInterface[]>>}
   *
   * @memberOf WishList
   */
  public static removeFromWishList(unitId: number): Promise<UnitInterface[]> {
    return WishListAPI.removeFromWishList(unitId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   * add unit to wishlist
   *
   * @static
   * @param {number} unitId
   * @returns {Promise<Payload<UnitInterface[]>>}
   *
   * @memberOf WishList
   */
  public static addToWishList(unitId: number): Promise<UnitInterface[]> {
    return WishListAPI.addToWishList(unitId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}

export const handleAddToWishList = (
  id: number,
  setWishListed: Dispatch<SetStateAction<boolean>>,
  successMsg: string
) => {
  setWishListed(true);
  WishList.addToWishList(id)
    .then(() => toast.success(successMsg))
    .catch((err) => {
      console.log(err);
      setWishListed(false);
    });
};

export const handleRemoveFromWishList = (
  id: number,
  setWishListed: Dispatch<SetStateAction<boolean>>,
  successMsg: string
) => {
  setWishListed(false);
  WishList.removeFromWishList(id)
    .then(() => {
      toast.success(successMsg);
    })
    .catch((err) => {
      console.log(err);
      setWishListed(true);
    });
};
