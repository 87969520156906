/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './unit-addons.module.scss';
import { Loader } from '@orascom/common-components';
import { UnitAddOn } from '@orascom/api-interfaces';
import NewsCard from '../../../components/common/news-card/news-card';
import { ROUTES } from '../../../api/routes';
import { v4 as uuidv4 } from 'uuid';
import { useGetMyUnits } from '../hooks/use-get-my-units';
import unitImagePlaceHolder from '../../../assets/images/uni-details.webp';
import { PreDeliveryUser as PreDeliveryUserUtilities } from '../../../utils/pre-delivery/user.utils';

export function UnitAddons() {
  const { t } = useTranslation();
  const [unitAddons, setUnitAddons] = useState<UnitAddOn[]>();
  const [unitId, setUnitId] = useState<number>();
  const [myUnits, loadingMyUnits] = useGetMyUnits();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (myUnits.length) {
      setUnitId(myUnits[0].id);
    }
  }, [myUnits]);

  useEffect(() => {
    if (unitId) {
      setLoading(true);
      PreDeliveryUserUtilities.getUnitAddons(unitId.toString())
        .then((res) => setUnitAddons(res))
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [unitId, myUnits]);

  if (!myUnits) {
    return <Loader />;
  }

  return (
    <div className={`${styles['addons']} pre-delivery-container`}>
      <h1 className="orascom__sub-title">{t('unitAddons')}</h1>
      <div className={styles['wrapper']}>
        <div>
          {myUnits.length > 0 && (
            <>
              <legend>{t('selectYourUnit')}</legend>

              <div role="group" className={styles['units']}>
                {myUnits.map((unit, index) => (
                  <label key={unit.id} className={styles['unit-wrapper']}>
                    <div className={styles['unit']}>
                      <img
                        src={unit.cover_image ?? unitImagePlaceHolder}
                        alt=""
                        loading="lazy"
                      />
                      <div className={styles['unit-info']}>
                        <div>
                          <p>
                            {`${unit.project.destination.name} - ${unit.project.name}`}
                          </p>
                          <p className={styles['unit-name']}>
                            {unit.unit_type.name}
                          </p>
                        </div>
                        <input
                          name="unitId"
                          type="radio"
                          defaultChecked={index == 0}
                          value={unit.id}
                          onChange={() => {
                            setUnitId(unit.id);
                          }}
                        ></input>
                      </div>
                    </div>
                  </label>
                ))}
              </div>
            </>
          )}
        </div>
        <div>
          <h2>{t('availableAddonsUnit')}</h2>
          {loading ? (
            <Loader />
          ) : unitAddons && !loadingMyUnits ? (
            <div className={styles['available-per-unit']}>
              {unitAddons.length ? (
                unitAddons.map((addon) => (
                  <NewsCard
                    key={uuidv4()}
                    image={addon.image}
                    title={addon.name ?? addon.title}
                    detailsPath={ROUTES['Pre_Delivery_Addon'].getPath?.(
                      addon.id
                    )}
                  />
                ))
              ) : (
                <p>No addons found for this unit</p>
              )}
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
}

export default UnitAddons;
