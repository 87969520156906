import { Link } from 'react-router-dom';

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: React.ReactNode;
  secondary?: boolean;
  asLink?: boolean;
  to?: string;
  openInNewTab?: boolean;
}

export interface AnchorProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  children: React.ReactNode;
}

export function NavyButton(props: Readonly<ButtonProps>) {
  if (props.asLink) {
    return (
      <Link
        to={props.to ?? '/'}
        className={`btn ${props.secondary ? 'btn--secondary' : 'btn--navy '}`}
      >
        {props.children}
        <div className="white" />
      </Link>
    );
  }

  return (
    <button
      {...props}
      className={`btn ${props.secondary ? 'btn--secondary' : 'btn--navy '}`}
    >
      {props.children}
      <div className="white" />
    </button>
  );
}

export function NavyAnchor(props: Readonly<AnchorProps>) {
  return (
    <a {...props} className={`btn btn--navy ${props.className}`}>
      {props.children}
      <div className="white" />
    </a>
  );
}
export function GoldAnchor(props: Readonly<AnchorProps>) {
  return (
    <a {...props} className={`btn btn--gold ${props.className}`}>
      {props.children}
      <div className="gold" />
    </a>
  );
}

export function GoldButton(props: Readonly<ButtonProps>) {
  if (props.asLink) {
    return (
      <Link
        to={props.to ?? '/'}
        className={`btn ${
          props.secondary ? 'btn--secondary-gold' : 'btn--gold'
        }`}
        target={props.openInNewTab ? '_blank' : '_self'}
      >
        {props.children}
        <div className="gold" />
      </Link>
    );
  }
  return (
    <button
      {...props}
      className={`btn ${props.secondary ? 'btn--secondary-gold' : 'btn--gold'}`}
    >
      {props.children}
      <div className="gold" />
    </button>
  );
}
