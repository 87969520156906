import { useEffect, useState } from 'react';
import ExploreIndicator from '../../components/common/explore-indicator/explore-indicator';
import styles from './about-us.module.scss';
import { Home as HomeUtilities } from '../../utils/home.utils';
import { PagesDataInterface } from '../../definitions/interfaces/home.interface';
import { useTranslation } from 'react-i18next';
import AboutOrascom from '../../components/common/about-orascom/about-orascom';
import aboutCoverImg from '../../assets/images/about-cover.webp';
import { Helmet } from 'react-helmet-async';
import getDocTitle from '../../utils/getDocTitle';

const aboutDocTitle = getDocTitle('About Us');
export function AboutUs() {
  const { t } = useTranslation();
  const [aboutUsMetaData, setAboutUsMetaData] = useState<PagesDataInterface>();

  useEffect(() => {
    HomeUtilities.getAboutUsMetaData()
      .then((res) => {
        setAboutUsMetaData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Helmet>
        <title>{aboutDocTitle}</title>
        <meta property="og:title" content={aboutDocTitle} />
        <meta name="description" content={t('aboutOrascomDevelopments')} />
        <meta
          property="og:description"
          content={t('aboutOrascomDevelopments')}
        />
      </Helmet>
      <div
        className={styles['wrapper']}
        style={{
          // TODO: change image when we receive final images
          backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.6951374299719888) 0%, rgba(0,0,0,0) 100%), url(${aboutCoverImg})`,
        }}
      >
        <div className={`${styles['content']} container`}>
          <p>{t('aboutOrascomDevelopments')}</p>
          <h1>{t('aboutOrascomDevelopmentsTitle')}</h1>
        </div>

        <ExploreIndicator title={t('learnMore')} className={styles['scroll']} />
      </div>

      <AboutOrascom
        title={t('whatWeDo')}
        subTitle={t('aLeadingDeveloper1')}
        subTitle2={t('aLeadingDeveloper2')}
        description={t('aboutUsDescription1')}
        description2={t('aboutUsDescription2')}
        aboutInfo={aboutUsMetaData?.metadata.general}
        showAboutButton={false}
        aboutPage
      />
    </>
  );
}

export default AboutUs;
