import {
  Common,
  OptionValue,
  ServiceRequestTypeEnum,
} from '@orascom/api-interfaces';

export interface ServiceRequestUnit {
  name: string;
  source_id: string;
}
export interface ServiceRequestCategory {
  code: string;
  name: string;
  source_id: string;
}

export enum ServiceRequestStatusEnum {
  IN_PROGRESS = 1,
  ON_HOLD = 2,
  WAITING_FOR_DETAILS = 3,
  RESEARCHING = 4,
  PROBLEM_SOLVED = 5,
  CANCELLED = 6,
  INFORMATION_PROVIDED = 1000,
  MERGED = 2000,
  PENDING = 127990000,
}

export interface ServiceRequest extends Common {
  unit: ServiceRequestUnit;
  project_name: string;
  unit_type: string;
  category: ServiceRequestCategory;
  request_created_at: number;
  status: ServiceRequestStatusEnum;
}

export enum SignContractRequestedOption {
  SIGN_ONLINE = 1,
  SIGN_OFFLINE = 2,
  REQUEST_DELIVARY = 3,
}
export interface ServiceRequestFormData {
  customer_source_id: string;
  preferred_time_of_contact?: number | null;
  preferred_way_of_contact?: number | null;
  request_details: string;
  service_slug: ServiceRequestTypeEnum;
  change_contact_details?: string;
  sign_contract_place?: string;
  sign_contract_visit_date_and_time?: string;
  sign_contract_requested_option?: SignContractRequestedOption;
}

export type SignContractMethod = 'dhl' | 'offline' | 'online';

export interface SignContractRequestInitialData {
  signContractMethod: SignContractMethod;
  meetingLocation: OptionValue | null;
  meetingDate: string;
  meetingTime: string;
  additionalInfo: string;
  governorate: string;
  district: string;
  address: string;
  floorNumber: string;
  apartment: string;
  contactType: OptionValue | null;
  deliveryDate: string;
  deliveryTime: string;
}
