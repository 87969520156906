import { useEffect, useState } from 'react';
import {
  hasViewedPromotion,
  markPromotionAsViewed,
} from '../utils/common.utils';

const usePromotion = (promotionId: number | undefined) => {
  const [promotionalModal, setPromotionalModal] = useState(false);
  const handleShowPromotion = () => {
    setPromotionalModal(true);
    document.body.style.overflow = 'hidden';
  };
  const handleClosePromotion = () => {
    setPromotionalModal(false);
    document.body.style.overflow = '';
  };
  useEffect(() => {
    if (promotionId && !hasViewedPromotion(promotionId)) {
      handleShowPromotion();
    }
  }, [promotionId]);

  useEffect(() => {
    // Mark the promotion as viewed when it is closed
    if (!promotionalModal && promotionId) {
      markPromotionAsViewed(promotionId);
    }
  }, [promotionalModal]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = '';
    };
  }, []);
  return { handleShowPromotion, handleClosePromotion, promotionalModal };
};

export default usePromotion;
