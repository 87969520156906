import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../api/routes';
import sideMenuIcon from '../../../assets/icons/side-menu.svg';
import closeMenuIcon from '../../../assets/icons/close.svg';
import backArrowIcon from '../../../assets/icons/back-arrow.svg';
import notificationsIcon from '../../../assets/icons/notifications.svg';
import Logo from '../../../assets/images/logo-horizontal.png';
import styles from './header.module.scss';
import { LanguageDropdown } from '@orascom/common-components';
import { showPreDeliveryLangs } from '../../../definitions/consts/envConstants';

/* eslint-disable-next-line */
export interface HeaderProps {
  openSidebar: (isSidebar: boolean) => void;
  isSidebar: boolean;
  backButtonPath?: string;
  sideNavIconStatus?: boolean;
}

export function Header(props: Readonly<HeaderProps>) {
  const { t } = useTranslation();

  useEffect(() => {
    if (props.isSidebar) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [props.isSidebar]);

  return (
    <div className={styles['header']}>
      <div className={styles['header__content']}>
        <div className={styles['header__side-nav']}>
          {!props.sideNavIconStatus ? null : (
            <button
              type="button"
              className={styles['header__side-nav-icon']}
              onClick={() => {
                props.openSidebar(!props.isSidebar);
              }}
            >
              {props.isSidebar ? (
                <img src={closeMenuIcon} alt="close-menu-icon" />
              ) : (
                <img src={sideMenuIcon} alt="side-menu-icon" />
              )}
            </button>
          )}
          {props.backButtonPath ? (
            <Link
              to={props.backButtonPath}
              className={styles['header__side-nav-back']}
            >
              <img src={backArrowIcon} alt="side-menu-icon" />
              {t('backToUnitAddons')}
            </Link>
          ) : (
            <Link
              to={ROUTES.Home.path}
              className={styles['header__side-nav-back']}
            >
              <img src={backArrowIcon} alt="side-menu-icon" />
              {t('backToMainSite')}
            </Link>
          )}
        </div>
        <Link
          to={ROUTES.Pre_Delivery_Home.path}
          className={styles['header__logo']}
        >
          <img src={Logo} alt="orascom-logo" />
        </Link>
        <div className={styles['header__nav']}>
          <button type="button" className={styles['header__nav-notifications']}>
            <img src={notificationsIcon} alt="side-menu-icon" />
          </button>
          {showPreDeliveryLangs && (
            <div className={styles['dropdowns']}>
              <LanguageDropdown />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
