/* eslint-disable @nx/enforce-module-boundaries */
import { useContext, useEffect, useState } from 'react';
import { USER_CONTEXT } from '../../../contexts/user-context';
import {
  getDoneReservationSales,
  getUserSaleUnits,
} from '../../../utils/authentication.utils';

import { UnitDetails } from '@orascom/api-interfaces';
import { UserReservationSale } from '../../../definitions/interfaces/common.interface';

export const useGetMyUnits = (): [
  myUnits: UnitDetails[],
  loadingMyUnits: boolean
] => {
  const { userReservationDetails } = useContext(USER_CONTEXT);

  const [loadingMyUnits, setLoadingMyUnits] = useState(true);
  const [myUnits, setMyUnits] = useState<UnitDetails[]>([]);
  const [doneSales, setDoneSales] = useState<UserReservationSale[]>([]);

  useEffect(() => {
    if (userReservationDetails) {
      setDoneSales(getDoneReservationSales(userReservationDetails));
    }
  }, [userReservationDetails]);

  useEffect(() => {
    if (userReservationDetails && doneSales) {
      const units = getUserSaleUnits(doneSales);
      if (units) {
        setMyUnits(units);
        setLoadingMyUnits(false);
      }
    }
  }, [userReservationDetails, doneSales]);

  return [myUnits, loadingMyUnits];
};
