/* eslint-disable import/no-extraneous-dependencies */
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';
import leftArrowIcon from '../../../assets/icons/left-arrow.svg';
import rightArrowIcon from '../../../assets/icons/right-arrow.svg';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import styles from './gallery-slider.module.scss';
import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';

/* eslint-disable-next-line */
export interface GallerySliderProps {
  children: React.ReactNode[];
  pagination?: boolean;
  autoPlay?: boolean;
}

export function GallerySlider(props: Readonly<GallerySliderProps>) {
  const language = i18next.language;
  return (
    <div className={styles['gallery-wrapper']}>
      <Swiper
        modules={[Pagination, Navigation, Autoplay, EffectFade]}
        rewind={true}
        pagination={
          props.pagination && {
            el: '.gallery-slider__pagination',
            dynamicBullets: false,
          }
        }
        navigation={{
          prevEl: '.gallery-slider__prev',
          nextEl: '.gallery-slider__next',
        }}
        dir={language === 'ar' ? 'rtl' : 'ltr'}
        key={language}
        {...(props.autoPlay && {
          speed: 5000,
          autoplay: {
            delay: 3000,
          },
          effect: 'fade',
          fadeEffect: {
            crossFade: true,
          },
        })}
      >
        {props.children?.map((child) => (
          <SwiperSlide key={uuidv4()}>{child}</SwiperSlide>
        ))}
        {!props.autoPlay && props.children.length > 1 && (
          <div className="gallery-slider__navigation">
            <button type="button" className="gallery-slider__prev">
              <img src={leftArrowIcon} alt="" role="presentation" />
            </button>
            <div className="gallery-slider__pagination"></div>
            <button type="button" className="gallery-slider__next">
              <img src={rightArrowIcon} alt="" role="presentation" />
            </button>
          </div>
        )}
      </Swiper>
    </div>
  );
}

export default GallerySlider;
