import { createContext, Context } from 'react';

interface UnitsCompareContextInterface {
  /**
   * an array of units (maximum 3 units) added to comparison
   *
   * @type {number[]}
   */
  units?: number[];

  /**
   * function to add unit Id to the list
   *
   */
  addUnit?: (unitId: number) => void;

  /**
   * a function to remove a unit from the array
   *
   */
  removeUnit?: (unitId: number) => void;

  /**
   * Flag used when reached maximum number of units in comparison
   */
  showError?: boolean;
}

export const UNIT_COMPARE_CONTEXT: Context<UnitsCompareContextInterface> =
  createContext({});
