import { useEffect, useState } from 'react';
import { SetURLSearchParams, useSearchParams } from 'react-router-dom';
import { CURRENCIES } from '../../lib/exchange.rates.utils';
import {
  CURRENCIES_ENUM,
  FiltersEnums,
  PriceSlider,
  UnitFilters,
} from '../../../../api-interfaces/src';
import { useCurrencyContext } from '@orascom/utils';

function setPriceSearchParams(
  min: number,
  max: number,
  currency: string,
  setSearchParams: SetURLSearchParams
) {
  setSearchParams(
    (searchParams) => {
      searchParams.set(FiltersEnums.MIN_PRICE, min.toString());
      searchParams.set(FiltersEnums.MAX_PRICE, max.toString());
      searchParams.set(FiltersEnums.CURRENCY, currency);
      return searchParams;
    },
    {
      replace: true,
    }
  );
}

const initPriceSlider = {
  minRange: 0,
  maxRange: 0,
  price: {
    min: 0,
    max: 0,
    currency: CURRENCIES_ENUM.USD,
  },
};
export const usePriceSlider = (unitFilters?: UnitFilters) => {
  const [priceSlider, setPriceSlider] = useState<PriceSlider>(initPriceSlider);
  const [searchParams, setSearchParams] = useSearchParams();

  const { convertPriceToActiveCurrency, currency: activeCurrency } =
    useCurrencyContext();

  // simplify price conversion
  const convertPrice = (value: number, currency?: CURRENCIES_ENUM) => {
    const convertedValue = convertPriceToActiveCurrency({
      value,
      currency: currency ?? priceSlider.price.currency,
    }).value;

    return Math.floor(convertedValue);
  };

  // recalculate price on unitFilters change
  useEffect(() => {
    if (!unitFilters) return;
    // the backend is returning the price in USD...
    const minRange = convertPrice(
      +unitFilters.min_dollar_price,
      CURRENCIES_ENUM.USD
    );
    const maxRange = convertPrice(
      +unitFilters.max_dollar_price,
      CURRENCIES_ENUM.USD
    );

    const minSearchPrice = searchParams.get(FiltersEnums.MIN_PRICE);
    const maxSearchPrice = searchParams.get(FiltersEnums.MAX_PRICE);
    const currency = searchParams.get(FiltersEnums.CURRENCY);
    const hasValue = minSearchPrice && maxSearchPrice && currency;

    // don't override existing values after fetching filters
    if (hasValue) {
      const min = Math.floor(Number(minSearchPrice));
      const max = Math.floor(Number(maxSearchPrice));

      const isInRange = (val: number) => val >= minRange && val <= maxRange;
      const isMinValid = isInRange(min) && min < max;
      const isMaxValid = isInRange(max) && max > min;
      const isCurrencyValid = CURRENCIES.includes(currency as CURRENCIES_ENUM);

      setPriceSlider({
        minRange: minRange,
        maxRange: maxRange,
        price: {
          min: isMinValid ? min : minRange,
          max: isMaxValid ? max : maxRange,
          currency: isCurrencyValid ? currency : activeCurrency,
        },
      });
    } else {
      setPriceSlider({
        minRange: minRange,
        maxRange: maxRange,
        price: {
          min: minRange,
          max: maxRange,
          currency: activeCurrency,
        },
      });
    }

    // no need to add activeCurrency to deps, there is another effect for it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitFilters]);

  // recalculate price on activeCurrency change
  useEffect(() => {
    if (!unitFilters) return;

    const minPrice = convertPrice(priceSlider.price.min);
    const maxPrice = convertPrice(priceSlider.price.max);

    setPriceSlider({
      ...priceSlider,
      price: {
        min: minPrice,
        max: maxPrice,
        currency: activeCurrency,
      },
      maxRange: convertPrice(priceSlider.maxRange),
      minRange: convertPrice(priceSlider.minRange),
    });

    const minSearchPrice = searchParams.get(FiltersEnums.MIN_PRICE);
    // don't change search params if there is no selected price (so we don't refetch units)
    if (minSearchPrice) {
      setPriceSearchParams(minPrice, maxPrice, activeCurrency, setSearchParams);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCurrency]);

  return { priceSlider, setPriceSlider };
};
