import './explore-indicator.scss';

/* eslint-disable-next-line */
export interface ExploreIndicatorProps {
  title: string;
  className?: string;
}

export function ExploreIndicator(props: Readonly<ExploreIndicatorProps>) {
  return (
    <div className={`wrapper ${props.className ? props.className : ''}`}>
      <p>{props.title}</p>
      <div className="line">
        <div className="dash" />
        <div className="dash" />
      </div>
    </div>
  );
}

export default ExploreIndicator;
