import { useTranslation } from 'react-i18next';
import React from 'react';
import styles from './reach-us.module.scss';

export interface ReachUsProps {
  title?: string;
  subTitle?: string;
  description: string;
  primaryButton: React.ReactNode;
  secondaryButton?: React.ReactNode;
}

export function ReachUs(props: Readonly<ReachUsProps>) {
  const { t } = useTranslation();
  return (
    <div className={styles['reach-us']}>
      <div className={styles['reach-us__description']}>
        <h6>{props.subTitle ?? t('reachUs')}</h6>
        <h3>{props.title ?? t('needDetailsGetInTouch')}</h3>
        <p>{props.description}</p>
      </div>
      <div className={styles['reach-us__buttons-wrapper']}>
        {props.primaryButton}
        {props.secondaryButton ?? null}
      </div>
    </div>
  );
}

export default ReachUs;
