/* eslint-disable import/no-extraneous-dependencies */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavyButton } from '../../components/common/button/button';
import NewsCard from '../../components/common/news-card/news-card';
import { NewsListingInterface } from '../../definitions/interfaces/home.interface';
import { Home as HomeUtilities } from '../../utils/home.utils';
import styles from './news-and-events.module.scss';
import { PaginationInterface } from '@orascom/api-interfaces';
import { Loader } from '@orascom/common-components';
import { ROUTES } from '../../api/routes';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet-async';
import getDocTitle from '../../utils/getDocTitle';

const newsDocTitle = getDocTitle('News & Events');
export function NewsAndEvents() {
  const { t } = useTranslation();
  const [news, setNews] = useState<NewsListingInterface[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationInterface>();
  const [loading, setLoading] = useState(true);
  const [loadingNews, setLoadingNews] = useState(false);
  const [showEvents, setShowEvents] = useState(false);
  const [featuredNews, setFeaturedNews] = useState<NewsListingInterface>();

  useEffect(() => {
    HomeUtilities.getNews(1, 1, false, true)
      .then((res) => {
        setFeaturedNews(res.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    HomeUtilities.getNews(1, 6, showEvents)
      .then((res) => {
        setNews(res.data);
        setPaginationData(res.meta);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }, [showEvents]);

  const loadMore = () => {
    setLoadingNews(true);
    HomeUtilities.getNews(
      paginationData?.current_page ? paginationData.current_page + 1 : 1,
      6,
      showEvents
    )
      .then((res) => {
        setNews((prevState) => {
          return [...prevState, ...res.data];
        });
        setPaginationData(res.meta);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingNews(false));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles['wrapper']}>
      <Helmet>
        <title>{newsDocTitle}</title>
        <meta property="og:title" content={newsDocTitle} />
        <meta name="description" content={t('stayUpToDate')} />
        <meta property="og:description" content={t('stayUpToDate')} />
      </Helmet>
      <p className="orascom__title">{t('newsAndEvents')}</p>
      <p className="orascom__sub-title">{t('stayUpToDate')}</p>
      {featuredNews && (
        <div className={styles['featured']}>
          <img
            src={featuredNews.image}
            alt={featuredNews?.title}
            loading="lazy"
          />
          <div className={styles['featured-info']}>
            <p className={styles['featured-date']}>
              {new Date(featuredNews?.date * 1000).toLocaleDateString('en-GB', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </p>

            <p className={styles['featured-title']}>{featuredNews.title}</p>
            <p className={styles['featured-description']}>
              {featuredNews.short_description}
            </p>

            <NavyButton
              asLink
              to={ROUTES.InnerNewsEvents.getPath?.(featuredNews.slug)}
            >
              {t('readMore')}
            </NavyButton>
          </div>
        </div>
      )}
      <div className="container">
        <h4 className={styles['filter-by']}>{t('filterBy')}</h4>
        <div className={styles['filter']}>
          <button
            type="button"
            onClick={() => setShowEvents(false)}
            className={!showEvents ? styles['active-filter'] : ''}
          >
            {t('news')}
          </button>
          <button
            type="button"
            onClick={() => setShowEvents(true)}
            className={showEvents ? styles['active-filter'] : ''}
          >
            {t('events')}
          </button>
        </div>
        {news && news.length > 0 && (
          <div className={styles['news-list']}>
            {news.map((item) => (
              <NewsCard
                key={uuidv4()}
                image={item.image}
                date={item.date}
                title={item.title}
                description={item.short_description}
                path={ROUTES.InnerNewsEvents.getPath?.(item.slug)}
                newsPage
              />
            ))}
          </div>
        )}
        <div className={styles['count']}>
          <hr />
          <span>
            {t('showing')} {news?.length ?? 0} {t('articlesOutOf')}{' '}
            {paginationData?.total ?? 0}
          </span>
        </div>
        {paginationData?.last_page &&
          paginationData?.current_page &&
          paginationData?.last_page > paginationData?.current_page && (
            <div className={styles['load-more']}>
              <NavyButton onClick={loadMore} disabled={loadingNews}>
                {t('loadMore')}
              </NavyButton>
            </div>
          )}
      </div>
    </div>
  );
}

export default NewsAndEvents;
