/* eslint-disable @nx/enforce-module-boundaries */
import styles from './reservation-info-form-fields.module.scss';
import { useTranslation } from 'react-i18next';
import UploadIcon from '../../assets/icons/reservation-upload.svg?react';
import NationalIdIcon from '../../assets/icons/identity-card.svg?react';
import PassportIcon from '../../assets/icons/passport.svg?react';
import { SelectDropdown } from '@orascom/common-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ErrorIcon from '../../assets/icons/error.svg';
import {
  CountriesEnum,
  MaritalStatusEnum,
  OptionValue,
} from '@orascom/api-interfaces';
import { ErrorMessage, Field } from 'formik';
import { useContext, useState } from 'react';
import { RESERVATION_CONTEXT } from 'libs/common-components/src/contexts/reservation.context';
import { validatePhone } from '@orascom/utils';

/* eslint-disable-next-line */
export interface ReservationInfoFormFieldsProps {
  errors: any;
  touched: any;
  values: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  // selectCountry: (val: string) => void;
  countries: OptionValue[];
  nationalities: OptionValue[];
  cities: OptionValue[];
  occupations: OptionValue[];
  jointBuyer: boolean;
  connections: OptionValue[];
}

export function ReservationInfoFormFields(
  props: Readonly<ReservationInfoFormFieldsProps>
) {
  const { t } = useTranslation();
  const {
    errors,
    touched,
    values,
    setFieldValue,
    countries,
    cities,
    jointBuyer,
    nationalities,
    occupations,
    connections,
  } = props;
  const reservationContext = useContext(RESERVATION_CONTEXT);
  const [nationalIdFrontImageText, setNationalIdFrontImageText] = useState('');
  const [nationalIdBackImageText, setNationalIdBackImageText] = useState('');
  const [passportImageText, setPassportImageText] = useState('');
  const [
    jointBuyerNationalIdFrontImageText,
    setJointBuyerNationalIdFrontImageText,
  ] = useState('');
  const [
    jointBuyerNationalIdBackImageText,
    setJointBuyerNationalIdBackImageText,
  ] = useState('');
  const [jointBuyerPassportImageText, setJointBuyerPassportImageText] =
    useState('');

  const relationShipsOptions =
    connections.length > 0
      ? connections
      : [
          { value: '1', label: t('employer') },
          { value: '2', label: t('employee') },
          { value: '3', label: t('partner') },
          { value: '4', label: t('colleague') },
          { value: '5', label: t('Referral') },
          { value: '6', label: t('Wife') },
          { value: '7', label: t('Son') },
          { value: '8', label: t('Husband') },
          { value: '9', label: t('Father') },
          { value: '10', label: t('Child') },
          { value: '11', label: t('Cousin') },
          { value: '12', label: t('Spouse / Partner') },
          { value: '13', label: t('Parent') },
        ];

  let errorFieldName = null;

  if (jointBuyer) {
    if (values.citiesFound) {
      errorFieldName = 'jointBuyerCity';
    } else {
      errorFieldName = 'jointBuyerCityName';
    }
  } else {
    if (values.citiesFound) {
      errorFieldName = 'city';
    } else {
      errorFieldName = 'cityName';
    }
  }
  return (
    <div className={styles['container']}>
      {jointBuyer && (
        <>
          <div className="input-wrapper">
            <label htmlFor="jointBuyerFirstName">
              {t('jointBuyerFirstName')}*
            </label>
            <Field
              name="jointBuyerFirstName"
              type="text"
              className={
                errors.jointBuyerFirstName && touched.jointBuyerFirstName
                  ? 'error'
                  : ''
              }
            />

            <ErrorMessage
              name="jointBuyerFirstName"
              render={(msg: string) => (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {msg}
                </p>
              )}
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor="jointBuyerLastName">
              {t('jointBuyerLastName')}*
            </label>
            <Field
              name="jointBuyerLastName"
              type="text"
              className={
                errors.jointBuyerLastName && touched.jointBuyerLastName
                  ? 'error'
                  : ''
              }
            />

            <ErrorMessage
              name="jointBuyerLastName"
              render={(msg: string) => (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {msg}
                </p>
              )}
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor="jointBuyerPhoneNumber">
              {t('jointBuyerPhoneNumber')}*
            </label>
            <div className="phone-wrapper">
              <PhoneInput
                countryCodeEditable={false}
                inputProps={{
                  name: 'jointBuyerCountryCode',
                }}
                country={'eg'}
                onChange={(phone) => {
                  setFieldValue('jointBuyerCountryCode', phone);
                  setFieldValue('jointBuyerPhoneNumber', '');
                }}
                enableSearch={true}
                excludeCountries={['il']}
                value={values.jointBuyerCountryCode}
              />
              <Field
                name="jointBuyerPhoneNumber"
                type="tel"
                placeholder="123 456 7890"
                validate={() =>
                  validatePhone(
                    values.jointBuyerPhoneNumber,
                    values.jointBuyerCountryCode
                  )
                }
                className={
                  errors.jointBuyerPhoneNumber && touched.jointBuyerPhoneNumber
                    ? 'error'
                    : ''
                }
              />
            </div>

            <ErrorMessage
              name="jointBuyerPhoneNumber"
              render={(msg: string) => (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {msg}
                </p>
              )}
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor="jointBuyerEmail">{t('jointBuyerEmail')}*</label>
            <Field
              name="jointBuyerEmail"
              type="email"
              placeholder="example@email.com"
              className={
                errors.jointBuyerEmail && touched.jointBuyerEmail ? 'error' : ''
              }
            />

            <ErrorMessage
              name="jointBuyerEmail"
              render={(msg: string) => (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {msg}
                </p>
              )}
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor="relationToBuyer">{t('relationToBuyer')}*</label>
            <SelectDropdown
              name="relationToBuyer"
              className={`${styles['select']} ${
                errors.relationToBuyer && touched.relationToBuyer ? 'error' : ''
              }`}
              placeholder={t('selectPlaceholder')}
              onChange={(option) => {
                setFieldValue('relationToBuyer', option);
              }}
              options={relationShipsOptions}
              selectedOption={values.relationToBuyer || null}
            />

            <ErrorMessage
              name="relationToBuyer"
              render={(msg: string) => (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {msg}
                </p>
              )}
            />
          </div>
        </>
      )}
      <div className="input-wrapper">
        <label htmlFor={jointBuyer ? 'jointBuyerNationality' : 'nationality'}>
          {jointBuyer ? t('jointBuyerNationality') : t('nationality')}*
        </label>

        <SelectDropdown
          name={jointBuyer ? 'jointBuyerNationality' : 'nationality'}
          className={`${styles['select']} ${
            errors.nationality && touched.nationality ? 'error' : ''
          }`}
          placeholder={t('selectPlaceholder')}
          onChange={(option) => {
            setFieldValue(
              jointBuyer ? 'jointBuyerNationality' : 'nationality',
              option
            );
          }}
          options={nationalities}
          disabled={jointBuyer && values.sameNationality}
          selectedOption={
            jointBuyer && !values.sameNationality
              ? values.jointBuyerNationality || null
              : values.nationality || null
          }
        />
        {jointBuyer && (
          <div className="input-wrapper checkbox-input-wrapper">
            <label>
              <Field
                type="checkbox"
                name="sameNationality"
                onClick={() =>
                  setFieldValue('jointBuyerNationality', values.nationality)
                }
              />
              {t('sameNationality')}
            </label>
          </div>
        )}
        {jointBuyer ? (
          <ErrorMessage
            name="jointBuyerNationality"
            render={(msg: string) => (
              <p className="error">
                <img src={ErrorIcon} role="presentation" alt="" />
                {msg}
              </p>
            )}
          />
        ) : (
          <ErrorMessage
            name="nationality"
            render={(msg: string) => (
              <p className="error">
                <img src={ErrorIcon} role="presentation" alt="" />
                {msg}
              </p>
            )}
          />
        )}
      </div>

      <div className="input-wrapper">
        <label htmlFor={jointBuyer ? 'jointBuyerCountry' : 'country'}>
          {jointBuyer ? t('jointBuyerCountry') : t('country')}*
        </label>

        <SelectDropdown
          name={jointBuyer ? 'jointBuyerCountry' : 'country'}
          className={`${styles['select']} ${
            errors.country && touched.country ? 'error' : ''
          }`}
          placeholder={t('selectPlaceholder')}
          onChange={(option) => {
            setFieldValue(jointBuyer ? 'jointBuyerCountry' : 'country', option);
          }}
          options={countries}
          disabled={jointBuyer && values.sameCountry}
          selectedOption={
            jointBuyer && !values.sameCountry
              ? values.jointBuyerCountry || null
              : values.country || null
          }
        />
        {jointBuyer && (
          <div className="input-wrapper checkbox-input-wrapper">
            <label>
              <Field
                type="checkbox"
                name="sameCountry"
                onClick={() =>
                  setFieldValue('jointBuyerCountry', values.country)
                }
              />
              {t('sameCountry')}
            </label>
          </div>
        )}
        {jointBuyer ? (
          <ErrorMessage
            name="jointBuyerCountry"
            render={(msg: string) => (
              <p className="error">
                <img src={ErrorIcon} role="presentation" alt="" />
                {msg}
              </p>
            )}
          />
        ) : (
          <ErrorMessage
            name="country"
            render={(msg: string) => (
              <p className="error">
                <img src={ErrorIcon} role="presentation" alt="" />
                {msg}
              </p>
            )}
          />
        )}
      </div>

      <div className="input-wrapper">
        <label htmlFor={jointBuyer ? 'jointBuyerCity' : 'city'}>
          {jointBuyer ? t('jointBuyerCity') : t('city')}*
        </label>
        {values.citiesFound ? (
          <SelectDropdown
            name={jointBuyer ? 'jointBuyerCity' : 'city'}
            className={`${styles['select']} ${
              errors.city && touched.city ? 'error' : ''
            }`}
            placeholder={t('selectPlaceholder')}
            onChange={(option) => {
              setFieldValue(jointBuyer ? 'jointBuyerCity' : 'city', option);
            }}
            options={cities}
            disabled={jointBuyer && values.sameCity}
            selectedOption={
              jointBuyer && !values.sameCity
                ? values.jointBuyerCity || null
                : values.city || null
            }
          />
        ) : (
          <Field
            name={jointBuyer ? 'jointBuyerCityName' : 'cityName'}
            type="text"
            className={
              (errors.cityName && touched.cityName ? 'error' : '') ||
              (errors.jointBuyerCityName && touched.jointBuyerCityName
                ? 'error'
                : '')
            }
            value={values.sameCity ? values.cityName : undefined}
            disabled={jointBuyer && values.sameCity}
          />
        )}
        {jointBuyer && (
          <div className="input-wrapper checkbox-input-wrapper">
            <label>
              <Field
                type="checkbox"
                name="sameCity"
                onClick={() =>
                  values.citiesFound
                    ? setFieldValue('jointBuyerCity', values.city)
                    : setFieldValue('jointBuyerCityName', values.cityName)
                }
              />
              {t('sameCity')}
            </label>
          </div>
        )}
        {errorFieldName && (
          <ErrorMessage
            name={errorFieldName}
            render={(msg: string) => (
              <p className="error">
                <img src={ErrorIcon} role="presentation" alt="" />
                {msg}
              </p>
            )}
          />
        )}
      </div>

      <div className="input-wrapper">
        <label htmlFor={jointBuyer ? 'jointBuyerDistrict' : 'district'}>
          {jointBuyer ? t('jointBuyerDistrict') : t('district')}*
        </label>
        <Field
          name={jointBuyer ? 'jointBuyerDistrict' : 'district'}
          type="text"
          className={
            (errors.district && touched.district ? 'error' : '') ||
            (errors.jointBuyerDistrict && touched.jointBuyerDistrict
              ? 'error'
              : '')
          }
          value={values.sameDistrict ? values.district : undefined}
          disabled={jointBuyer && values.sameDistrict}
        />
        {jointBuyer && (
          <div className="input-wrapper checkbox-input-wrapper">
            <label>
              <Field
                type="checkbox"
                name="sameDistrict"
                onClick={() =>
                  setFieldValue('jointBuyerDistrict', values.district)
                }
              />
              {t('sameDistrict')}
            </label>
          </div>
        )}
        {jointBuyer ? (
          <ErrorMessage
            name="jointBuyerDistrict"
            render={(msg: string) => (
              <p className="error">
                <img src={ErrorIcon} role="presentation" alt="" />
                {msg}
              </p>
            )}
          />
        ) : (
          <ErrorMessage
            name="district"
            render={(msg: string) => (
              <p className="error">
                <img src={ErrorIcon} role="presentation" alt="" />
                {msg}
              </p>
            )}
          />
        )}
      </div>
      <div className="input-wrapper">
        <label htmlFor={jointBuyer ? 'jointBuyerAddress' : 'address'}>
          {jointBuyer ? t('jointBuyerAddress') : t('homeAddress')}*
        </label>
        <Field
          name={jointBuyer ? 'jointBuyerAddress' : 'address'}
          type="text"
          className={
            (errors.address && touched.address ? 'error' : '') ||
            (errors.jointBuyerAddress && touched.jointBuyerAddress
              ? 'error'
              : '')
          }
          value={values.sameAddress ? values.address : undefined}
          disabled={jointBuyer && values.sameAddress}
        />
        {jointBuyer && (
          <div className="input-wrapper checkbox-input-wrapper">
            <label>
              <Field
                type="checkbox"
                name="sameAddress"
                onClick={() =>
                  setFieldValue('jointBuyerAddress', values.address)
                }
              />
              {t('sameAddress')}
            </label>
          </div>
        )}
        {jointBuyer ? (
          <ErrorMessage
            name="jointBuyerAddress"
            render={(msg: string) => (
              <p className="error">
                <img src={ErrorIcon} role="presentation" alt="" />
                {msg}
              </p>
            )}
          />
        ) : (
          <ErrorMessage
            name="address"
            render={(msg: string) => (
              <p className="error">
                <img src={ErrorIcon} role="presentation" alt="" />
                {msg}
              </p>
            )}
          />
        )}
      </div>
      <div className="input-wrapper">
        <label htmlFor={jointBuyer ? 'jointBuyerOccupation' : 'occupation'}>
          {jointBuyer ? t('jointBuyerOccupation') : t('occupation')}*
        </label>
        {values.occupationsFound ? (
          <SelectDropdown
            name={jointBuyer ? 'jointBuyerOccupation' : 'occupation'}
            className={`${styles['select']} ${
              errors.city && touched.city ? 'error' : ''
            }`}
            placeholder={t('selectPlaceholder')}
            onChange={(option) => {
              setFieldValue(
                jointBuyer ? 'jointBuyerOccupation' : 'occupation',
                option
              );
            }}
            options={occupations}
            selectedOption={
              jointBuyer
                ? values.jointBuyerOccupation || null
                : values.occupation || null
            }
          />
        ) : (
          <Field
            name={jointBuyer ? 'jointBuyerOccupationName' : 'occupationName'}
            type="text"
            className={
              (errors.occupationName && touched.occupationName
                ? 'error'
                : '') ||
              (errors.jointBuyerOccupationName &&
              touched.jointBuyerOccupationName
                ? 'error'
                : '')
            }
          />
        )}
        {jointBuyer ? (
          values.occupationsFound ? (
            <ErrorMessage
              name="jointBuyerOccupation"
              render={(msg: string) => (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {msg}
                </p>
              )}
            />
          ) : (
            <ErrorMessage
              name="jointBuyerOccupationName"
              render={(msg: string) => (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {msg}
                </p>
              )}
            />
          )
        ) : values.occupationsFound ? (
          <ErrorMessage
            name="occupation"
            render={(msg: string) => (
              <p className="error">
                <img src={ErrorIcon} role="presentation" alt="" />
                {msg}
              </p>
            )}
          />
        ) : (
          <ErrorMessage
            name="occupationName"
            render={(msg: string) => (
              <p className="error">
                <img src={ErrorIcon} role="presentation" alt="" />
                {msg}
              </p>
            )}
          />
        )}
      </div>
      <div role="group" className="reservation-radio-buttons--small">
        <label
          htmlFor={jointBuyer ? 'jointBuyerMaritalStatus' : 'maritalStatus'}
        >
          {t('maritalStatus')}*
        </label>
        <div>
          <label>
            <div>
              <p>{t('single')}</p>
              <Field
                type="radio"
                name={jointBuyer ? 'jointBuyerMaritalStatus' : 'maritalStatus'}
                value={MaritalStatusEnum.SINGLE}
              />
            </div>
          </label>
          <label>
            <div>
              <p>{t('married')}</p>
              <Field
                type="radio"
                name={jointBuyer ? 'jointBuyerMaritalStatus' : 'maritalStatus'}
                value={MaritalStatusEnum.MARRIED}
              />
            </div>
          </label>
        </div>
      </div>

      <div className="reservation-sub-form">
        <h2 className="reservation-form-title">
          {jointBuyer
            ? t('jointBuyerPersonalIdentificationForm')
            : t('personalIdentificationForm')}
        </h2>
        <div role="group" className="reservation-radio-buttons--big">
          <div>
            {reservationContext.reservationUnitDetails?.project.destination
              .country.slug !== CountriesEnum.MONTENEGRO && (
              <label>
                <div>
                  <p>
                    <NationalIdIcon />
                    {t('nationalId')}
                  </p>
                  <Field
                    type="radio"
                    name={
                      jointBuyer
                        ? 'jointBuyerIdentificationForm'
                        : 'identificationForm'
                    }
                    value="nationalId"
                  />
                </div>
              </label>
            )}
            <label>
              <div>
                <p>
                  <PassportIcon />
                  {t('passport')}
                </p>
                <Field
                  type="radio"
                  name={
                    jointBuyer
                      ? 'jointBuyerIdentificationForm'
                      : 'identificationForm'
                  }
                  value="passport"
                />
              </div>
            </label>
          </div>
        </div>
        {values.jointBuyerIdentificationForm === 'nationalId' && jointBuyer && (
          <>
            <div className="input-wrapper">
              <label htmlFor="jointBuyerNationalIdNumber">
                {t('nationalIdNumber')}*
              </label>
              <Field
                name="jointBuyerNationalIdNumber"
                type="text"
                className={
                  errors.jointBuyerNationalIdNumber &&
                  touched.jointBuyerNationalIdNumber
                    ? 'error'
                    : ''
                }
              />

              <ErrorMessage
                name="jointBuyerNationalIdNumber"
                render={(msg: string) => (
                  <p className="error">
                    <img src={ErrorIcon} role="presentation" alt="" />
                    {msg}
                  </p>
                )}
              />
            </div>
            <div className="reservation-upload-container">
              <div className="input-wrapper">
                <label htmlFor="jointBuyerNationalIdFrontImage">
                  {t('nationalIdFrontImage')}*
                </label>
                <div className="reservation-upload">
                  <UploadIcon />

                  {jointBuyerNationalIdFrontImageText.length ? (
                    <>
                      <span className="reservation-upload__file">
                        {jointBuyerNationalIdFrontImageText}
                      </span>
                      <button
                        className="reservation-upload__remove"
                        onClick={() =>
                          setJointBuyerNationalIdFrontImageText('')
                        }
                      >
                        x
                      </button>
                    </>
                  ) : (
                    <span className="reservation-upload__placeholder">
                      {t('uploadImagePlaceholder')}
                    </span>
                  )}

                  <input
                    id="jointBuyerNationalIdFrontImage"
                    name="jointBuyerNationalIdFrontImage"
                    type="file"
                    accept="image/*,.pdf"
                    className={
                      errors.jointBuyerNationalIdFrontImage &&
                      touched.jointBuyerNationalIdFrontImage
                        ? 'error'
                        : ''
                    }
                    onClick={(event) => {
                      if (jointBuyerNationalIdFrontImageText.length) {
                        event.preventDefault();
                        event.currentTarget.value = '';
                        setJointBuyerNationalIdFrontImageText('');
                        setFieldValue('jointBuyerNationalIdFrontImage', {});
                      }
                    }}
                    onChange={(event) => {
                      if (event.currentTarget.files) {
                        setJointBuyerNationalIdFrontImageText(
                          event.currentTarget.files[0].name
                        );
                        setFieldValue(
                          'jointBuyerNationalIdFrontImage',
                          event.currentTarget.files[0]
                        );
                      }
                    }}
                  />
                </div>

                <ErrorMessage
                  name="jointBuyerNationalIdFrontImage"
                  render={(msg: string) => (
                    <p className="error">
                      <img src={ErrorIcon} role="presentation" alt="" />
                      {JSON.stringify(msg)}
                    </p>
                  )}
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="jointBuyerNationalIdBackImage">
                  {t('nationalIdBackImage')}*
                </label>
                <div className="reservation-upload">
                  <UploadIcon />

                  {jointBuyerNationalIdBackImageText.length ? (
                    <>
                      <span className="reservation-upload__file">
                        {jointBuyerNationalIdBackImageText}
                      </span>
                      <button
                        className="reservation-upload__remove"
                        onClick={() => setJointBuyerNationalIdBackImageText('')}
                      >
                        x
                      </button>
                    </>
                  ) : (
                    <span className="reservation-upload__placeholder">
                      {t('uploadImagePlaceholder')}
                    </span>
                  )}
                  <input
                    id="jointBuyerNationalIdBackImage"
                    name="jointBuyerNationalIdBackImage"
                    type="file"
                    accept="image/*,.pdf"
                    className={
                      errors.jointBuyerNationalIdBackImage &&
                      touched.jointBuyerNationalIdBackImage
                        ? 'error'
                        : ''
                    }
                    onClick={(event) => {
                      if (jointBuyerNationalIdBackImageText.length) {
                        event.preventDefault();
                        event.currentTarget.value = '';
                        setJointBuyerNationalIdBackImageText('');
                        setFieldValue('jointBuyerNationalIdBackImage', {});
                      }
                    }}
                    onChange={(event) => {
                      if (event.currentTarget.files) {
                        setJointBuyerNationalIdBackImageText(
                          event.currentTarget.files[0].name
                        );
                        setFieldValue(
                          'jointBuyerNationalIdBackImage',
                          event.currentTarget.files[0]
                        );
                      }
                    }}
                  />
                </div>

                <ErrorMessage
                  name="jointBuyerNationalIdBackImage"
                  render={(msg: string) => (
                    <p className="error">
                      <img src={ErrorIcon} role="presentation" alt="" />
                      {JSON.stringify(msg)}
                    </p>
                  )}
                />
              </div>
            </div>
          </>
        )}
        {values.jointBuyerIdentificationForm === 'passport' && jointBuyer && (
          <>
            <div className="input-wrapper">
              <label htmlFor="jointBuyerPassportNumber">
                {t('passportNumber')}*
              </label>
              <Field
                name="jointBuyerPassportNumber"
                type="text"
                className={
                  errors.jointBuyerPassportNumber &&
                  touched.jointBuyerPassportNumber
                    ? 'error'
                    : ''
                }
              />

              <ErrorMessage
                name="jointBuyerPassportNumber"
                render={(msg: string) => (
                  <p className="error">
                    <img src={ErrorIcon} role="presentation" alt="" />
                    {msg}
                  </p>
                )}
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="jointBuyerPassportImage">
                {t('passportImage')}*
              </label>
              <div className="reservation-upload">
                <UploadIcon />

                {jointBuyerPassportImageText.length ? (
                  <>
                    <span className="reservation-upload__file">
                      {jointBuyerPassportImageText}
                    </span>
                    <button
                      className="reservation-upload__remove"
                      onClick={() => setJointBuyerPassportImageText('')}
                    >
                      x
                    </button>
                  </>
                ) : (
                  <span className="reservation-upload__placeholder">
                    {t('uploadImagePlaceholder')}
                  </span>
                )}
                <input
                  id="jointBuyerPassportImage"
                  name="jointBuyerPassportImage"
                  type="file"
                  accept="image/*,.pdf"
                  className={
                    errors.jointBuyerPassportImage &&
                    touched.jointBuyerPassportImage
                      ? 'error'
                      : ''
                  }
                  onClick={(event) => {
                    if (jointBuyerPassportImageText.length) {
                      event.preventDefault();
                      event.currentTarget.value = '';
                      setJointBuyerPassportImageText('');
                      setFieldValue('jointBuyerPassportImage', '');
                    }
                  }}
                  onChange={(event) => {
                    if (event.currentTarget.files) {
                      setJointBuyerPassportImageText(
                        event.currentTarget.files[0].name
                      );
                      setFieldValue(
                        'jointBuyerPassportImage',
                        event.currentTarget.files[0]
                      );
                    }
                  }}
                />
              </div>

              <ErrorMessage
                name="jointBuyerPassportImage"
                render={(msg: string) => (
                  <p className="error">
                    <img src={ErrorIcon} role="presentation" alt="" />
                    {JSON.stringify(msg)}
                  </p>
                )}
              />
            </div>
          </>
        )}
        {values.identificationForm === 'nationalId' && !jointBuyer && (
          <>
            <div className="input-wrapper">
              <label htmlFor="nationalIdNumber">{t('nationalIdNumber')}*</label>
              <Field
                name="nationalIdNumber"
                type="text"
                className={
                  errors.nationalIdNumber && touched.nationalIdNumber
                    ? 'error'
                    : ''
                }
              />

              <ErrorMessage
                name="nationalIdNumber"
                render={(msg: string) => (
                  <p className="error">
                    <img src={ErrorIcon} role="presentation" alt="" />
                    {msg}
                  </p>
                )}
              />
            </div>
            <div className="reservation-upload-container">
              <div className="input-wrapper">
                <label htmlFor="nationalIdFrontImage">
                  {t('nationalIdFrontImage')}*
                </label>
                <div className="reservation-upload">
                  <UploadIcon />

                  {nationalIdFrontImageText.length ? (
                    <>
                      <span className="reservation-upload__file">
                        {nationalIdFrontImageText}
                      </span>
                      <button
                        className="reservation-upload__remove"
                        onClick={() => setNationalIdFrontImageText('')}
                      >
                        x
                      </button>
                    </>
                  ) : (
                    <span className="reservation-upload__placeholder">
                      {t('uploadImagePlaceholder')}
                    </span>
                  )}
                  <input
                    id="nationalIdFrontImage"
                    name="nationalIdFrontImage"
                    type="file"
                    accept="image/*,.pdf"
                    className={
                      errors.nationalIdFrontImage &&
                      touched.nationalIdFrontImage
                        ? 'error'
                        : ''
                    }
                    onClick={(event) => {
                      if (nationalIdFrontImageText.length) {
                        event.preventDefault();
                        event.currentTarget.value = '';
                        setNationalIdFrontImageText('');
                        setFieldValue('nationalIdFrontImage', {});
                      }
                    }}
                    onChange={(event) => {
                      if (event.currentTarget.files) {
                        setNationalIdFrontImageText(
                          event.currentTarget.files[0].name
                        );
                        setFieldValue(
                          'nationalIdFrontImage',
                          event.currentTarget.files[0]
                        );
                      }
                    }}
                  />
                </div>

                <ErrorMessage
                  name="nationalIdFrontImage"
                  render={(msg: string) => (
                    <p className="error">
                      <img src={ErrorIcon} role="presentation" alt="" />
                      {JSON.stringify(msg)}
                    </p>
                  )}
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="nationalIdBackImage">
                  {t('nationalIdBackImage')}*
                </label>
                <div className="reservation-upload">
                  <UploadIcon />

                  {nationalIdBackImageText.length ? (
                    <>
                      <span className="reservation-upload__file">
                        {nationalIdBackImageText}
                      </span>
                      <button
                        className="reservation-upload__remove"
                        onClick={() => setNationalIdBackImageText('')}
                      >
                        x
                      </button>
                    </>
                  ) : (
                    <span className="reservation-upload__placeholder">
                      {t('uploadImagePlaceholder')}
                    </span>
                  )}
                  <input
                    id="nationalIdBackImage"
                    name="nationalIdBackImage"
                    type="file"
                    accept="image/*,.pdf"
                    className={
                      errors.nationalIdBackImage && touched.nationalIdBackImage
                        ? 'error'
                        : ''
                    }
                    onClick={(event) => {
                      if (nationalIdBackImageText.length) {
                        event.preventDefault();
                        event.currentTarget.value = '';
                        setNationalIdBackImageText('');
                        setFieldValue('nationalIdBackImage', {});
                      }
                    }}
                    onChange={(event) => {
                      if (event.currentTarget.files) {
                        setNationalIdBackImageText(
                          event.currentTarget.files[0].name
                        );
                        setFieldValue(
                          'nationalIdBackImage',
                          event.currentTarget.files[0]
                        );
                      }
                    }}
                  />
                </div>

                <ErrorMessage
                  name="nationalIdBackImage"
                  render={(msg: string) => (
                    <p className="error">
                      <img src={ErrorIcon} role="presentation" alt="" />
                      {JSON.stringify(msg)}
                    </p>
                  )}
                />
              </div>
            </div>
          </>
        )}
        {values.identificationForm === 'passport' && !jointBuyer && (
          <>
            <div className="input-wrapper">
              <label htmlFor="passportNumber">{t('passportNumber')}*</label>
              <Field
                name="passportNumber"
                type="text"
                className={
                  errors.passportNumber && touched.passportNumber ? 'error' : ''
                }
              />

              <ErrorMessage
                name="passportNumber"
                render={(msg: string) => (
                  <p className="error">
                    <img src={ErrorIcon} role="presentation" alt="" />
                    {msg}
                  </p>
                )}
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="passportImage">{t('passportImage')}*</label>
              <div className="reservation-upload">
                <UploadIcon />
                {passportImageText.length ? (
                  <>
                    <span className="reservation-upload__file">
                      {passportImageText}
                    </span>
                    <button
                      className="reservation-upload__remove"
                      onClick={() => setPassportImageText('')}
                    >
                      x
                    </button>
                  </>
                ) : (
                  <span className="reservation-upload__placeholder">
                    {t('uploadImagePlaceholder')}
                  </span>
                )}
                <input
                  id="passportImage"
                  name="passportImage"
                  type="file"
                  accept="image/*,.pdf"
                  className={
                    errors.passportImage && touched.passportImage ? 'error' : ''
                  }
                  onClick={(event) => {
                    if (passportImageText.length) {
                      event.preventDefault();
                      event.currentTarget.value = '';
                      setPassportImageText('');
                      setFieldValue('passportImage', '');
                    }
                  }}
                  onChange={(event) => {
                    if (event.currentTarget.files) {
                      setPassportImageText(event.currentTarget.files[0].name);
                      setFieldValue(
                        'passportImage',
                        event.currentTarget.files[0]
                      );
                    }
                  }}
                />
              </div>

              <ErrorMessage
                name="passportImage"
                render={(msg: string) => (
                  <p className="error">
                    <img src={ErrorIcon} role="presentation" alt="" />
                    {JSON.stringify(msg)}
                  </p>
                )}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ReservationInfoFormFields;
