import { ChangeEvent, HTMLProps, InputHTMLAttributes } from 'react';

const STEP_VALUE = 0.1;

type CustomInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'type' | 'value'
>;

interface RangeSliderProps extends CustomInputProps {
  sliderValues: number[];
  inputValue?: number;
  onChange: (n: number) => void;
  sliderWrapperProps?: HTMLProps<HTMLDivElement>;
  showBottomValues?: boolean;
  bottomValueProps?: HTMLProps<HTMLDivElement>;
}
export const RangeSlider = ({
  sliderValues,
  inputValue,
  onChange,
  showBottomValues,
  sliderWrapperProps,
  ...inputProps
}: RangeSliderProps) => {
  // rounding to 1 decimal(to fit with STEP_VALUE)
  let minValue = 0;
  let maxValue = 0;
  let fixedValues: number[] = [];

  if (sliderValues[0] !== undefined) {
    fixedValues = sliderValues.map((value) => parseFloat(value.toFixed(1)));
    minValue = Math.min(...fixedValues);
    maxValue = Math.max(...fixedValues);
  }

  const sliderValuesLen = sliderValues.length;

  const snapToValue = (value: number) => {
    // To get the actual value we iterate the real values, not the rounded ones
    let closestValue = sliderValues[0];
    for (let i = 1; i < sliderValuesLen; i++) {
      if (Math.abs(sliderValues[i] - value) < Math.abs(closestValue - value)) {
        closestValue = sliderValues[i];
      }
    }
    return closestValue;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    const snappedValue = snapToValue(value);
    onChange(snappedValue);
  };

  return (
    <div style={{ position: 'relative' }} {...sliderWrapperProps}>
      <input
        type="range"
        min={minValue}
        max={maxValue}
        step={STEP_VALUE}
        value={inputValue}
        onChange={handleChange}
        list="marks"
        style={{
          width: '100%',
        }}
        {...inputProps}
      />

      {showBottomValues &&
        fixedValues.map((value, i) => {
          const labelPosition = (value - minValue) / (maxValue - minValue);

          return (
            <span
              key={value}
              style={{
                position: 'absolute',
                left: labelPosition * 100 + '%',
                bottom: '-25px',
                transform:
                  i === 0
                    ? undefined
                    : i === sliderValuesLen - 1
                    ? 'translateX(-100%)'
                    : 'translateX(-50%)',
              }}
            >
              {
                // render the actual value, not the rounded one
                sliderValues[i]
              }
            </span>
          );
        })}
    </div>
  );
};
