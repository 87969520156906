import { useContext, useEffect, useState } from 'react';
import { ROUTES } from '../../api/routes';
import { GoldButton } from '../common/button/button';
import styles from './resume-reservation-banner.module.scss';
import { useTranslation } from 'react-i18next';
import { USER_CONTEXT } from '../../contexts/user-context';
import { getReservationSalesStatuses } from '../../utils/authentication.utils';
import { UserReservationSale } from '../../definitions/interfaces/common.interface';

export interface ResumeReservationProps {
  banner_home: boolean;
}
export function ResumeReservationBanner(
  props: Readonly<ResumeReservationProps>
) {
  const { t } = useTranslation();
  const { userReservationDetails } = useContext(USER_CONTEXT);
  const [activeSale, setActiveSale] = useState<UserReservationSale>();

  useEffect(() => {
    if (userReservationDetails) {
      setActiveSale(getReservationSalesStatuses(userReservationDetails).pop());
    }
  }, [userReservationDetails]);

  if (activeSale) {
    return (
      <div
        className={`${styles['wrapper']} resume-reservation__banner ${
          props.banner_home && `${styles['wrapper--home']} wrapper--home`
        } `}
      >
        <p>
          {t('resumeReservationDesc')} {activeSale.destination.name}{' '}
          {t('fromHere')}
        </p>
        <GoldButton
          asLink
          to={ROUTES.ReserverProperty.getPath?.(activeSale.unit.id)}
        >
          {t('resumeReservation')}
        </GoldButton>
      </div>
    );
  } else {
    return null;
  }
}

export default ResumeReservationBanner;
