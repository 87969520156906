import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import {
  Auth,
  ConfirmationResult,
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  User,
  UserCredential,
} from 'firebase/auth';
import { exist as _exist } from '@orascom/utils';

/**
 * Firebase configuration object used in Firebase Initialization function.
 * @type {FirebaseOptions}
 */
const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.NX_FIREBASE_API_KEY,
  authDomain: process.env.NX_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NX_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NX_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NX_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NX_FIREBASE_APP_ID,
};

/**
 * implementation of firebase related functionalities.
 *
 * @export
 */
export class Firebase {
  /**
   * firebase App Instance.
   *
   * @static
   */
  static app: FirebaseApp;

  /**
   * firebase authentication service.
   *
   * @static
   */
  static auth: Auth;

  /**
   * firebase RecaptchaVerifier Object.
   *
   * @static
   */
  static recaptchaVerifier: RecaptchaVerifier;

  /**
   * firebase confirmation object used in verifying mobile number.
   *
   * @static
   */
  static confirmationResult?: ConfirmationResult;

  /**
   * current logged in firebase user details.
   *
   * @static
   */
  static user?: User;

  /**
   *  Initialize the firebase App with the Recaptcha verifier.
   *
   * @static
   * @param {string} recaptchaVerifierContainerOrId id of the sign-in button or
   * the div containing it in order to render the recaptcha inside.
   * @memberof Firebase
   */
  public static init(recaptchaVerifierContainerOrId = 'recaptcha-verifier', reinitializeRecaptcha = false) {
    if (!_exist(this.app)) {
      this.app = initializeApp(firebaseConfig);
    }

    if (!_exist(this.auth)) {
      this.auth = getAuth();
      try {
        this.recaptchaVerifier = new RecaptchaVerifier(
          recaptchaVerifierContainerOrId,
          {
            size: 'invisible',
          },
          this.auth
        );
        (window as any).recaptchaVerifier = this.recaptchaVerifier;
      } catch (error) {
        console.error({ error });
      }
    } else if(reinitializeRecaptcha) {
      try {
        this.recaptchaVerifier = new RecaptchaVerifier(
          recaptchaVerifierContainerOrId,
          {
            size: 'invisible',
          },
          this.auth
        );
        (window as any).recaptchaVerifier = this.recaptchaVerifier;
      } catch (error) {
        console.error({ error });
      }
    }
  }

  /**
   * Sign in user using phone number.
   *
   * @static
   * @param {string} phoneNumber Full Phone number of the user including
   * the country code.
   * @return {*}  {Promise<void>} Promise to sign the user in using firebase.
   */
  public static signIn(phoneNumber: string): Promise<void> {
    this.confirmationResult = undefined;
    Firebase.init('recaptcha-verifier', true);

    return signInWithPhoneNumber(this.auth, phoneNumber, this.recaptchaVerifier)
      .then((confirmationResult: ConfirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        this.confirmationResult = confirmationResult;
      })
      .catch((error: Error) => {
        console.error(error);
        this.recaptchaVerifier?.render();
        return Promise.reject(error);
      });
  }

  /**
   * Verify phone number through  OTP sent by Firebase.
   * This function needs to be called after the user attempt to sign in.
   *
   * @static
   * @param {string} code 6 digits OTP entered by the user.
   * @return {*}  {(Promise<User> | undefined)} Promise to return the signed in user.
   * @memberof Firebase
   */
  public static verifyPhoneNumber(
    code: string
  ): Promise<UserCredential> | undefined {
    if (!_exist(this.confirmationResult)) {
      return Promise.reject();
    }

    return this.confirmationResult?.confirm(code).catch((error: Error) => {
      // User couldn't sign in (bad verification code?)
      // ...
      console.error('[Firebase.verifyPhoneNumber] error', error);
      return Promise.reject(error);
    });
  }

  public static logout(): Promise<unknown> {
    Firebase.init();

    return this.auth.signOut();
  }
}
