import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styles from './units-filters.module.scss';
import { InputHTMLAttributes } from 'react';

interface OnResaleButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  wrapperClassName?: string;
}
const OnResaleButton = ({
  wrapperClassName,
  disabled,
  ...inputProps
}: OnResaleButtonProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const resaleValue = searchParams.get('on_resale');
  return (
    <div
      className={`${styles['toggle']} ${wrapperClassName} ${
        disabled && 'toggle-disabled'
      }`}
    >
      <label className="switch">
        <input
          type="checkbox"
          readOnly
          checked={resaleValue === '1'}
          onChange={() => {
            setSearchParams((searchParams) => {
              searchParams.set('on_resale', resaleValue === '1' ? '0' : '1');
              return searchParams;
            }, { replace: true });
          }}
          {...inputProps}
        />
        <span className="slider round"></span>
      </label>
      <p>{t('onResale')}</p>
    </div>
  );
};

export default OnResaleButton;
