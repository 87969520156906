import React from 'react';
import { useSearchParams } from 'react-router-dom';

export const useClearSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params: { label: string; value: string }[] = [];
  searchParams.forEach((value, key) => {
    params.push({ label: key, value });
  });

  const onClearAllFilters = () => {
    setSearchParams((searchParams) => {
      params.forEach((param) => {
        searchParams.delete(param.label);
      });

      return searchParams;
    }, { replace: true });
  };
  return { params, onClearAllFilters, searchParams, setSearchParams };
};

export default useClearSearchParams;
