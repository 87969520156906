/**
 * @author Ahmed Serag
 * @date 2022-12-20
 * @description implementation of authentication related utility functions.
 * @filename authentication.ts
 */
import { Authentication as AuthenticationAPI } from '../api/authentication.api';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getPayloadData, handleError } from '@orascom/utils';
import { Firebase } from './firebase';
import { UserCredential } from 'firebase/auth';
import {
  User,
  UserReservationIds,
  UserReservationSaleStatusEnum,
  UserResponse,
  UnitDetails,
} from '@orascom/api-interfaces';
import {
  UserReservationDetailsInterface,
  UserReservationSale,
} from '../definitions/interfaces/common.interface';

/**
 * group of Authentication helper functionalities.
 */
export class Authentication {
  public static registerUserOnFirebase(
    phone: string,
    email: string
  ): Promise<unknown> {
    return AuthenticationAPI.checkUserDetails(phone, email)
      .then(() => {
        return Firebase.signIn(phone);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static registerUserOnBackend(
    OTP: string,
    email: string,
    name: string,
    phone: string
  ) {
    const firebasePromise = Firebase.verifyPhoneNumber(OTP);
    let promise: Promise<any>;

    if (!firebasePromise) {
      return Promise.reject(new Error("can't verify OTP"));
    }

    promise = firebasePromise;

    promise = promise.then(
      (userCredential: UserCredential): Promise<unknown> => {
        return AuthenticationAPI.register({
          email,
          phone: userCredential.user.phoneNumber ?? phone,
          name,
          token: (userCredential.user as any).accessToken,
        })
          .then((result) => {
            return getPayloadData(result);
          })
          .catch((error) => {
            return Promise.reject(handleError(error));
          });
      }
    );

    return promise.catch((error) => Promise.reject(handleError(error)));
  }

  public static signInOnFirebase(phone: string): Promise<unknown> {
    const promise = AuthenticationAPI.checkUserDetails(phone)
      .then(() => {
        return Promise.reject('User Not found');
      })
      .catch((errors) => {
        // TODO: handle errors based on error message!
        if (
          errors?.message ===
          'The phone number or email address are already in use'
        ) {
          return Firebase.signIn(phone);
        }
        if (errors?.phone) {
          return Promise.reject('Phone number is invalid');
        }
        return Promise.reject(errors);
      });

    return promise.catch((error) => {
      return Promise.reject(handleError(error));
    });
  }

  public static signInOnBackend(OTP: string): Promise<UserResponse> {
    const firebasePromise = Firebase.verifyPhoneNumber(OTP);
    let promise: Promise<any>;

    if (!firebasePromise) {
      return Promise.reject(new Error("can't verify OTP"));
    }

    promise = firebasePromise;

    promise = promise.then(
      (userCredential: UserCredential): Promise<unknown> => {
        return AuthenticationAPI.login(
          userCredential.user.phoneNumber as string,
          (userCredential.user as any).accessToken
        )
          .then((result) => {
            return getPayloadData(result);
          })
          .catch((error) => {
            return Promise.reject(handleError(error));
          });
      }
    );

    return promise.catch((error) => Promise.reject(handleError(error)));
  }

  public static getLoggedInUser(): Promise<User> {
    return AuthenticationAPI.getCurrentUser()
      .then((result) => {
        return getPayloadData(result) as unknown as User;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static logout(): Promise<unknown> {
    return Promise.all([AuthenticationAPI.logout(), Firebase.logout()]).catch(
      (errors) => {
        console.log(errors);
        return Promise.reject(handleError(errors));
      }
    );
  }

  public static resendOTP(phone: string): Promise<unknown> {
    return Firebase.signIn(phone).catch((error) => {
      console.log(error);
      Promise.reject(handleError(error));
    });
  }

  public static getUserReservationDetails(): Promise<UserReservationDetailsInterface> {
    return AuthenticationAPI.getUserReservationDetails()
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}

export const getReservationLeadIdOrCustomerId = (
  data?: UserReservationDetailsInterface | null,
  destinationId?: string | null
): UserReservationIds => {
  const customer = data?.customers?.find(
    (c) => c?.destination?.id.toString() === destinationId
  );

  const lead = data?.leads.find(
    (l) => l?.destination?.id.toString() === destinationId
  );
  return {
    customerId: customer?.customer_id,
    leadId: lead?.lead_id,
  };
};

export const getReservationSalesStatuses = (
  data: UserReservationDetailsInterface
) => {
  const activeSale = (saleStatus: string) =>
    saleStatus !== UserReservationSaleStatusEnum.DONE;
  return data.sales.filter(
    (sale) =>
      sale?.crm_status !== 'canceled' &&
      sale?.crm_status !== 'contracted' &&
      sale?.portal_sale === true &&
      activeSale(sale?.status)
  );
};

export const getDoneReservationSales = (
  data: UserReservationDetailsInterface
) => {
  const doneSale = (saleStatus: string) =>
    saleStatus === UserReservationSaleStatusEnum.DONE;

  return data.sales.filter(
    (sale) =>
      (sale?.crm_status === 'contracted' || sale?.crm_status === 'reserved') &&
      doneSale(sale?.status)
  );
};

export const getUserSaleByUnitId = (
  userReservations?: UserReservationDetailsInterface | null,
  unitId?: string | null
): UserReservationSale | undefined => {
  return userReservations?.sales?.find(
    (sale) =>
      sale.unit?.id &&
      sale.unit.id.toString() === unitId &&
      sale?.crm_status !== 'canceled'
  );
};

export const getUserSaleUnits = (
  userReservationsSales: UserReservationSale[]
): UnitDetails[] => {
  return userReservationsSales.map((sale) => sale.unit);
};
