import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './payment-summary.module.scss';
import warningIcon from '../../assets/icons/warning.svg';
import greenTick from '../../assets/icons/green-tick.svg';
import {
  Accordion,
  GoldButton,
  RESERVATION_CONTEXT,
} from '@orascom/common-components';
import {
  ReservationStateEnum,
  UnitPaymentTerms,
  InstallmentFrequencyMapper,
} from '@orascom/api-interfaces';
import { CommonEventParameters, exist } from '@orascom/utils';
import { usePaymentSummary } from './usePaymentSummary';
import { USER_CONTEXT } from '../../contexts/user-context';
import { analyticsDownLoadPaymentPlanEvent } from '../../utils/analytics-events';

interface PaymentSummaryProps {
  alwaysOpen?: boolean;
  twoColumns?: boolean;
  termsAndConditions?: boolean;
  readTerms: boolean;
  handleTermsCheckboxClick: () => void;
  handleShowTermsOnly: () => void;
  selectedPaymentTerms: UnitPaymentTerms;
}

export function PaymentSummary(props: Readonly<PaymentSummaryProps>) {
  const { t } = useTranslation();
  const userContext = useContext(USER_CONTEXT);
  const { setReservationState, sale, selectedPaymentTerms } =
    useContext(RESERVATION_CONTEXT);
  const paymentSummary = usePaymentSummary(
    selectedPaymentTerms ?? props.selectedPaymentTerms,
    sale
  );
  const containerClass = `${styles['container']} ${
    props.twoColumns ? styles['container-columns'] : ''
  }`;

  const commonEventParams: CommonEventParameters = {
    userId: userContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Shopper',
    pageName: location.pathname,
  };

  return (
    <div className={containerClass}>
      <Accordion
        title={t('paymentPlan')}
        arrows
        alwaysOpen={props.alwaysOpen}
        downloadUrl
        commonEventParams={commonEventParams}
        pdfAnalyticsEvent={() =>
          analyticsDownLoadPaymentPlanEvent(commonEventParams)
        }
      >
        <div className={styles['wrapper']} id="payment-plan">
          {props.selectedPaymentTerms && (
            <div
              className={`${styles['details']} ${
                props.twoColumns ? styles['details-columns'] : ''
              }`}
            >
              <div className={styles['details-section-wrapper']}>
                <h4 className="orascom__sub-title">{t('unitPrice')}</h4>
                <dl className={styles['details-section']}>
                  <div className={styles['details-item']}>
                    <dt>{t('originalUnitPrice')}</dt>
                    <dd>
                      {paymentSummary.originalUnitPrice}{' '}
                      <small className={styles['currency']}>
                        {paymentSummary.currency}
                      </small>
                    </dd>
                  </div>
                  <div
                    className={`${styles['details-item']} ${styles['details-item--discount']}`}
                  >
                    <dt>{t('discount')}</dt>
                    <dd>
                      {paymentSummary.discountAmount}{' '}
                      <small className={styles['currency']}>
                        {paymentSummary.currency} (
                        {paymentSummary.discountPercent} %)
                      </small>
                    </dd>
                  </div>
                  <div className={styles['details-item']}>
                    <dt>{t('unitPriceAfterDiscount')}</dt>
                    <dd className={styles['bold-price']}>
                      {paymentSummary.unitPriceAfterDiscount}{' '}
                      <small className={styles['currency']}>
                        {paymentSummary.currency}
                      </small>
                    </dd>
                  </div>
                </dl>
              </div>
              <div className={styles['details-section-wrapper']}>
                <h4 className="orascom__sub-title">{t('unitInstallments')}</h4>
                <dl className={styles['details-section']}>
                  {paymentSummary.totalNumberOfInstallments > 0 &&
                  paymentSummary.beforeDeliveryInstallmentsCount <= 0 ? (
                    <div className={styles['details-item']}>
                      <dt>
                        {t('installments')} (
                        {paymentSummary.totalNumberOfInstallments})
                      </dt>
                      <dd>
                        {paymentSummary.installmentAmount}
                        <small className={styles['currency']}>
                          {' '}
                          {paymentSummary.currency}
                        </small>
                      </dd>
                    </div>
                  ) : null}
                  {exist(paymentSummary.beforeDeliveryInstallmentsCount) &&
                  paymentSummary.beforeDeliveryInstallmentsCount > 0 ? (
                    <div className={styles['details-item']}>
                      <dt>
                        {t('beforeDeliveryInstallments')} (
                        {paymentSummary.beforeDeliveryInstallmentsCount})
                      </dt>
                      <dd>
                        {paymentSummary.beforeDeliveryInstallmentAmount}
                        <small className={styles['currency']}>
                          {' '}
                          {paymentSummary.currency}
                        </small>
                      </dd>
                    </div>
                  ) : null}
                  {exist(paymentSummary.afterDeliveryInstallmentsCount) &&
                  paymentSummary.afterDeliveryInstallmentsCount > 0 ? (
                    <div className={styles['details-item']}>
                      <dt>
                        {t('afterDeliveryInstallments')} (
                        {paymentSummary.afterDeliveryInstallmentsCount})
                      </dt>
                      <dd>
                        {paymentSummary.afterDeliveryInstallmentAmount}
                        <small className={styles['currency']}>
                          {' '}
                          {paymentSummary.currency}
                        </small>
                      </dd>
                    </div>
                  ) : null}
                  <div className={styles['details-item']}>
                    <dt>{t('frequency')}</dt>
                    <dd>
                      {
                        InstallmentFrequencyMapper[
                          paymentSummary.installmentsFrequency
                        ]
                      }
                    </dd>
                  </div>
                  <div className={styles['details-item']}>
                    <dt>{t('duration')}</dt>
                    <dd>{paymentSummary.durationInYears} Years</dd>
                  </div>
                  {paymentSummary.deliveryAmount &&
                  paymentSummary.deliveryAmount !== '0' ? (
                    <div className={styles['details-item']}>
                      <dt>
                        {t('deliveryPercent')} ({paymentSummary.deliveryPercent}
                        %)
                      </dt>
                      <dd>
                        {paymentSummary.deliveryAmount}{' '}
                        <small className={styles['currency']}>
                          {' '}
                          {paymentSummary.currency}
                        </small>{' '}
                      </dd>
                    </div>
                  ) : null}
                </dl>
              </div>

              {(paymentSummary.clubHouseFees &&
                paymentSummary.clubHouseFees !== '0') ||
              (paymentSummary.maintenanceFees &&
                paymentSummary.maintenanceFees !== '0') ? (
                <div className={styles['details-section-wrapper']}>
                  <h4 className="orascom__sub-title">
                    {t('clubhouseAndMaintenanceFees')}
                  </h4>
                  <dl className={styles['details-section']}>
                    {paymentSummary.clubHouseFees ? (
                      <div className={styles['details-item']}>
                        <dt>{t('clubhouseFees')}</dt>
                        <dd>
                          {paymentSummary.clubHouseFees}{' '}
                          <small className={styles['currency']}>
                            {paymentSummary.currency}
                          </small>
                        </dd>
                      </div>
                    ) : null}
                    {paymentSummary.maintenanceFees ? (
                      <div className={styles['details-item']}>
                        <dt>{t('maintenanceFees')}</dt>
                        <dd>
                          {paymentSummary.maintenanceFees}{' '}
                          <small className={styles['currency']}>
                            {paymentSummary.currency}
                          </small>
                        </dd>
                      </div>
                    ) : null}
                    <div className={styles['details-item']}>
                      <dt>
                        {t('clubhouseInstallments')} (
                        {paymentSummary.clubHouseInstallmentCount})
                      </dt>
                      <dd>
                        {paymentSummary.clubHouseInstallmentAmount}{' '}
                        <small className={styles['currency']}>
                          {paymentSummary.currency}
                        </small>
                      </dd>
                    </div>
                  </dl>
                </div>
              ) : null}
              <div className={styles['details-section-wrapper']}>
                <h4 className="orascom__sub-title">{t('totalPrice')}</h4>
                <dl
                  className={`${styles['details-section']} ${styles['details-item--span']}`}
                >
                  <div className={styles['details-item']}>
                    <dt>
                      {t('downPayment')}{' '}
                      <small className={styles['details-item-note']}>
                        {t('downpaymentDisclaimer')}
                      </small>
                    </dt>
                    <dd className={styles['bold-price']}>
                      {paymentSummary.downPayment}{' '}
                      <small className={styles['currency']}>
                        {paymentSummary.currency}
                      </small>
                    </dd>
                  </div>
                  <div className={styles['details-item']}>
                    <dt>{t('totalUnitPrice')}</dt>
                    <dd className={styles['bold-price']}>
                      {paymentSummary.finalUnitPrice}{' '}
                      <small className={styles['currency']}>
                        {paymentSummary.currency}
                      </small>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          )}

          {!props.alwaysOpen && (
            <div className={styles['reservation-fee']}>
              <dl className={styles['details-item']}>
                <dt>
                  {t('reservationFee')}{' '}
                  {props.twoColumns && (
                    <p>
                      <img src={greenTick} alt="" role="presentation" />{' '}
                      <span>{t('paidOnline')}</span>
                    </p>
                  )}
                </dt>
                <dd>
                  {paymentSummary?.reservationAmount}{' '}
                  <small className={styles['currency']}>
                    {paymentSummary.currency}
                  </small>
                </dd>
              </dl>
              <div className={styles['reservation-fee-note']}>
                <img src={warningIcon} role="presentation" alt="" />{' '}
                <p>
                  <span>{t('paymentSummaryNote')}</span>
                  <button
                    type="button"
                    className={styles['terms-link']}
                    onClick={props.handleShowTermsOnly}
                  >
                    {t('terms&Conditions')}
                  </button>
                </p>
              </div>
            </div>
          )}

          {props.termsAndConditions && (
            <div className={styles['terms-wrapper']}>
              <div className={styles['reserve-now']}>
                <p>{t('reserveNow')}</p>
                <p>
                  {paymentSummary?.reservationAmount}{' '}
                  <small className={styles['currency']}>
                    {paymentSummary?.currency}
                  </small>
                </p>
              </div>
              <div className={styles['terms']}>
                <input
                  type="checkbox"
                  checked={props.readTerms}
                  readOnly
                  name="terms-checkbox"
                  id="terms-checkbox"
                  onClick={props.handleTermsCheckboxClick}
                />
                <label htmlFor="terms-checkbox">
                  <span>{t('haveReadAndAccepted')} </span>
                  <span className={styles['terms-link']}>
                    {t('terms&Conditions')}
                  </span>
                  <span>{t('howTheyAffectPrice')}</span>
                </label>
              </div>
              <GoldButton
                disabled={!props.readTerms}
                onClick={() => {
                  setReservationState?.(
                    ReservationStateEnum.PAY_RESERVATION_FEE
                  );
                }}
              >
                {t('proceedToCheckout')}
              </GoldButton>
            </div>
          )}
        </div>
      </Accordion>
    </div>
  );
}

export default PaymentSummary;
