import { useState } from 'react';
import styles from './unit-video.module.scss';
import playVideoButton from '../../assets/icons/play-video.svg';
import { useTranslation } from 'react-i18next';

/* eslint-disable-next-line */
export interface UnitVideoProps {
  unitVideo: string;
  unitVideoThumbnail: string;
}

export function UnitVideo(props: Readonly<UnitVideoProps>) {
  const { t } = useTranslation();
  const [playVideo, setPlayVideo] = useState(false);

  const handlePlayVideo = () => {
    const video = document.getElementById('unit-video') as HTMLVideoElement;
    if (video) {
      if (video.paused) {
        // Play the video
        video.play();
        setPlayVideo(true);
      } else {
        // Pause the video
        video.pause();
        setPlayVideo(false);
      }
    }
  };

  return (
    <div className={styles['unit-video']}>
      <div className={styles['headings']}>
        <h1 className="orascom__title">{t('exploreYourNewHome')}</h1>
        <h2 className="orascom__sub-title">{t('takeVideoTour')}</h2>
      </div>
      <button
        className={styles['video-container']}
        onClick={() => handlePlayVideo()}
      >
        <video
          data-automation="VideoPlayer"
          height="100%"
          width="100%"
          id="unit-video"
          preload="metadata"
          aria-label="video-player"
          poster={props.unitVideoThumbnail}
          className="video"
          loop
        >
          <source src={props.unitVideo} type="video/mp4" />
        </video>
        <button
          className={`${playVideo ? styles['pause'] : styles['play']} ${
            styles['video-button']
          }`}
        >
          <img src={playVideoButton} alt="play-video" />
        </button>
      </button>
    </div>
  );
}

export default UnitVideo;
