import styles from './sales-offer-installment-plan.module.scss';
import { UnitPaymentTerms } from '@orascom/api-interfaces';
import { v4 as uuidv4 } from 'uuid';

function SalesOfferInstallmentPlan({
  selectedPaymentTerms,
}: Readonly<{
  selectedPaymentTerms: UnitPaymentTerms;
}>) {
  const installmentsBefore = Array(
    selectedPaymentTerms.total_number_of_installments_before_delivery > 0
      ? selectedPaymentTerms.total_number_of_installments_before_delivery
      : 0
  );
  const installmentBeforeStart = selectedPaymentTerms?.first_installment_amount
    ? 2
    : 1;
  const installmentsAfter = Array(
    selectedPaymentTerms.total_number_of_installments_after_delivery > 0
      ? selectedPaymentTerms.total_number_of_installments_after_delivery
      : 0
  );
  let installmentAfterStart =
    installmentsBefore?.length + installmentBeforeStart;

  if (selectedPaymentTerms?.delivery_amount > 0) {
    installmentAfterStart += 1;
  }

  const getInstallmentRow = (index: number, amount: number) => {
    return (
      <tr key={uuidv4()}>
        <td>
          <p className={styles['details']}>Installment {index}</p>
        </td>
        <td>
          <p className={styles['unit__percentage']}>
            {((amount / selectedPaymentTerms.total_unit_price) * 100).toFixed(
              0
            )}
            %
          </p>
        </td>
        <td>
          <p className={styles['details']}>
            {Math.round(amount).toLocaleString()}{' '}
            {selectedPaymentTerms.currency}
          </p>
        </td>
      </tr>
    );
  };

  return (
    <div className={styles['installments-card']}>
      <p className={styles['header']}>DETAILED INSTALLMENT PLAN</p>
      <table>
        <thead>
          <tr>
            <th>Schedule</th>
            <th>% Unit</th>
            <th>Due Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p className={styles['details']}>Downpayment</p>
            </td>
            <td>
              {' '}
              <p className={styles['unit__percentage']}>
                {Math.round(selectedPaymentTerms.downpayment_percent)}%
              </p>
            </td>
            <td>
              <p className={styles['details']}>
                {Math.round(
                  selectedPaymentTerms.downpayment_amount
                ).toLocaleString()}{' '}
                {selectedPaymentTerms.currency}
              </p>
            </td>
          </tr>
          {selectedPaymentTerms?.first_installment_amount ? (
            <tr>
              <td>
                <p className={styles['details']}>First Installment</p>
              </td>
              <td>
                {' '}
                <p className={styles['unit__percentage']}>
                  {Math.round(selectedPaymentTerms.first_installment_percent)}%
                </p>
              </td>
              <td>
                <p className={styles['details']}>
                  {Math.round(
                    selectedPaymentTerms.first_installment_amount
                  ).toLocaleString()}{' '}
                  {selectedPaymentTerms.currency}
                </p>
              </td>
            </tr>
          ) : null}
          {[...installmentsBefore]?.map((_, index) =>
            getInstallmentRow(
              index + installmentBeforeStart,
              selectedPaymentTerms.unit_installment_before_delivery_per_frequency
            )
          )}
          {selectedPaymentTerms?.delivery_amount > 0 ? (
            <tr>
              <td>
                <p className={styles['details']}>Delivery Amount</p>
              </td>
              <td>
                <p className={styles['unit__percentage']}>
                  {Math.round(selectedPaymentTerms?.delivery_percent)}%
                </p>
              </td>
              <td>
                <p className={styles['details']}>
                  {Math.round(selectedPaymentTerms?.delivery_amount)}{' '}
                  {selectedPaymentTerms.currency}
                </p>
              </td>
            </tr>
          ) : null}
          {[...installmentsAfter]?.map((_, index) =>
            getInstallmentRow(
              index + installmentAfterStart,
              selectedPaymentTerms.unit_installment_after_delivery_per_frequency
            )
          )}
          {selectedPaymentTerms?.installment_amount &&
          selectedPaymentTerms?.total_number_of_installments
            ? [
                ...Array(selectedPaymentTerms?.total_number_of_installments),
              ].map((_, i) => (
                <tr key={uuidv4()}>
                  <td>
                    <p className={styles['details']}>Installment {i + 1}</p>
                  </td>
                  <td>
                    <p className={styles['unit__percentage']}>
                      {(
                        ((selectedPaymentTerms?.installment_amount ?? 0) /
                          selectedPaymentTerms.total_unit_price) *
                        100
                      ).toFixed(0)}
                      %
                    </p>
                  </td>
                  <td>
                    <p className={styles['details']}>
                      {selectedPaymentTerms?.installment_amount?.toFixed(0)}{' '}
                      {selectedPaymentTerms.currency}
                    </p>
                  </td>
                </tr>
              ))
            : null}
          <tr>
            <td>
              <p className={styles['details']}>Unit Price After Discount</p>
            </td>
            <td>
              {' '}
              <p className={styles['unit__percentage']}>100%</p>
            </td>
            <td>
              <p className={styles['details']}>
                {Math.round(
                  selectedPaymentTerms.price_after_discount
                ).toLocaleString()}{' '}
                {selectedPaymentTerms.currency}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SalesOfferInstallmentPlan;
