/**
 * @author Salma Hefnawy
 * @date 2024-02-06
 * @description Implementation of analytics events related utilities.
 * @filename analytics-events.ts
 */

import { CommonEventParameters } from '@orascom/utils';

export interface UnitEventParameters {
  position?: string;
  unitId?: string;
}

export function analyticsHeaderTalkToSalesClickEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'talk_tosales', params);
  } catch (e) {
    console.error('Error in analyticsHeaderTalkToSalesClickEvent', e);
  }
}

export function analyticsHeaderWishListClickEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'open_favorites', params);
  } catch (e) {
    console.error('Error in analyticsHeaderWishListClickEvent', e);
  }
}

export function analyticsHeaderSearchClickEvent(params: CommonEventParameters) {
  try {
    gtag('event', 'open_favorites', params);
  } catch (e) {
    console.error('Error in analyticsHeaderSearchClickEvent', e);
  }
}

export function analyticsSideBarCustomEvent(params: CommonEventParameters) {
  try {
    gtag('event', 'sidebar', params);
  } catch (e) {
    console.error('Error in analyticsSideBarCustomEvent', e);
  }
}

export function analyticsSideBarCountriesCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'countries', params);
  } catch (e) {
    console.error('Error in analyticsSideBarCountriesCustomEvent', e);
  }
}

export function analyticsSideBarCountrySelectionCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `countries_${params.name}`, params);
  } catch (e) {
    console.error('Error in analyticsSideBarCountrySelectionCustomEvent', e);
  }
}

export function analyticsSideBarDestinationsCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'destinations', params);
  } catch (e) {
    console.error('Error in analyticsSideBarDestinationsCustomEvent', e);
  }
}

export function analyticsSideBarDestinationSelectionCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `destinations_${params.name}`, params);
  } catch (e) {
    console.error(
      'Error in analyticsSideBarDestinationSelectionCustomEvent',
      e
    );
  }
}

export function analyticsSideBarInvestmentCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'investment', params);
  } catch (e) {
    console.error('Error in analyticsSideBarInvestmentCustomEvent', e);
  }
}

export function analyticsSideBarAboutUsCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'aboutus', params);
  } catch (e) {
    console.error('Error in analyticsSideBarAboutUsCustomEvent', e);
  }
}

export function analyticsGetAssistanceCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'get_assistance', params);
  } catch (e) {
    console.error('Error in analyticsGetAssistanceCustomEvent', e);
  }
}

export function analyticsLearnMoreCustomEvent(params: CommonEventParameters) {
  try {
    gtag('event', 'learnmore', params);
  } catch (e) {
    console.error('Error in analyticsLearnMoreCustomEvent', e);
  }
}

export function analyticsContactUsCustomEvent(params: CommonEventParameters) {
  try {
    gtag('event', 'send_request_contactus_page', params);
  } catch (e) {
    console.error('Error in analyticsContactUsCustomEvent', e);
  }
}

export function analyticsTalkToUsCustomEvent(params: CommonEventParameters) {
  try {
    gtag('event', 'send_request_talktosales_form', params);
  } catch (e) {
    console.error('Error in analyticsTalkToUsCustomEvent', e);
  }
}

export function analyticsExploreDestinationCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `explore_${params.name}`, params);
  } catch (e) {
    console.error('Error in analyticsExploreDestinationCustomEvent', e);
  }
}

export function analyticsAddToCompareCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `addto_compare_${params.name}`, params);
  } catch (e) {
    console.error('Error in analyticsAddToCompareCustomEvent', e);
  }
}

export function analyticsUnitsAddedToCompareCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `compare`, params);
  } catch (e) {
    console.error('Error in analyticsUnitsAddedToCompareCustomEvent', e);
  }
}

export function analyticsAddToFavoritesCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `addto_favorites_${params.name}`, params);
  } catch (e) {
    console.error('Error in analyticsAddToFavoritesCustomEvent', e);
  }
}

export function analyticsReserveUnitCustomEvent(params: UnitEventParameters) {
  try {
    gtag('event', `reserve_${params.position}`, params);
  } catch (e) {
    console.error('Error in analyticsReserveUnitCustomEvent', e);
  }
}

export function analyticsInterestedUnitCustomEvent(
  params: UnitEventParameters
) {
  try {
    gtag('event', `interested`, params);
  } catch (e) {
    console.error('Error in analyticsInterestedUnitCustomEvent', e);
  }
}

export function analyticsDownloadUnitSaleOfferCustomEvent(
  params: UnitEventParameters
) {
  try {
    gtag('event', `download_salesoffer`, params);
  } catch (e) {
    console.error('Error in analyticsDownloadUnitSaleOfferCustomEvent', e);
  }
}

export function analyticsMoreAboutDestinationCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `moreabout_destination`, params);
  } catch (e) {
    console.error('Error in analyticsMoreAboutDestinationCustomEvent', e);
  }
}

export function analyticsRequestSalesCallCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `request_salescall`, params);
  } catch (e) {
    console.error('Error in analyticsRequestSalesCallCustomEvent', e);
  }
}

export function analyticsDownloadBrochureCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `download_brochure_bottom`, params);
  } catch (e) {
    console.error('Error in analyticsDownloadBrochureCustomEvent', e);
  }
}

export function analyticsDownloadProjectBrochureCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `download_brochure_project`, params);
  } catch (e) {
    console.error('Error in analyticsDownloadProjectBrochureCustomEvent', e);
  }
}

export function analyticsDownloadDestinationBrochureCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `download_brochure_destination`, params);
  } catch (e) {
    console.error(
      'Error in analyticsDownloadDestinationBrochureCustomEvent',
      e
    );
  }
}

export function analyticsExplorePropertiesCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `explore_properties`, params);
  } catch (e) {
    console.error('Error in analyticsExplorePropertiesCustomEvent', e);
  }
}

export function analyticsSignupEvent(params: CommonEventParameters) {
  try {
    gtag('event', `signup`, params);
  } catch (e) {
    console.error('Error in analyticsSignupEvent', e);
  }
}

export function analyticsSideBarContactUsCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `contactus_sidebar`, params);
  } catch (e) {
    console.error('Error in analyticsSideBarContactUsCustomEvent', e);
  }
}

export function analyticsFooterContactUsCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `contactus_footer`, params);
  } catch (e) {
    console.error('Error in analyticsFooterContactUsCustomEvent', e);
  }
}

export function analyticsGetInTouchCustomEvent(params: CommonEventParameters) {
  try {
    gtag('event', `get_in_touch`, params);
  } catch (e) {
    console.error('Error in analyticsGetInTouchCustomEvent', e);
  }
}

export function analyticsRegisterInterestCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `register_interest`, params);
  } catch (e) {
    console.error('Error in analyticsRegisterInterestCustomEvent', e);
  }
}

export function analyticsSideBarLoginCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `login_sidebar`, params);
  } catch (e) {
    console.error('Error in analyticsSideBarLoginCustomEvent', e);
  }
}

export function analyticsReservationFlowLoginCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `login_reservation_flow`, params);
  } catch (e) {
    console.error('Error in analyticsReservationFlowLoginCustomEvent', e);
  }
}

export function analyticsLoginSubmitCustomEvent(params: CommonEventParameters) {
  try {
    gtag('event', `signin_send_code_sidebar`, params);
  } catch (e) {
    console.error('Error in analyticsLoginSubmitCustomEvent', e);
  }
}

export function analyticsLoginSubmitFromRegistrationFlowCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `signin_send_code_reservation_flow`, params);
  } catch (e) {
    console.error(
      'Error in analyticsLoginSubmitFromRegistrationFlowCustomEvent',
      e
    );
  }
}

export function analyticsSideBarSignUpCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `signup_sidebar`, params);
  } catch (e) {
    console.error('Error in analyticsSideBarSignUpCustomEvent', e);
  }
}

export function analyticsSignUpSendCodeCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `signup_send_code_sidebar`, params);
  } catch (e) {
    console.error('Error in analyticsSignUpSendCodeCustomEvent', e);
  }
}

export function analyticsSignUpSendCodeFromReservationFlowCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `signup_send_code_reservation_flow`, params);
  } catch (e) {
    console.error(
      'Error in analyticsSignUpSendCodeFromReservationFlowCustomEvent',
      e
    );
  }
}

export function analyticsContinueToReservationCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `continue_to_reservation`, params);
  } catch (e) {
    console.error('Error in analyticsContinueToReservationCustomEvent', e);
  }
}

export function analyticsDownLoadPaymentPlanEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `download_payment_plan`, params);
  } catch (e) {
    console.error('Error in analyticsDownLoadPaymentPlanEvent', e);
  }
}

export function analyticsSaveAndProceedToCheckoutEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `save_proceed_to_checkout`, params);
  } catch (e) {
    console.error('Error in analyticsSaveAndProceedToCheckoutEvent', e);
  }
}

export function analyticsPayReservationFeeEvent(params: CommonEventParameters) {
  try {
    gtag('event', `pay_reservation_fees`, params);
  } catch (e) {
    console.error('Error in analyticsPayReservationFeeEvent', e);
  }
}

export function analyticsAcceptAndProceedTermsEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `Accept_proceed_terms`, params);
  } catch (e) {
    console.error('Error in analyticsAcceptAndProceedTermsEvent', e);
  }
}
