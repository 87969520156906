import { Home as HomeUtilities } from '../../utils/home.utils';
import { useTranslation } from 'react-i18next';
import SectionsPage from '../../components/sections-page/sections-page';
import styles from './privacy-policy.module.scss';

export function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <div className="container">
      <h1 className={styles['title']}>{t('privacyPolicy')}</h1>
      <SectionsPage getSectionsData={HomeUtilities.getPrivacyPolicy} />
    </div>
  );
}

export default PrivacyPolicy;
