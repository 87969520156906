import { useTranslation } from 'react-i18next';
import { FiltersEnums, PriceSlider, PriceValue, UnitFilters } from '@orascom/api-interfaces';
import { useWindowDimensions } from '@orascom/utils';
import { useSearchParams } from 'react-router-dom';

import PriceRangeSlider from '../price-range-slider/price-range-slider';
import { GoldButton } from '../button/button';
import ConditionalWrapper from '../conditional-wrapper/conditional-wrapper';
import Modal from '../modal/modal';
import FilterIcon from '../../../assets/icons/filter.svg';
import styles from './units-filters.module.scss';
import { MultiFilterDropdown, SingleFilterDropdown } from './filter-dropdown';
import {
  SortByOptions,
  convertToOptionsWithSlug,
  convertNumFiltersToOptions,
  convertStringFiltersToOptions,
  handleClearAllFilters,
} from './filters-utils';
import { ReactNode, useState } from 'react';
import ClearFilters from './clear-filters';
import OnResaleButton from './on-resale-button';

interface UnitsFiltersProps {
  unitFilters?: UnitFilters;
  isLoadingFilters: boolean;
  showResale: boolean;
  expanded?: boolean;
  priceSlider?: PriceSlider;
  setPriceValue?: (price: PriceValue) => void;
  isLoadingCurrency?: boolean;
}

export function UnitsFilters({
  expanded = true,
  showResale = true,
  unitFilters,
  isLoadingFilters,
  priceSlider,
  isLoadingCurrency = false,
  setPriceValue,
}: Readonly<UnitsFiltersProps>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const onShowFiltersModal = () => {
    setShowFiltersModal(true);
    document.body.style.overflow = 'hidden';
  };

  const onCloseModal = () => {
    setShowFiltersModal(false);
    document.body.style.overflow = '';
  };

  const onPriceChangeEnd = (price: PriceValue) => {
    setSearchParams((searchParams) => {
      searchParams.set(FiltersEnums.MIN_PRICE, price.min.toString());
      searchParams.set(FiltersEnums.MAX_PRICE, price.max.toString());
      searchParams.set(FiltersEnums.CURRENCY, price.currency);
      return searchParams;
    }, { replace: true });
  };

  // broker doesn't need price slider
  const hasPriceSlider = !!priceSlider && !!setPriceValue;

  return (
    <>
      <div className={styles['mobile-filters']}>
        <button type="button" onClick={onShowFiltersModal}>
          <img src={FilterIcon} role="presentation" alt="" />
          {t('filterAndSort')}
        </button>
      </div>
      <ConditionalWrapper
        condition={width < 768}
        wrap={(wrappedChildren: ReactNode) => (
          <Modal
            show={showFiltersModal}
            onClose={onCloseModal}
            title={t('filterBy')}
            bodyBackgroundClass="search-modal__background"
          >
            {wrappedChildren}
            <GoldButton onClick={onCloseModal}>{t('done')}</GoldButton>
            <button
              className={styles['modal-clear']}
              onClick={() =>
                handleClearAllFilters(searchParams, setSearchParams)
              }
            >
              {t('clearFilters')}
            </button>
          </Modal>
        )}
      >
        <div className={`container ${styles['wrapper']}`}>
          <div
            className={styles['dropdowns-container']}
            style={{
              justifyContent: !expanded ? 'space-between' : 'flex-start',
            }}
          >
            <div className={styles['dropdowns']}>
              <MultiFilterDropdown
                isLoading={isLoadingFilters}
                filterOptions={convertToOptionsWithSlug(unitFilters?.countries)}
                filterKey="countries"
              />
              <MultiFilterDropdown
                isLoading={isLoadingFilters}
                filterOptions={convertToOptionsWithSlug(
                  unitFilters?.destinations
                )}
                filterKey="destinations"
              />
              <MultiFilterDropdown
                isLoading={isLoadingFilters}
                filterOptions={convertToOptionsWithSlug(unitFilters?.projects)}
                filterKey="projects"
                labelTranslationKey="neighborhoods"
              />

              {!expanded && showResale && (
                <OnResaleButton
                  wrapperClassName={styles['mt-on-bg-screen']}
                  disabled={isLoadingFilters}
                />
              )}
              {expanded && (
                <>
                  <MultiFilterDropdown
                    isLoading={isLoadingFilters}
                    filterOptions={unitFilters?.unit_types}
                    filterKey="unit_types"
                    labelTranslationKey="unitType"
                  />
                  <MultiFilterDropdown
                    isLoading={isLoadingFilters}
                    filterOptions={convertNumFiltersToOptions(
                      unitFilters?.built_up_areas
                    )}
                    filterKey="built_up_areas"
                    labelTranslationKey="unitSize"
                    getOptionLabel={(v) => `${v.name} ㎡`}
                  />
                  <MultiFilterDropdown
                    isLoading={isLoadingFilters}
                    filterOptions={convertNumFiltersToOptions(
                      unitFilters?.bedrooms
                    )}
                    filterKey="bedrooms"
                    labelTranslationKey="noOfBedrooms"
                  />
                  <MultiFilterDropdown
                    isLoading={isLoadingFilters}
                    filterOptions={convertNumFiltersToOptions(
                      unitFilters?.bathrooms
                    )}
                    filterKey="bathrooms"
                    labelTranslationKey="noOfBathrooms"
                  />
                  <MultiFilterDropdown
                    isLoading={isLoadingFilters}
                    filterOptions={convertStringFiltersToOptions(
                      unitFilters?.finishing_status
                    )}
                    filterKey="finishing_status"
                    labelTranslationKey="finishingStatus"
                  />

                  {showResale && <OnResaleButton disabled={isLoadingFilters} />}

                  {hasPriceSlider && (
                    <PriceRangeSlider
                      disabled={isLoadingCurrency || isLoadingFilters}
                      priceSlider={priceSlider}
                      onChange={(val) => {
                        setPriceValue({ ...priceSlider.price, ...val });
                      }}
                      onChangeEnd={(val) => onPriceChangeEnd(val)}
                    />
                  )}
                </>
              )}
            </div>
            <SingleFilterDropdown
              wrapperClassName={styles['sort-filter']}
              isClearable
              filterOptions={SortByOptions}
              filterKey="sort_by"
              labelTranslationKey="sortBy"
            />
          </div>
        </div>
      </ConditionalWrapper>
      {unitFilters && (
        <ClearFilters expanded={expanded} unitFilters={unitFilters} />
      )}
    </>
  );
}
