import { useTranslation } from 'react-i18next';
import styles from './booking-experience.module.scss';
import { Home as HomeUtilities } from '../../utils/home.utils';
import { useEffect, useState } from 'react';
import { filterArrOfObjectsByKeyValue } from '@orascom/utils';
import cardIcon from '../../assets/icons/credit-card.svg';
import unitIcon from '../../assets/icons/unit.svg';
import destinationIcon from '../../assets/icons/destination.svg';
import propertyIcon from '../../assets/icons/property.svg';
import { Loader } from '@orascom/common-components';
import { Helmet } from 'react-helmet-async';
import getDocTitle from '../../utils/getDocTitle';

const bookingDocTitle = getDocTitle('Booking Experience');
export function BookingExperience() {
  const { t } = useTranslation();

  const [homeMetaData, setHomeMetaData] = useState<Record<string, unknown>[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    HomeUtilities.getHomeMetaData()
      .then((res) => {
        setHomeMetaData(
          res.metadata.general as unknown as Record<string, unknown>[]
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="container">
      <Helmet>
        <title>{bookingDocTitle}</title>
        <meta property="og:title" content={bookingDocTitle} />
        <meta name="description" content={bookingDocTitle} />
        <meta property="og:description" content={bookingDocTitle} />
      </Helmet>
      <h1 className={styles['title']}>{t('seamlessBookingExperience')}</h1>
      <div className={styles['wrapper']}>
        <div className={styles['booking-exp__key-steps']}>
          <div className={styles['booking-exp__key-step']}>
            <img src={destinationIcon} alt="" role="presentation" />
            <h6>{t('exploreDestinations')}</h6>
            {homeMetaData && (
              <p>
                {`
                ${t('explore')}
                ${
                  filterArrOfObjectsByKeyValue(
                    homeMetaData,
                    'name',
                    'total_destinations'
                  )?.value ?? ''
                }
                ${t('destinationsIn')}
                ${
                  filterArrOfObjectsByKeyValue(
                    homeMetaData,
                    'name',
                    'total_countries'
                  )?.value ?? ''
                }
                ${t('countriesAcross')}
                ${
                  filterArrOfObjectsByKeyValue(
                    homeMetaData,
                    'name',
                    'total_continents'
                  )?.value ?? ''
                }
                ${t('continents')}.
                `}
              </p>
            )}
          </div>
          <div className={styles['booking-exp__key-step']}>
            <img src={propertyIcon} alt="" role="presentation" />
            <h6>{t('checkAvailableProperties')}</h6>
            <p>{t('seeAvailableUnitsForSale')}</p>
          </div>
          <div className={styles['booking-exp__key-step']}>
            <img src={unitIcon} alt="" role="presentation" />
            <h6>{t('bookUnit')}</h6>
            <p>{t('fillQuickFormToConfirm')}</p>
          </div>
          <div className={styles['booking-exp__key-step']}>
            <img src={cardIcon} alt="" role="presentation" />
            <h6>{t('payDownpayment')}</h6>
            <p>{t('payYourUnitDownpaymentOnline')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingExperience;
