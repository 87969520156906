import { ReactElement, useEffect } from 'react';
import styles from './tooltip.module.scss'; // You can style your tooltip using CSS

const COUNT_DOWN = 10;
interface TooltipProps {
  children: ReactElement;
  position: 'top' | 'right' | 'bottom' | 'left';
  onClose?: () => void;
  className?: string;
}
export const Tooltip = ({
  children,
  position,
  onClose,
  className,
}: TooltipProps) => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (onClose) onClose();
    }, COUNT_DOWN * 10000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div
      className={`${styles['tooltip']} ${styles[position]} ${
        className && styles[className]
      }`}
    >
      <p
        style={{
          fontSize: 'small',
        }}
      >
        {children}
      </p>
      <button className={styles['close-btn']} onClick={onClose}>
        x
      </button>
    </div>
  );
};
