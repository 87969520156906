/* eslint-disable @nx/enforce-module-boundaries */
/**
 * @author Salma Hefnawy
 * @date 2022-11-16
 * @description implementation of unit related utility functions.
 * @filename Unit.ts
 */

import { PreDeliveryUser as PreDeliveryUserAPI } from '../../api/pre-delivery/user.api';
import { getPayloadData, handleError } from '@orascom/utils';

import {
  OwnerCard,
  IssueType,
  CurrencyInterface,
} from '../../definitions/interfaces/pre-delivery.interface';
import {
  UnitAddOn,
  PaginationPayload,
  ResaleFormData,
} from '@orascom/api-interfaces';
import { NewsListingInterface } from '../../definitions/interfaces/home.interface';
import {
  ServiceRequest,
  ServiceRequestFormData,
} from '../../definitions/interfaces/service-requests.interface';

/**
 * group of pre-delivery user helpers functionalities.
 */
export class PreDeliveryUser {
  /**
   *get owner cards  data.
   *
   * @static
   * @returns {Promise<OwnerCard[]>}
   * @memberof PreDeliveryUser
   */
  public static getOwnerCards(): Promise<OwnerCard[]> {
    return PreDeliveryUserAPI.getOwnerCards()
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get issue types data.
   *
   * @static
   * @returns {Promise<IssueType[]>}
   * @memberof PreDeliveryUser
   */
  public static getIssueTypes(): Promise<IssueType[]> {
    return PreDeliveryUserAPI.getIssueTypes()
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   * submit assistance  request  form
   *
   * @static
   * @param {ServiceRequestFormData} [formData]
   * @returns {Promise<string>}
   *
   * @memberof PreDeliveryUser
   */
  public static submitServiceRequest(
    unitId: string,
    formData: ServiceRequestFormData
  ): Promise<string> {
    return PreDeliveryUserAPI.submitServiceRequest(unitId, formData)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   * submit resale  request  form
   *
   * @static
   * @param {ResaleFormData} [formData]
   * @returns {Promise<string>}
   *
   * @memberof PreDeliveryUser
   */
  public static submitResaleRequest(
    unitId: string,
    formData: ResaleFormData
  ): Promise<string> {
    return PreDeliveryUserAPI.submitResaleRequest(unitId, formData)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   * get list of pre-delivery referrals
   *
   * @static
   * @param {number} page
   * @param {number} perPage
   * @returns {Promise<PaginationPayload<ServiceRequest[]>>}
   *
   * @memberOf Referral
   */
  public static getServiceRequests(
    countryId: number,
    customerSourceId: string,
    unitId: string
  ): Promise<ServiceRequest[]> {
    return PreDeliveryUserAPI.getServiceRequests(
      countryId,
      customerSourceId,
      unitId
    )
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get user latest news.
   *
   * @static
   * @returns {Promise<PaginationPayload<NewsListingInterface[]>>}
   * @param {number} [page=1]
   * @param {number} [per_page=10]
   * @param {boolean} [is_event=false]
   * @param {boolean} [is_featured=false]
   * @memberof User
   */
  public static getNews(
    page: number,
    per_page: number,
    is_event = false,
    is_featured = false
  ): Promise<PaginationPayload<NewsListingInterface[]>> {
    return PreDeliveryUserAPI.getNews(
      String(page),
      String(per_page),
      is_event,
      is_featured
    )
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get all addon data data.
   *
   * @static
   * @returns {Promise<UnitAddOn>}
   * @memberof PreDeliveryUser
   */
  public static getAddon(addonId: string): Promise<UnitAddOn> {
    return PreDeliveryUserAPI.getAddon(addonId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get all unit addons data data.
   *
   * @static
   * @returns {Promise<UnitAddOn>}
   * @memberof PreDeliveryUser
   */
  public static getUnitAddons(unitId: string): Promise<UnitAddOn[]> {
    return PreDeliveryUserAPI.getUnitAddons(unitId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getCurrencies(countryId: string): Promise<CurrencyInterface[]> {
    return PreDeliveryUserAPI.getCurrencies(countryId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
