import {
  CountriesEnum,
  MontenegroOnlinePaymentBody,
} from '@orascom/api-interfaces';
import { Unit as UnitUtils } from '../../utils/unit.utils';
import queryString from 'query-string';
import { OmanOnlinePaymentProjectsKeys } from '../../definitions/consts/oman-online-payment-projects';

const handleMontenegroOnlinePayment = (data: MontenegroOnlinePaymentBody) => {
  const montenegroOnlinePaymentForm = document.getElementById(
    'montenegro-online-payment-form'
  ) as HTMLFormElement;
  const shopID = document.getElementById('shop-id') as HTMLInputElement;
  const shoppingCartID = document.getElementById(
    'shopping-cart-id'
  ) as HTMLInputElement;
  const version = document.getElementById('version') as HTMLInputElement;
  const TotalAmount = document.getElementById(
    'total-amount'
  ) as HTMLInputElement;
  const returnURL = document.getElementById('return-URL') as HTMLInputElement;
  const returnErrorURL = document.getElementById(
    'return-error-URL'
  ) as HTMLInputElement;
  const cancelURL = document.getElementById('cancel-URL') as HTMLInputElement;
  const signature = document.getElementById('signature') as HTMLInputElement;

  shopID.value = data.shop_id;
  shoppingCartID.value = data.shopping_cart_id;
  version.value = '2.0';
  TotalAmount.value = data.amount.toString();
  returnURL.value = data.success_url;
  returnErrorURL.value = data.fail_url;
  cancelURL.value = data.cancel_url;
  signature.value = data.signature;

  montenegroOnlinePaymentForm.submit();
};

const handleThawaniOnlinePayment = (
  projectSlug: string | undefined,
  paymentToken: string
) => {
  if (!projectSlug) {
    throw new Error("This project doesn't support online payment");
  }

  const paymentKey = OmanOnlinePaymentProjectsKeys[projectSlug];
  if (paymentKey) {
    window.location.href = `${process.env['NX_THAWANI_IFRAME_URL']}${paymentToken}?key=${paymentKey}`;
  } else {
    throw new Error("This project doesn't support online payment");
  }
};

const handlePaymentToken = (
  country: CountriesEnum | undefined,
  projectSlug: string | undefined,
  paymentToken: string | MontenegroOnlinePaymentBody
) => {
  switch (country) {
    case CountriesEnum.EGYPT:
      window.location.href = `${process.env['NX_PAYMOB_IFRAME_URL']}${paymentToken}`;
      return;
    case CountriesEnum.OMAN:
      handleThawaniOnlinePayment(projectSlug, paymentToken as string);
      return;
    case CountriesEnum.MONTENEGRO:
      handleMontenegroOnlinePayment(
        paymentToken as MontenegroOnlinePaymentBody
      );
      return;
    default:
      console.log('Please provide a valid country', country, paymentToken);
      return;
  }
};

const handleEgyptPaymentCallback = (
  onPaymentSuccess: (payment: unknown) => void,
  onPaymentFailure: (payment: unknown) => void
) => {
  const queryParams = queryString.parse(window.location.search);
  if (queryParams['success'] === 'true') {
    onPaymentSuccess({
      id: queryParams['id'],
      amount: queryParams['amount_cents'],
      currency: queryParams['currency'],
      success: queryParams['success'],
      message: queryParams['data.message'],
    });
  } else if (queryParams['success'] === 'false') {
    onPaymentFailure({
      id: queryParams['id'],
      amount: queryParams['amount_cents'],
      currency: queryParams['currency'],
      success: queryParams['success'],
      message: queryParams['data.message'],
    });
  }
};

const handleOmanPaymentCallback = (
  onPaymentSuccess: (payment: unknown) => void,
  onPaymentFailure: (payment: unknown) => void
) => {
  const queryParams = queryString.parse(window.location.search);
  if (queryParams['reserve-unit'] === 'true') {
    onPaymentSuccess({
      'reserve-unit': 'true',
    });
  } else if (queryParams['reserve-unit'] === 'false') {
    onPaymentFailure({
      'reserve-unit': 'false',
    });
  }
};

export const useOnlinePayment: (
  unitCountry: CountriesEnum | undefined,
  projectSlug: string | undefined,
  unitId: number | undefined,
  saleId: number | undefined,
  amount: number,
  onPaymentSuccess: (payment: unknown) => void,
  onPaymentFailure: (payment: unknown) => void
) => [() => void] = (
  unitCountry,
  projectSlug,
  unitId,
  saleId,
  amount,
  onPaymentSuccess,
  onPaymentFailure
) => {
  const payOnline = async () => {
    if (!unitId || !saleId) return;
    const paymentToken: string = await UnitUtils.getSalePaymentToken(
      unitId,
      saleId,
      amount
    );
    if (paymentToken) {
      handlePaymentToken(unitCountry, projectSlug, paymentToken);
    } else {
      onPaymentFailure('Cannot get payment token');
    }
  };

  handleEgyptPaymentCallback(onPaymentSuccess, onPaymentFailure);
  handleOmanPaymentCallback(onPaymentSuccess, onPaymentFailure);

  return [payOnline];
};
