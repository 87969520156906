import { createContext, useState, ReactNode, useEffect } from 'react';
import { CURRENCIES_ENUM } from '@orascom/api-interfaces';
import { fetchExchangeRates } from '../lib/exchange.rates.utils';

export interface Price {
  value: number;
  currency: string;
}
export interface DisableCurrencySetting {
  disabled: boolean;
  info: string;
}
export const initialCurrencySetting = {
  disabled: false,
  info: '',
};
export const CurrencyContext = createContext<{
  currency: string;
  setActiveCurrency: (currency: string) => void;
  rates: Record<string, number> | null;
  isLoading: boolean;
  convertPriceToActiveCurrency: (price: Price) => Price;
  disableCurrencySetting: DisableCurrencySetting;
  setDisableCurrencySetting: React.Dispatch<
    React.SetStateAction<DisableCurrencySetting>
  >;
}>({
  currency: CURRENCIES_ENUM.EGP,
  setActiveCurrency: () => {},
  convertPriceToActiveCurrency: (price: Price) => price,
  rates: null,
  isLoading: true,
  disableCurrencySetting: initialCurrencySetting,
  setDisableCurrencySetting: () => {},
});

interface CurrencyContextProps {
  children: ReactNode;
  getCurrenciesUrl?: (sourceCurrency: string, currencies: string) => string;
  defaultDollar?: boolean;
}
// cache fetched rates
const fetchedRates: Record<string, Record<string, number> | null> = {};
export const CurrencyContextProvider: React.FC<CurrencyContextProps> = (
  props
) => {
  const [currency, setCurrency] = useState<string>(
    props.defaultDollar ? CURRENCIES_ENUM.USD : CURRENCIES_ENUM.EGP
  );
  const [rates, setRates] = useState<Record<string, number> | null>(null);
  const [isLoadingCurrency, setIsLoadingCurrency] = useState<boolean>(true);
  const [disableCurrencySetting, setDisableCurrencySetting] =
    useState<DisableCurrencySetting>(initialCurrencySetting);

  function setActiveCurrency(sourceCurrency: string) {
    // get rates from cache
    if (fetchedRates[sourceCurrency]) {
      setCurrency(sourceCurrency);
      setRates(fetchedRates[sourceCurrency]);
      return;
    }

    setIsLoadingCurrency(true);
    if (props.getCurrenciesUrl) {
      fetchExchangeRates(sourceCurrency, props.getCurrenciesUrl)
        .then((data) => {
          setCurrency(sourceCurrency);
          setRates(data);
          fetchedRates[sourceCurrency] = data;
        })
        .finally(() => setIsLoadingCurrency(false));
    }
  }

  function convertPriceToActiveCurrency(price: Price): Price {
    const isSameCurrency = price.currency === currency;
    if (isLoadingCurrency || !rates || isSameCurrency) return price;

    const rateKey = `${currency}${price.currency}`;
    const rate = rates[rateKey];

    // just in case
    if (!rate) {
      console.error(`Conversion rate for ${rateKey} not found.`);
      return price;
    }

    const convertedPrice = price.value / rate;

    return {
      value: convertedPrice,
      currency,
    };
  }

  useEffect(() => {
    setActiveCurrency(
      JSON.parse(localStorage.getItem('currency') as string)?.label || currency
    );
  }, []); // eslint-disable-line

  const currencyContextValue = {
    currency,
    setActiveCurrency,
    rates,
    isLoading: isLoadingCurrency,
    convertPriceToActiveCurrency,
    disableCurrencySetting,
    setDisableCurrencySetting,
  };

  return (
    <CurrencyContext.Provider value={currencyContextValue}>
      {props.children}
    </CurrencyContext.Provider>
  );
};
