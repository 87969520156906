/* eslint-disable @nx/enforce-module-boundaries */
import styles from './sign-contract.module.scss';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import ErrorIcon from '../../../assets/icons/error.svg';
import { ErrorMessage, Field, FormikErrors, FormikTouched } from 'formik';
import { ContactTypeEnum } from '../../../definitions/interfaces/pre-delivery.interface';
import { SelectDropdown } from '@orascom/common-components';
import { SignContractRequestInitialData } from '../../../definitions/interfaces/service-requests.interface';

/* eslint-disable-next-line */
export interface SignContractViaDhlFormFieldsProps {
  errors: FormikErrors<SignContractRequestInitialData>;
  touched: FormikTouched<SignContractRequestInitialData>;
  values: SignContractRequestInitialData;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export function SignContractViaDhlFormFields(
  props: Readonly<SignContractViaDhlFormFieldsProps>
) {
  const { t } = useTranslation();
  const { errors, touched } = props;

  return (
    <div className="reservation-sub-form">
      <h2 className="reservation-form-title">{t('requestContractDelivery')}</h2>

      <div className="input-wrapper">
        <label htmlFor="governorate">{t('governorate')}*</label>

        <Field
          name="governorate"
          type="text"
          placeholder={t('enterGovernerate')}
        />

        <ErrorMessage
          name="governorate"
          render={(msg: string) => (
            <p className="error">
              <img src={ErrorIcon} role="presentation" alt="" />
              {msg}
            </p>
          )}
        />
      </div>

      <div className="input-wrapper">
        <label htmlFor="district">{t('district')}*</label>
        <Field
          name="district"
          type="text"
          className={errors.district && touched.district ? 'error' : ''}
        />

        <ErrorMessage
          name="district"
          render={(msg: string) => (
            <p className="error">
              <img src={ErrorIcon} role="presentation" alt="" />
              {msg}
            </p>
          )}
        />
      </div>

      <div className="input-wrapper">
        <label htmlFor="address">{t('homeAddress')}*</label>
        <Field
          name="address"
          type="text"
          className={errors.address && touched.address ? 'error' : ''}
        />

        <ErrorMessage
          name="address"
          render={(msg: string) => (
            <p className="error">
              <img src={ErrorIcon} role="presentation" alt="" />
              {msg}
            </p>
          )}
        />
      </div>

      <div className="input-wrappers">
        <div className="input-wrapper">
          <label htmlFor="floorNumber">
            {t('floor')} {t('optional')}
          </label>
          <Field
            name="floorNumber"
            type="text"
            className={errors.floorNumber && touched.floorNumber ? 'error' : ''}
          />

          <ErrorMessage
            name="floorNumber"
            render={(msg: string) => (
              <p className="error">
                <img src={ErrorIcon} role="presentation" alt="" />
                {msg}
              </p>
            )}
          />
        </div>
        <div className="input-wrapper">
          <label htmlFor="apartment">
            {t('apartment')} {t('optional')}
          </label>
          <Field
            name="apartment"
            type="text"
            className={errors.apartment && touched.apartment ? 'error' : ''}
          />

          <ErrorMessage
            name="apartment"
            render={(msg: string) => (
              <p className="error">
                <img src={ErrorIcon} role="presentation" alt="" />
                {msg}
              </p>
            )}
          />
        </div>
      </div>

      <div className="input-wrapper">
        <label htmlFor="contactType">{t('contactType')}*</label>
        <SelectDropdown
          name="contactType"
          className={`${styles['select']} ${
            errors.contactType && touched.contactType ? 'error' : ''
          }`}
          placeholder={t('selectPlaceholder')}
          onChange={(option) => props.setFieldValue('contactType', option)}
          options={[
            {
              value: ContactTypeEnum.VIRTUAL_MEETING,
              label: 'Virtual Meeting',
            },
            {
              value: ContactTypeEnum.MOBILE,
              label: 'Mobile',
            },
            {
              value: ContactTypeEnum.EMAIL,
              label: 'Email',
            },
            {
              value: ContactTypeEnum.WHATS_APP,
              label: 'WhatsApp',
            },
          ]}
          selectedOption={props.values.contactType || null}
        />

        <ErrorMessage
          name="contactType"
          render={(msg: string) => (
            <p className="error">
              <img src={ErrorIcon} role="presentation" alt="" />
              {msg}
            </p>
          )}
        />
      </div>
      <div className="input-wrappers">
        <div className="input-wrapper">
          <label htmlFor="deliveryDate">{t('preferredDateDelivery')}*</label>
          <Field
            name="deliveryDate"
            type="date"
            min={new Date().toISOString().split('T')[0]}
            placeholder="MM / YY"
            className={
              errors.deliveryDate && touched.deliveryDate ? 'error' : ''
            }
          />

          <ErrorMessage
            name="deliveryDate"
            render={(msg: string) => (
              <p className="error">
                <img src={ErrorIcon} role="presentation" alt="" />
                {msg}
              </p>
            )}
          />
        </div>
        <div className="input-wrapper">
          <label htmlFor="deliveryTime">{t('preferredTimeDelivery')}*</label>
          <Field
            name="deliveryTime"
            type="time"
            placeholder="01:00"
            className={
              errors.deliveryTime && touched.deliveryTime ? 'error' : ''
            }
          />

          <ErrorMessage
            name="deliveryTime"
            render={(msg: string) => (
              <p className="error">
                <img src={ErrorIcon} role="presentation" alt="" />
                {msg}
              </p>
            )}
          />
        </div>
      </div>
      <div className="input-wrapper">
        <label htmlFor="additionalInfo">{t('furtherNotes')}</label>
        <Field
          id="additionalInfo"
          name="additionalInfo"
          as="textarea"
          className={styles['textarea']}
          placeholder={t('notePlacholder')}
        />

        <ErrorMessage
          name="additionalInfo"
          render={(msg: string) => (
            <p className="error">
              <img src={ErrorIcon} role="presentation" alt="" />
              {msg}
            </p>
          )}
        />
      </div>
      <p className={styles['note']}>
        A message will be sent to your email to confirm the meeting.
      </p>
    </div>
  );
}

export default SignContractViaDhlFormFields;
