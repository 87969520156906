/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useRef, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import styles from './unit.module.scss';
import backArrowIcon from '../../../assets/icons/back-arrow.svg';
import { UnitDetails } from '@orascom/api-interfaces';
import { Loader, CardsSlider } from '@orascom/common-components';
import UnitConstructionUpdates from '../../../components/pre-delivery/unit-construction-updates/unit-construction-updates';
import { useTranslation } from 'react-i18next';
import DownloadIcon from '../../../assets/icons/download.svg';
import { ROUTES } from '../../../api/routes';
import NewsCard from '../../../components/common/news-card/news-card';
import { v4 as uuidv4 } from 'uuid';
import MyUnitCard from '../../../components/pre-delivery/my-unit-card/my-unit-card';
import { useGetMyUnits } from '../hooks/use-get-my-units';

export function Unit() {
  const { t } = useTranslation();
  const [unitDetails, setUnitDetails] = useState<UnitDetails>();

  const unitId = useParams<{ unitId: string }>().unitId;
  const documentsSection = useRef<HTMLDivElement>(null);

  const executeScroll = () => documentsSection.current?.scrollIntoView();

  const [myUnits, loadingMyUnits] = useGetMyUnits();
  useEffect(() => {
    if (unitId && myUnits.length) {
      setUnitDetails(myUnits.find((unit) => unit.id.toString() === unitId));
    }
  }, [unitId, myUnits]);

  if (loadingMyUnits || !unitDetails) {
    return <Loader />;
  }

  return (
    <div className={`${styles['unit']} pre-delivery-container`}>
      <header className={styles['header']}>
        <NavLink to={ROUTES.Pre_Delivery_Home.path} className="back-button">
          <img src={backArrowIcon} alt="side-menu-icon" />
          <>{t('backToMyUnits')}</>
        </NavLink>
        <div className={styles['header-details']}>
          <NavLink to={ROUTES.Pre_Delivery_Home.path}>
            <>{t('myUnits')}</>
          </NavLink>
          <span>/</span>
          <p>{t('unitDetails')}</p>
        </div>
      </header>
      <MyUnitCard
        unitDetails={unitDetails}
        unitPage="unit-details"
        executeScroll={executeScroll}
      />

      {/* <div className={styles['investment']}>
        <div className={styles['investment-background']} />
        <div className={styles['investment-content']}>
          <h2 className="orascom__title">{t('investmentInsights')}</h2>
          <h3 className="orascom__sub-title">{t('watchInvestmentGrow')}</h3>
          <div className={styles['investment-content-details']}>
            <div>
              <label htmlFor="">{t('originalUnitPrice')}</label>
              <p>
                15,531,980 <small>{t('egp')}</small>
              </p>
            </div>
            <div className={styles['investment-content-details-appreciation']}>
              <label htmlFor="">{t('estimatedAppreciationValue')}</label>
              <p>
                16,239,000 <small>{t('egp')}</small>{' '}
                <span>
                  <UpPolygonIcon />
                  10%
                </span>
              </p>
            </div>
            <div>
              <label htmlFor="">{t('rentalValue')}</label>
              <p>
                33,231 <small>{t('egp')}</small>
              </p>
            </div>
          </div>
        </div>
        <GoldButton asLink to={ROUTES.Investment.path} openInNewTab>
          {t('learnMore')}
        </GoldButton>
      </div> */}
      {unitDetails.construction_updates?.length ? (
        <UnitConstructionUpdates
          constructionUpdates={unitDetails.construction_updates}
        />
      ) : null}
      {unitDetails.live_url ? (
        <div className={styles['live-view']}>
          <h1 className="orascom__title">{t('watchUnitLive')}</h1>
          <h2 className="orascom__sub-title">{t('liveUnitView')}</h2>
          <iframe
            src={unitDetails.live_url}
            title={unitDetails.unit_number}
          ></iframe>
        </div>
      ) : null}
      {unitDetails.addons.length > 0 && (
        <div className={`${styles['addons']}`}>
          <div className={`${styles['headers']}`}>
            <p className="orascom__title">{t('unitAddons')}</p>
            <h2 className="orascom__sub-title">{t('enhanceUnit')}</h2>
          </div>
          <CardsSlider>
            {unitDetails.addons.map((addon) => (
              <NewsCard
                key={uuidv4()}
                image={addon.image}
                title={addon.title ?? ''}
                detailsPath={ROUTES['Pre_Delivery_Addon'].getPath?.(addon.id)}
              />
            ))}
          </CardsSlider>
        </div>
      )}
      <div
        className={styles['documents']}
        id="unit-documents"
        ref={documentsSection}
      >
        <div className={styles['documents-background']} />

        <div>
          <h3>{t('unitDocuments')}</h3>
          <p>{t('unitDocumentsDescription')}</p>
        </div>

        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href={unitDetails.brochure}
              download
            >
              <img src={DownloadIcon} role="presentation" alt="" />
              <span>{t('downloadUnitBrochure')}</span>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href={unitDetails.project.destination.brochure}
              download
            >
              <img src={DownloadIcon} role="presentation" alt="" />
              <span>
                {t('downloadDestinationBrochure', {
                  destination: unitDetails?.project?.destination?.name,
                })}
              </span>
            </a>
          </li>
          {/* <li>
            <a
              target="_blank"
              rel="noreferrer"
              href={unitDetails.sale?.receipt}
              download
            >
              <img src={DownloadIcon} role="presentation" alt="" />
              <span>{t('downloadPurchaseReceipt')}</span>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href={unitDetails.sale.contract}
              download
            >
              <img src={DownloadIcon} role="presentation" alt="" />
              <span>{t('downloadUnitContract')}</span>
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  );
}

export default Unit;
