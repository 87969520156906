import { UnitDetails } from '@orascom/api-interfaces';

type DetailValue = string | number;
// union of keys which values are StrOrNum
type StrOrNumKeys<T> = {
  [K in keyof T]: T[K] extends DetailValue ? K : never;
}[keyof T];

type SpaceKey = { key: StrOrNumKeys<UnitDetails>; translationKey: string };
const spaceKeys: SpaceKey[] = [
  { key: 'total_area', translationKey: 'totalArea' },
  { key: 'built_up_area', translationKey: 'builtUpArea' },
  { key: 'garden_area', translationKey: 'gardenArea' },
  { key: 'roof_area', translationKey: 'roofArea' },
  { key: 'terrace_area', translationKey: 'terraceArea' },
  { key: 'net_area', translationKey: 'netArea' },
];

type DetailKey = {
  key: StrOrNumKeys<UnitDetails>;
  translationKey: string;
  transform?: (v: DetailValue) => string;
};
const detailKeys: DetailKey[] = [
  { key: 'name', translationKey: 'unitNumber' },
  { key: 'bedrooms', translationKey: 'no.Rooms' },
  { key: 'bathrooms', translationKey: 'no.Bathrooms' },
  { key: 'view', translationKey: 'view' },
  { key: 'direction', translationKey: 'direction' },
  {
    key: 'construction_status',
    translationKey: 'constructionStatus',
    transform: (value: DetailValue) => value?.toString().replace(/_/g, ' '),
  },
  { key: 'finishing_status', translationKey: 'finishingStatus' },
  // { key: 'delivery_date', translationKey: 'deliveryDate' },
];

export { spaceKeys, detailKeys, DetailValue };
