/**
 * @author Salma Hefnawy
 * @date 2022-10-24
 * @description implementation of destination related API calls.
 * @filename destination.ts
 */
import { Network } from './network';
import { ENDPOINTS } from './endpoints';
import {
  DestinationTestimonial,
  DestinationConstructionUpdates,
} from '../definitions/interfaces/destination.interface';
import {
  Payload,
  DestinationProject,
  UnitType,
  DestinationFacilities,
  Destination as DestinationInterface,
  Location,
} from '@orascom/api-interfaces';

/**
 * implement destinations related calls.
 */
export class Destination {
  /**
   * get all destination data from API.
   *
   * @static
   * @returns {Promise<Payload<DestinationInterface>}
   * @memberof Destination
   */
  public static getDestinationInfo(
    destinationSlug: string
  ): Promise<Payload<DestinationInterface>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getDestinationInfo.url(
        destinationSlug
      )}`,
      {
        method: ENDPOINTS.getDestinationInfo.method,
      }
    );
  }

  /**
   * get destination facilities by destination id
   *
   * @static
   * @param {string} destinationSlug
   * @returns {Promise<Payload<DestinationFacilities[]>>}
   *
   * @memberOf Destination
   */
  public static getDestinationFacilities(
    destinationSlug: string
  ): Promise<Payload<DestinationFacilities[]>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getDestinationFacilities.url(
        destinationSlug
      )}`,
      {
        method: ENDPOINTS.getDestinationFacilities.method,
      }
    );
  }

  /**
   * get all destination testimonials data from API.
   *
   * @static
   * @returns {Promise<Payload<DestinationTestimonial[]>}
   * @memberof Destination
   */
  public static getDestinationTestimonials(
    destinationSlug: string
  ): Promise<Payload<DestinationTestimonial[]>> {
    return Network.fetch(
      `${
        process.env.NX__API_ENDPOINT
      }${ENDPOINTS.getDestinationTestimonials.url(destinationSlug)}`,
      {
        method: ENDPOINTS.getDestinationTestimonials.method,
      }
    );
  }

  /**
   * get all destination projects from API.
   *
   * @static
   * @returns {Promise<Payload<DestinationProject[]>}
   * @memberof Destination
   */
  public static getDestinationProjects(
    destinationSlug: string
  ): Promise<Payload<DestinationProject[]>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getDestinationProjects.url(
        destinationSlug
      )}`,
      {
        method: ENDPOINTS.getDestinationProjects.method,
      }
    );
  }

  /**
   * get all destination unit types from API.
   *
   * @static
   * @returns {Promise<Payload<UnitType[]>}
   * @memberof Destination
   */
  public static getDestinationUnitTypes(
    destinationSlug: string
  ): Promise<Payload<UnitType[]>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getDestinationUnitTypes.url(
        destinationSlug
      )}`,
      {
        method: ENDPOINTS.getDestinationUnitTypes.method,
      }
    );
  }

  /**
   * get all destination construction updates from API.
   *
   * @static
   * @returns {Promise<Payload<DestinationConstructionUpdates[]>}
   * @memberof Destination
   */
  public static getDestinationConstructionUpdates(
    destinationSlug: string
  ): Promise<Payload<DestinationConstructionUpdates[]>> {
    return Network.fetch(
      `${
        process.env.NX__API_ENDPOINT
      }${ENDPOINTS.getDestinationConstructionUpdates.url(destinationSlug)}`,
      {
        method: ENDPOINTS.getDestinationConstructionUpdates.method,
      }
    );
  }

  /**
   * gets destination locations
   *
   * @static
   * @param {string} destinationSlug
   * @returns {Promise<Payload<Location[]>>}
   *
   * @memberOf Destination
   */
  public static getDestinationLocations(
    destinationSlug: string
  ): Promise<Payload<Location[]>> {
    return Network.fetch(
      `${
        process.env['NX__BROKER_API_ENDPOINT']
      }${ENDPOINTS.getDestinationLocations.url(destinationSlug)}`,
      {
        method: ENDPOINTS.getDestinationLocations.method,
      }
    );
  }
}
