import { useTranslation } from 'react-i18next';
import Numeral from 'numeral';
import styles from './unit-reservation-fee.module.scss';

export interface UnitReservationFeeProps {
  fee?: number | string;
  currency?: string;
  inForm?: boolean;
}

export function UnitReservationFee(props: Readonly<UnitReservationFeeProps>) {
  const { t } = useTranslation();
  return (
    <div
      className={`${styles['wrapper']} ${
        props.inForm && styles['wrapper-mobile']
      }`}
    >
      <h5>{t('reservationFeePaidOnline')}</h5>
      <h5 className={styles['price']}>
        {Numeral(props.fee).format('0,0')} <small>{props.currency}</small>
      </h5>
    </div>
  );
}

export default UnitReservationFee;
