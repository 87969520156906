/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import styles from './unit-masterplan.module.scss';
import { UnitMasterPlan as UnitMasterPlanInterface } from '@orascom/api-interfaces';
import { SelectDropdown } from '@orascom/common-components';
import { useTranslation } from 'react-i18next';
import { downloadImage } from '@orascom/utils';

/* eslint-disable-next-line */
export interface UnitMasterPlanProps {
  masterPlans: UnitMasterPlanInterface[];
  unitName: string;
}

export function UnitMasterPlan(props: Readonly<UnitMasterPlanProps>) {
  const { t } = useTranslation();
  const [selectedMasterPlan, setSelectedMasterPlan] =
    useState<UnitMasterPlanInterface>(props.masterPlans[0]);

  const masterPlanOptions = props.masterPlans.map((masterPlan) => {
    return { label: masterPlan.title, value: masterPlan.id };
  });

  const handleMasterPlanSelectOnChange = (masterPlanId: number) => {
    const selectedMasterPlanOption = props.masterPlans.find(
      (masterPlan) => masterPlan.id === masterPlanId
    );
    setSelectedMasterPlan(selectedMasterPlanOption as UnitMasterPlanInterface);
  };

  return (
    <div className={styles['unit-master-plan']}>
      <div className={styles['info']}>
        <h1 className="orascom__title">{t('intoThePlan')}</h1>
        <h2 className="orascom__sub-title">{t('floorPlan')}</h2>

        <div className="filter">
          {props.masterPlans.map((masterPlan) => (
            <button
              key={masterPlan.id}
              type="button"
              onClick={() => setSelectedMasterPlan(masterPlan)}
              className={
                selectedMasterPlan.id === masterPlan.id ? 'active-filter' : ''
              }
            >
              {masterPlan.title}
            </button>
          ))}
        </div>
        <div className={styles['dropdown-wrapper']}>
          <SelectDropdown
            options={masterPlanOptions}
            placeholder={t('floorPlan')}
            onChange={(val) =>
              handleMasterPlanSelectOnChange(Number(val?.value))
            }
            defaultValue={masterPlanOptions[0]}
            selectedOption={{
              label: selectedMasterPlan.title,
              value: selectedMasterPlan.id,
            }}
          />
        </div>
        <div className={styles['image--responsive']}>
          <PhotoProvider maskOpacity={0.8}>
            <div className={styles['image--responsive']}>
              <PhotoView src={selectedMasterPlan.image}>
                <img
                  src={selectedMasterPlan.image}
                  alt="master-plan"
                  loading="lazy"
                />
              </PhotoView>
            </div>
          </PhotoProvider>

          <button
            className={styles['download-btn']}
            onClick={() =>
              downloadImage(
                selectedMasterPlan.image,
                `${props.unitName} ${selectedMasterPlan.title} floorplan`
              )
            }
          >
            {t('downloadFloorPlanImage')}
          </button>
        </div>
      </div>
      <div className={styles['image']}>
        <PhotoProvider maskOpacity={0.8}>
          <div className={styles['image']}>
            <PhotoView src={selectedMasterPlan.image}>
              <img
                src={selectedMasterPlan.image}
                alt="master-plan"
                loading="lazy"
              />
            </PhotoView>
          </div>
        </PhotoProvider>
        <div className={styles['image-footer']}>
          <p>{selectedMasterPlan.title}</p>
          <button
            className={styles['download-btn']}
            onClick={() =>
              downloadImage(
                selectedMasterPlan.image,
                `${props.unitName} ${selectedMasterPlan.title} floorplan`
              )
            }
          >
            {t('downloadFloorPlanImage')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default UnitMasterPlan;
