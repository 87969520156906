import { useState, useRef, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import plusSign from '../../../assets/icons/plus.svg';
import minusSign from '../../../assets/icons/minus.svg';
import styles from './montenegro-unit-payment-plan.module.scss';
import { Loader } from '@orascom/common-components';
import {
  UnitDetails as UnitDetailsInterface,
  InstallmentFrequencyMapper,
  UnitPaymentTerms,
} from '@orascom/api-interfaces';
import Numeral from 'numeral';
import { RESERVATION_CONTEXT } from 'libs/common-components/src/contexts/reservation.context';
import { downloadSalesOffer } from '@orascom/utils';

export interface MontenegroUnitPaymentPlanProps {
  paymentTerms: UnitPaymentTerms[];
  reservationPage?: boolean;
  readTerms?: boolean;
  acknowledgedNonRefundable?: boolean;
  saveButton?: React.ReactNode;
  handleSelectPaymentTerms?: (paymentTerms: UnitPaymentTerms) => void;
  updateReservationFeeValue?: (value: number) => void;
  unitDetails?: UnitDetailsInterface;
  selectPaymentTerms?: UnitPaymentTerms;
  updateReadTermsState?: () => void;
  updateAcknowledgedNonRefundableState?: () => void;
}

export function MontenegroUnitPaymentPlan({
  paymentTerms,
  reservationPage,
  readTerms,
  acknowledgedNonRefundable,
  updateReadTermsState,
  updateAcknowledgedNonRefundableState,
  saveButton,
  handleSelectPaymentTerms,
  updateReservationFeeValue,
  unitDetails,
  selectPaymentTerms,
}: Readonly<MontenegroUnitPaymentPlanProps>) {
  const [loadingPDF, setLoadingPDF] = useState(false);
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [showInstallments, setShowInstallments] = useState<string>('');

  const installmentsElement = useRef<null | HTMLDivElement>(null);
  const [selectedPaymentPlan, setSelectedPaymentPlan] =
    useState<UnitPaymentTerms>(paymentTerms[0]);
  const { setSelectedPaymentTerms, selectedPaymentTerms } =
    useContext(RESERVATION_CONTEXT);

  useEffect(() => {
    setSelectedPaymentPlan(paymentTerms[0]);
    if (handleSelectPaymentTerms) {
      handleSelectPaymentTerms(paymentTerms[0]);
    }
    if (updateReservationFeeValue) {
      updateReservationFeeValue(paymentTerms[0].prereservation_amount);
    }
  }, []);
  const unitCurrency = paymentTerms[activeTab].currency;
  const paymentPlansDetails = paymentTerms[activeTab].payment_plan_details;

  async function handleDownloadSales() {
    setLoadingPDF(true);
    try {
      if (unitDetails && selectPaymentTerms) {
        await downloadSalesOffer(unitDetails, selectPaymentTerms);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingPDF(false);
    }
  }

  return (
    <div
      className={`${styles['wrapper']} card ${
        reservationPage && 'reservation-wrapper'
      }`}
    >
      {!reservationPage ? (
        <>
          <h3>{t('paymentPlan')}</h3>
          <p className={styles['note']}>
            {t('userOurPaymentCalculatorAndCustomizeYourPayment')}
          </p>
        </>
      ) : null}
      <div
        className={`${styles['tabs']} ${reservationPage && 'reservation-tabs'}`}
      >
        <label>{t('selectYourPlan')}</label>
        <div
          className={`${styles['grid']} ${
            reservationPage && 'reservation-grid'
          }`}
        >
          {paymentTerms.map((paymentTerm, index) => {
            return (
              <button
                key={uuidv4()}
                onClick={() => {
                  if (setSelectedPaymentTerms) {
                    setSelectedPaymentTerms(paymentTerm);
                  }
                  if (handleSelectPaymentTerms) {
                    handleSelectPaymentTerms(paymentTerm);
                  }
                  if (updateReservationFeeValue) {
                    updateReservationFeeValue(
                      paymentTerm.prereservation_amount
                    );
                  }
                  setActiveTab(index);
                }}
                className={`${styles['tab']} ${
                  activeTab === index ? styles['tab--active'] : ''
                }`}
              >
                {paymentTerm.paymentplan_name}
              </button>
            );
          })}
        </div>
      </div>
      {!reservationPage ? (
        <div className={styles['tabs__content']}>
          <h4 className={styles['sub-title']}>{t('unitPrice')}</h4>
          <div className={styles['grid']}>
            <div className="card">
              <p className={styles['label']}>{t('originalUnitPrice')}</p>
              <h2 className={styles['value']}>
                {Numeral(paymentTerms[activeTab].unit_price).format('0,0')}{' '}
                {unitCurrency}
              </h2>
            </div>
            <div className="card">
              <p className={styles['label']}>{t('reservationFees')}</p>
              <h2 className={styles['value']}>
                {Numeral(paymentTerms[activeTab].prereservation_amount).format(
                  '0,0'
                )}{' '}
                {unitCurrency}
              </h2>
            </div>
            {paymentTerms[activeTab].discount_amount ? (
              <>
                <div className="card">
                  <p className={styles['label']}>{t('discount')}</p>
                  <h2 className={styles['value']}>
                    {Numeral(paymentTerms[activeTab].discount_amount).format(
                      '0,0'
                    )}{' '}
                    ({paymentTerms[activeTab].discount_percent}%) {unitCurrency}
                  </h2>
                </div>
                <div className={`card ${styles['card-blue']}`}>
                  <p className={styles['label']}>
                    {t('unitPriceAfterDiscount')}
                  </p>
                  <h2 className={styles['value']}>
                    {`${Numeral(
                      paymentTerms[activeTab].price_after_discount
                    ).format('0,0')} ${unitCurrency}`}
                  </h2>
                </div>
              </>
            ) : null}
          </div>
          <div>
            <h4 className={styles['sub-title']}>{t('paymentPlan')}</h4>
            <div className={styles['payment-plan']}>
              <div className={`${styles['grid']} ${styles['headers']}`}>
                <p> {t('millestones')}</p>
                <p> {t('amount')}</p>
                <p> {t('amountDueIn')}</p>
              </div>
              <div>
                {paymentPlansDetails?.map((paymentPlan) => {
                  return (
                    <div
                      className={styles['payment-plan-details']}
                      key={uuidv4()}
                    >
                      <div className={`${styles['grid']} ${styles['row']}`}>
                        <p>{paymentPlan.payment_type}</p>
                        <p>
                          {Numeral(paymentPlan.payment_amount).format('0,0')}{' '}
                          {unitCurrency} ({paymentPlan.payment_percent}%)
                        </p>
                        <p>
                          {paymentPlan.due_date}
                          {paymentPlan.is_installment ? (
                            showInstallments === paymentPlan.payment_type ? (
                              <button
                                type="button"
                                onClick={() => setShowInstallments('')}
                              >
                                <img src={minusSign} alt="minus-sign" />
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={() =>
                                  setShowInstallments(paymentPlan.payment_type)
                                }
                              >
                                <img src={plusSign} alt="plus-sign" />
                              </button>
                            )
                          ) : null}
                        </p>
                      </div>
                      {paymentPlan.is_installment ? (
                        <div
                          ref={installmentsElement}
                          style={
                            showInstallments === paymentPlan.payment_type
                              ? {
                                  height:
                                    installmentsElement?.current?.scrollHeight,
                                }
                              : { height: '0px' }
                          }
                          className={styles['installments']}
                        >
                          <hr />
                          <div className={styles['grid']}>
                            <div className="card">
                              <p className={styles['label']}>{t('duration')}</p>
                              <h2 className={styles['value']}>
                                {paymentPlan.installment_duration_in_years}{' '}
                                Years
                              </h2>
                            </div>
                            <div className="card">
                              <p className={styles['label']}>
                                {t('frequency')}
                              </p>
                              <h2 className={styles['value']}>
                                {
                                  InstallmentFrequencyMapper[
                                    paymentPlan.installment_frequency
                                  ]
                                }
                              </h2>
                            </div>
                            {/* <div className="card">
                              <p className={styles['label']}>
                                {t('firstInstallment')}
                              </p>
                              <h2 className={styles['value']}>
                                {Numeral(paymentPlan.payment_amount).format(
                                  '0,0'
                                )}{' '}
                                {unitCurrency} ({paymentPlan.payment_percent}%)
                              </h2>
                            </div> */}
                            <div className="card">
                              <p className={styles['label']}>
                                {t('installmentAmount')}
                                {'  '}.
                                <small>
                                  {paymentPlan.total_number_of_installments}{' '}
                                  {t('installments')}
                                </small>
                              </p>
                              <h2 className={styles['value']}>
                                {Numeral(paymentPlan.installment_amount).format(
                                  '0,0'
                                )}{' '}
                                {unitCurrency}
                              </h2>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
            {unitDetails?.project.sales_offer && selectPaymentTerms && (
              <button
                id="download-btn"
                className={`${styles['download-btn']}`}
                onClick={handleDownloadSales}
              >
                {loadingPDF ? <Loader /> : t('downloadSalesOffer')}
              </button>
            )}
          </div>
        </div>
      ) : null}

      {reservationPage ? (
        <div className={styles['footer']}>
          <div className={styles['reservation-fee']}>
            <p>{t('reservationFee')}</p>
            <p>
              {selectedPaymentTerms &&
                Numeral(selectedPaymentTerms?.prereservation_amount).format(
                  '0,0'
                )}{' '}
              <small className={styles['currency']}>
                {selectedPaymentTerms?.currency}
              </small>
            </p>
          </div>

          <div className={styles['terms']}>
            <input
              type="checkbox"
              checked={readTerms}
              readOnly
              name="terms-checkbox"
              id="terms-checkbox"
              onClick={updateReadTermsState}
            />
            <label htmlFor="terms-checkbox">
              <span>{t('haveReadAndAccepted')} </span>
              <span className={styles['terms-link']}>
                {t('terms&Conditions')}
              </span>
              <span>{t('howTheyAffectPrice')}</span>
            </label>
          </div>
          <div className={styles['terms']}>
            <input
              type="checkbox"
              checked={acknowledgedNonRefundable}
              onClick={updateAcknowledgedNonRefundableState}
              name="non-refundable-checkbox"
              id="non-refundable-checkbox"
            />
            <label htmlFor="non-refundable-checkbox">
              <span>{t('nonRefundableTerm')}</span>
            </label>
          </div>
          {saveButton}
        </div>
      ) : null}
    </div>
  );
}

export default MontenegroUnitPaymentPlan;
