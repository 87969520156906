import { CURRENCIES_ENUM } from '@orascom/api-interfaces';

export const CURRENCIES = [
  CURRENCIES_ENUM.EGP,
  CURRENCIES_ENUM.USD,
  CURRENCIES_ENUM.EUR,
  CURRENCIES_ENUM.OMR,
  CURRENCIES_ENUM.AED,
];

export async function fetchExchangeRates(
  sourceCurrency: string,
  getCurrenciesUrl: (sourceCurrency: string, currencies: string) => string
): Promise<{ [key: string]: number } | null> {
  const baseUrl = process.env['NX__API_ENDPOINT'];
  const getEndpoint = getCurrenciesUrl;
  const currencies = CURRENCIES.join(',');
  try {
    const response = await fetch(
      baseUrl + getEndpoint(sourceCurrency, currencies)
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch exchange rates: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    return null;
  }
}
