import { GoldButton, NavyButton } from '../common/button/button';
import userIcon from '../../assets/icons/user.svg';
import signatureIcon from '../../assets/icons/signature.svg';
import { useContext } from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { RESERVATION_CONTEXT } from 'libs/common-components/src/contexts/reservation.context';
import { ReservationStateEnum } from '@orascom/api-interfaces';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import styles from './reservation-payment-successful.module.scss';
import { useTranslation } from 'react-i18next';
import greenTick from '../../assets/icons/green-tick.svg';
import { USER_CONTEXT } from '../../contexts/user-context';

export function ReservationPaymentSuccessful() {
  const { t } = useTranslation();
  const { setReservationState, reservationUnitDetails } =
    useContext(RESERVATION_CONTEXT);
  const navigate = useNavigate();

  const userContext = useContext(USER_CONTEXT);

  return (
    <div className={styles['wrapper']}>
      <div className={styles['banner']}>
        <h5 className={styles['title']}>
          <img src={greenTick} alt="" role="presentation" />
          {t('paymentSuccessful')}
        </h5>
        <p className={styles['body']}>
          {t('congratulationsOnFinishingFirstStep')}{' '}
          <strong>{`${reservationUnitDetails?.unit_type.name} - ${reservationUnitDetails?.project.destination.name} - ${reservationUnitDetails?.project.name}"`}</strong>
        </p>
        <p className={styles['body']}>
          {t('weHaveSentAnEmailReceiptTo')}{' '}
          <strong>{userContext?.user?.email}</strong>{' '}
          {t('acknowledgingThePaymentForTheReservationFee')}
        </p>
      </div>
      <h5 className={styles['title']}>{t('upcomingSteps')}</h5>
      <div className={styles['steps']}>
        <div className={styles['step']}>
          <img src={userIcon} alt="" role="presentation" />
          <h6>{t('fillOutRestYourInformation')}</h6>
          <p>{t('inOrderForReservationToBeEffective')}</p>
        </div>
        <div className={styles['step']}>
          <img src={signatureIcon} alt="" role="presentation" />
          <h6>{t('downloadAndSignReservationForm')}</h6>
          <p>{t('downloadSignAndUploadAgain')}</p>
        </div>
      </div>
      <div className={styles['buttons']}>
        <GoldButton
          onClick={() => setReservationState?.(ReservationStateEnum.YOUR_INFO)}
        >
          {t('saveAndProceed')}
        </GoldButton>
        <NavyButton onClick={() => navigate(ROUTES.Home.path)}>
          {t('saveAndContinueLater')}
        </NavyButton>
      </div>
    </div>
  );
}

export default ReservationPaymentSuccessful;
