/* eslint-disable @nx/enforce-module-boundaries */
/**
 * @author Salma Hefnawy
 * @date 2022-11-06
 * @description implementation of unit related API calls.
 * @filename unit.ts
 */
import { Network } from './network';
import { ENDPOINTS } from './endpoints';

import {
  PaginationPayload,
  Payload,
  UnitCompareInterface,
  UnitDetails,
  UnitInterface,
  UnitReservationDetails,
  UnitPaymentTerms as UnitPaymentTermsInterface,
  Common,
  AddDraftSaleParams,
  ReserveTransactionCodeParams,
  UnitReservationNationality,
  UnitReservationCountry,
  UnitReservationCity,
  UnitReservationOccupation,
  UpdateUserInfoRequest,
  SaleInstallmentsResponse,
  UnitReservationConnection,
} from '@orascom/api-interfaces';
import i18next from 'i18next';
import { DraftSaleResponse } from '../definitions/interfaces/common.interface';
import { toast } from 'react-toastify';

/**
 * implement unit related calls.
 */
export class Unit {
  /**
   * get all unit details data from API.
   *
   * @static
   * @param {string} unitId
   * @returns {Promise<<Payload<UnitDetails>>}
   * @memberof Unit
   */
  public static getUnitDetails(unitId: string): Promise<Payload<UnitDetails>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getUnitDetails.url(unitId)}`,
      {
        method: ENDPOINTS.getUnitDetails.method,
      }
    );
  }

  /**
   * get all unit payments data from API.
   *
   * @static
   * @param {string} unitId
   * @returns {Promise<UnitPayment[]>}
   * @memberof Unit
   */
  public static getUnitPayments(
    unitId: string
  ): Promise<Payload<Payload<UnitPaymentTermsInterface[]>>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getUnitPayments.url(unitId)}`,
      {
        method: ENDPOINTS.getUnitPayments.method,
      }
    );
  }

  /**
   * get all unit details data from API.
   *
   * @static
   * @param {number[]} unitsIds
   * @returns {Promise<<Payload<UnitCompareInterface[]>>}
   * @memberof Unit
   */
  public static getCompareUnits(
    unitsIds: number[]
  ): Promise<Payload<UnitCompareInterface[]>> {
    const url = new URL(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.compareUnits.path}`
    );
    for (const index in unitsIds) {
      url.searchParams.append(`unit_ids[${index}]`, `${unitsIds[index]}`);
    }

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.compareUnits.method,
    });
  }

  /**
   * fetch all units depending on optional search params
   *
   * @static
   * @param {number} page
   * @param {number} perPage
   * @param {string} [filters]
   * @returns {Promise<Payload<PaginationPayload<UnitInterface[]>>>}
   *
   * @memberOf Unit
   */
  public static getUnits(
    searchParams?: URLSearchParams | string
  ): Promise<Payload<PaginationPayload<UnitInterface[]>[]>> {
    const baseUrl = process.env['NX__API_ENDPOINT'];
    const unitsEndpoint = ENDPOINTS.getUnits.url();

    const url = new URL(`${baseUrl}${unitsEndpoint}`);

    if (searchParams) {
      url.search = searchParams.toString();
    }

    return Network.fetch(url.href, {
      method: ENDPOINTS.getUnits.method,
    });
  }

  /**
   * get all unit reservation details data from API.
   *
   * @static
   * @param {string} unitId
   * @returns {Promise<<Payload<UnitReservationDetails>>}
   * @memberof Unit
   */
  public static getUnitReservationDetails(
    unitId: string
  ): Promise<Payload<UnitReservationDetails>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getUnitReservationDetails.url(
        unitId
      )}`,
      {
        method: ENDPOINTS.getUnitReservationDetails.method,
      }
    );
  }

  /**
   * get all unit reservation nationalities.
   *
   * @static
   * @param {string} countryId
   * @returns {Promise<<Payload<UnitReservationNationality[]>>}
   * @memberof Unit
   */
  public static getUnitReservationNationalities(
    countryId: string
  ): Promise<Payload<UnitReservationNationality[]>> {
    const url = new URL(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getUnitReservationNationalities.path}`
    );
    url.searchParams.append('country_id', String(countryId));

    const fetchURL = decodeURIComponent(url.href);

    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getUnitReservationNationalities.method,
    });
  }

  /**
   * get all unit reservation cities.
   *
   * @static
   * @param {string} countryId
   * @returns {Promise<<Payload<UnitReservationCity[]>>}
   * @memberof Unit
   */
  public static getUnitReservationCities(
    countryId: string
  ): Promise<Payload<UnitReservationCity[]>> {
    const url = new URL(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getUnitReservationCities.path}`
    );
    url.searchParams.append('country_id', String(countryId));

    const fetchURL = decodeURIComponent(url.href);

    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getUnitReservationCities.method,
    });
  }

  /**
   * get all unit reservation countries.
   *
   * @static
   * @param {string} countryId
   * @returns {Promise<<Payload<UnitReservationCountry[]>>}
   * @memberof Unit
   */
  public static getUnitReservationCountries(
    countryId: string
  ): Promise<Payload<UnitReservationCountry[]>> {
    const url = new URL(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getUnitReservationCountries.path}`
    );
    url.searchParams.append('country_id', String(countryId));

    const fetchURL = decodeURIComponent(url.href);

    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getUnitReservationCountries.method,
    });
  }

  /**
   * get all unit reservation occupations.
   *
   * @static
   * @param {string} countryId
   * @returns {Promise<<Payload<UnitReservationOccupation[]>>}
   * @memberof Unit
   */
  public static getUnitReservationOccupations(
    countryId: string
  ): Promise<Payload<UnitReservationOccupation[]>> {
    const url = new URL(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getUnitReservationOccupations.path}`
    );
    url.searchParams.append('country_id', String(countryId));

    const fetchURL = decodeURIComponent(url.href);

    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getUnitReservationOccupations.method,
    });
  }

  public static getUnitReservationConnections(
    countryId: string
  ): Promise<Payload<UnitReservationConnection[]>> {
    const url = new URL(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getUnitReservationConnections.path}`
    );
    url.searchParams.append('country_id', String(countryId));

    const fetchURL = decodeURIComponent(url.href);

    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getUnitReservationConnections.method,
    });
  }

  public static addDraftSale(
    unitId: number,
    draftSaleData: AddDraftSaleParams
  ): Promise<Payload<DraftSaleResponse>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.addDraftSale.url(unitId)}`,
      {
        method: ENDPOINTS.addDraftSale.method,
        body: JSON.stringify(draftSaleData),
      }
    );
  }

  public static reserveTransactionCode(
    unitId: number,
    saleId: number,
    reserveTransactionCodeData: ReserveTransactionCodeParams
  ): Promise<Common> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.reserveTransactionCode.url(
        unitId,
        saleId
      )}`,
      {
        method: ENDPOINTS.reserveTransactionCode.method,
        body: JSON.stringify(reserveTransactionCodeData),
      }
    );
  }

  public static reserveAttachment(
    unitId: number,
    saleId: number,
    signReservationForm: string,
    file: File
  ): Promise<Payload<DraftSaleResponse>> {
    const formData = new FormData();
    formData.append('attachment', file);
    formData.append('sign_reservation_form', signReservationForm);

    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.reserveAttachment.url(
        unitId,
        saleId
      )}`,
      {
        method: ENDPOINTS.reserveTransactionCode.method,
        body: formData,
      },
      null
    );
  }

  public static getSalePaymentToken(
    unitId: number,
    saleId: number,
    amount: number
  ): Promise<Payload<string>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getSalePaymentToken.url(
        unitId,
        saleId
      )}`,
      {
        method: ENDPOINTS.reserveTransactionCode.method,
        body: JSON.stringify({ amount }),
      }
    );
  }

  public static getSaleInstallments(
    saleId: string,
    countrySlug: string
  ): Promise<Payload<SaleInstallmentsResponse>> {
    const url = new URL(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getSaleInstallments.url(
        saleId
      )}`
    );
    url.searchParams.append('country_slug', countrySlug);

    const fetchURL = decodeURIComponent(url.href);
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getSaleInstallments.method,
    });
  }

  public static updateReservedUserInfo(
    unitId: number,
    saleId: number,
    userInfo: UpdateUserInfoRequest
  ): Promise<Payload<DraftSaleResponse>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.updateReservedUserInfo.url(
        unitId,
        saleId
      )}`,
      {
        method: ENDPOINTS.updateReservedUserInfo.method,
        body: JSON.stringify(userInfo),
      }
    );
  }

  public static downloadReservationForm(
    unitId: number,
    saleId: number,
    customerId: string
  ) {
    const url = new URL(
      `${
        process.env['NX__API_ENDPOINT']
      }${ENDPOINTS.downloadReservationForm.url(unitId, saleId)}`
    );
    url.searchParams.append('customer_source_id', String(customerId));

    const fetchURL = decodeURIComponent(url.href);
    const headers = {
      locale: i18next.language ?? 'en',
    };
    (headers as any)['Authorization'] = `Bearer ${localStorage.getItem(
      process.env['NX_SHOPPER_ACCESS_TOKEN_KEY'] as string
    )}`;

    return fetch(fetchURL, {
      method: ENDPOINTS.downloadReservationForm.method,
      headers,
    }).catch((error: Error) => {
      return Promise.reject(error);
    });
  }

  public static downloadOmanSalesOffer(unitId: number): Promise<any> {
    const url = new URL(
      `${process.env['NX__API_ENDPOINT']}${ENDPOINTS.downloadOmanSalesOffer.url(
        unitId
      )}`
    );

    return fetch(url, {
      method: ENDPOINTS.downloadOmanSalesOffer.method,
    })
      .then((res) => {
        if (!res.ok) {
          toast.error('This unit doesnt have a sales offer');
          throw new Error('Network response was not ok');
        }
        return res.blob();
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static finishUnitReservation(
    unitId: number,
    saleId: number
  ): Promise<Payload<string>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.finishUnitReservation.url(
        unitId,
        saleId
      )}`,
      {
        method: ENDPOINTS.finishUnitReservation.method,
      }
    );
  }
}
