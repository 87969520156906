import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from './country-destinations.module.scss';
import { Country as CountryUtilities } from '../../utils/country.utils';
import { CountryDestination as CountryDestinationInterface } from '../../definitions/interfaces/country.interface';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../api/routes';
import { FadeInSection, Loader } from '@orascom/common-components';

export function CountryDestinations() {
  const { t } = useTranslation();
  const countryId = useParams<{ countrySlug: string; countryId: string }>()
    .countryId;

  const [countryDestinations, setCountryDestinations] =
    useState<CountryDestinationInterface[]>();
  const [countryName, setCountryName] = useState('');

  useEffect(() => {
    if (countryId) {
      CountryUtilities.getCountries(countryId)
        .then((res) => {
          setCountryDestinations(res[0].destinations);
          setCountryName(res[0].name);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [countryId]);

  if (!countryDestinations) {
    return <Loader />;
  }
  return (
    <div className={styles['wrapper']}>
      <div className={styles['headers']}>
        <h1 className="orascom__title">{t('discoverYourWorld')}</h1>
        <h2 className="orascom__sub-title">
          {t('exploreOurDestinations')} in {countryName}
        </h2>
      </div>
      {countryDestinations.map((countryDestination) => {
        return (
          <div key={countryDestination.id} className={styles['card']}>
            <FadeInSection>
              <div className={styles['section']}>
                <Link
                  to={ROUTES.Destination.getPath?.(countryDestination.slug)}
                  className={styles['section-image']}
                >
                  <img
                    src={countryDestination.cover_image}
                    alt=""
                    loading="lazy"
                  />
                </Link>
                <div className={styles['section-info']}>
                  <h2 className="orascom__sub-title">
                    {countryDestination.name}
                  </h2>
                  <p>{countryDestination.welcome_description}</p>
                  <Link
                    to={ROUTES.Destination.getPath?.(countryDestination.slug)}
                    className="btn btn--navy"
                  >
                    {t('learnMore')}
                    <div className="white" />
                  </Link>
                </div>
              </div>
            </FadeInSection>
          </div>
        );
      })}
    </div>
  );
}

export default CountryDestinations;
