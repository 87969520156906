/**
 * @author Salma Hefnawy
 * @date 2022-11-29
 * @description implementation of offer related API calls.
 * @filename offer.ts
 */
import { Network } from '../network';
import { PRE_DELIVERY_ENDPOINTS } from '../endpoints';
import { PaginationPayload, Payload } from '@orascom/api-interfaces';
import { OfferInterface } from '../../definitions/interfaces/pre-delivery.interface';

/**
 * implement offer related calls.
 */
export class Offer {
  /**
   * get list of pre-delivery offers
   *
   * @static
   * @param {number} page
   * @param {number} perPage
   * @returns {Promise<Payload<PaginationPayload<OfferInterface[]>>>}
   *
   * @memberOf Offer
   */
  public static getOffers(
    page: number,
    perPage: number
  ): Promise<Payload<PaginationPayload<OfferInterface[]>>> {
    const url = new URL(
      `${process.env.NX__PRE_DELIVERY_API_ENDPOINT}${PRE_DELIVERY_ENDPOINTS.getOffers.path}`
    );
    url.searchParams.append('page', String(page));
    url.searchParams.append('per_page', String(perPage));

    return Network.fetch(url.href, {
      method: PRE_DELIVERY_ENDPOINTS.getOffers.method,
    });
  }
}
