import { ChangeEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Field,
  ErrorMessage,
  FormikErrors,
  FormikValues,
  FormikTouched,
} from 'formik';
import SecureIcon from '../../assets/icons/secure.svg?react';
import UploadIcon from '../../assets/icons/reservation-upload.svg?react';
import ErrorIcon from '../../assets/icons/error.svg';
import WarningIcon from '../../assets/icons/warning.svg';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { RESERVATION_CONTEXT } from 'libs/common-components/src/contexts/reservation.context';

// Todo: this will be used after integration
// Todo: this should be refactored to a common component

/* eslint-disable-next-line */
export interface BankFormProps {
  page?: 'reservation' | 'buy-addon';
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<any>;
}

export function BankForm({
  touched,
  errors,
  values,
  page,
  setFieldValue,
}: Readonly<BankFormProps>) {
  const { t } = useTranslation();
  const { reservationUnitDetails } = useContext(RESERVATION_CONTEXT);

  const [bankReceiptImageText, setBankReceiptImageText] = useState('');
  const bankTransferDescription =
    page !== 'buy-addon' ? t('bankTransferDesc') : t('bankTransferAddonDesc');

  const handleBankReceiptChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      setBankReceiptImageText(event.currentTarget.files[0].name);
      setFieldValue('bankReceipt', event.currentTarget.files[0]);
    }
  };

  return (
    <>
      {values.paymentMethod === 'bank-transfer' && (
        <div className="reservation-sub-form">
          <h2 className="reservation-form-title">
            {t('payUsingBankTransfer')}
            <span>
              <SecureIcon />
              {t('securePayment')}
            </span>
          </h2>
          <p>{bankTransferDescription}</p>
          <div className="reservation-sub-form--bank-info">
            <div>
              <label>{t('bankAccountNumber')}</label>
              <p>
                {reservationUnitDetails?.project.destination.account_number}
              </p>
            </div>
            <div>
              <label>{t('bankAccountName')}</label>
              <p>ORASCOMDH EGYPT</p>
            </div>
          </div>
          <p className="reservation-sub-form--note">
            <img src={WarningIcon} role="presentation" alt="" />{' '}
            {t('bankTransferNote')}
          </p>
          <div className="input-wrapper">
            <label htmlFor="swiftCode">{t('transactionCode')}*</label>
            <Field
              name="swiftCode"
              type="text"
              placeholder="AAAA BB CC DDD"
              className={errors.swiftCode && touched.swiftCode ? 'error' : ''}
            />

            <ErrorMessage
              name="swiftCode"
              render={(msg: string) => (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {msg}
                </p>
              )}
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor="bankReceipt">{t('bankReceiptPhoto')}*</label>
            <div className="reservation-upload">
              <UploadIcon />

              {bankReceiptImageText.length ? (
                <>
                  <span className="reservation-upload__file">
                    {bankReceiptImageText}
                  </span>
                  <button
                    className="reservation-upload__remove"
                    onClick={() => setBankReceiptImageText('')}
                  >
                    x
                  </button>
                </>
              ) : (
                <span className="reservation-upload__placeholder">
                  {t('uploadImagePlaceholder')}
                </span>
              )}
              <input
                id="bankReceipt"
                name="bankReceipt"
                type="file"
                accept="image/*,.pdf"
                className={
                  errors.bankReceipt && touched.bankReceipt ? 'error' : ''
                }
                onChange={(event) => {
                  handleBankReceiptChange(event);
                }}
              />
            </div>

            <ErrorMessage
              name="bankReceipt"
              render={(msg: string) => (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {msg}
                </p>
              )}
            />
          </div>
        </div>
      )}
      {page !== 'buy-addon' && values.paymentMethod === 'bank-receipt' && (
        <div className="reservation-sub-form">
          <h2 className="reservation-form-title">
            {t('uploadBankReceipt')}
            <span>
              <SecureIcon />
              {t('securePayment')}
            </span>
          </h2>
          <p>{t('uploadBankReceiptNote')}</p>
          <div className="reservation-sub-form--bank-info">
            <div>
              <label>{t('bankAccountNumber')}</label>
              <p>
                {reservationUnitDetails?.project.destination.account_number}
              </p>
            </div>
            <div>
              <label>{t('bankAccountName')}</label>
              <p>ORASCOMDH EGYPT</p>
            </div>
          </div>
          <div className="input-wrapper">
            <label htmlFor="bankReceipt">{t('bankReceiptPhoto')}*</label>
            <div className="reservation-upload">
              <UploadIcon />

              {bankReceiptImageText.length ? (
                <>
                  <span className="reservation-upload__file">
                    {bankReceiptImageText}
                  </span>
                  <button
                    className="reservation-upload__remove"
                    onClick={() => setBankReceiptImageText('')}
                  >
                    x
                  </button>
                </>
              ) : (
                <span className="reservation-upload__placeholder">
                  {t('uploadImagePlaceholder')}
                </span>
              )}
              <input
                id="bankReceipt"
                name="bankReceipt"
                type="file"
                accept="image/*,.pdf"
                className={
                  errors.bankReceipt && touched.bankReceipt ? 'error' : ''
                }
                onChange={(event) => {
                  handleBankReceiptChange(event);
                }}
              />
            </div>

            <ErrorMessage
              name="bankReceipt"
              render={(msg: string) => (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {msg}
                </p>
              )}
            />
          </div>
        </div>
      )}
    </>
  );
}
