import { Helmet } from 'react-helmet-async';
import getDocTitle from '../../utils/getDocTitle';

const notFoundTitle = getDocTitle('Not Available Unit');
const NotAvailableUnitHelmet = () => {
  return (
    <Helmet>
      <title>{notFoundTitle}</title>
      <meta property="og:title" content={notFoundTitle} />
    </Helmet>
  );
};

export default NotAvailableUnitHelmet;
