import React, { useState } from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Swiper as SwiperType, FreeMode, Navigation, Thumbs } from 'swiper';
import styles from './thumbs-gallery.module.scss';

interface ThumbsGalleryProps {
  images: string[];
  featuredSwiperProps?: SwiperProps;
  thumbsSwiperProps?: SwiperProps;
}

export const ThumbsGallery = ({
  images,
  featuredSwiperProps,
  thumbsSwiperProps,
}: ThumbsGalleryProps) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);

  return (
    <>
      <Swiper
        preloadImages={false}
        spaceBetween={10}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className={styles['featured-swiper']}
        navigation={true}
        centeredSlides
        {...featuredSwiperProps}
      >
        {images.map((image, index) => (
          <SwiperSlide key={image}>
            <img src={image} alt={`${index + 1}`} loading="lazy" />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className={styles['thumbs-swiper']}
        {...thumbsSwiperProps}
      >
        {images.map((image, index) => (
          <SwiperSlide key={image}>
            <img src={image} alt={`Thumbnail ${index + 1}`} loading="lazy" />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
