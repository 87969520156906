/**
 * @author Salma Hefnawy
 * @date 2022-10-23
 * @description implementation of country related API calls.
 * @filename country.ts
 */
import { Network } from './network';
import { ENDPOINTS } from './endpoints';
import {
  Country as CountryInterface,
  CountryInvestmentsInterface,
} from '../definitions/interfaces/country.interface';
import {
  PaginationPayload,
  Payload,
  UnitInterface,
  CountryInfo as CountryInfoInterface,
} from '@orascom/api-interfaces';

/**
 * implement country related calls.
 */
export class Country {
  /**
   * get all country data from API.
   *
   * @static
   * @returns {Promise<<Payload<CountryInfoInterface>>}
   * @memberof Country
   */
  public static getCountryInfo(
    countrySlug: string
  ): Promise<Payload<CountryInfoInterface>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getCountryInfo.url(
        countrySlug
      )}`,
      {
        method: ENDPOINTS.getCountryInfo.method,
      }
    );
  }

  /**
   * get all countries data from API.
   *
   * @static
   * @param {string} countryId
   * @returns {Promise<<Payload<CountryInterface[]>>}
   * @memberof Country
   */
  public static getCountries(
    countryId?: string
  ): Promise<Payload<CountryInterface[]>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getCountries.url(countryId)}`,
      {
        method: ENDPOINTS.getCountries.method,
      }
    );
  }

  /**
   * get list of units in a specific country based on search params
   *
   * @static
   * @param {string} countryId
   * @param {number} page
   * @param {number} perPage
   * @param {string} [filters]
   * @returns {Promise<Payload<PaginationPayload<UnitInterface[]>>>}
   *
   * @memberOf Country
   */
  public static getCountryUnits(
    countryId: string,
    page: number,
    perPage: number,
    filters?: string
  ): Promise<Payload<PaginationPayload<UnitInterface[]>>> {
    const url = new URL(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getCountryUnits.url(
        countryId
      )}`
    );
    url.searchParams.append('page', String(page));
    url.searchParams.append('per_page', String(perPage));
    const fetchURL = filters ? url.href.concat(filters) : url.href;
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getCountryUnits.method,
    });
  }

  /**
   * get all countries investments data from API.
   *
   * @static
   * @returns {Promise<<Payload<CountryInvestmentsInterface[]>>}
   * @memberof Country
   */
  public static getCountriesInvestments(): Promise<
    Payload<CountryInvestmentsInterface[]>
  > {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getCountriesInvestments.path}`,
      {
        method: ENDPOINTS.getCountriesInvestments.method,
      }
    );
  }
}
