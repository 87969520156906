/**
 * @author Salma Hefnawy
 * @date 2022-12-11
 * @description implementation of referral related API calls.
 * @filename referral.ts
 */
import { Network } from '../network';
import { PRE_DELIVERY_ENDPOINTS } from '../endpoints';
import { PaginationPayload, Payload } from '@orascom/api-interfaces';
import { ReferralInterface } from '../../definitions/interfaces/pre-delivery.interface';

/**
 * implement referral related calls.
 */
export class Referral {
  /**
   * get list of pre-delivery referrals
   *
   * @static
   * @param {number} page
   * @param {number} perPage
   * @returns {Promise<Payload<PaginationPayload<ReferralInterface[]>>>}
   *
   * @memberOf Offer
   */
  public static getMyReferrals(
    page: number,
    perPage: number
  ): Promise<Payload<PaginationPayload<ReferralInterface[]>>> {
    const url = new URL(
      `${process.env.NX__PRE_DELIVERY_API_ENDPOINT}${PRE_DELIVERY_ENDPOINTS.getMyReferrals.path}`
    );
    url.searchParams.append('current_page', String(page));
    url.searchParams.append('per_page', String(perPage));

    return Network.fetch(url.href, {
      method: PRE_DELIVERY_ENDPOINTS.getMyReferrals.method,
    });
  }
}
