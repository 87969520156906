/* eslint-disable @nx/enforce-module-boundaries */
import styles from './sign-contract.module.scss';
import { useTranslation } from 'react-i18next';
import { startOfTomorrow } from 'date-fns';

import 'react-phone-input-2/lib/style.css';
import ErrorIcon from '../../../assets/icons/error.svg';
import { ErrorMessage, Field, FormikErrors, FormikTouched } from 'formik';
import { SignContractRequestInitialData } from '../../../definitions/interfaces/service-requests.interface';
import { SelectDropdown } from '@orascom/common-components';

/* eslint-disable-next-line */
export interface SignContractOfflineFormFieldsProps {
  errors: FormikErrors<SignContractRequestInitialData>;
  touched: FormikTouched<SignContractRequestInitialData>;
  values: SignContractRequestInitialData;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export function SignContractOfflineFormFields(
  props: Readonly<SignContractOfflineFormFieldsProps>
) {
  const { t } = useTranslation();
  const { errors, touched, values, setFieldValue } = props;

  return (
    <div className="reservation-sub-form">
      <h2 className="reservation-form-title">{t('scheduleOfflineMeeting')}</h2>
      <div className="input-wrapper">
        <label htmlFor="meetingLocation">{t('whereIsMeeting')}*</label>
        {/* <Field
          name="meetingLocation"
          type="text"
          placeholder="Enter Meeting Location"
          className={
            errors.meetingLocation && touched.meetingLocation ? 'error' : ''
          }
        /> */}
        <SelectDropdown
          name="meetingLocation"
          className={`${styles['select']} ${
            errors.meetingLocation && touched.meetingLocation ? 'error' : ''
          }`}
          placeholder={t('selectPlaceholder')}
          onChange={(option) => setFieldValue('meetingLocation', option)}
          options={[
            {
              value: 'Nile City Towers Office',
              label: 'Nile City Towers Office',
            },
            {
              value: 'Makadi Sales Office (Salah Salem)',
              label: 'Makadi Sales Office (Salah Salem)',
            },
            {
              value: 'Owest Sales Office (Capital Business Park)',
              label: 'Owest Sales Office (Capital Business Park)',
            },
            {
              value: 'Gouna Sales Office (Gouna)',
              label: 'Gouna Sales Office (Gouna)',
            },
            {
              value: 'Lustica Bay HQ Sales Office',
              label: 'Lustica Bay HQ Sales Office',
            },
            {
              value: 'Marina Village Sales Office',
              label: 'Marina Village Sales Office',
            },
            {
              value: 'Tivat Sales Office',
              label: 'Tivat Sales Office',
            },
            {
              value: 'Oman HQ Sales Office',
              label: 'Oman HQ Sales Office',
            },
            {
              value: 'Hawana Salalah Sales Office',
              label: 'Hawana Salalah Sales Office',
            },
            {
              value: 'Jebel Sifah Sales Office',
              label: 'Jebel Sifah Sales Office',
            },
          ]}
          selectedOption={values.meetingLocation || null}
        />

        <ErrorMessage
          name="meetingLocation"
          render={(msg: string) => (
            <p className="error">
              <img src={ErrorIcon} role="presentation" alt="" />
              {msg}
            </p>
          )}
        />
      </div>
      <div className="input-wrappers">
        <div className="input-wrapper">
          <label htmlFor="meetingDate">{t('preferredDateMeeting')}*</label>
          <Field
            name="meetingDate"
            type="date"
            min={startOfTomorrow().toISOString().split('T')[0]}
            placeholder="MM / YY"
            className={errors.meetingDate && touched.meetingDate ? 'error' : ''}
          />

          <ErrorMessage
            name="meetingDate"
            render={(msg: string) => (
              <p className="error">
                <img src={ErrorIcon} role="presentation" alt="" />
                {msg}
              </p>
            )}
          />
        </div>
        <div className="input-wrapper">
          <label htmlFor="meetingTime">{t('preferredTimeMeeting')}*</label>
          <Field
            name="meetingTime"
            type="time"
            placeholder="01:00"
            className={errors.meetingTime && touched.meetingTime ? 'error' : ''}
          />

          <ErrorMessage
            name="meetingTime"
            render={(msg: string) => (
              <p className="error">
                <img src={ErrorIcon} role="presentation" alt="" />
                {msg}
              </p>
            )}
          />
        </div>
      </div>
      <div className="input-wrapper">
        <label htmlFor="additionalInfo">{t('furtherNotes')}</label>
        <Field
          id="additionalInfo"
          name="additionalInfo"
          as="textarea"
          className={styles['textarea']}
          placeholder={t('notePlacholder')}
        />

        <ErrorMessage
          name="additionalInfo"
          render={(msg: string) => (
            <p className="error">
              <img src={ErrorIcon} role="presentation" alt="" />
              {msg}
            </p>
          )}
        />
      </div>
      <p className={styles['note']}>
        A message will be sent to your email to confirm the meeting.
      </p>
    </div>
  );
}

export default SignContractOfflineFormFields;
