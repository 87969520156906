import styles from './image-text-overlay.module.scss';

interface ImageTextOverlayProps {
  image: string;
  title: string;
  subTitle: string;
  gradient?: boolean;
  maxContentWidth?: number;
  children?: React.ReactNode;
}

export function ImageTextOverlay(props: Readonly<ImageTextOverlayProps>) {
  return (
    <div
      className={styles['image-text-overlay']}
      style={{
        backgroundImage: `${
          props.gradient
            ? 'linear-gradient(0deg, rgba(0,0,0,0.6951374299719888) 0%, rgba(0,0,0,0) 100%),'
            : ''
        } url(${props.image})`,
      }}
    >
      <div
        className={styles['image-text-overlay__content']}
        style={{
          maxWidth: `${props.maxContentWidth ?? 310}px`,
        }}
      >
        <h6>{props.title}</h6>
        <h3>{props.subTitle}</h3>
        {props.children}
      </div>
    </div>
  );
}

export default ImageTextOverlay;
