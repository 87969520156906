import Select, {
  GroupBase,
  Props,
  components,
  OptionProps,
} from 'react-select';
import styles from './select.module.scss';

function InputOption<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: OptionProps<Option, IsMulti, Group>) {
  return (
    <components.Option {...props}>
      {props.label}
      <input type="checkbox" checked={props.isSelected} readOnly />
    </components.Option>
  );
}

// this custom component is just a styled react-select
export function FilterSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({ components, isMulti, ...props }: Props<Option, IsMulti, Group>) {
  return (
    <div className={styles['select']}>
      <Select
        classNamePrefix="select-multi"
        className="select"
        hideSelectedOptions={false}
        components={{
          ...(isMulti && { Option: InputOption<Option, IsMulti, Group> }),
          ...components,
        }}
        isMulti={isMulti}
        {...props}
      />
    </div>
  );
}
