/**
 * @author Salma Hefnawy
 * @date 2022-10-23
 * @description implementation of Country related utility functions.
 * @filename Country.ts
 */

import {
  Country as CountryInterface,
  CountryInvestmentsInterface,
} from '../definitions/interfaces/country.interface';
import { Country as CountryAPI } from '../api/country.api';
import { getPayloadData, handleError } from '@orascom/utils';
import {
  PaginationPayload,
  UnitInterface,
  CountryInfo as CountryInfoInterface,
} from '@orascom/api-interfaces';

/**
 * group of Country helpers functionalities.
 */
export class Country {
  /**
   *get all Country data.
   *
   * @static
   * @returns {Promise<CountryInfoInterface>}
   * @memberof Country
   */
  public static getCountryInfo(
    countrySlug: string
  ): Promise<CountryInfoInterface> {
    return CountryAPI.getCountryInfo(countrySlug)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get countries data.
   *
   * @static
   * @returns {Promise<CountryInterface[]>}
   * @memberof Country
   */
  public static getCountries(countryId?: string): Promise<CountryInterface[]> {
    return CountryAPI.getCountries(countryId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   * get list of units in a specific country based on search params
   *
   * @static
   * @param {string} countryId
   * @param {number} page
   * @param {number} perPage
   * @param {string} [filters]
   * @returns {Promise<PaginationPayload<UnitInterface[]>>}
   *
   * @memberOf Country
   */
  public static getCountryUnits(
    countryId: string,
    page: number,
    perPage: number,
    filters?: string
  ): Promise<PaginationPayload<UnitInterface[]>> {
    return CountryAPI.getCountryUnits(countryId, page, perPage, filters)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get countries investments data.
   *
   * @static
   * @returns {Promise<CountryInvestmentsInterface[]>}
   * @memberof Country
   */
  public static getCountriesInvestments(): Promise<
    CountryInvestmentsInterface[]
  > {
    return CountryAPI.getCountriesInvestments()
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
