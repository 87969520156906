import { useEffect, useState } from 'react';
import styles from './owner-cards.module.scss';
import { PreDeliveryUser as PreDeliveryUserUtilities } from '../../../utils/pre-delivery/user.utils';
import { OwnerCard } from '../../../../src/definitions/interfaces/pre-delivery.interface';
import { useTranslation } from 'react-i18next';
import { Modal } from '@orascom/common-components';
/* eslint-disable-next-line */

export function OwnerCards() {
  const [ownerCards, setOwnerCards] = useState<OwnerCard[]>([]);
  const [cardToExpand, setCardToExpand] = useState<OwnerCard>();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (card: OwnerCard) => {
    setCardToExpand(card);
    setShowModal(true);
    document.body.style.overflow = 'hidden';
  };
  const handleCloseModal = () => {
    setShowModal(false);
    document.body.style.overflow = '';
  };

  useEffect(() => {
    PreDeliveryUserUtilities.getOwnerCards()
      .then((res) => setOwnerCards(res))
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className={`${styles['wrapper']} pre-delivery-container`}>
      <h1 className={styles['page-title']}>{t('myAccessCards')}</h1>
      <div className={styles['cards']}>
        {ownerCards.length > 0 &&
          ownerCards?.map((card) => (
            <div key={card.id} className={styles['card']}>
              <p>{`${card?.destination_name} - ${card?.project_name}`}</p>
              <button
                className={styles['image-wrapper']}
                onClick={() => handleShowModal(card)}
              >
                <img
                  src={card.image}
                  alt={`${card?.project_name}-card`}
                  loading="lazy"
                />
                <div className={styles['card-overlay']}>{t('expand')}</div>
              </button>
            </div>
          ))}
      </div>
      <Modal
        show={showModal}
        onClose={handleCloseModal}
        title={`${cardToExpand?.destination_name} - ${cardToExpand?.project_name}`}
      >
        <img
          src={cardToExpand?.image}
          alt=""
          className={styles['modal-image']}
          loading="lazy"
        />
      </Modal>
    </div>
  );
}

export default OwnerCards;
