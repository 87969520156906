/* eslint-disable @nx/enforce-module-boundaries */
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { RESERVATION_CONTEXT } from 'libs/common-components/src/contexts/reservation.context';
import Accordion from 'libs/common-components/src/lib/accordion/accordion';
import styles from './payment-summary.module.scss';
import warningIcon from '../../assets/icons/warning.svg';
import greenTick from '../../assets/icons/green-tick.svg';
import plusSign from 'libs/common-components/assets/icons/plus.svg';
import minusSign from 'libs/common-components/assets/icons/minus.svg';
import { GoldButton } from '@orascom/common-components';
import {
  ReservationStateEnum,
  UnitPaymentTerms,
  InstallmentFrequencyMapper,
  CountriesEnum,
  PaymentPlanDetails,
} from '@orascom/api-interfaces';

interface PaymentSummaryProps {
  alwaysOpen?: boolean;
  twoColumns?: boolean;
  termsAndConditions?: boolean;
  readTerms: boolean;
  handleTermsCheckboxClick: () => void;
  handleShowTermsOnly: () => void;
  selectedPaymentTerms: UnitPaymentTerms;
  unitCountry: CountriesEnum | undefined;
}

export function MontenegroPaymentSummary(props: Readonly<PaymentSummaryProps>) {
  const { t } = useTranslation();
  const { setReservationState, selectedPaymentTerms } =
    useContext(RESERVATION_CONTEXT);

  const [showInstallments, setShowInstallments] = useState<string>('');

  const installmentsElement = useRef<null | HTMLDivElement>(null);

  const containerClass = `${styles['container']} ${
    props.twoColumns ? styles['container-columns'] : ''
  }`;

  const paymentPlansDetails = selectedPaymentTerms?.payment_plan_details?.length
    ? selectedPaymentTerms?.payment_plan_details
    : [];

  const currency = selectedPaymentTerms?.currency;

  if (!paymentPlansDetails.length) {
    return null;
  }

  const paymentPlanInstallmentsButton = (paymentPlan: PaymentPlanDetails) =>
    showInstallments === paymentPlan.payment_type ? (
      <button type="button" onClick={() => setShowInstallments('')}>
        <img src={minusSign} alt="minus-sign" />
      </button>
    ) : (
      <button
        type="button"
        onClick={() => setShowInstallments(paymentPlan.payment_type)}
      >
        <img src={plusSign} alt="plus-sign" />
      </button>
    );

  return (
    <>
      <div className={styles['container']}>
        <div
          className={`${styles['wrapper']} ${styles['wrapper--montenegro']}`}
        >
          <div className={styles['details-section-wrapper']}>
            <h4>{t('totalPrice')}</h4>
            <dl
              className={`${styles['details-section']} ${styles['details-item--span']}`}
            >
              <div className={styles['details-item']}>
                <dd className={styles['bold-price']}>
                  {selectedPaymentTerms?.total_unit_price.toLocaleString()}{' '}
                  <small className={styles['currency']}>{currency}</small>
                </dd>
              </div>
              <p>(Total Price Including Tax)</p>
            </dl>
            <p>
              You will just be charged for the reservation fee, which is{' '}
              {selectedPaymentTerms?.prereservation_amount.toLocaleString()}{' '}
              {selectedPaymentTerms?.currency}
            </p>
          </div>
        </div>
      </div>

      <div className={containerClass}>
        <Accordion
          title={t('paymentPlan')}
          arrows
          alwaysOpen={props.alwaysOpen}
          {...(!props.alwaysOpen && {
            downloadUrl: true,
          })}
        >
          <div className={styles['wrapper']} id="payment-plan">
            {props.selectedPaymentTerms && (
              <div
                className={`${styles['details']} ${
                  props.twoColumns ? styles['details-columns'] : ''
                }`}
              >
                {paymentPlansDetails.map((paymentPlan) => (
                  <div
                    className={styles['details-section-wrapper']}
                    key={uuidv4()}
                  >
                    <h4 className="orascom__sub-title">
                      {paymentPlan.payment_type}
                    </h4>
                    <dl className={styles['details-section']}>
                      <div className={styles['details-item']}>
                        <dt>{t('amount')}</dt>
                        <dd>
                          {paymentPlan.payment_amount.toLocaleString()}{' '}
                          <small className={styles['currency']}>
                            {currency}
                          </small>
                        </dd>
                      </div>
                    </dl>
                    <dl className={styles['details-section']}>
                      <div className={styles['details-item']}>
                        <dt>{t('amountDueIn')}</dt>
                        <dd>{paymentPlan.due_date} </dd>
                      </div>
                    </dl>

                    {paymentPlan.is_installment ? (
                      <dl className={styles['details-section']}>
                        <div className={styles['details-item']}>
                          <dt>
                            The amount is paid in{' '}
                            {paymentPlan.total_number_of_installments}{' '}
                            installments
                          </dt>
                          <dd>
                            <p>
                              {paymentPlan.is_installment
                                ? paymentPlanInstallmentsButton(paymentPlan)
                                : null}
                            </p>
                          </dd>
                        </div>
                      </dl>
                    ) : null}
                    {paymentPlan.is_installment ? (
                      <div
                        ref={installmentsElement}
                        style={
                          showInstallments === paymentPlan.payment_type
                            ? {
                                height:
                                  installmentsElement?.current?.scrollHeight,
                              }
                            : { height: '0px' }
                        }
                        className={styles['installments']}
                      >
                        <dl className={styles['details-section']}>
                          <div className={styles['details-item']}>
                            <dt>{t('duration')}</dt>
                            <dd>
                              {paymentPlan.installment_duration_in_years} Years
                            </dd>
                          </div>
                        </dl>

                        <dl className={styles['details-section']}>
                          <div className={styles['details-item']}>
                            <dt>{t('frequency')}</dt>
                            <dd>
                              {
                                InstallmentFrequencyMapper[
                                  paymentPlan.installment_frequency
                                ]
                              }
                            </dd>
                          </div>
                        </dl>

                        {/* <dl className={styles['details-section']}>
                          <div className={styles['details-item']}>
                            <dt> {t('firstInstallment')}</dt>
                            <dd>
                              {paymentPlan.payment_amount.toLocaleString()}{' '}
                              {currency}
                            </dd>
                          </div>
                        </dl> */}

                        <dl className={styles['details-section']}>
                          <div className={styles['details-item']}>
                            <dt> {t('installmentAmount')} </dt>
                            <dd>
                              {paymentPlan.installment_amount.toLocaleString()}{' '}
                              {currency}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            )}

            {!props.alwaysOpen && (
              <div className={styles['reservation-fee']}>
                <dl className={styles['details-item']}>
                  <dt>
                    {t('reservationFee')}{' '}
                    {props.twoColumns && (
                      <p>
                        <img src={greenTick} alt="" role="presentation" />{' '}
                        <span>{t('paidOnline')}</span>
                      </p>
                    )}
                  </dt>
                  <dd>
                    {selectedPaymentTerms?.prereservation_amount.toLocaleString()}{' '}
                    <small className={styles['currency']}>{currency}</small>
                  </dd>
                </dl>
                <div className={styles['reservation-fee-note']}>
                  <img src={warningIcon} role="presentation" alt="" />{' '}
                  <p>
                    <span>{t('paymentSummaryNote')}</span>
                    <button
                      type="button"
                      className={styles['terms-link']}
                      onClick={props.handleShowTermsOnly}
                    >
                      {t('terms&Conditions')}
                    </button>
                  </p>
                </div>
              </div>
            )}

            {props.termsAndConditions && (
              <div className={styles['terms-wrapper']}>
                <div className={styles['reserve-now']}>
                  <p>{t('reserveNow')}</p>
                  <p>
                    {selectedPaymentTerms?.prereservation_amount.toLocaleString()}{' '}
                    <small className={styles['currency']}>{currency}</small>
                  </p>
                </div>
                <div className={styles['terms']}>
                  <input
                    type="checkbox"
                    checked={props.readTerms}
                    readOnly
                    name="terms-checkbox"
                    id="terms-checkbox"
                    onClick={props.handleTermsCheckboxClick}
                  />
                  <label htmlFor="terms-checkbox">
                    <span>{t('haveReadAndAccepted')} </span>
                    <span className={styles['terms-link']}>
                      {t('terms&Conditions')}
                    </span>
                    <span>{t('howTheyAffectPrice')}</span>
                  </label>
                </div>
                <GoldButton
                  disabled={!props.readTerms}
                  onClick={() => {
                    setReservationState?.(
                      ReservationStateEnum.PAY_RESERVATION_FEE
                    );
                  }}
                >
                  {t('proceedToCheckout')}
                </GoldButton>
              </div>
            )}
          </div>
        </Accordion>
      </div>
    </>
  );
}

export default MontenegroPaymentSummary;
