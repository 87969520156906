import { Link } from 'react-router-dom';

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: React.ReactNode;
  secondary?: boolean | null;
  asLink?: boolean;
  to?: string;
  linkState?: unknown;
  className?: string;
  searchParams?: string;
  onClick?: any;
}

export interface AnchorProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  children: React.ReactNode;
}

export function NavyButton({ secondary, ...props }: Readonly<ButtonProps>) {
  if (props.asLink) {
    return (
      <Link
        to={{ pathname: props.to ?? '/', search: props.searchParams ?? '' }}
        state={props.linkState}
        className={`btn ${secondary ? 'btn--secondary' : 'btn--navy '} ${
          props.className ?? ''
        }`}
        onClick={props.onClick}
        onKeyDown={props.onClick}
      >
        {props.children}
        <div className="white" />
      </Link>
    );
  }

  return (
    <button
      {...props}
      className={`btn ${secondary ? 'btn--secondary' : 'btn--navy '} ${
        props.className ?? ''
      }`}
    >
      {props.children}
      <div className="white" />
    </button>
  );
}

export function NavyAnchor(props: Readonly<AnchorProps>) {
  return (
    <a {...props} className={`btn btn--navy ${props.className ?? ''}`}>
      {props.children}
      <div className="white" />
    </a>
  );
}

export function GoldButton({ secondary, ...props }: Readonly<ButtonProps>) {
  if (props.asLink) {
    return (
      <Link
        to={props.to ?? '/'}
        state={props.linkState}
        className={`btn ${secondary ? 'btn--secondary-gold' : 'btn--gold'} ${
          props.className ?? ''
        }`}
        onClick={props.onClick}
      >
        {props.children}
        <div className="gold" />
      </Link>
    );
  }
  return (
    <button
      {...props}
      className={`btn ${secondary ? 'btn--secondary-gold' : 'btn--gold'} ${
        props.className ?? ''
      }`}
    >
      {props.children}
      <div className="gold" />
    </button>
  );
}
