import {
  CountryInfo,
  Destination,
  FilterOption,
  FiltersEnums,
  SortValuesEnums,
  UnitFilters,
  UnitProject,
} from '@orascom/api-interfaces';
import { isNonNullNumber, isNonNullString } from '@orascom/utils';
import { SetURLSearchParams } from 'react-router-dom';
import { MultiValue } from 'react-select';

export function convertNumFiltersToOptions(
  filters: (number | null)[] = []
): FilterOption[] {
  return filters.filter(isNonNullNumber).map((val) => ({
    id: val,
    name: val.toString(),
  }));
}

export function convertStringFiltersToOptions(
  filters: (string | null)[] = []
): FilterOption[] {
  return filters.filter(isNonNullString).map((val) => ({
    id: val,
    name: val,
  }));
}

export function convertToOptionsWithSlug(
  filters:
    | Pick<CountryInfo, 'id' | 'name' | 'slug'>[]
    | Omit<UnitProject, 'destination'>[]
    | Destination[]
    | undefined
): FilterOption[] {
  return (
    filters?.map((val) => ({
      id: val.slug?.toString() ?? '',
      name: val.name,
    })) ?? []
  );
}


export function extendMultiSearchParams(
  key: string,
  values: MultiValue<FilterOption>,
  setSearchParams: SetURLSearchParams
) {
  setSearchParams((searchParams) => {
    if (!values.length) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, values.map((v) => v.id).join(','));
    }

    return searchParams;
  }, { replace: true });
}

export function getSelectedOptions(
  key: string,
  allOptions: FilterOption[],
  searchParams: URLSearchParams
): FilterOption[] | undefined {
  if (!allOptions) return [];
  const searchValues = searchParams.get(key);
  if (!searchValues) return undefined;

  const searchValuesArray = searchValues
    .split(',')
    .map((value) => value.trim());
  const selectedOptions = allOptions.filter((option) =>
    searchValuesArray.includes(option.id.toString())
  );
  return selectedOptions;
}

/**
 * Clear all url filters
 */
export function handleClearAllFilters(
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams
) {
  const params: { label: string; value: string }[] = [];
  searchParams.forEach((value, key) => {
    params.push({ label: key, value });
  });

  setSearchParams((searchParams) => {
    params.forEach((param) => {
      searchParams.delete(param.label);
    });

    return searchParams;
  }, { replace: true });
}

export const SortByOptions = [
  {
    id: SortValuesEnums.HIGHEST_PRICE,
    name: 'Highest Price',
  },
  {
    id: SortValuesEnums.LOWEST_PRICE,
    name: 'Lowest Price',
  },
  {
    id: SortValuesEnums.NEWEST,
    name: 'Newest',
  },
];

function joinFiltersWithCommas(options: FilterOption[] = []): string {
  return options.map((option) => option.name).join(', ');
}

function getDisplayLabels(
  label: string,
  options: FilterOption[],
  searchParams: URLSearchParams
) {
  const selectedOptions = getSelectedOptions(label, options, searchParams);
  if (!selectedOptions) return '';
  return joinFiltersWithCommas(selectedOptions);
}

type NumLabels = keyof Pick<
  UnitFilters,
  'bedrooms' | 'bathrooms' | 'built_up_areas'
>;
type StringLabels = keyof Pick<UnitFilters, 'finishing_status'>;
type OptionLabels = keyof Pick<
  UnitFilters,
  'countries' | 'destinations' | 'projects' | 'unit_types'
>;

const stringFilters: StringLabels[] = ['finishing_status'];
const numFilters: NumLabels[] = ['bedrooms', 'bathrooms', 'built_up_areas'];
const optionFilters: OptionLabels[] = [
  'countries',
  'destinations',
  'projects',
  'unit_types',
];

/**
 * Generates the display label for a filter based on its type.
 *
 * Unit filters can have different types: string, numeric, and option.
 * The search parameters contain the filter values but not their labels.
 * This function detects the type of filter and retrieves the appropriate label to display.
 *
 * @param {string} label - The key of the filter parameter.
 * @param {UnitFilters} unitFilters - The full set of unit filters available.
 * @param {URLSearchParams} searchParams - The react-router URL search parameters.
 * @param {function} t - Translation function.
 * @returns {string} - The formatted display label for the filter.
 */
export const getSelectedLabels = (
  label: string,
  unitFilters: UnitFilters,
  searchParams: URLSearchParams,
  t: (key: string) => string
): string => {
  let displayLabel = '';

  // numeric filter
  if (numFilters.includes(label as NumLabels)) {
    const numOptions = convertNumFiltersToOptions(
      unitFilters[label as NumLabels]
    );
    displayLabel = getDisplayLabels(label, numOptions, searchParams);
  }

  // string filter
  if (stringFilters.includes(label as StringLabels)) {
    const stringOptions = convertStringFiltersToOptions(
      unitFilters[label as StringLabels]
    );
    displayLabel = getDisplayLabels(label, stringOptions, searchParams);
  }

  // option filter
  if (optionFilters.includes(label as OptionLabels)) {
    displayLabel = getDisplayLabels(
      label,
      label !== 'unit_types'
        ? convertToOptionsWithSlug(unitFilters[label as OptionLabels])
        : unitFilters[label as OptionLabels],
      searchParams
    );
  }

  if (label === FiltersEnums.SORT_BY) {
    const selected = getSelectedOptions(label, SortByOptions, searchParams);
    displayLabel = `${t('sortBy')}: ${selected ? selected[0].name : ''}`;
  }

  if (label === FiltersEnums.MIN_PRICE) {
    const minPrice = searchParams.get(FiltersEnums.MIN_PRICE);
    const maxPrice = searchParams.get(FiltersEnums.MAX_PRICE);
    const currency = searchParams.get(FiltersEnums.CURRENCY);
    if (minPrice && maxPrice && currency) {
      const formattedMin = Number(minPrice).toLocaleString();
      const formattedMax = Number(maxPrice).toLocaleString();
      displayLabel = `${t(
        'price'
      )}: ${formattedMin} - ${formattedMax} ${currency}`;
    }
  }

  return displayLabel;
};
