import { useEffect } from 'react';
import { UnitFilters } from '@orascom/api-interfaces';
import { useSearchParams } from 'react-router-dom';

// remove unused search params
export const useCleanUpSearchParams = (availableSearchKeys: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    searchParams.forEach((_, key) => {
      if (!availableSearchKeys.includes(key as keyof UnitFilters)) {
        setSearchParams((params) => {
          params.delete(key);
          return params;
        }, { replace: true });
      }
    });
  });
};

export default useCleanUpSearchParams;
