import styles from './sales-offer-installment-plan.module.scss';
import { UnitPaymentTerms } from '@orascom/api-interfaces';
import { v4 as uuidv4 } from 'uuid';

function SalesOfferInstallmentPlanMontenegro({
  selectedPaymentTerms,
}: Readonly<{
  selectedPaymentTerms: UnitPaymentTerms;
}>) {
  return (
    <div className={styles['installments-card']}>
      <p className={styles['header']}>DETAILED INSTALLMENT PLAN</p>
      <table>
        <tr>
          <th>Milestones</th>
          <th>Amount</th>
          <th>Amount Due in</th>
        </tr>
        {selectedPaymentTerms.payment_plan_details?.map((plan) => (
          <tr key={uuidv4()}>
            <td>
              <p className={styles['details']}>{plan.payment_type}</p>
            </td>
            <td>
              <p className={styles['unit__percentage']}>
                {Math.round(plan.payment_amount).toLocaleString()}
                {selectedPaymentTerms.currency}
              </p>
            </td>
            <td>
              <p className={styles['details']}>{plan.due_date}</p>
            </td>
          </tr>
        ))}

        <tr>
          <td>
            <p className={styles['details']}>Unit Price After Discount</p>
          </td>
          <td>
            {' '}
            <p className={styles['unit__percentage']}>100%</p>
          </td>
          <td>
            <p className={styles['details']}>
              {selectedPaymentTerms.price_after_discount.toLocaleString()}{' '}
              {selectedPaymentTerms.currency}
            </p>
          </td>
        </tr>
      </table>
    </div>
  );
}

export default SalesOfferInstallmentPlanMontenegro;
