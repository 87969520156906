import { GoldButton } from '@orascom/common-components';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../api/routes';
import styles from './not-found.module.scss';
import homeImage from '../../assets/images/home.webp';
import MinimalFooter from '../../components/common/minimal-footer/minimal-footer';
import { Helmet } from 'react-helmet-async';
import getDocTitle from '../../utils/getDocTitle';
import Header from '../../components/header/header';
const notFoundTitle = getDocTitle('Not Found');
const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <div className={`${styles['container']} auth-container`}>
      <Helmet>
        <title>{notFoundTitle}</title>
        <meta property="og:title" content={notFoundTitle} />
        <meta name="description" content={t('notFoundDesc')} />
        <meta property="og:description" content={t('notFoundDesc')} />
      </Helmet>
      <div className={styles['wrapper']}>
        <Header header_transparent={false} auth header_home={false} />
        <div className={`${styles['not-found-info']}`}>
          <h1 className={`${styles['not-found-info-title']} orascom__title`}>
            {t`error404`}
          </h1>
          <h2
            className={`${styles['not-found-info-sub-title']} orascom__sub-title`}
          >
            {t`notFoundPageTitle1`}
            <br />
            {t`notFoundPageTitle2`}
          </h2>

          <p className={styles['not-found-info-desc']}>
            {t`notFoundPageDesc1`}
            <br />
            {t`notFoundPageDesc2`}
          </p>
          <GoldButton asLink to={ROUTES.Home.path}>
            {t('backToHome')}
          </GoldButton>
        </div>
        <img
          src={homeImage}
          role="presentation"
          alt=""
          className={styles['mobile-image']}
          loading="lazy"
        />
        <MinimalFooter showLinks />
      </div>

      <img
        src={homeImage}
        role="presentation"
        alt=""
        className={styles['image']}
        loading="lazy"
      />
    </div>
  );
};

export default NotFoundPage;
