import { DestinationProject } from '@orascom/api-interfaces';
import { NavyButton } from '../button/button';
import styles from './neighborhood-card.module.scss';
import { useTranslation } from 'react-i18next';
import {
  CommonEventParameters,
  routeSearchParamsConstructor,
} from '@orascom/utils';
import { ROUTES } from '../../../api/routes';
import { analyticsDownloadProjectBrochureCustomEvent } from '../../../utils/analytics-events';
import { useContext } from 'react';
import { USER_CONTEXT } from '../../../contexts/user-context';

/* eslint-disable-next-line */
export interface NeighborhoodCardProps {
  project: DestinationProject;
  destinationName: string;
  destinationId: string;
  destinationCountryName: string;
  destinationCountryId: string;
  description: string;
}

export function NeighborhoodCard(props: Readonly<NeighborhoodCardProps>) {
  const { t } = useTranslation();
  const userContext = useContext(USER_CONTEXT);

  const analyticsEventParams: CommonEventParameters = {
    userId: userContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Broker',
    pageName: location.pathname,
  };

  return (
    <div className={styles['wrapper']}>
      <img
        className={styles['image']}
        src={props.project.image}
        alt={props.project.name}
        loading="lazy"
      />
      <div className={styles['info']}>
        <p className={styles['name']}>{props.project.name}</p>
        {/* <p className={styles['date']}>{`${t(
          'deliveryStarting'
        )}${getYearQuarter(props.project.delivery_date)}`}</p> */}
        <p className={styles['description']}>{props.description}</p>
        <p className={styles['property-types']}>
          <span>{`${t('propertyTypes')}: `}</span>
          {props.project.unit_types.map((type) => type.name).join(', ')}
        </p>

        <div className={styles['action-buttons']}>
          <NavyButton
            asLink
            to={ROUTES.AvailableUnits.path}
            searchParams={routeSearchParamsConstructor(
              [],
              [
                {
                  value: props.destinationId,
                  label: props.destinationName,
                },
              ],
              [
                {
                  value: props.project.slug ?? '',
                  label: props.project.name,
                },
              ]
            ).toString()}
          >
            {t('seeAvailableUnits') as string}
          </NavyButton>

          <a
            target="_blank"
            rel="noreferrer"
            className={styles['brochure']}
            href={props.project.brochure}
            download={`${props.project.name}-brochure`}
            onClick={() =>
              analyticsDownloadProjectBrochureCustomEvent(analyticsEventParams)
            }
          >
            {t('downloadBrochure')}
          </a>
        </div>
      </div>
    </div>
  );
}

export default NeighborhoodCard;
