import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useSignContractSchema = () => {
  const { t } = useTranslation();
  const SignContractFormSchema = Yup.object().shape({
    meetingLocation: Yup.object()
      .nullable()
      .when('signContractMethod', {
        is: 'offline',
        then: Yup.object().nullable().required(t('fieldIsRequired')),
      }),
    meetingDate: Yup.string().when('signContractMethod', {
      is: 'offline',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    meetingTime: Yup.string().when('signContractMethod', {
      is: 'offline',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    governorate: Yup.string().when('signContractMethod', {
      is: 'dhl',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    district: Yup.string().when('signContractMethod', {
      is: 'dhl',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    address: Yup.string().when('signContractMethod', {
      is: 'dhl',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    contactType: Yup.object()
      .nullable()
      .when('signContractMethod', {
        is: 'dhl',
        then: Yup.object().nullable().required(t('fieldIsRequired')),
      }),
    deliveryDate: Yup.string().when('signContractMethod', {
      is: 'dhl',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    deliveryTime: Yup.string().when('signContractMethod', {
      is: 'dhl',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
  });

  return [SignContractFormSchema];
};
