import { useEffect, useState } from 'react';
import {
  FiltersEnums,
  UnitFilters,
  filterKeysArray,
} from '@orascom/api-interfaces';
import { useSearchParams } from 'react-router-dom';
import useCleanUpSearchParams from './use-clean-up-search-params';
import getFiltersSearchParams from '../../lib/get-filters-query-string';

// these keys are not used in the backend to retrieve filters
const ignoreKeys = [
  FiltersEnums.MIN_PRICE,
  FiltersEnums.MAX_PRICE,
  FiltersEnums.CURRENCY,
  FiltersEnums.SORT_BY,
  FiltersEnums.BEDROOMS,
  FiltersEnums.BATHROOMS,
  FiltersEnums.FINISHING_STATUS,
  FiltersEnums.UNIT_NAME,
  FiltersEnums.UNIT_SIZES,
];

interface UseUnitFiltersProps {
  getUnitFilters?: (searchParams?: URLSearchParams) => Promise<UnitFilters>;
}

export const useUnitFilters = ({ getUnitFilters }: UseUnitFiltersProps) => {
  const [unitFilters, setUnitFilters] = useState<UnitFilters>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [lastSearchQuery, setLastSearchQuery] = useState<string>('');

  const [isLoading, setIsLoading] = useState(true);

  // remove unused search params initially
  useCleanUpSearchParams(filterKeysArray);

  useEffect(() => {
    const filtersSearchParams = getFiltersSearchParams({
      searchParams,
      ignoreKeys,
    });

    const filtersQueryString = filtersSearchParams.toString();

    // do not refetch if the search params are the same (this helps to prevent refetch for ignored params)
    if (unitFilters && lastSearchQuery === filtersQueryString) return;

    setIsLoading(true);
    if (getUnitFilters) {
      getUnitFilters(filtersSearchParams)
        .then((res) => {
          setIsLoading(false);
          setUnitFilters(res);
          setLastSearchQuery(filtersQueryString);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setSearchParams]);
  return { filters: unitFilters, isLoading };
};
