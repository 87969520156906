import { useTranslation } from 'react-i18next';
import WelcomeToOrascomImage from '../../assets/images/welcome-to-orascom1.webp';
import WelcomeToOrascomImage2 from '../../assets/images/welcome-to-orascom2.webp';
import { CardsSlider } from '@orascom/common-components';
import ExploreIndicator from '../common/explore-indicator/explore-indicator';
import styles from './welcome-banner.module.scss';

export function WelcomeBanner() {
  const { t } = useTranslation();

  return (
    <div className={`${styles['welcome-banner']} welcome-banner`}>
      <CardsSlider slidesPerView={1} slidesPerViewMobile={1}>
        <div className={styles['welcome-banner__wrapper']}>
          <img
            src={WelcomeToOrascomImage}
            alt="welcome banner"
            loading="lazy"
          />
          <div className={styles['welcome-banner__info']}>
            <h1>{t('welcomeTitle1')}</h1>
            <p>{t('welcomeDescription1')}</p>

            <ExploreIndicator
              className={styles['explore']}
              title={t('exploreOurWorld')}
            />
          </div>
        </div>
        <div className={styles['welcome-banner__wrapper']}>
          <img
            src={WelcomeToOrascomImage2}
            alt="welcome banner"
            loading="lazy"
          />
          <div className={styles['welcome-banner__info']}>
            <h1>{t('welcomeTitle2')}</h1>
            <p>{t('welcomeDescription2')}</p>

            <ExploreIndicator
              className={styles['explore']}
              title={t('exploreOurWorld')}
            />
          </div>
        </div>
      </CardsSlider>
    </div>
  );
}

export default WelcomeBanner;
