/**
 * @author Salma Hefnawy
 * @date 2022-10-20
 * @description implementation of home related utility functions.
 * @filename home.ts
 */

import {
  PagesDataInterface,
  NewsDetailsInterface,
  NewsListingInterface,
  ActivePromotionInterface,
  CustomPageInterface,
} from '../definitions/interfaces/home.interface';
import { Home as HomeAPI } from '../api/home.api';
import { getPayloadData, handleError, parseError } from '@orascom/utils';
import { PaginationPayload } from '@orascom/api-interfaces';

/**
 * group of Home helpers functionalities.
 */
export class Home {
  /**
   *get all home meta data.
   *
   * @static
   * @returns {Promise<PagesDataInterface>}
   * @memberof Home
   */
  public static getHomeMetaData(): Promise<PagesDataInterface> {
    return HomeAPI.getHomeMetaData()
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getActivePromotions(): Promise<ActivePromotionInterface[]> {
    return HomeAPI.getActivePromotions()
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get all home meta data.
   *
   * @static
   * @returns {Promise<PagesDataInterface>}
   * @memberof Home
   */
  public static getAboutUsMetaData(): Promise<PagesDataInterface> {
    return HomeAPI.getAboutUsMetaData()
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get privacy policy data.
   *
   * @static
   * @returns {Promise<PagesDataInterface>}
   * @memberof Home
   */
  public static getPrivacyPolicy(): Promise<PagesDataInterface> {
    return HomeAPI.getPrivacyPolicy()
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  /**
   *get faqs data.
   *
   * @static
   * @returns {Promise<PagesDataInterface>}
   * @memberof Home
   */
  public static getFAQs(): Promise<PagesDataInterface> {
    return HomeAPI.getFAQs()
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get terms and conditions data.
   *
   * @static
   * @returns {Promise<PagesDataInterface>}
   * @memberof Home
   */
  public static getTermsAndConditions(): Promise<PagesDataInterface> {
    return HomeAPI.getTermsAndConditions()
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  /**
   *get  home latest news.
   *
   * @static
   * @returns {Promise<PaginationPayload<NewsListingInterface[]>>}
   * @param {number} [page=1]
   * @param {number} [per_page=10]
   * @param {boolean} [is_event=false]
   * @param {boolean} [is_featured=false]
   * @memberof Home
   */
  public static getNews(
    page: number,
    per_page: number,
    is_event = false,
    is_featured = false
  ): Promise<PaginationPayload<NewsListingInterface[]>> {
    return HomeAPI.getNews(
      String(page),
      String(per_page),
      is_event,
      is_featured
    )
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   * get news details
   *
   * @static
   * @param {string} slug
   * @returns {Promise<NewsDetailsInterface>}
   * @memberof Home
   */
  public static getNewsDetails(slug: string): Promise<NewsDetailsInterface> {
    return HomeAPI.getNewsDetails(slug)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getCustomPage(): Promise<CustomPageInterface[]> {
    return HomeAPI.getCustomPage()
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }
}
