import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useReservationInformationFormSchema = () => {
  const { t } = useTranslation();
  const ReservationInformationFormSchema = Yup.object().shape({
    nationality: Yup.object().nullable().required(t('fieldIsRequired')),
    country: Yup.object().nullable().required(t('fieldIsRequired')),
    // city: Yup.object().nullable().required(t('fieldIsRequired')),
    city: Yup.object()
      .nullable()
      .when('citiesFound', {
        is: (citiesFound: boolean) => citiesFound === true,
        then: Yup.object().required(t('fieldIsRequired')),
      }),
    citiesFound: Yup.boolean().nullable(),
    cityName: Yup.string()
      .nullable()
      .when('citiesFound', {
        is: (citiesFound: boolean) => citiesFound === false,
        then: Yup.string().required(t('fieldIsRequired')),
      }),
    district: Yup.string().nullable().required(t('fieldIsRequired')),
    address: Yup.string().required(t('fieldIsRequired')),
    // occupation: Yup.object().nullable().required(t('fieldIsRequired')),
    occupationsFound: Yup.boolean().nullable(),
    occupation: Yup.object()
      .nullable()
      .when('occupationsFound', {
        is: (occupationsFound: boolean) => occupationsFound === true,
        then: Yup.object().required(t('fieldIsRequired')),
      }),
    occupationName: Yup.string()
      .nullable()
      .when('occupationsFound', {
        is: (occupationsFound: boolean) => occupationsFound === false,
        then: Yup.string().required(t('fieldIsRequired')),
      }),
    maritalStatus: Yup.string().required(t('fieldIsRequired')),
    identificationForm: Yup.string().required(t('fieldIsRequired')),
    nationalIdNumber: Yup.string().when('identificationForm', {
      is: 'nationalId',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    nationalIdFrontImage: Yup.mixed().when('identificationForm', {
      is: 'nationalId',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    nationalIdBackImage: Yup.mixed().when('identificationForm', {
      is: 'nationalId',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    passportNumber: Yup.string().when('identificationForm', {
      is: 'passport',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    passportImage: Yup.mixed().when('identificationForm', {
      is: 'passport',
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    jointBuyer: Yup.boolean().nullable(),
    jointBuyerFirstName: Yup.string().when('jointBuyer', {
      is: (jointBuyer: boolean) => jointBuyer === true,
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    jointBuyerLastName: Yup.string().when('jointBuyer', {
      is: (jointBuyer: boolean) => jointBuyer === true,
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    jointBuyerPhoneNumber: Yup.string().when('jointBuyer', {
      is: (jointBuyer: boolean) => jointBuyer === true,
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    jointBuyerCountryCode: Yup.string().when('jointBuyer', {
      is: (jointBuyer: boolean) => jointBuyer === true,
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    jointBuyerEmail: Yup.string()
      .email()
      .when('jointBuyer', {
        is: (jointBuyer: boolean) => jointBuyer === true,
        then: Yup.string().required(t('fieldIsRequired')),
      }),
    jointBuyerNationality: Yup.object()
      .nullable()
      .when('jointBuyer', {
        is: (jointBuyer: boolean) => jointBuyer === true,
        then: Yup.object().nullable().required(t('fieldIsRequired')),
      }),
    jointBuyerCountry: Yup.object()
      .nullable()
      .when('jointBuyer', {
        is: (jointBuyer: boolean) => jointBuyer === true,
        then: Yup.object().nullable().required(t('fieldIsRequired')),
      }),
    jointBuyerCity: Yup.object()
      .nullable()
      .when('jointBuyer', {
        is: (jointBuyer: boolean) => jointBuyer === true,
        then: Yup.object()
          .nullable()
          .when('citiesFound', {
            is: (citiesFound: boolean) => citiesFound === true,
            then: Yup.object().required(t('fieldIsRequired')),
          }),
      }),
    jointBuyerCityName: Yup.string()
      .nullable()
      .when('jointBuyer', {
        is: (jointBuyer: boolean) => jointBuyer === true,
        then: Yup.string()
          .nullable()
          .when('citiesFound', {
            is: (citiesFound: boolean) => citiesFound === false,
            then: Yup.string().required(t('fieldIsRequired')),
          }),
      }),
    jointBuyerDistrict: Yup.string().when('jointBuyer', {
      is: (jointBuyer: boolean) => jointBuyer === true,
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    jointBuyerAddress: Yup.string().when('jointBuyer', {
      is: (jointBuyer: boolean) => jointBuyer === true,
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    jointBuyerOccupation: Yup.object()
      .nullable()
      .when('jointBuyer', {
        is: (jointBuyer: boolean) => jointBuyer === true,
        then: Yup.object()
          .nullable()
          .when('occupationsFound', {
            is: (occupationsFound: boolean) => occupationsFound === true,
            then: Yup.object().required(t('fieldIsRequired')),
          }),
      }),
    jointBuyerOccupationName: Yup.string()
      .nullable()
      .when('jointBuyer', {
        is: (jointBuyer: boolean) => jointBuyer === true,
        then: Yup.string()
          .nullable()
          .when('occupationsFound', {
            is: (occupationsFound: boolean) => occupationsFound === false,
            then: Yup.string().required(t('fieldIsRequired')),
          }),
      }),
    jointBuyerMaritalStatus: Yup.string().when('jointBuyer', {
      is: (jointBuyer: boolean) => jointBuyer === true,
      then: Yup.string().required(t('fieldIsRequired')),
    }),
    jointBuyerIdentificationForm: Yup.string().nullable(),
    jointBuyerNationalIdNumber: Yup.mixed().when('jointBuyer', {
      is: (jointBuyer: boolean) => jointBuyer === true,
      then: Yup.mixed().when('jointBuyerIdentificationForm', {
        is: 'nationalId',
        then: Yup.string().required(t('fieldIsRequired')),
      }),
    }),
    jointBuyerPassportNumber: Yup.mixed().when('jointBuyer', {
      is: (jointBuyer: boolean) => jointBuyer === true,
      then: Yup.mixed().when('jointBuyerIdentificationForm', {
        is: 'passport',
        then: Yup.string().required(t('fieldIsRequired')),
      }),
    }),
    jointBuyerNationalIdFrontImage: Yup.mixed()
      .nullable()
      .when('jointBuyer', {
        is: (jointBuyer: boolean) => jointBuyer === true,
        then: Yup.mixed().when('jointBuyerIdentificationForm', {
          is: 'nationalId',
          then: Yup.mixed().required(t('fieldIsRequired')),
        }),
      }),
    jointBuyerNationalIdBackImage: Yup.mixed()
      .nullable()
      .when('jointBuyer', {
        is: (jointBuyer: boolean) => jointBuyer === true,
        then: Yup.mixed().when('jointBuyerIdentificationForm', {
          is: 'nationalId',
          then: Yup.mixed().required(t('fieldIsRequired')),
        }),
      }),
    jointBuyerPassportImage: Yup.mixed()
      .nullable()
      .when('jointBuyer', {
        is: (jointBuyer: boolean) => jointBuyer === true,
        then: Yup.mixed().when('jointBuyerIdentificationForm', {
          is: 'passport',
          then: Yup.mixed().required(t('fieldIsRequired')),
        }),
      }),
    relationToBuyer: Yup.mixed().nullable(),
    sameNationality: Yup.boolean().nullable(),
    sameCountry: Yup.boolean().nullable(),
    sameCity: Yup.boolean().nullable(),
    sameDistrict: Yup.boolean().nullable(),
    sameAddress: Yup.boolean().nullable(),
  });

  return [ReservationInformationFormSchema];
};
