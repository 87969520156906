import { useContext } from 'react';
import styles from './account-settings.module.scss';
import { useTranslation } from 'react-i18next';
import { USER_CONTEXT } from '../../contexts/user-context';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Loader, NavyButton } from '@orascom/common-components';

export function AccountSettings() {
  const { t } = useTranslation();
  const userContext = useContext(USER_CONTEXT);

  if (!userContext?.user) {
    return <Loader />;
  }
  return (
    <div className={`${styles['wrapper']} container`}>
      <p className="orascom__sub-title">{t('accountSettings')}</p>
      <div className={styles['info']}>
        <div>
          <label>{t('fullName')}</label>
          <p className="name">{userContext.user.name}</p>
        </div>
        <div>
          <label>{t('email')}</label>
          <p>{userContext.user.email}</p>
        </div>
        <div>
          <label>{t('phoneNumber')}</label>
          <p>{userContext.user.phone}</p>
        </div>
      </div>
    </div>
  );
}

export default AccountSettings;
