import React, { useContext, useRef, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../api/routes';
import Header from '../header/header';
import styles from './side-nav-bar.module.scss';
import HomeIcon from '../../../assets/icons/home.svg?react';
import ReferralsIcon from '../../../assets/icons/community.svg?react';
import OffersIcon from '../../../assets/icons/offers.svg?react';
import WorldIcon from '../../../assets/icons/world.svg?react';
import SettingsIcon from '../../../assets/icons/settings.svg?react';
import HelpIcon from '../../../assets/icons/help.svg?react';
import LogoutIcon from '../../../assets/icons/logout.svg?react';
import backArrowIcon from '../../../assets/icons/back-arrow.svg';
import downArrowIcon from '../../../assets/icons/down-arrow.svg';
import { Authentication } from '../../../utils/authentication.utils';
import { useTranslation } from 'react-i18next';
import { LanguageDropdown } from '@orascom/common-components';
import { USER_CONTEXT } from '../../../contexts/user-context';
import {
  showPreDeliveryLangs,
  showPreDeliveryPay,
} from '../../../definitions/consts/envConstants';

export interface SideNavBarProps {
  side_nav?: boolean;
}
export function SideNavBar(props: Readonly<SideNavBarProps>) {
  const { t } = useTranslation();
  const [isSidebar, setIsSidebar] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const navigate = useNavigate();
  const UserContext = useContext(USER_CONTEXT);

  const openSidebar = (isSidebarState: boolean) => {
    return setIsSidebar(isSidebarState);
  };

  const sideNavBar = useRef<HTMLDivElement>(null);
  const subMenu = useRef<HTMLUListElement>(null);

  const handleShowSubMenu = (closeMenu?: boolean) => {
    if (!closeMenu) {
      setShowSubMenu(!showSubMenu);
    } else {
      setShowSubMenu(false);
    }

    if (sideNavBar?.current) {
      if (sideNavBar.current.style.paddingBottom === '150px' || closeMenu) {
        sideNavBar.current.style.paddingBottom = '24px';
      } else {
        sideNavBar.current.style.paddingBottom = '150px';
      }
    }
    if (subMenu?.current) {
      if (subMenu.current.style.maxHeight || closeMenu) {
        subMenu.current.style.maxHeight = '';
        subMenu.current.style.marginBottom = '0';
      } else {
        subMenu.current.style.maxHeight = 'fit-content';
        subMenu.current.style.marginBottom = '30px';
      }
    }
  };

  return (
    <header className={styles['navigation']}>
      <Header
        openSidebar={openSidebar}
        isSidebar={isSidebar}
        backButtonPath={!props.side_nav ? ROUTES.Pre_Delivery_Addons.path : ''}
        sideNavIconStatus={props.side_nav ?? true}
      />
      {!props.side_nav ? null : (
        <div
          ref={sideNavBar}
          className={`${styles['side-nav-bar']}  ${
            isSidebar && styles['side-nav-bar--active']
          } `}
        >
          <h1 className="orascom__sub-title">
            <span>{t('hi')},</span> {UserContext.user?.name}
          </h1>
          <NavLink
            className={styles['cards-link']}
            to={ROUTES.Pre_Delivery_Owner_Cards.path}
            onClick={() => {
              openSidebar(false);
              handleShowSubMenu(true);
            }}
          >
            {t('seeMyCards')}
          </NavLink>
          <nav className={styles['navigation-links']}>
            <div className={styles['navigation-links-menu']}>
              <NavLink
                className={`${styles['navigation-link']} ${
                  showSubMenu && styles['navigation-link--active']
                }`}
                to={ROUTES.Pre_Delivery_Home.path}
                onClick={() => handleShowSubMenu()}
              >
                <HomeIcon />
                <>{t('unitsManagement')}</>
                <img src={downArrowIcon} alt="side-menu-icon" />
              </NavLink>
              <ul
                className={`${styles['navigation-sub-menu-links']} sub-menu`}
                ref={subMenu}
              >
                <li>
                  <NavLink
                    className={styles['navigation-link']}
                    to={ROUTES.Pre_Delivery_Home.path}
                    onClick={() => openSidebar(false)}
                  >
                    <>{t('myUnits')}</>
                  </NavLink>
                </li>
                {showPreDeliveryPay && (
                  <li>
                    <NavLink
                      className={styles['navigation-link']}
                      to={ROUTES.Pre_Delivery_Payments.path}
                      onClick={() => openSidebar(false)}
                    >
                      <>{t('unitPayments')}</>
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    className={styles['navigation-link']}
                    to={ROUTES.Pre_Delivery_Services.path}
                    onClick={() => openSidebar(false)}
                  >
                    <>{t('unitServices')}</>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={styles['navigation-link']}
                    to={ROUTES.Pre_Delivery_Addons.path}
                    onClick={() => openSidebar(false)}
                  >
                    <>{t('unitAddons')}</>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={styles['navigation-link']}
                    to={ROUTES.Pre_Delivery_Resell.path}
                  >
                    <>{t('resellAssistance')}</>
                  </NavLink>
                </li>
              </ul>
            </div>
            <NavLink
              className={styles['navigation-link']}
              to={ROUTES.Pre_Delivery_Referrals.path}
              onClick={() => {
                openSidebar(false);
                handleShowSubMenu(true);
              }}
            >
              <ReferralsIcon />
              <>{t('referrals')}</>
            </NavLink>
            <NavLink
              className={styles['navigation-link']}
              to={ROUTES.Pre_Delivery_Offers.path}
              onClick={() => {
                openSidebar(false);
                handleShowSubMenu(true);
              }}
            >
              <OffersIcon />
              <>{t('exclusiveOffer')}</>
            </NavLink>
            <NavLink
              className={styles['navigation-link']}
              to={ROUTES.Pre_Delivery_News_And_Events.path}
              onClick={() => {
                openSidebar(false);
                handleShowSubMenu(true);
              }}
            >
              <WorldIcon />
              <>{t('newsLaunchesEvents')}</>
            </NavLink>
            <NavLink
              className={styles['navigation-link']}
              to={ROUTES.Pre_Delivery_Settings.path}
              onClick={() => {
                openSidebar(false);
                handleShowSubMenu(true);
              }}
            >
              <SettingsIcon />
              <>{t('accountSettings')}</>
            </NavLink>

            <NavLink
              className={styles['navigation-link']}
              to={ROUTES.Pre_Delivery_Get_Help.path}
              onClick={() => {
                openSidebar(false);
                handleShowSubMenu(true);
              }}
            >
              <>
                <HelpIcon />
                {t('getHelp')}
              </>
            </NavLink>
          </nav>
          <button
            className={styles['navigation-logout-button']}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              Authentication.logout().finally(() => {
                if (UserContext.setUser) {
                  UserContext.setUser(null);
                  navigate(ROUTES.Home.path);
                  navigate(0);
                }
              });
            }}
          >
            <LogoutIcon />
            {t('logout')}
          </button>
          <div className={styles['navigation--responsive']}>
            <Link
              to={ROUTES.Home.path}
              className={styles['navigation--responsive-button']}
            >
              <img src={backArrowIcon} alt="side-menu-icon" />
              {t('backToMainSite')}
            </Link>
            {showPreDeliveryLangs && (
              <div className={styles['dropdowns']}>
                <LanguageDropdown />
              </div>
            )}
          </div>
        </div>
      )}
    </header>
  );
}

export default SideNavBar;
