import { useEffect, useState } from 'react';
import {
  CURRENCIES_ENUM,
  FiltersEnums,
  PaginationInterface,
  PaginationPayload,
  UnitInterface,
} from '@orascom/api-interfaces';
import { useParams, useSearchParams } from 'react-router-dom';
import { useClearSearchParams } from '@orascom/common-components';
import { getFiltersSearchParams } from '@orascom/utils';

const PER_PAGE = 12;

type GetUnits = (
  page: number,
  perPage: number,
  searchParams?: URLSearchParams,
  unitId?: string,
  price?: number,
  designType?: string,
  similarUnits?: boolean
) => Promise<PaginationPayload<UnitInterface[]>>;

interface UseUnitProps {
  getUnits: GetUnits;
  // TODO: refactor useCurrencyConverter
  selectedCurrency?: string;
  convertPrice?: (
    price: number,
    currency: string,
    targetCurrency: string
  ) => {
    price: number;
    currency: string;
  };
  similarUnits?: boolean;
  onResale?: boolean;
}
export function useUnits(useUnitsProps: UseUnitProps) {
  const { getUnits, selectedCurrency, convertPrice, similarUnits, onResale } =
    useUnitsProps;

  const [page, setPage] = useState(1);
  const [units, setUnits] = useState<UnitInterface[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationInterface>();
  const [isLoadingUnits, setIsLoadingUnits] = useState(true);
  const [isLoadingMoreUnits, setIsLoadingMoreUnits] = useState(false);
  const [forceLoadUnits, setForceLoadUnits] = useState(false);

  const [searchParams] = useSearchParams();
  const [lastCurrency, setLastCurrency] = useState<string | null>(null);
  const { onClearAllFilters } = useClearSearchParams();
  const { unitId, price, designType } = useParams();
  const hasMoreUnits = paginationData
    ? paginationData.current_page < paginationData.last_page
    : false;

  useEffect(() => {
    if (!paginationData) {
      return;
    }
    setPage(1);
    setForceLoadUnits((v) => !v);
  }, [searchParams]);

  useEffect(() => {
    let ignore = false;

    const fetchUnits = async () => {
      if (page === 1) {
        setIsLoadingUnits(true);
      } else {
        setIsLoadingMoreUnits(true);
      }

      const filtersSearchParams = getFiltersSearchParams({
        searchParams,
      });

      const minPrice = filtersSearchParams.get(FiltersEnums.MIN_PRICE);
      const maxPrice = filtersSearchParams.get(FiltersEnums.MAX_PRICE);

      const priceTypeGuard = minPrice && maxPrice;

      if (onResale) {
        filtersSearchParams.set(FiltersEnums.ON_RESALE, '1');
      }
      if (selectedCurrency && convertPrice && priceTypeGuard) {
        const minDollarPrice = convertPrice(
          +minPrice,
          selectedCurrency,
          CURRENCIES_ENUM.USD
        );
        const maxDollarPrice = convertPrice(
          +maxPrice,
          selectedCurrency,
          CURRENCIES_ENUM.USD
        );

        filtersSearchParams.set(
          'min_dollar_price',
          minDollarPrice.price.toFixed()
        );
        filtersSearchParams.set(
          'max_dollar_price',
          maxDollarPrice.price.toFixed()
        );
        filtersSearchParams.delete(FiltersEnums.MIN_PRICE);
        filtersSearchParams.delete(FiltersEnums.MAX_PRICE);
      }

      try {
        const response = await getUnits(
          page,
          PER_PAGE,
          filtersSearchParams,
          unitId,
          Number(price),
          designType,
          similarUnits
        );

        if (ignore) return; // hint: The `finally` block runs even when there's a return statement in the try block

        if (page === 1) {
          setUnits(response.data);
        } else {
          setUnits((prevUnits) => [...prevUnits, ...response.data]);
        }

        setPaginationData(response.meta);
      } catch {
        onClearAllFilters();
      } finally {
        // don't stop loading indicator when another fetch is in progress
        if (!ignore && selectedCurrency) {
          setIsLoadingUnits(false);
          setIsLoadingMoreUnits(false);
          setLastCurrency(selectedCurrency);
        }
      }
    };

    fetchUnits();

    return () => {
      ignore = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, forceLoadUnits]);

  const loadMoreUnits = () => {
    if (!isLoadingUnits && !isLoadingMoreUnits && hasMoreUnits) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return {
    units,
    isLoadingUnits,
    isLoadingMoreUnits,
    loadMoreUnits,
    hasMoreUnits,
    paginationData,
  };
}
