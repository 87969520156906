import React, { useEffect, useState } from 'react';
import Select, { components, OptionProps, SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import { OptionValue } from '@orascom/api-interfaces';
import styles from './select.module.scss';

interface SelectProps {
  name?: string;
  id?: string;
  options?: OptionValue[];
  placeholder: string;
  onChange: (newValue: SingleValue<OptionValue>) => void;
  disabled?: boolean;
  className?: string;
  keyValue?: number | string;
  selectedOption?: OptionValue | null;
  defaultValue?: OptionValue;
  isClearable?: boolean;
  isSearchable?: boolean;
  defaultOptions?: OptionValue[] | boolean;
  isLoading?: boolean;
}

interface AsyncSelectProps extends SelectProps {
  loadOptions: (
    inputValue: string,
    callback?: (options: OptionValue[]) => void,
    search?: boolean,
    searchParams?: any
  ) => void;
}

export interface MultiSelectProps {
  name?: string;
  id?: string;
  options: OptionValue[];
  placeholder: string;
  onChange: (newValue: OptionValue[]) => void;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  keyValue?: number | string;
  selectedOptions?: OptionValue[];
  defaultValue?: OptionValue[];
}

export function SelectDropdown({
  disabled = false,
  ...props
}: Readonly<SelectProps>) {
  return (
    <div className={`${props.className} ${styles['select']}`}>
      <Select
        options={props.options}
        className="select"
        placeholder={props.placeholder}
        onChange={(value) => props.onChange(value)}
        classNamePrefix="select"
        isDisabled={disabled}
        key={props.keyValue}
        value={props.selectedOption}
        isClearable={props.isClearable}
        isSearchable={props.isSearchable ?? true}
        isLoading={props.isLoading}
      />
    </div>
  );
}

export function AsyncSelectDropdown({
  disabled = false,
  ...props
}: Readonly<AsyncSelectProps>) {
  return (
    <div className={`${props.className} ${styles['select']}`}>
      <AsyncSelect
        cacheOptions
        // options={props.options}
        className="select"
        placeholder={props.placeholder}
        onChange={(value) => props.onChange(value)}
        classNamePrefix="select"
        isDisabled={disabled}
        key={props.keyValue}
        value={props.selectedOption}
        loadOptions={props.loadOptions}
        isClearable={props.isClearable}
        defaultOptions={props.defaultOptions}
      />
    </div>
  );
}

/**
 *
 * @param custom component for multi select
 * @returns
 */
const InputOption: React.FC<OptionProps> = ({
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      innerProps={innerProps}
    >
      {children}
      <input
        type="checkbox"
        checked={Boolean(
          (rest.selectProps.value as OptionValue[])?.find(
            (v) => v.label === children
          )
        )}
        readOnly
      />
    </components.Option>
  );
};

export function MultiSelectDropdown({
  disabled = false,
  ...props
}: Readonly<MultiSelectProps>) {
  const [selectedOptions, setSelectedOptions] = useState<OptionValue[]>([]);

  useEffect(() => {
    setSelectedOptions([]);
  }, [props.keyValue]);

  useEffect(() => {
    if (props.selectedOptions) {
      setSelectedOptions(props.selectedOptions);
    }
  }, [props.selectedOptions]);

  useEffect(() => {
    if (props.defaultValue) {
      setSelectedOptions(props.defaultValue);
    }
  }, [props.defaultValue]);

  return (
    <div className={`${props.className} ${styles['select']}`}>
      <div className="select-multi__wrapper">
        <Select
          options={props.options}
          isMulti
          className="select"
          placeholder={
            selectedOptions.length || props.selectedOptions?.length
              ? ''
              : props.placeholder
          }
          classNamePrefix="select-multi"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          isClearable={false}
          isDisabled={disabled || props.isLoading}
          isLoading={props.isLoading}
          defaultValue={props.defaultValue}
          blurInputOnSelect={false}
          onChange={(opt) => {
            if (Array.isArray(opt)) {
              const options = opt.map((c) => c);
              setSelectedOptions(options);
              props.onChange(options);
            }
          }}
          key={props.keyValue}
          components={{
            Option: InputOption,
          }}
          value={props.selectedOptions}
        />
        {props.selectedOptions?.length ? (
          <span className="select-multi__count">
            {props.selectedOptions.map((s, i) =>
              props.selectedOptions?.[i]?.label ===
              props.selectedOptions?.[i - 1]?.label
                ? ''
                : `${s.label}${
                    (props.selectedOptions?.length as number) > i + 1
                      ? ', '
                      : ''
                  }`
            )}
          </span>
        ) : selectedOptions.length ? (
          <span className="select-multi__count">
            {selectedOptions.map((s, i) =>
              selectedOptions?.[i]?.label === selectedOptions?.[i - 1]?.label
                ? ''
                : `${s.label}${selectedOptions?.length > i + 1 ? ', ' : ''}`
            )}
          </span>
        ) : null}
      </div>
    </div>
  );
}
