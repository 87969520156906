import { Link } from 'react-router-dom';
import Numeral from 'numeral';
import styles from './news-card.module.scss';
import { useTranslation } from 'react-i18next';

/* eslint-disable-next-line */
export interface NewsCardProps {
  image?: string;
  date?: number;
  title: string;
  price?: number;
  path?: string;
  description?: string;
  newsPage?: boolean;
  detailsPath?: string;
}

export function NewsCard(props: Readonly<NewsCardProps>) {
  const { t } = useTranslation();

  const newsDate = props.date ? (
    <p className={styles['date']}>
      {new Date(props.date * 1000).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })}
    </p>
  ) : null;

  if (props.path) {
    return (
      <Link
        to={props.path}
        className={`${styles['wrapper']} ${
          props.newsPage ? styles['news-page'] : ''
        }`}
      >
        <img src={props.image} alt={props.title} loading="lazy" />
        <div className={styles['info']}>
          {newsDate}
          <p className={styles['title']}>{props.title}</p>
          <p className={styles['description']}>{props.description}</p>
          {props.newsPage && <p className={styles['link']}>{t('readMore')}</p>}
        </div>
      </Link>
    );
  }

  return (
    <div
      className={`${styles['wrapper']} ${
        props.newsPage ? styles['news-page'] : ''
      }`}
    >
      <img src={props.image} alt={props.title} loading="lazy" />
      <div className={styles['info']}>
        {newsDate}
        <p className={styles['title']}>{props.title}</p>
        <p className={styles['description']}>{props.description}</p>
        {props.price ? (
          <p className="orascom__title">
            {Numeral(props.price).format('0,0')} EGP
          </p>
        ) : null}
        {props.detailsPath && (
          <Link to={props.detailsPath} className={styles['link']}>
            {t('viewDetails')}
          </Link>
        )}
      </div>
    </div>
  );
}

export default NewsCard;
