/**
 * @author Salma Hefnawy
 * @date 2022-11-06
 * @description implementation of unit related utility functions.
 * @filename Unit.ts
 */

import { Unit as UnitAPI } from '../api/unit.api';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  constructSimilarUnitsParams,
  downloadSalesOfferOman,
  getPayloadData,
  handleError,
} from '@orascom/utils';

import {
  Common,
  PaginationPayload,
  UnitCompareInterface,
  UnitDetails,
  UnitInterface,
  UnitPaymentTerms,
  UnitReservationCity,
  UnitReservationCountry,
  UnitReservationDetails,
  AddDraftSaleParams,
  ReserveTransactionCodeParams,
  UnitReservationNationality,
  UnitReservationOccupation,
  ReservationStateEnum,
  UpdateUserInfoRequest,
  CountriesEnum,
  SaleInstallmentsResponse,
  UnitReservationConnection,
  FiltersEnums,
} from '@orascom/api-interfaces';
import { parse as QSParse } from 'query-string';
import { DraftSaleResponse } from '../definitions/interfaces/common.interface';

/**
 * group of Unit helpers functionalities.
 */
export class Unit {
  /**
   *get unit details data.
   *
   * @static
   * @returns {Promise<UnitDetails>}
   * @memberof Unit
   */
  public static getUnitDetails(unitId: string): Promise<UnitDetails> {
    return UnitAPI.getUnitDetails(unitId).then((result) => {
      return getPayloadData(result);
    });
  }

  /**
   *get unit payments data.
   *
   * @static
   * @returns {Promise<UnitPayment[]>}
   * @memberof Unit
   */
  public static getUnitPayments(unitId: string): Promise<UnitPaymentTerms[]> {
    return UnitAPI.getUnitPayments(unitId)
      .then((result) => {
        return Promise.resolve(result.data?.data ?? []);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get compare units details data.
   *
   * @static
   * @returns {Promise<UnitCompareInterface[]>}
   * @memberof Unit
   */
  public static getCompareUnits(
    unitsIds: number[]
  ): Promise<UnitCompareInterface[]> {
    return UnitAPI.getCompareUnits(unitsIds)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   * fetch all units depending on optional search params
   *
   * @static
   * @param {number} page
   * @param {number} perPage
   * @param {string} [filters]
   * @returns {Promise<PaginationPayload<UnitInterface[]>>}
   *
   * @memberOf Unit
   */
  public static getUnits(
    page: number,
    perPage: number,
    searchParams?: URLSearchParams,
    unitId?: string,
    price?: number,
    designType?: string,
    similarUnits?: boolean
  ): Promise<PaginationPayload<UnitInterface[]>> {
    if (searchParams) {
      // on_resale is 0 by default (temporary)
      if (!searchParams.has(FiltersEnums.ON_RESALE) && !similarUnits) {
        searchParams.append(FiltersEnums.ON_RESALE, '0');
      }

      searchParams.append('page', page.toString());
      searchParams.append('per_page', perPage.toString());
      if (unitId) {
        searchParams.append('exclude_id', unitId.toString());
      }
      if (price) {
        searchParams.append('min_dollar_price', (price * 0.8).toFixed());
        searchParams.append('max_dollar_price', (price * 1.2).toFixed());
      }
      if (designType) {
        searchParams.append('design_types[]', designType);
      }
    }

    return UnitAPI.getUnits(searchParams)
      .then((result) => {
        if (result.data[0]) {
          return result.data[0];
        }
        return result.data as unknown as PaginationPayload<UnitInterface[]>;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   * fetch the similar units of a unit based on the tags
   * @param searchParams
   * @returns
   */
  public static getSimilarUnits(
    unitId: string,
    price: number,
    designType?: string
  ): Promise<PaginationPayload<UnitInterface[]>> {
    const queryString = constructSimilarUnitsParams(unitId, price, designType);
    return UnitAPI.getUnits(queryString)
      .then((result) => {
        if (result.data[0]) {
          return result.data[0];
        }
        return result.data as unknown as PaginationPayload<UnitInterface[]>;
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get unit reservation details data.
   *
   * @static
   * @returns {Promise<UnitReservationDetails>}
   * @memberof Unit
   */
  public static getUnitReservationDetails(
    unitId: string
  ): Promise<UnitReservationDetails> {
    return UnitAPI.getUnitReservationDetails(unitId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get unit reservation nationalities.
   *
   * @static
   * @returns {Promise<UnitReservationNationality[]>}
   * @memberof Unit
   */
  public static getUnitReservationNationalities(
    countryId: string
  ): Promise<UnitReservationNationality[]> {
    return UnitAPI.getUnitReservationNationalities(countryId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get unit reservation countries.
   *
   * @static
   * @returns {Promise<UnitReservationCountry[]>}
   * @memberof Unit
   */
  public static getUnitReservationCountries(
    countryId: string
  ): Promise<UnitReservationCountry[]> {
    return UnitAPI.getUnitReservationCountries(countryId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get unit reservation cities.
   *
   * @static
   * @returns {Promise<UnitReservationCity[]>}
   * @memberof Unit
   */
  public static getUnitReservationCities(
    countryId: string
  ): Promise<UnitReservationCity[]> {
    return UnitAPI.getUnitReservationCities(countryId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  /**
   *get unit reservation occupations.
   *
   * @static
   * @returns {Promise<UnitReservationOccupation[]>}
   * @memberof Unit
   */
  public static getUnitReservationOccupations(
    countryId: string
  ): Promise<UnitReservationOccupation[]> {
    return UnitAPI.getUnitReservationOccupations(countryId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getUnitReservationConnections(
    countryId: string
  ): Promise<UnitReservationConnection[]> {
    return UnitAPI.getUnitReservationConnections(countryId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static addDraftSale(
    unitId: number,
    draftSaleData: AddDraftSaleParams
  ): Promise<DraftSaleResponse> {
    return UnitAPI.addDraftSale(unitId, draftSaleData)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static reserveTransactionCode(
    unitId: number,
    saleId: number,
    reserveTransactionCodeData: ReserveTransactionCodeParams
  ): Promise<Common> {
    return UnitAPI.reserveTransactionCode(
      unitId,
      saleId,
      reserveTransactionCodeData
    )
      .then((result): Promise<Common> => {
        return Promise.resolve(result);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static reserveAttachment(
    unitId: number,
    saleId: number,
    signReservationForm: string,
    attachment: File
  ): Promise<DraftSaleResponse> {
    return UnitAPI.reserveAttachment(
      unitId,
      saleId,
      signReservationForm,
      attachment
    )
      .then((result): Promise<DraftSaleResponse> => {
        return Promise.resolve(result.data);
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  }

  public static getSaleReservationState(
    saleState?: string
  ): ReservationStateEnum {
    let reservationState: ReservationStateEnum;
    const queryParams = QSParse(window.location.search);
    // Validate if the user is coming from the payment gateway
    if (
      queryParams['id'] &&
      queryParams['success'] &&
      queryParams['amount_cents'] &&
      queryParams['currency']
    ) {
      return ReservationStateEnum.PAY_RESERVATION_FEE_DONE;
    }

    switch (saleState) {
      case 'draft':
        reservationState = ReservationStateEnum.PAY_RESERVATION_FEE;
        break;
      case 'pending_sales':
        reservationState = ReservationStateEnum.RESERVATION_FORM;
        break;
      case 'pending_info':
        reservationState = ReservationStateEnum.YOUR_INFO;
        break;
      case 'download_form':
      case 'pending_finish':
        reservationState = ReservationStateEnum.RESERVATION_FORM;
        break;
      default:
        reservationState = ReservationStateEnum.INITIAL_STATE;
    }

    return reservationState;
  }

  public static getSalePaymentToken(
    unitId: number,
    saleId: number,
    amount: number
  ): Promise<string> {
    return UnitAPI.getSalePaymentToken(unitId, saleId, amount)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static getSaleInstallments(
    saleId: string,
    countrySlug: string
  ): Promise<SaleInstallmentsResponse> {
    return UnitAPI.getSaleInstallments(saleId, countrySlug)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static updateReservedUserInfo(
    unitId: number,
    saleId: number,
    userInfo: UpdateUserInfoRequest
  ): Promise<DraftSaleResponse> {
    return UnitAPI.updateReservedUserInfo(unitId, saleId, userInfo)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static finishUnitReservation(
    unitId: number,
    saleId: number
  ): Promise<string> {
    return UnitAPI.finishUnitReservation(unitId, saleId)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }

  public static downloadOmanSalesOffer(
    unitId: number,
    unitName: string
  ): Promise<any> {
    return UnitAPI.downloadOmanSalesOffer(unitId)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        downloadSalesOfferOman(url, unitName);
      })
      .catch((err: Error) => {
        console.log(err);
      });
  }
}

export const handleAttachMultipleReservationFiles = (
  unitId: number,
  saleId: number,
  files: File[]
) => {
  return Promise.all(
    files.map((file) => Unit.reserveAttachment(unitId, saleId, '0', file))
  );
};

export function getReservationAmountFromReservationPercent(
  unitPrice: number,
  reservationPercent: number
): number {
  return Math.ceil((unitPrice * reservationPercent) / 100);
}

/**
 * check if reservation amount is valid or not.
 * reservation amount should be less than or equal downpayment if downpayment is more than 0.
 * reservation amount should be more than or equal initial reservation amount.
 * resrvation amount should be less than or equal unit price.
 */
export function isValidReservationAmount(
  unitCountry: CountriesEnum | undefined,
  unitPrice: number | undefined = 0,
  reservationAmount: number | undefined = 0,
  initialReservationPercent: number | undefined = 0,
  initialReservationAmount: number | undefined = 0,
  downPaymentPercent: number | undefined = 0,
  downPaymentAmount: number | undefined = 0
): boolean {
  let valid = true;

  if (unitCountry && unitCountry !== CountriesEnum.EGYPT) {
    return true;
  }

  if (downPaymentPercent > 0 && reservationAmount > downPaymentAmount) {
    valid = false;
  }

  if (reservationAmount < initialReservationAmount) {
    valid = false;
  }

  if (reservationAmount > unitPrice) {
    valid = false;
  }

  if (
    initialReservationPercent > downPaymentPercent &&
    reservationAmount > initialReservationAmount
  ) {
    valid = false;
  }

  return valid;
}
