/**
 * @author Salma Hefnawy
 * @date 2022-12-10
 * @description implementation of referral related utility functions.
 * @filename referral.ts
 */

import { Referral as ReferralAPI } from '../../api/pre-delivery/referral.api';
import { getPayloadData, handleError } from '@orascom/utils';
import { PaginationPayload } from '@orascom/api-interfaces';
import { ReferralInterface } from '../../definitions/interfaces/pre-delivery.interface';

/**
 * group of Referral helpers functionalities.
 */
export class Referral {
  /**
   * get list of pre-delivery referrals
   *
   * @static
   * @param {number} page
   * @param {number} perPage
   * @returns {Promise<PaginationPayload<ReferralInterface[]>>}
   *
   * @memberOf Referral
   */
  public static getMyReferrals(
    page: number,
    perPage: number
  ): Promise<PaginationPayload<ReferralInterface[]>> {
    return ReferralAPI.getMyReferrals(page, perPage)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
