import { Filter as FilterAPI } from '../api/filter.api';
import { getPayloadData, handleError } from '@orascom/utils';
import { UnitFilters } from '@orascom/api-interfaces';

/**
 * group of Filter helpers functionalities.
 */
export class Filter {
  public static getUnitFilters(
    searchParams?: URLSearchParams
  ): Promise<UnitFilters> {
    return FilterAPI.getUnitFilters(searchParams)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
