import { Home as HomeUtilities } from '../../utils/home.utils';
import { useTranslation } from 'react-i18next';
import SectionsPage from '../../components/sections-page/sections-page';
import styles from './terms-and-conditions.module.scss';

export function TermsAndConditions() {
  const { t } = useTranslation();

  return (
    <div className="container">
      <h1 className={styles['title']}>{t('termsAndConditions')}</h1>
      <SectionsPage getSectionsData={HomeUtilities.getTermsAndConditions} />
    </div>
  );
}

export default TermsAndConditions;
