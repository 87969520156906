/**
 * @author Salma Hefnawy
 * @description Implementation of Common utilities.
 */

import { t } from 'i18next';
import {
  UnitsEnum,
  UserReservationDetailsInterface,
} from '../definitions/interfaces/common.interface';
import Numeral from 'numeral';
import { getUserSaleByUnitId } from './authentication.utils';
/**
 *check if the given value is empty.
 *
 * @export
 * @param {*} value
 * @returns {boolean}
 */
export function isEmpty(value: any): boolean {
  return value === undefined || value == null || value.length === 0;
}

export const resolveUnit = (unit: string) => {
  switch (unit) {
    case UnitsEnum.ACRE:
      return t('ac');
    case UnitsEnum.METER_SQUARE:
      return t('m²');
    case UnitsEnum.METER:
      return t('m');
    case UnitsEnum.PERCENT:
      return t('%');
    case UnitsEnum.PLUS:
      return '+';
    case UnitsEnum.SECONDS:
      return t('seconds');
    case UnitsEnum.MINUTES:
      return t('minutes');
    case UnitsEnum.HOURS:
      return t('hours');

    default:
      return '';
  }
};

export const formatNumberInfoItem = (value: string) => {
  if (Number(value) < 1000) {
    return Number(value);
  } else if (Number(value) < 1000000) {
    // format number to k format
    return Numeral(value).format('0a');
  } else {
    // format number to m format
    return Numeral(value).format('0.0a');
  }
};

export const formatInfoItem = (value: string, type: string, unit: string) => {
  if (type === 'number') {
    return `${formatNumberInfoItem(value)} ${resolveUnit(unit)}`;
  } else {
    return value;
  }
};

export const formatLocationInfoItem = (time: number, unit: string) => {
  return `${time} ${resolveUnit(unit)}`;
};

export const getCustomerId = (
  unitId: number,
  reservationDetals: UserReservationDetailsInterface
) => {
  const userSale = getUserSaleByUnitId(reservationDetals, unitId.toString());
  if (userSale) {
    return userSale.customer_id;
  }
};

export function calculateTimeLeft(unixTimestamp: number) {
  const targetDate = new Date(unixTimestamp * 1000);
  const currentDate = new Date();

  // Check if the target date is before the current date
  if (targetDate < currentDate) {
    return null;
  }

  let years = targetDate.getFullYear() - currentDate.getFullYear();
  let months = targetDate.getMonth() - currentDate.getMonth();
  let days = targetDate.getDate() - currentDate.getDate();

  // Adjust if the day difference is negative
  if (days < 0) {
    months -= 1;
    days += new Date(
      targetDate.getFullYear(),
      targetDate.getMonth(),
      0
    ).getDate();
  }

  // Adjust if the month difference is negative
  if (months < 0) {
    years -= 1;
    months += 12;
  }

  return { years, months, days };
}

// Function to check if the promotion has been viewed
export function hasViewedPromotion(promotionId: number) {
  const viewedPromotionsString = sessionStorage.getItem('viewedPromotions');
  const viewedPromotions = viewedPromotionsString
    ? JSON.parse(viewedPromotionsString)
    : [];
  return viewedPromotions.includes(promotionId);
}

// Function to mark the promotion as viewed
export function markPromotionAsViewed(promotionId: number) {
  const viewedPromotionsString = sessionStorage.getItem('viewedPromotions');
  const viewedPromotions = viewedPromotionsString
    ? JSON.parse(viewedPromotionsString)
    : [];
  if (!viewedPromotions.includes(promotionId)) {
    viewedPromotions.push(promotionId);
    sessionStorage.setItem(
      'viewedPromotions',
      JSON.stringify(viewedPromotions)
    );
  }
}
