import { DestinationProject } from '@orascom/api-interfaces';
import NeighborhoodCard from '../common/neighborhood-card/neighborhood-card';
import styles from './destination-projects.module.scss';

/* eslint-disable-next-line */
export interface DestinationProjectsProps {
  title: string;
  subTitle: string;
  projects: DestinationProject[];
  destinationName: string;
  destinationId: string;
  destinationCountryName: string;
  destinationCountryId: string;
}

export function DestinationProjects(props: Readonly<DestinationProjectsProps>) {
  return (
    <div className={`${styles['wrapper']} container`}>
      <h4 className="orascom__title">{props.title}</h4>
      <h5 className="orascom__sub-title">{props.subTitle}</h5>
      <div className={`${styles['projects']}`}>
        {props.projects.map((project) => (
          <NeighborhoodCard
            key={project.id}
            project={project}
            destinationName={props.destinationName}
            destinationId={props.destinationId}
            destinationCountryName={props.destinationCountryName}
            destinationCountryId={props.destinationCountryId}
            description={project.short_description}
          />
        ))}
      </div>
    </div>
  );
}

export default DestinationProjects;
