/**
 * @author Salma Hefnawy
 * @date 2022-10-20
 * @description implementation of home related API calls.
 * @filename home.ts
 */
import { Network } from './network';
import { PaginationPayload, Payload } from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';
import {
  PagesDataInterface,
  NewsDetailsInterface,
  NewsListingInterface,
  ActivePromotionInterface,
  CustomPageInterface,
} from '../definitions/interfaces/home.interface';

/**
 * implement home related calls.
 */
export class Home {
  /**
   * get all home meta data from API.
   *
   * @static
   * @param {number} [page=1]
   * @returns {Promise<Payload<PagesDataInterface>}
   * @memberof Home
   */
  public static getHomeMetaData(): Promise<Payload<PagesDataInterface>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getHomeMetaData.path}`,
      {
        method: ENDPOINTS.getHomeMetaData.method,
      }
    );
  }

  /**
   * get all about us meta data from API.
   *
   * @static
   * @param {number} [page=1]
   * @returns {Promise<Payload<PagesDataInterface>}
   * @memberof Home
   */
  public static getAboutUsMetaData(): Promise<Payload<PagesDataInterface>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getAboutUsMetaData.path}`,
      {
        method: ENDPOINTS.getAboutUsMetaData.method,
      }
    );
  }

  /**
   * get all privacy policy  data from API.
   *
   * @static
   * @param {number} [page=1]
   * @returns {Promise<Payload<PagesDataInterface>}
   * @memberof Home
   */
  public static getPrivacyPolicy(): Promise<Payload<PagesDataInterface>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getPrivacyPolicy.path}`,
      {
        method: ENDPOINTS.getPrivacyPolicy.method,
      }
    );
  }

  /**
   * get all faqs data from API.
   *
   * @static
   * @param {number} [page=1]
   * @returns {Promise<Payload<PagesDataInterface>}
   * @memberof Home
   */
  public static getFAQs(): Promise<Payload<PagesDataInterface>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getFAQs.path}`,
      {
        method: ENDPOINTS.getFAQs.method,
      }
    );
  }

  /**
   * get all terms and conditions data from API.
   *
   * @static
   * @param {number} [page=1]
   * @returns {Promise<Payload<PagesDataInterface>}
   * @memberof Home
   */
  public static getTermsAndConditions(): Promise<Payload<PagesDataInterface>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getTermsAndConditions.path}`,
      {
        method: ENDPOINTS.getTermsAndConditions.method,
      }
    );
  }

  /**
   * get latest news in home page.
   *
   * @static
   * @param {string} [page=1]
   * @param {string} [per_page=10]
   * @param {boolean} [is_event=false]
   * @param {boolean} [is_featured=false]
   * @returns {Promise<Payload<PaginationPayload<NewsListingInterface[]>>>}
   * @memberof Home
   */
  public static getNews(
    page: string,
    per_page: string,
    is_event: boolean,
    is_featured: boolean
  ): Promise<Payload<PaginationPayload<NewsListingInterface[]>>> {
    const url = new URL(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getNews.path}`
    );
    url.searchParams.append('page', page);
    url.searchParams.append('per_page', per_page);
    if (!is_featured) {
      url.searchParams.append('is_event', `${is_event ? 1 : 0}`);
    }
    url.searchParams.append('is_featured', `${is_featured ? 1 : 0}`);

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.getNews.method,
    });
  }

  /**
   * get news details
   *
   * @static
   * @param {string} slug
   * @returns {Promise<Payload<NewsDetailsInterface>>}
   * @memberof Home
   */
  public static getNewsDetails(
    slug: string
  ): Promise<Payload<NewsDetailsInterface>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getNewsDetails.url(slug)}`,
      {
        method: ENDPOINTS.getNewsDetails.method,
      }
    );
  }

  /**
   * get active promotion on shopper level details
   * @returns {Promise<ActivePromotionInterface>}
   */
  public static getActivePromotions(): Promise<
    Payload<ActivePromotionInterface[]>
  > {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getActivePromotion.url}`,
      {
        method: ENDPOINTS.getActivePromotion.method,
      }
    );
  }

  public static getCustomPage(): Promise<Payload<CustomPageInterface[]>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getCustomPage.url}`,
      {
        method: ENDPOINTS.getCustomPage.method,
      }
    );
  }
}
