import { Network } from './network';
import { ENDPOINTS } from './endpoints';
import {
  PaginationPayload,
  Payload,
  UnitInterface,
} from '@orascom/api-interfaces';

/**
 * implement wishlist related calls.
 */
export class WishList {
  /**
   * get wishlist
   *
   * @static
   * @param {number} page
   * @param {number} perPage
   * @returns {Promise<Payload<PaginationPayload<UnitInterface[]>>>}
   *
   * @memberOf WishList
   */
  public static getWishList(
    page: number,
    perPage: number
  ): Promise<Payload<PaginationPayload<UnitInterface[]>>> {
    const url = new URL(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.getWishList.path}`
    );
    url.searchParams.append('page', String(page));
    url.searchParams.append('per_page', String(perPage));

    return Network.fetch(url.href, {
      method: ENDPOINTS.getWishList.method,
    });
  }

  /**
   * add unit to wishlist
   *
   * @static
   * @param {number} unitId
   * @returns {Promise<Payload<UnitInterface[]>>}
   *
   * @memberOf WishList
   */
  public static addToWishList(
    unitId: number
  ): Promise<Payload<UnitInterface[]>> {
    const url = new URL(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.addToWishList.url(
        String(unitId)
      )}`
    );

    return Network.fetch(url.href, {
      method: ENDPOINTS.addToWishList.method,
    });
  }

  /**
   * remove unit from wishlist
   *
   * @static
   * @param {number} unitId
   * @returns {Promise<Payload<UnitInterface[]>>}
   *
   * @memberOf WishList
   */
  public static removeFromWishList(
    unitId: number
  ): Promise<Payload<UnitInterface[]>> {
    const url = new URL(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.removeFromWishList.url(
        String(unitId)
      )}`
    );

    return Network.fetch(url.href, {
      method: ENDPOINTS.removeFromWishList.method,
    });
  }
}
