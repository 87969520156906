/* eslint-disable import/no-extraneous-dependencies */
import styles from './sign-up-form.module.scss';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { GoldButton } from '../../components/common/button/button';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import ErrorIcon from '../../assets/icons/error.svg';
import InfoIcon from '../../assets/icons/info-grey.svg';
import { SignUpFormInterface } from '../../definitions/interfaces/authentication.interface';
import { Authentication as AuthenticationUtil } from '../../utils/authentication.utils';
import { useState } from 'react';
import { isEmpty } from '../../utils/common.utils';
import {
  analyticsReservationFlowLoginCustomEvent,
  analyticsSignUpSendCodeCustomEvent,
  analyticsSignUpSendCodeFromReservationFlowCustomEvent,
} from '../../utils/analytics-events';
import { CommonEventParameters, validatePhone } from '@orascom/utils';

export interface SignUpFormProps {
  onFinish: (values: Required<SignUpFormInterface>) => void;
  values?: SignUpFormInterface;
}

export function SignUpForm(props: Readonly<SignUpFormProps>) {
  const { t } = useTranslation();

  const [registrationErrors, setRegistrationErrors] = useState<string[]>([]);
  const { search } = useLocation();

  const stringValidationRegex = /^(?!\s+$)/;

  const SignUpFormSchema = Yup.object().shape({
    name: Yup.string()
      .max(25)
      .required(t('pleaseEnterFullName'))
      .matches(stringValidationRegex, t('whiteSpacesError')),
    email: Yup.string().email().required(t('pleaseEnterValidEmail')),
    phone: Yup.string().required(t('pleaseEnterPhoneNumber')),
  });

  const commonEventParams: CommonEventParameters = {
    timestamp: Date.now().toString(),
    portal: 'Shopper',
    pageName: location.pathname,
  };

  const SendOTP = (
    values: Required<SignUpFormInterface>,
    actions?: FormikHelpers<Required<SignUpFormInterface>>
  ) => {
    const phoneNumberWithCountryCode = `+${values.code}${parseInt(
      values.phone
    )}`;

    AuthenticationUtil.registerUserOnFirebase(
      phoneNumberWithCountryCode,
      values.email
    )
      .then(() => {
        actions?.setSubmitting(false);
        props.onFinish({
          name: values.name,
          email: values.email,
          phone: values.phone,
          code: values.code,
        });
        if (search.includes('reserve-unit')) {
          analyticsSignUpSendCodeFromReservationFlowCustomEvent(
            commonEventParams
          );
        } else {
          analyticsSignUpSendCodeCustomEvent(commonEventParams);
        }
      })
      .catch((registerErrors) => {
        setRegistrationErrors(registerErrors);
        actions?.setSubmitting(false);
        return Promise.resolve();
      });
  };

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('signUp')}</h1>
      <p className={styles['subtitle']}>{t('signUpSubtitle')}</p>
      <Formik
        initialValues={{
          name: props.values?.name ?? '',
          email: props.values?.email ?? '',
          phone: props.values?.phone ?? '',
          code: props.values?.code ?? '20',
        }}
        validationSchema={SignUpFormSchema}
        onSubmit={SendOTP}
      >
        {({
          errors,
          touched,
          setFieldValue,
          values,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <Form>
            <div className="input-wrapper">
              <label htmlFor="name">{`${t('fullName')}*`}</label>
              <Field
                name="name"
                type="text"
                className={errors.name && touched.name ? 'error' : ''}
              />
              {errors.name && touched.name ? (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {errors.name}
                </p>
              ) : null}
            </div>
            <div className="input-wrapper">
              <label htmlFor="email">{`${t('email')}*`}</label>
              <Field
                name="email"
                type="email"
                className={errors.email && touched.email ? 'error' : ''}
                placeholder="example@example"
              />
              {errors.email && touched.email ? (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {errors.email}
                </p>
              ) : null}
            </div>
            <div className="input-wrapper">
              <label htmlFor="phone">{t('phoneNumber')}*</label>
              <div className={styles['phone-wrapper']}>
                <PhoneInput
                  countryCodeEditable={false}
                  inputProps={{
                    name: 'code',
                  }}
                  country={'eg'}
                  onChange={(code) => {
                    setFieldValue('code', code);
                    setFieldValue('phone', '');
                  }}
                  enableSearch={true}
                  excludeCountries={['il']}
                  value={values.code}
                />
                <Field
                  name="phone"
                  type="tel"
                  validate={() => validatePhone(values.phone, values.code)}
                  className={errors.phone && touched.phone ? 'error' : ''}
                  placeholder="123 456 7890"
                />
              </div>
              {errors.phone && touched.phone ? (
                <p className="error">
                  <img src={ErrorIcon} role="presentation" alt="" />
                  {errors.phone}
                </p>
              ) : null}
              <p className={styles['otp-disclaimer']}>
                <img src={InfoIcon} role="presentation" alt="" />
                {t('weWillSendOTP')}
              </p>
            </div>
            <p className={styles['disclaimer']}>
              {t('bySigningUp')}
              <Link to={ROUTES.TermsAndConditions.path}>
                {t('termsOfService')}
              </Link>
              {t('toTheAnd')}
              <Link to={ROUTES.PrivacyPolicy.path}>{t('privacyPolicy')}</Link>
            </p>
            <div className={styles['send-code']}>
              <GoldButton
                className={styles['contact-btn']}
                type="submit"
                id="sign-in-button"
                disabled={isSubmitting || !(isValid && dirty)}
              >
                {t('sendCode')}
              </GoldButton>
              <p>
                <span>{t('alreadyHaveAccount')}</span>
                <Link
                  to={`${ROUTES.Login.path}${search}`}
                  onClick={() =>
                    analyticsReservationFlowLoginCustomEvent(commonEventParams)
                  }
                >
                  {t('login')}
                </Link>
              </p>
            </div>
            {!isEmpty(registrationErrors) && (
              <div className={styles['errors-container']}>
                {registrationErrors.map((error, index) => (
                  <>
                    {error.includes('too-many-requests') ? (
                      <span>{t('tooManyAttempts')}</span>
                    ) : (
                      <span>{error}</span>
                    )}
                    {index < registrationErrors.length - 1 && <span>, </span>}
                  </>
                ))}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SignUpForm;
