import {
  PagesDataInterface,
  Sections,
} from '../../definitions/interfaces/home.interface';
import styles from './sections-page.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { Loader } from '@orascom/common-components';
import { errorMessagesHandler } from '@orascom/utils';

export interface SectionsPageProps {
  getSectionsData: () => Promise<PagesDataInterface>;
}
export function SectionsPage(props: Readonly<SectionsPageProps>) {
  const { t } = useTranslation();
  const location = useLocation();
  const [sortedSections, setSortedSections] = useState<Sections[]>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    props
      .getSectionsData()
      .then((res) => {
        setSortedSections(res.sections.sort((a, b) => a.order - b.order));
      })
      .catch((err) => errorMessagesHandler(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loader />;
  }
  if (!sortedSections?.length) {
    return <p> no sections </p>;
  }
  return (
    <div className={styles['wrapper']}>
      <div className={styles['details']}>
        <div className={styles['sections']}>
          {sortedSections.map((section) => (
            <div
              className={styles['section']}
              key={section.id}
              id={section.id.toString()}
            >
              <p className={styles['name']}>{section.name}</p>
              <p className={styles['description']}>
                {parse(section.description)}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles['content-table']}>
        <h2>{t('tableOfContents')}</h2>
        <hr className={styles['line']}></hr>
        <div className={styles['anchors']}>
          {sortedSections.map((section) => (
            <a
              key={section.id}
              href={`${location.pathname}#${section.id.toString()}`}
            >
              {section.name}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SectionsPage;
