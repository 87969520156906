import { filterArrOfObjectsByKeyValue } from '@orascom/utils';
import { useTranslation } from 'react-i18next';
import { AboutInfo as AboutInfoInterface } from '@orascom/api-interfaces';
import bookingExpImg from '../../assets/images/booking-exp-img.webp';
import cardIcon from '../../assets/icons/credit-card.svg';
import unitIcon from '../../assets/icons/unit.svg';
import destinationIcon from '../../assets/icons/destination.svg';
import propertyIcon from '../../assets/icons/property.svg';
import styles from './booking-experience.module.scss';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../api/routes';

export interface BookingExperienceProps {
  info: AboutInfoInterface[];
}

export function BookingExperience(props: Readonly<BookingExperienceProps>) {
  const { info } = props;
  const generalMetaDataInfo = info as unknown as Record<string, unknown>[];

  const { t } = useTranslation();

  return (
    <div className={styles['booking-exp']}>
      <div className={styles['booking-exp__title']}>
        <h6>{t('artOfLiving')}</h6>
        <h3>{t('seamlessBookingExperience')}</h3>
        <div className={styles['booking-exp__content']}>
          <img src={bookingExpImg} alt="booking-experience" loading="lazy" />
          <div className={styles['booking-exp__key-steps']}>
            <div className={styles['booking-exp__key-step']}>
              <img src={destinationIcon} alt="" role="presentation" />
              <h6>{t('exploreDestinations')}</h6>
              <p>
                {`
                ${t('explore')}
                ${
                  filterArrOfObjectsByKeyValue(
                    generalMetaDataInfo,
                    'name',
                    'total_destinations'
                  )?.value ?? ''
                }
                ${t('destinationsIn')}
                ${
                  filterArrOfObjectsByKeyValue(
                    generalMetaDataInfo,
                    'name',
                    'total_countries'
                  )?.value ?? ''
                }
                ${t('countriesAcross')}
                ${
                  filterArrOfObjectsByKeyValue(
                    generalMetaDataInfo,
                    'name',
                    'total_continents'
                  )?.value ?? ''
                }
                ${t('continents')}.
                `}
              </p>
            </div>
            <div className={styles['booking-exp__key-step']}>
              <img src={propertyIcon} alt="" role="presentation" />
              <h6>{t('checkAvailableProperties')}</h6>
              <p>{t('seeAvailableUnitsForSale')}</p>
            </div>
            <div className={styles['booking-exp__key-step']}>
              <img src={unitIcon} alt="" role="presentation" />
              <h6>{t('bookUnit')}</h6>
              <p>{t('fillQuickFormToConfirm')}</p>
            </div>
            <div className={styles['booking-exp__key-step']}>
              <img src={cardIcon} alt="" role="presentation" />
              <h6>{t('payDownpayment')}</h6>
              <p>{t('payYourUnitDownpaymentOnline')}</p>
            </div>
            <Link to={ROUTES.BookingExperience.path} className={styles['link']}>
              {t('readMore')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingExperience;
