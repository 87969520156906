/* eslint-disable import/no-extraneous-dependencies */
import styles from './unit-facilities.module.scss';
import facilityImage from '../../assets/images/unit-facility-image.png';
import { UnitFacility } from '@orascom/api-interfaces';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

/* eslint-disable-next-line */
export interface UnitFacilitiesProps {
  unitFacilities: UnitFacility[];
}

export function UnitFacilities(props: Readonly<UnitFacilitiesProps>) {
  const { t } = useTranslation();
  return (
    <div className={styles['unit-facilities']}>
      <div className={styles['info']}>
        <div className={styles['info-headings']}>
          <h1 className="orascom__title">{t('offeredServices')}</h1>
          <h2 className="orascom__sub-title">{t('featuresAndAmenities')}</h2>
        </div>
        <div className={styles['image--responsive']}>
          <img src={facilityImage} alt="facility" loading="lazy" />
        </div>
        <div className={styles['info-facilities']}>
          {props.unitFacilities?.map((facility) => (
            <div className={styles['info-facilities-item']} key={uuidv4()}>
              <img src={facility.icon} alt="" loading="lazy" />
              <p>{facility.title}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles['image']}>
        <img src={facilityImage} alt="facility" loading="lazy" />
      </div>
    </div>
  );
}

export default UnitFacilities;
