import spinner from './spinner.svg';
import styles from './spinner.module.scss';

export function Spinner() {
  return (
    <div className={styles['spinner']}>
      <img src={spinner} alt="" role="presentation" />
    </div>
  );
}

export default Spinner;
