import styles from './wish-list.module.scss';
import { WishList as WishListUtilities } from '../../utils/wishlist.utils';
import { useEffect, useState } from 'react';
import { PaginationInterface, UnitInterface } from '@orascom/api-interfaces';
import PropertyCard from '../../components/common/property-card/property-card';
import { Loader, NavyButton } from '@orascom/common-components';
import { useTranslation } from 'react-i18next';
import InfoIcon from '../../assets/icons/info.svg';
import CompareButton from '../../components/common/compare-button/compare-button';
import { toast } from 'react-toastify';
import getDocTitle from '../../utils/getDocTitle';
import { Helmet } from 'react-helmet-async';

export function WishList() {
  const { t } = useTranslation();
  const [units, setUnits] = useState<UnitInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingWishList, setLoadingWishList] = useState(false);
  const [paginationData, setPaginationData] = useState<PaginationInterface>();
  const [propertyRemoved, setPropertyRemoved] = useState(false);

  useEffect(() => {
    setLoading(true);
    WishListUtilities.getWishList(1, 6)
      .then((res) => {
        setUnits(res.data);
        setPaginationData(res.meta);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [propertyRemoved]);

  const loadMore = () => {
    setLoadingWishList(true);
    WishListUtilities.getWishList(
      paginationData?.current_page ? paginationData.current_page + 1 : 1,
      6
    )
      .then((res) => {
        setUnits((prevState) => {
          return [...prevState, ...res.data];
        });
        setPaginationData(res.meta);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingWishList(false));
  };

  const handleRemoveFromWishList = (id: number) => {
    WishListUtilities.removeFromWishList(id)
      .then(() => {
        setPropertyRemoved(!propertyRemoved);
        toast.success(t('unitRemovedFromWishlist') as string);
      })
      .catch((err) => console.log(err));
  };

  if (loading) {
    return <Loader />;
  }

  const wishListDocTitle = getDocTitle('Wish List');
  return (
    <div className={`${styles['wrapper']} container`}>
      <Helmet>
        <title>{wishListDocTitle}</title>
        <meta property="og:title" content={wishListDocTitle} />
        <meta name="description" content={wishListDocTitle} />
        <meta property="og:description" content={wishListDocTitle} />
      </Helmet>
      <CompareButton />
      <p className="orascom__sub-title">
        {t('myWishList', {
          count: paginationData?.total,
        })}
      </p>
      <p className={styles['disclaimer']}>
        <img src={InfoIcon} role="presentation" alt="" />
        <span>{t('wishListDisclaimer')}</span>
      </p>
      {units.length > 0 ? (
        <>
          <div className={styles['units-list']}>
            {units?.map((unit) => (
              <PropertyCard
                key={unit.id}
                unit={unit}
                removeFromWishList={() => handleRemoveFromWishList(unit.id)}
                wishlistPage
              />
            ))}
          </div>
          <div className={styles['count']}>
            <hr />
            <span>
              {t('showing')} {units?.length ?? 0} {t('unitsOutOf')}{' '}
              {paginationData?.total ?? 0}
            </span>
          </div>
          {paginationData?.last_page &&
            paginationData?.current_page &&
            paginationData?.last_page > paginationData?.current_page && (
              <div className={styles['load-more']}>
                <NavyButton onClick={loadMore} disabled={loadingWishList}>
                  {t('loadMore')}
                </NavyButton>
              </div>
            )}
        </>
      ) : (
        <div className={styles['no-results']}>
          <h4>{t('noWishListUnits')}</h4>
        </div>
      )}
    </div>
  );
}

export default WishList;
