import { useTranslation } from 'react-i18next';
import styles from './highlights.module.scss';
import {
  GoldButton,
  ImageTextOverlay,
  NavyButton,
} from '@orascom/common-components';
import { CUSTOM_PAGES_CONTEXT } from '../../contexts/custom-pages';
import { useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ReachUs from '../../components/common/reach-us/reach-us';
import { ROUTES } from '../../api/routes';
import ExploreIndicator from '../../components/common/explore-indicator/explore-indicator';
import AboutOrascom from '../../components/common/about-orascom/about-orascom';
import { v4 as uuidv4 } from 'uuid';

const Highlights = () => {
  const { t } = useTranslation();
  const { customPages } = useContext(CUSTOM_PAGES_CONTEXT);
  const { pageSlug } = useParams();
  const foundPage = customPages?.find((page) => page.slug === pageSlug);
  const sortedSections = foundPage?.sections.sort((a, b) => a.order - b.order);
  const targetDivRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <div className={styles['container']}>
      <div className={styles['banner']}>
        <ImageTextOverlay
          image={foundPage?.image ?? ''}
          title={t('highlights')}
          subTitle={foundPage?.name ?? ''}
          gradient
          maxContentWidth={600}
        >
          <div className={styles['button']}>
            {' '}
            <NavyButton secondary onClick={handleScroll}>
              {t('learnMore')}
            </NavyButton>
          </div>
        </ImageTextOverlay>
      </div>
      <div className={`${styles['text']} container`}>
        <p className={styles['description']}>{foundPage?.description}</p>
        <ExploreIndicator title={t('learnMore')} />
      </div>
      <div ref={targetDivRef}>
        {sortedSections?.map((section, index) => {
          const showAbout = section.name && (section.image || section.video);
          return showAbout ? (
            <AboutOrascom
              key={uuidv4()}
              subTitle={section.name}
              description={section.description}
              videoUrl={section.video}
              aboutImage={section.image}
              showAboutButton={false}
              customPage={true}
              imageFirst={index % 2 === 0}
            />
          ) : (
            <div key={uuidv4()} className={`${styles['text']} container`}>
              <h2>{section.name}</h2>
              <p className={styles['description']}>{section.description}</p>
            </div>
          );
        })}
      </div>
      <ReachUs
        description={t('weWouldLoveToShareIdeas')}
        primaryButton={
          <GoldButton asLink to={ROUTES.ContactUs.path}>
            {t('getInTouch')}
          </GoldButton>
        }
      />
    </div>
  );
};

export default Highlights;
