import styles from './video.module.scss';
import playButton from '../../../assets/icons/play-button.svg';
import { useState, useRef } from 'react';

export interface VideoPlayerProps {
  videoUrl: string;
  className?: string;
}

export function VideoPlayer(props: Readonly<VideoPlayerProps>) {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className={styles['video-container']}>
      <video
        ref={videoRef}
        onClick={togglePlayPause}
        className={props.className ? styles[props.className] : ''}
        src={props.videoUrl}
      />
      <button className={styles['control-btn']} onClick={togglePlayPause}>
        {!isPlaying && <img src={playButton} alt="play" />}
      </button>
    </div>
  );
}
export default VideoPlayer;
