import { UnitInterface } from '@orascom/api-interfaces';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CardsSlider } from '@orascom/common-components';
import PropertyCard from '../property-card/property-card';
import './property-cards-swiper.scss';

export interface PropertyCardsSwiperProps {
  title: string;
  units: UnitInterface[];
  viewAllLink?: string;
  linkState?: unknown;
  searchParams?: string;
}

export function PropertyCardsSwiper(props: Readonly<PropertyCardsSwiperProps>) {
  const { title, units, viewAllLink, linkState, searchParams } = props;
  const { t } = useTranslation();
  return (
    <div className="property-cards-swiper ">
      {viewAllLink ? (
        <h5 className=" property-cards-swiper__title">
          <span>{title}</span>
          <Link
            to={{ pathname: viewAllLink, search: searchParams ?? '' }}
            state={linkState}
          >
            {t('viewAllProperties')}
          </Link>
        </h5>
      ) : (
        <h5 className="property-cards-swiper__title">{title}</h5>
      )}
      <CardsSlider spaceBetween={window.innerWidth > 769 ? 24 : 16}>
        {units.map((unit) => (
          <PropertyCard key={unit.id} unit={unit} />
        ))}
      </CardsSlider>
    </div>
  );
}

export default PropertyCardsSwiper;
