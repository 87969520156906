/* eslint-disable @nx/enforce-module-boundaries */
import styles from './unit-payments.module.scss';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Loader } from '@orascom/common-components';
import MyUnitCard from '../../../components/pre-delivery/my-unit-card/my-unit-card';
import { useGetMyUnits } from '../hooks/use-get-my-units';

export function UnitPayments() {
  const { t } = useTranslation();

  const [myUnits, loadingMyUnits] = useGetMyUnits();

  if (loadingMyUnits) {
    return <Loader />;
  }

  return (
    <div className={`${styles['unit-payments']} pre-delivery-container`}>
      <h1 className="orascom__sub-title">{t('unitPayments')}</h1>
      {myUnits.map((unit) => (
        <MyUnitCard
          key={uuidv4()}
          unitDetails={unit}
          unitPage="unit-payments"
        />
      ))}
    </div>
  );
}

export default UnitPayments;
