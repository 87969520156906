/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { PaginationInterface } from '@orascom/api-interfaces';
import { Loader, NavyButton } from '@orascom/common-components';
import { ReferralInterface } from '../../../definitions/interfaces/pre-delivery.interface';
import { Referral as ReferralUtils } from '../../../utils/pre-delivery/referral.util';
import styles from './my-referrals.module.scss';
import { ROUTES } from '../../../api/routes';
import backArrowIcon from '../../../assets/icons/back-arrow.svg';
import UserIcon from '../../../assets/icons/user.svg?react';
import { v4 as uuidv4 } from 'uuid';

export function MyReferrals() {
  const { t } = useTranslation();
  const [paginationData, setPaginationData] = useState<PaginationInterface>();
  const [loadingReferrals, setLoadingReferrals] = useState(false);
  const [searchingReferrals, setSearchingReferrals] = useState(false);
  const [referrals, setReferrals] = useState<ReferralInterface[]>([]);

  const unitsCurrentPage = paginationData?.current_page
    ? paginationData.current_page + 1
    : 1;
  const unitsPage = (page?: number) => {
    return page ?? unitsCurrentPage;
  };
  const loadMyReferralHandler = (loadMore: boolean, page?: number) => {
    setLoadingReferrals(true);
    if (!loadMore) {
      setSearchingReferrals(true);
    }
    ReferralUtils.getMyReferrals(unitsPage(page), 9)
      .then((res) => {
        if (loadMore) {
          setReferrals((prevState) => {
            return [...prevState, ...res.data];
          });
        } else {
          setReferrals(res.data);
        }

        setPaginationData(res.meta);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadingReferrals(false);
        if (!loadMore) {
          setSearchingReferrals(false);
        }
      });
  };

  useEffect(() => {
    loadMyReferralHandler(false, paginationData?.current_page);
  }, []);

  if (loadingReferrals) {
    return <Loader />;
  }
  return (
    <div className={`${styles['my-referrals']} pre-delivery-container`}>
      <header className={styles['header']}>
        <NavLink
          to={ROUTES.Pre_Delivery_Referrals.path}
          className="back-button"
        >
          <img src={backArrowIcon} alt="side-menu-icon" />
          <>{t('backToReferrals')}</>
        </NavLink>
        <div className={styles['header-details']}>
          <NavLink to={ROUTES.Pre_Delivery_Referrals.path}>
            {t('referrals')}
          </NavLink>
          <span>/</span>
          <p>{t('myReferrals')}</p>
        </div>
      </header>
      <h1 className="orascom__sub-title">{t('myReferrals')}</h1>
      <p className="orascom__paragraph">no referrals found</p>
      {/* {!searchingReferrals && (
        <div className={styles['details']}>
          {referrals.map((referral) => (
            <div className={styles['details-item']} key={uuidv4()}>
              <h2 className="orascom__sub-title">
                <UserIcon /> <span>{referral.name}</span>
              </h2>
              <div className={styles['details-item-info']}>
                <div>
                  <label>{t('referralPhoneNumber')}</label>
                  <p>{referral.phone_number}</p>
                </div>
                <div>
                  <label>{t('requestSubmissionDate')}</label>
                  <p>
                    {new Date(
                      referral.request_submission_date * 1000
                    ).toLocaleDateString('en-GB', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </p>
                </div>
                <div>
                  <label>{t('status')}</label>
                  <p>
                    {referral.status === 'Ongoing' ? (
                      <span
                        className={`${styles['status']} ${styles['ongoing']}`}
                      ></span>
                    ) : referral.status === 'Folded' ? (
                      <span
                        className={`${styles['status']} ${styles['folded']}`}
                      ></span>
                    ) : (
                      <span
                        className={`${styles['status']} ${styles['closed-deal']}`}
                      ></span>
                    )}
                    {referral.status}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )} */}
      {/* <div className={styles['count']}>
        <hr />
        <span>
          {t('showing')} {referrals.length ?? 0} {t('dealsOutOf')}{' '}
          {paginationData?.total ?? 0}
        </span>
      </div> */}
      {/* {paginationData?.last_page &&
        paginationData?.current_page &&
        paginationData?.last_page > paginationData?.current_page && (
          <div className={styles['load-more-button']}>
            <NavyButton
              onClick={() => loadMyReferralHandler(true)}
              disabled={loadingReferrals}
            >
              {t('loadMore')}
            </NavyButton>
          </div>
        )} */}
    </div>
  );
}

export default MyReferrals;
