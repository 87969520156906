import { OptionValue } from '@orascom/api-interfaces';
import i18next from 'i18next';

export function changeLanguage(lang: OptionValue) {
  if (lang.value === 'ar') {
    document.documentElement.setAttribute('dir', 'rtl');
    document.documentElement.setAttribute('lang', 'ar');
  } else {
    document.documentElement.setAttribute('dir', 'ltr');
    document.documentElement.setAttribute('lang', lang.value as string);
  }
  localStorage.setItem('lang', JSON.stringify(lang));
  i18next.changeLanguage(String(lang.value));
}
