import { Network } from './network';
import { ENDPOINTS, PRE_DELIVERY_ENDPOINTS } from './endpoints';
import { Payload } from '@orascom/api-interfaces';
import {
  ContactUsFormData,
  SubmitLeadData,
} from '../definitions/interfaces/contact.interface';
import { CountryOffice } from '../components/common/country-office-card/country-office-card';

/**
 * implement contact us related calls.
 */

export class Contact {
  /**
   *
   * @static
   * @param {ContactUsFormData} [formData]
   * @returns {Promise<Payload<string>>}
   *
   * @memberOf Contact
   */
  public static submitContactForm(
    formData: ContactUsFormData
  ): Promise<Payload<string>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.contactUs.path}`,
      {
        method: ENDPOINTS.contactUs.method,
        body: JSON.stringify(formData),
      }
    );
  }

  /**
   *
   * @static
   * @returns {Promise<Payload<CountryOffice[]>>}
   *
   * @memberOf Contact
   */
  public static getSalesContacts(): Promise<Payload<CountryOffice[]>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.salesContacts.path}`,
      {
        method: ENDPOINTS.salesContacts.method,
      }
    );
  }

  /**
   *
   * @static
   * @param {ContactUsFormData} [formData]
   * @returns {Promise<Payload<string>>}
   *
   * @memberOf Contact
   */
  public static submitReferralForm(
    formData: ContactUsFormData
  ): Promise<Payload<string>> {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${PRE_DELIVERY_ENDPOINTS.submitReferral.path}`,
      {
        method: PRE_DELIVERY_ENDPOINTS.submitReferral.method,
        body: JSON.stringify(formData),
      }
    );
  }

  /**
   *
   * @static
   * @param {ContactUsFormData} [formData]
   * @returns {Promise<Payload<string>>}
   *
   * @memberOf Contact
   */
  public static submitShopperLead(formData: SubmitLeadData): Promise<
    Payload<
      Payload<{
        lead_source_id: string;
        customer_source_id: string;
      }>
    >
  > {
    return Network.fetch(
      `${process.env.NX__API_ENDPOINT}${ENDPOINTS.submitShopperLead.path}`,
      {
        method: ENDPOINTS.submitShopperLead.method,
        body: JSON.stringify(formData),
      }
    );
  }
}
