/**
 * @author Salma Hefnawy
 * @date 2022-11-29
 * @description implementation of offer related utility functions.
 * @filename offer.ts
 */

import { Offer as OfferAPI } from '../../api/pre-delivery/offer.api';
import { getPayloadData, handleError } from '@orascom/utils';
import { PaginationPayload } from '@orascom/api-interfaces';
import { OfferInterface } from '../../definitions/interfaces/pre-delivery.interface';

/**
 * group of Offer helpers functionalities.
 */
export class Offer {
  /**
   * get list of pre-delivery offers
   *
   * @static
   * @param {number} page
   * @param {number} perPage
   * @returns {Promise<PaginationPayload<OfferInterface[]>>}
   *
   * @memberOf Offer
   */
  public static getOffers(
    page: number,
    perPage: number
  ): Promise<PaginationPayload<OfferInterface[]>> {
    return OfferAPI.getOffers(page, perPage)
      .then((result) => {
        return getPayloadData(result);
      })
      .catch((error) => {
        return Promise.reject(handleError(error));
      });
  }
}
