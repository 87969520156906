import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './minimal-footer.module.scss';
import { ROUTES } from '../../../api/routes';
/* eslint-disable-next-line */
export interface MinimalFooterProps {
  showLinks?: boolean;
  reservation?: boolean;
}

export function MinimalFooter(props: Readonly<MinimalFooterProps>) {
  const { t } = useTranslation();
  return (
    <footer
      className={`${props.reservation && 'container'} ${
        props.reservation && styles['wrapper-reservation']
      }`}
    >
      <div className={`${styles['wrapper']} `}>
        {props.showLinks && (
          <p>
            <Link to={ROUTES.TermsAndConditions.path}>
              {t('termsOfService')}
            </Link>
            <Link to={ROUTES.PrivacyPolicy.path}>{t('privacyPolicy')}</Link>
          </p>
        )}
        <span>{`${new Date().getUTCFullYear()} ${t('rightsReserved')}`}</span>
      </div>
    </footer>
  );
}

export default MinimalFooter;
