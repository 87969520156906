import styles from './skeleton-unit.module.scss';

export const SkeletonUnit = () => {
  return (
    <div className={styles['skeleton-unit']}>
      <div className={styles['skeleton-unit__image']}></div>
      <div className={styles['skeleton-unit-container']}>
        <div className={styles['skeleton-unit__title']}></div>
        <div className={styles['skeleton-unit__sub-title']}></div>
        <div className={styles['skeleton-unit__price']}></div>
        <div className={styles['skeleton-unit__info']}>
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
};
