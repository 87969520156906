import { useTranslation } from 'react-i18next';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import styles from './master-plan.module.scss';
import { downloadImage } from '@orascom/utils';

/* eslint-disable-next-line */
export interface MasterPlanProps {
  title: string;
  subTitle: string;
  subDescription: string;
  image: string;
  brochure?: string;
  unitName: string;
}

export function MasterPlan(props: Readonly<MasterPlanProps>) {
  const { t } = useTranslation();

  return (
    <div className={`${styles['wrapper']} container`}>
      <div className={styles['info']}>
        <h1 className={`${styles['info-title']} orascom__title`}>
          {props.subTitle}
        </h1>
        <h2 className={`${styles['info-sub-title']} orascom__sub-title`}>
          {props.title}
        </h2>
        <p className={styles['info-desc']}>{props.subDescription}</p>
        {props.image ? (
          <PhotoProvider maskOpacity={0.8}>
            <div className={styles['image--responsive']}>
              <PhotoView src={props.image}>
                <img src={props.image} alt="master-plan" loading="lazy" />
              </PhotoView>
            </div>
          </PhotoProvider>
        ) : null}
        {props.brochure && (
          <a
            href={props.brochure}
            download
            target="_blank"
            rel="noreferrer"
            className={`btn btn--navy ${styles['info-button']}`}
          >
            {t('downloadBrochure')}
            <div className="white" />
          </a>
        )}
      </div>

      {props.image ? (
        <div>
          <PhotoProvider maskOpacity={0.8}>
            <div className={styles['image']}>
              <PhotoView src={props.image}>
                <img src={props.image} alt="master-plan" loading="lazy" />
              </PhotoView>
            </div>
          </PhotoProvider>
          <button
            className={styles['download-btn']}
            onClick={() =>
              downloadImage(props.image, `${props.unitName} masterplan`)
            }
          >
            {t('downloadMasterPlanImage')}
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default MasterPlan;
