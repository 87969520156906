import { useState, useRef, useEffect, ReactNode } from 'react';
import styles from './fade-in-section.module.scss';

export interface FadeInSectionProps {
  children: ReactNode;
  slowerEffect?: boolean;
}

// component to add fade in animation for sections only once while scrolling down
export function FadeInSection(props: Readonly<FadeInSectionProps>) {
  const [isVisible, setIsVisible] = useState(false);
  const domRef = useRef<HTMLDivElement>(null);
  const wrapperClasses = `${styles['fade-in-section']} ${
    props.slowerEffect ? styles['fade-in-section--slow'] : ''
  } ${isVisible ? styles['is-visible'] : ''}`;

  useEffect(() => {
    const current = domRef.current;
    if (!current) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // make it fade in once only to make it always fade while scrolling up or down remove condition and change initial state to true
        if (entry.isIntersecting) {
          setIsVisible(entry.isIntersecting);
        }
      });
    });
    observer.observe(current);
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, []);

  return (
    <div className={wrapperClasses} ref={domRef}>
      {props.children}
    </div>
  );
}

export default FadeInSection;
