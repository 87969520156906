import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './unit-details.module.scss';
import ShareIcon from '../../assets/icons/share-icon.svg';
import EmailIcon from '../../assets/icons/email.svg';
import CopyIcon from '../../assets/icons/copy-to-clipboard.svg';
import { toast } from 'react-toastify';

const ShareAction = ({ unitTypeName }: { unitTypeName: string }) => {
  const { t } = useTranslation();
  const [showShareMenu, setShowShareMenu] = useState(false);
  const shareMenu = useRef<HTMLUListElement>(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (
        shareMenu.current &&
        !shareMenu.current.contains(event.target as Node)
      ) {
        setShowShareMenu(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [shareMenu]);

  const handleSharing = async () => {
    if ('share' in navigator) {
      try {
        await navigator
          .share({
            title: unitTypeName,
            url: document.location.href,
            // TODO: add share description
            text: '',
          })
          .then(() =>
            console.log('Hooray! Your content was shared to tha world')
          );
      } catch (error) {
        console.log(error);
      }
    } else {
      // fallback code
      setShowShareMenu(true);
    }
  };
  const copyToClipboard = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(window.location.href);
      toast.success(t('copySuccessMessage') as string);
      setShowShareMenu(false);
    } else {
      document.execCommand('copy', true, window.location.href);
    }
  };
  return (
    <div className={styles['share']}>
      <button
        type="button"
        onClick={handleSharing}
        className={styles['share-btn']}
      >
        <img src={ShareIcon} alt="share" />
      </button>
      {showShareMenu && (
        <ul ref={shareMenu} className={styles['share-menu']}>
          <li>
            <a
              href={`mailto:?subject=Orascom Property&body=Check out this property from Orascom: ${window.location.href}`}
              title={unitTypeName}
              target="_blank"
              onClick={() => setShowShareMenu(false)}
              rel="noreferrer"
            >
              <img src={EmailIcon} role="presentation" alt="" />
              {t('email')}
            </a>
          </li>
          <li>
            <button type="button" onClick={copyToClipboard}>
              <img src={CopyIcon} role="presentation" alt="" />
              {t('copyToClipboard')}
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default ShareAction;
