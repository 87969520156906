/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable import/no-extraneous-dependencies */
import styles from './unit-addon.module.scss';
import Numeral from 'numeral';
import { useEffect, useRef, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PreDeliveryUser as PreDeliveryUserUtilities } from '../../../utils/pre-delivery/user.utils';
import { UnitAddOn } from '@orascom/api-interfaces';
import { GoldButton, Loader } from '@orascom/common-components';
import { ROUTES } from '../../../api/routes';
import backArrowIcon from '../../../assets/icons/back-arrow.svg';
import DownArrow from '../../../assets/icons/arrow-down.svg?react';
import GallerySlider from '../../../components/common/gallery-slider/gallery-slider';
import RequestAddonForm from '../../../components/pre-delivery/request-addon-form/request-addon-form';
import { v4 as uuidv4 } from 'uuid';

export function UnitAddon() {
  const { t } = useTranslation();
  const addonForm = useRef<null | HTMLDivElement>(null);

  const executeScroll = () => {
    addonForm.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const addonId = useParams<{ addonId: string }>().addonId;
  const [addonDetails, setAddonDetails] = useState<UnitAddOn>();

  useEffect(() => {
    if (addonId) {
      PreDeliveryUserUtilities.getAddon(addonId)
        .then((res) => setAddonDetails(res))
        .catch((err) => {
          console.log(err);
        });
    }
  }, [addonId]);

  if (!addonDetails) {
    return <Loader />;
  }
  return (
    <div className={`${styles['addon']} pre-delivery-container`}>
      <header className={styles['header']}>
        <NavLink to={ROUTES.Pre_Delivery_Addons.path} className="back-button">
          <img src={backArrowIcon} alt="side-menu-icon" />
          <>{t('backToUnitAddons')}</>
        </NavLink>
        <div className={styles['header-details']}>
          <NavLink to={ROUTES.Pre_Delivery_Addons.path}>
            {t('unitAddons')}
          </NavLink>
          <span>/</span>
          <p>{t('addonDetails')}</p>
        </div>
      </header>
      <div className={styles['about']}>
        <div className={styles['about-content']}>
          <h1 className="orascom__title">{t('addon')}</h1>
          <h2 className="orascom__sub-title">{addonDetails.title}</h2>
          <p className={styles['about-price']}>
            {Numeral(addonDetails.price).format('0,0')} {t('egp')}
          </p>
          <p className={styles['about-note']}>{t('for')}</p>
          <p className={styles['about-unit']}>
            {addonDetails.unit?.project.name} -{' '}
            {addonDetails.unit?.project.destination.name} -{' '}
            {addonDetails.unit?.unit_type.name}
          </p>
          <div
            className={`${styles['about-image']} ${styles['about-image--responsive']}`}
          >
            <img
              src={addonDetails.image}
              alt={addonDetails.title}
              loading="lazy"
            />
          </div>
          <p className={styles['about-desc']}> {addonDetails.description}</p>
          {addonDetails.is_purchasable ? (
            <GoldButton
              asLink
              to={ROUTES.Pre_Delivery_Buy_Addon.getPath?.(addonDetails.id)}
            >
              {t('buyAddon')}
            </GoldButton>
          ) : (
            <button
              type="button"
              onClick={executeScroll}
              className={styles['about-request-link']}
            >
              {t('requestAddon')}
              <DownArrow />
            </button>
          )}
        </div>
        <div
          className={`${styles['about-image']} ${styles['about-image--desktop']}`}
        >
          <img
            src={addonDetails.image}
            alt={addonDetails.title}
            loading="lazy"
          />
        </div>
      </div>
      {addonDetails.gallery?.length ? (
        <div className={styles['gallery-container']}>
          <div className={styles['background']} />
          <h2 className="orascom__sub-title">{t('viewAddonOnYourUnit')}</h2>
          <div className={styles['gallery']}>
            <GallerySlider pagination>
              {addonDetails.gallery.map((galleryImage) => (
                <img
                  key={uuidv4()}
                  src={galleryImage}
                  alt="gallery"
                  loading="lazy"
                />
              ))}
            </GallerySlider>
          </div>
        </div>
      ) : null}
      {addonDetails.metadata?.specs?.length ? (
        <div className={styles['table']}>
          <div className={styles['background']} />
          <h2 className="orascom__sub-title">{t('addonSpecs')}</h2>
          <table>
            <tbody>
              {addonDetails.metadata?.specs?.map((data) => {
                return (
                  <tr key={uuidv4()}>
                    <td>{data.name}</td>
                    <td>
                      {data.value} {data.unit ?? ''}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : null}
      {!addonDetails.is_purchasable ? (
        <div className={styles['request-form']} ref={addonForm}>
          <h2 className="orascom__sub-title">{t('requestAddon')}</h2>
          <p>{t('requestAddonDesc')}</p>
          {addonDetails.unit?.id && (
            <RequestAddonForm
              addonId={addonDetails.id}
              unitId={addonDetails.unit?.id}
              addonName={addonDetails.title ?? ''}
            />
          )}
        </div>
      ) : null}
    </div>
  );
}

export default UnitAddon;
