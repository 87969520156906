/* eslint-disable @nx/enforce-module-boundaries */
import { useTranslation } from 'react-i18next';
import Numeral from 'numeral';
import styles from './payment-progress.module.scss';
import { PieChart } from 'react-minimal-pie-chart';
import { SaleInstallmentsResponse } from '@orascom/api-interfaces';
import { v4 as uuidv4 } from 'uuid';

/* eslint-disable-next-line */
export interface PaymentProgressProps {
  unitInstallments: SaleInstallmentsResponse;
  fullChartInfo?: boolean;
  unitId?: number;
  handleClick?: () => void;
}

export function PaymentProgress(props: Readonly<PaymentProgressProps>) {
  const { t } = useTranslation();

  const totalCost =
    props.unitInstallments?.total_installments_values +
    props.unitInstallments?.down_payment_paid +
    props.unitInstallments?.down_payment_unpaid;
  const totalUnpaid =
    props.unitInstallments?.down_payment_unpaid +
    props.unitInstallments?.total_installments_unpaid;

  const totalPaid =
    props.unitInstallments?.down_payment_paid +
    props.unitInstallments.total_installments_paid;

  const downPaymentPercentage =
    totalCost > 0
      ? +(
          (props.unitInstallments?.down_payment_paid / totalCost) *
          100
        ).toFixed(1)
      : 0;
  const totalInstallmentsPercentage =
    totalCost > 0
      ? +(
          (props.unitInstallments.total_installments_paid / totalCost) *
          100
        ).toFixed(1)
      : 0;
  const totalUnpaidPercentage = +((totalUnpaid / totalCost) * 100).toFixed(1);
  const totalPaidPercentage =
    Number(downPaymentPercentage) + Number(totalInstallmentsPercentage);

  const paymentCurrency =
    props.unitInstallments?.installments?.length &&
    props.unitInstallments?.installments[0]?.currency;
  const chartData = props.fullChartInfo
    ? [
        {
          title: t('paidDownpayment'),
          value: props.unitInstallments?.down_payment_paid,
          key: downPaymentPercentage,
          color: '#C8A557',
        },
        {
          title: t('installmentsPaid'),
          value: props.unitInstallments.total_installments_paid,
          key: totalInstallmentsPercentage,
          color: '#DAC38E',
        },
        {
          title: t('remainingAmount'),
          value: totalUnpaid,
          key: totalUnpaidPercentage,
          color: '#FAF6EE',
        },
      ]
    : [
        {
          title: t('totalAmountPaid'),
          value: totalPaid,
          key: totalPaidPercentage,
          color: '#C8A557',
        },
        {
          title: t('remainingAmount'),
          value: totalUnpaid,
          key: totalUnpaidPercentage,
          color: '#FAF6EE',
        },
      ];

  return (
    <div className={`${styles['progress-chart']} progress-chart`}>
      {props.fullChartInfo && <h2>{t('paymentProgress')}</h2>}
      <div className={`${styles['progress-chart-data']} progress-chart-data`}>
        <div className={styles['progress-chart-data-pie']}>
          <PieChart
            style={{
              width: props.fullChartInfo ? '175px' : '78px',
              height: props.fullChartInfo ? '175px' : '78px',
              border: '1px solid #C8A557',
              borderRadius: '50%',
              padding: props.fullChartInfo ? '12px' : '5px',
            }}
            className="pie-chart"
            startAngle={270}
            data={chartData}
          />

          <div className={styles['progress-chart-data--total-responsive']}>
            <h3>{totalPaidPercentage}%</h3>
            <label>{t('totalAmountPaid')}</label>
          </div>
        </div>

        <div>
          <div className={styles['progress-chart-data--total']}>
            <h3>{totalPaidPercentage}%</h3>
            <label>{t('totalAmountPaid')}</label>
          </div>
          {props.fullChartInfo && (
            <ul className={styles['progress-chart-data--info']}>
              {chartData.map((data) => {
                return (
                  <li style={{ color: data.color }} key={uuidv4()}>
                    <label>{data.title}</label>
                    <p>{`${Numeral(data.value).format(
                      '0,0'
                    )} ${paymentCurrency} (${data.key}%)`}</p>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default PaymentProgress;
