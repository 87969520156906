import styles from './buy-addon.module.scss';
import { useContext, useEffect, useState } from 'react';
import Numeral from 'numeral';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PreDeliveryUser as PreDeliveryUserUtilities } from '../../../utils/pre-delivery/user.utils';
import { UnitAddOn } from '@orascom/api-interfaces';
import { GoldButton, Loader, NavyButton } from '@orascom/common-components';
import ReservationFee from '../../../components/reservation-fee/reservation-fee';
import successIcon from '../../../assets/icons/success.svg';
import { USER_CONTEXT } from '../../../contexts/user-context';
import { ROUTES } from '../../../api/routes';
import backArrowIcon from '../../../assets/icons/back-arrow.svg';

export function BuyAddon() {
  const { t } = useTranslation();
  const addonId = useParams<{ addonId: string }>().addonId;
  const [addonDetails, setAddonDetails] = useState<UnitAddOn>();
  //Todo: this will be refactored after integration
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const userContext = useContext(USER_CONTEXT);

  useEffect(() => {
    if (addonId) {
      PreDeliveryUserUtilities.getAddon(addonId)
        .then((res) => setAddonDetails(res))
        .catch((err) => {
          console.log(err);
        });
    }
  }, [addonId]);

  const updateFormSubmissionState = (formSubmissionState: boolean) => {
    setFormSubmitted(formSubmissionState);
  };

  if (!addonDetails) {
    return <Loader />;
  }
  return (
    <div className={`${styles['buy-addon']} container`}>
      {formSubmitted ? (
        <section className={styles['success']}>
          <h1 className="orascom__sub-title ">
            {t('purchaseCompleted')}{' '}
            <img src={successIcon} role="presentation" alt="" />
          </h1>
          <div className={styles['success-info']}>
            <h2>{t('purchaseDetails')}</h2>

            <p>
              {t('yourAddonHasBeen')} <span>{t('purchasedSuccessfully')}</span>{' '}
              {t('receiptSentOnYour')} <span>{userContext?.user?.email}</span>
            </p>
          </div>
          <p>{t('purchasedSuccessfullyNote')}</p>
          <div className={styles['success-buttons']}>
            <GoldButton
              asLink
              to={ROUTES.Pre_Delivery_Addon.getPath?.(addonDetails.id)}
            >
              {t('goToMyAddon')}
            </GoldButton>
            <NavyButton
              asLink
              to={ROUTES.Pre_Delivery_Unit.getPath?.(addonDetails.unit?.id)}
            >
              {t('backToMyUnit')}
            </NavyButton>
          </div>
        </section>
      ) : (
        <>
          <NavLink to={ROUTES.Pre_Delivery_Addons.path} className="back-button">
            <img src={backArrowIcon} alt="side-menu-icon" />
            <>{t('backToUnitAddons')}</>
          </NavLink>
          <section>
            <h1 className="orascom__sub-title desktop">{t('buyAddon')}</h1>
            <ReservationFee
              page="buy-addon"
              updateFormSubmissionState={updateFormSubmissionState}
            />
          </section>
        </>
      )}

      <aside>
        <h1 className="orascom__sub-title responsive">{t('buyAddon')}</h1>
        <div className={styles['image']}>
          <img
            src={addonDetails.image}
            alt={addonDetails.title}
            loading="lazy"
          />
        </div>
        <h2>
          {addonDetails.title} {t('addon')}
        </h2>
        <div className={styles['info']}>
          <div>
            <label>{t('installedFor')}</label>
            <p>
              {addonDetails.unit?.project.name} -{' '}
              {addonDetails.unit?.project.destination.name} -{' '}
              {addonDetails.unit?.unit_type.name}
            </p>
          </div>
          <div>
            <label>{t('addonPrice')}</label>
            <p className="price">
              {Numeral(addonDetails.price).format('0,0')} {t('egp')}
            </p>
          </div>
        </div>
        <p className={styles['note']}>{t('buyAddonNote')}</p>
      </aside>
    </div>
  );
}

export default BuyAddon;
