import { useParams } from 'react-router-dom';
import UnitPaymentCalculator from '../common/unit-payment-calculator/unit-payment-calculator';
import { GoldButton } from '../common/button/button';
import { ROUTES } from '../../api/routes';
import styles from './unit-payment-plan.module.scss';
import { useTranslation } from 'react-i18next';
import {
  CountriesEnum,
  UnitPaymentTerms,
  UnitDetails as UnitDetailsInterface,
} from '@orascom/api-interfaces';
import { analyticsReserveUnitCustomEvent } from '../../utils/analytics-events';
import { showReservationButton } from '../../definitions/consts/envConstants';

export interface UnitPaymentPlanProps {
  handleSelectPaymentTerms?: (paymentTerms: UnitPaymentTerms) => void;
  unitCountry: CountriesEnum | undefined;
  unitDetails: UnitDetailsInterface;
}
export function UnitPaymentPlan(props: Readonly<UnitPaymentPlanProps>) {
  const { t } = useTranslation();
  const unitId = useParams<{ unitId: string }>().unitId;
  return (
    <div
      id="payment-plan"
      className={`${styles['unit-payment-plan']} container`}
    >
      <div className={styles['headings']}>
        <h1 className="orascom__title">{t('installments')}</h1>
        <h2 className="orascom__sub-title">{t('paymentPlan')}</h2>
        <p className="orascom__title">
          {t('usePaymentCalculatorAndCustomizeYourPayment')}
        </p>
      </div>
      <UnitPaymentCalculator
        handleSelectPaymentTerms={props.handleSelectPaymentTerms}
        unitCountry={props.unitCountry}
        unitDetails={props.unitDetails}
        saveButton={
          showReservationButton ? (
            <GoldButton
              asLink
              to={ROUTES.ReserverProperty.getPath?.(unitId)}
              onClick={() => {
                analyticsReserveUnitCustomEvent({
                  position: 'bottom',
                  unitId: unitId,
                });
              }}
            >
              {t('reserveUnit')}
            </GoldButton>
          ) : null
        }
      />
    </div>
  );
}

export default UnitPaymentPlan;
