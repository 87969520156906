import loader from './orascom-loader.svg';
import styles from './loader.module.scss';

export function Loader() {
  return (
    <div className={`${styles['loader']} loader-wrapper`}>
      <img src={loader} alt="" role="presentation" />
    </div>
  );
}

export default Loader;
