import { User as UserInterface } from '@orascom/api-interfaces';
import * as React from 'react';
import { UserReservationDetailsInterface } from '../definitions/interfaces/common.interface';

/**
 * user context that holds under user creation.
 */

export const USER_CONTEXT: React.Context<{
  /**
   * currently logged in user details
   */
  user?: UserInterface | null;

  /**
   * set User Value.
   */
  setUser?: (user: UserInterface | null) => void;

  /**
   * a boolean which is true if the user is loading.
   */
  isLoading?: boolean;

  userReservationDetails?: UserReservationDetailsInterface | null;
  setUserReservationDetails?: (
    userReservationDetails: UserReservationDetailsInterface | null
  ) => void;

  isLoadingUserReservationDetails?: boolean;
}> = React.createContext({});
