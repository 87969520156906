import { useTranslation } from 'react-i18next';
import { GoldButton } from '../button/button';
import styles from './compare-button.module.scss';
import ErrorIcon from '../../../assets/icons/info.svg';
import { ROUTES } from '../../../api/routes';
import { useContext } from 'react';
import { UNIT_COMPARE_CONTEXT } from '../../../contexts/unit-comparison.context';

export function CompareButton() {
  const unitContext = useContext(UNIT_COMPARE_CONTEXT);
  const { t } = useTranslation();

  return (
    <div className={styles['container']}>
      {unitContext.units && unitContext.units?.length > 0 && (
        <div className={styles['wrapper']}>
          <span>{unitContext.units?.length}</span>
          <GoldButton asLink to={ROUTES['CompareProperties'].path}>
            {t('compare')}
          </GoldButton>
        </div>
      )}
      {unitContext.showError && (
        <p className={styles['error']}>
          <img src={ErrorIcon} role="presentation" alt="" />
          <span>{t('maximumCompareError')}</span>
        </p>
      )}
    </div>
  );
}

export default CompareButton;
