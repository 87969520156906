import styles from './sales-offer-floor-plan.module.scss';
import { UnitDetails } from '@orascom/api-interfaces';

const MONTENEGRO_UNITS_BATHROOMS: Record<string, string> = {
  '1': '1',
  '2': '1.5',
  '3': '2',
  '4': '2.5',
  '5': '3',
  '753240000': '3.5',
  '6': '4',
  '753240001': '4.5',
  '7': '5',
  '8': '6',
};
const MONTENEGRO_UNITS_FLOOR_NUMBER: Record<string, string> = {
  '753240000': 'Basement',
  '0': 'Ground',
  '1': 'First',
  '2': 'Second',
  '3': 'Third',
  '4': 'Fourth',
};

function SalesOfferFloorPlan({ unit }: Readonly<{ unit: UnitDetails }>) {
  let unitFloorNumber: number | string = unit.floor_number;
  let unitBathroomsNumber: number | string = unit.bathrooms;

  if (
    unitFloorNumber &&
    unit.project?.destination?.country?.slug === 'montenegro'
  ) {
    unitFloorNumber = MONTENEGRO_UNITS_FLOOR_NUMBER[unitFloorNumber];
  }

  if (
    unitBathroomsNumber &&
    unit.project?.destination?.country?.slug === 'montenegro'
  ) {
    unitBathroomsNumber = MONTENEGRO_UNITS_BATHROOMS[unitBathroomsNumber];
  }

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{unit.unit_type.name}</h1>
      <div className={styles['details']}>
        <div className={styles['unit__details']}>
          {unit.bedrooms > 0 && (
            <p className={styles['bedroom__details']}>
              {' '}
              {unit.bedrooms} Bedrooms
            </p>
          )}
          {unitBathroomsNumber && (
            <p className={styles['bathroom__details']}>
              {' '}
              {unitBathroomsNumber} Bathrooms
            </p>
          )}
          {Number(unit.built_up_area) > 0 &&
            !isNaN(Number(unit.built_up_area)) && (
              <p className={styles['area']}>
                {unit.built_up_area}m² Built Up Area
              </p>
            )}
          {unitFloorNumber && (
            <p className={styles['floor']}> Floor: {unitFloorNumber}</p>
          )}
        </div>
        <div
          className={styles['unit__location']}
        >{`${unit.project.destination.name} - ${unit.project.name}`}</div>
      </div>
      <div className={styles['unit__specs']}>
        {unit.name && (
          <p>
            {' '}
            <b>Unit name:</b> {unit.name}
          </p>
        )}
        {unit.unit_type.name && (
          <p>
            {' '}
            <b>Unit type: </b>
            {unit.unit_type.name}
          </p>
        )}
        {unit.construction_status && (
          <p>
            {' '}
            <b>Construction status: </b>
            {unit.construction_status}
          </p>
        )}
        {unit.price ? (
          <p>
            <b> Unit price: </b>
            {unit.price} {unit.currency}
          </p>
        ) : null}

        {unit.finishing_status && (
          <p>
            <b>Finishing status:</b> {unit.finishing_status}
          </p>
        )}
        {Number(unit.garden_area) > 0 && !isNaN(Number(unit.garden_area)) && (
          <p>{unit.garden_area}m² Garden Area</p>
        )}
        {Number(unit.total_area) > 0 && !isNaN(Number(unit.total_area)) && (
          <p>{unit.total_area}m² Total Area</p>
        )}
      </div>
      <div className={styles['floor__plans']}>FLOOR PLANS</div>

      <div>
        {unit.masterplans.length == 0 && <p>No floorplans for this unit</p>}
      </div>
    </div>
  );
}

export default SalesOfferFloorPlan;
