import { OptionValue } from './common.interface';

export enum MaritalStatusEnum {
  SINGLE = '1',
  MARRIED = '2',
}

export interface UpdateUserInfo {
  nationality_id: string;
  country_id: string;
  city_id: string;
  city_name: string;
  district_id: string;
  address: string;
  occupation_code: string;
  marital_status: MaritalStatusEnum;
  national_id_number?: string;
  passport_number?: string;
  has_joint: boolean;
  joint_first_name?: string;
  joint_last_name?: string;
  joint_mobile_number?: string;
  joint_email?: string;
  joint_country_id?: string;
  joint_nationality_id?: string;
  joint_city_id?: string;
  joint_city_name?: string;
  joint_district_id?: string;
  joint_occupation_code?: string;
  joint_marital_status?: MaritalStatusEnum;
  joint_national_id_number?: string;
  joint_passport_number?: string;
}

export interface UpdateUserInfoFormData {
  nationality: OptionValue | null;
  country: OptionValue | null;
  city: OptionValue | null;
  cityName: string;
  citiesFound: boolean;
  district: string;
  address: string;
  occupation: OptionValue | null;
  occupationName: string;
  occupationsFound: boolean;
  maritalStatus: MaritalStatusEnum;
  identificationForm: string;
  nationalIdNumber: string;
  nationalIdFrontImage?: File;
  nationalIdBackImage?: File;
  passportNumber: string;
  passportImage?: File;
  jointBuyer: boolean;
  jointBuyerFirstName: string;
  jointBuyerLastName: string;
  jointBuyerCountryCode: string;
  jointBuyerPhoneNumber: string;
  jointBuyerEmail: string;
  jointBuyerNationality: OptionValue | null;
  jointBuyerCountry: OptionValue | null;
  jointBuyerCity: OptionValue | null;
  jointBuyerCityName: string;
  relationToBuyer?: OptionValue | null;
  sameNationality: boolean;
  sameCountry: boolean;
  sameCity: boolean;
  sameDistrict: boolean;
  sameAddress: boolean;
  jointBuyerDistrict: string;
  jointBuyerAddress: string;
  jointBuyerOccupation: OptionValue | null;
  jointBuyerOccupationName: string;
  jointBuyerMaritalStatus: MaritalStatusEnum;
  jointBuyerIdentificationForm: string;
  jointBuyerNationalIdNumber: string;
  jointBuyerNationalIdFrontImage?: File;
  jointBuyerNationalIdBackImage?: File;
  jointBuyerPassportNumber: string;
  jointBuyerPassportImage?: File;
}

export interface UpdateUserInfoRequest {
  customer_id: string;
  nationality_id?: string;
  country_id?: string;
  city_id?: string;
  city_name?: string;
  district_id?: string;
  district_name?: string;
  other_district_name?: string;
  address?: string;
  occupation_code?: string;
  occupation_name?: string;
  other_occupation_name?: string;
  marital_status: MaritalStatusEnum;
  national_id_number?: string;
  passport_number?: string;
  has_joint_buyer: boolean;
  joint_buyer_first_name?: string;
  joint_buyer_last_name?: string;
  joint_buyer_mobile_number?: string;
  joint_buyer_email?: string;
  joint_buyer_country_id?: string;
  joint_buyer_nationality_id?: string;
  joint_buyer_city_id?: string;
  joint_buyer_city_name?: string;
  joint_buyer_district_id?: string;
  joint_buyer_district_name?: string;
  joint_buyer_other_district_name?: string;
  joint_buyer_occupation_code?: string;
  joint_buyer_occupation_name?: string;
  joint_buyer_other_occupation_name?: string;
  joint_buyer_marital_status?: MaritalStatusEnum;
  joint_buyer_national_id_number?: string;
  joint_buyer_passport_number?: string;
  joint_buyer_address?: string;
  joint_buyer_connection_role_id?: string;
  joint_buyer_connection_role_code?: string;
  joint_buyer_mobile_country_code_id?: string;
}
