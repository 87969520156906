import {
  ImageTextOverlay,
  Loader,
  NavyButton,
} from '@orascom/common-components';
import ExploreIndicator from '../../components/common/explore-indicator/explore-indicator';
import styles from './investment.module.scss';
import InvestmentBanner from '../../assets/images/investment.webp';
import { useTranslation } from 'react-i18next';
import CashIcon from '../../assets/icons/cash.svg';
import ScaleIcon from '../../assets/icons/scale.svg';
import CallIcon from '../../assets/icons/call.svg';
import CastleIcon from '../../assets/icons/castle.svg';
import DownArrowIcon from '../../assets/icons/arrow-down.svg';
import DownArrowIconWhite from '../../assets/icons/arrow-down--white.svg';
import { Country as CountryUtilities } from '../../utils/country.utils';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CountryInvestmentsInterface } from '../../definitions/interfaces/country.interface';
import { ROUTES } from '../../api/routes';
import ContactUsForm from '../../components/common/contact-us-form/contact-us-form';
import ContactUsImage from '../../assets/images/investment-contact-us.webp';
import { formatInfoItem } from '../../utils/common.utils';
import {
  analyticsGetAssistanceCustomEvent,
  analyticsLearnMoreCustomEvent,
} from '../../utils/analytics-events';
import { Helmet } from 'react-helmet-async';
import getDocTitle from '../../utils/getDocTitle';
import { useLocation } from 'react-router-dom';
import { USER_CONTEXT } from '../../contexts/user-context';
import { CommonEventParameters } from '@orascom/utils';

const investmentDocTitle = getDocTitle('Investment');
export function Investment() {
  const { t } = useTranslation();
  const [countries, setCountries] = useState<CountryInvestmentsInterface[]>([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const requestAssistanceRef = useRef<null | HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const userContext = useContext(USER_CONTEXT);
  const executeScroll = () => {
    requestAssistanceRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const setFormSubmission = (val: boolean) => {
    setIsFormSubmitted(val);
  };

  useEffect(() => {
    CountryUtilities.getCountriesInvestments()
      .then((res) => setCountries(res))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loader />;
  }
  const analyticsEventParams: CommonEventParameters = {
    userId: userContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Shopper',
    pageName: location.pathname,
  };
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>{investmentDocTitle}</title>
        <meta property="og:title" content={investmentDocTitle} />
        <meta name="description" content={t('investmentBannerDescription')} />
        <meta
          property="og:description"
          content={t('investmentBannerDescription')}
        />
      </Helmet>
      <div>
        <div className={styles['banner']}>
          <ImageTextOverlay
            image={InvestmentBanner}
            title={t('builtToAddValue')}
            subTitle={t('startInvestmentJourney')}
            gradient
            maxContentWidth={550}
          />

          <button
            type="button"
            onClick={() => {
              executeScroll();
              analyticsGetAssistanceCustomEvent(analyticsEventParams);
            }}
            className={styles['request-assistance']}
          >
            <span>{t('getAssistance')}</span>
            <img src={DownArrowIconWhite} role="presentation" alt="" />
          </button>
        </div>
        <div className={`${styles['intro']} container`}>
          <p className={styles['description']}>
            {t('investmentBannerDescription')}
          </p>
          <p className={styles['description']}>
            {t('secondInvestmentBannerDescription')}
          </p>
          <ExploreIndicator title={t('learnMoreAboutInvestment')} />
        </div>
      </div>

      <div className={`${styles['why-invest']} container`}>
        <p className="orascom__title">{t('securityAndProsperity')}</p>
        <p className="orascom__sub-title">{t('whyInvestInOrascom')}</p>
        <p className="orascom__paragraph">
          {t('whyInvestInOrascomDescription')}
        </p>

        <div className={styles['why-invest-cards']}>
          <div className={styles['why-invest-card']}>
            <img src={CashIcon} role="presentation" alt="" />
            <p>{t('masterPlannedCommunities')}</p>
            <p>{t('masterPlannedCommunitiesDescription')}</p>
          </div>
          <div className={styles['why-invest-card']}>
            <img src={CastleIcon} role="presentation" alt="" />
            <p>{t('hotLocation')}</p>
            <p>{t('hotLocationDescription')}</p>
          </div>
          <div className={styles['why-invest-card']}>
            <img src={ScaleIcon} role="presentation" alt="" />
            <p>{t('stability')}</p>
            <p>{t('stabilityDescription')}</p>
          </div>
          <div className={styles['why-invest-card']}>
            <img src={CallIcon} role="presentation" alt="" />
            <p>{t('peaceOfMind')}</p>
            <p>{t('peaceOfMindDescription')}</p>
          </div>
        </div>
      </div>

      <div className={styles['where-to-invest']}>
        <p className="orascom__title">{t('exploreCountries')}</p>
        <p className="orascom__sub-title">{t('whereToInvest')}</p>
        <ul>
          {countries.map((country) => (
            <li key={country.id} className={styles['country-card']}>
              <img
                src={country.investment_image}
                alt={country.name}
                className={styles['country-image']}
                loading="lazy"
              />

              <div className={styles['country-info']}>
                <p>{country.name}</p>
                <p>{country.investment_description}</p>
                <dl>
                  {country.metadata?.investment?.map((item) => (
                    <React.Fragment key={item.name}>
                      <dt>
                        {formatInfoItem(
                          String(item.value),
                          String(item.type),
                          String(item.unit)
                        )}
                      </dt>
                      <dd>{item.name}</dd>
                    </React.Fragment>
                  ))}
                </dl>

                <div className={styles['country-buttons']}>
                  <NavyButton
                    asLink
                    to={ROUTES.Country.getPath?.(country.slug, country.id)}
                    onClick={() =>
                      analyticsLearnMoreCustomEvent(analyticsEventParams)
                    }
                  >
                    {t('learnMore')}
                  </NavyButton>

                  <button
                    type="button"
                    onClick={() => {
                      executeScroll();
                      analyticsGetAssistanceCustomEvent(analyticsEventParams);
                    }}
                    className={styles['request-assistance']}
                  >
                    <span>{t('getAssistance')}</span>
                    <img src={DownArrowIcon} role="presentation" alt="" />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div ref={requestAssistanceRef} className={styles['reach-us']}>
        {!isFormSubmitted && (
          <img
            className={styles['image']}
            src={ContactUsImage}
            alt="contact-us"
            loading="lazy"
          />
        )}
        <div>
          <p className="orascom__title">{t('exploreCountries')}</p>
          <p className="orascom__sub-title">{t('getAssistanceAndStart')}</p>
          <p className={styles['disclaimer']}>{t('reachUsDisclaimer')}</p>
          <ContactUsForm
            setFormSubmission={setFormSubmission}
            doneButtonAsLink={true}
            doneButtonRoute={ROUTES.Home.path}
            investmentPage
          />
        </div>
      </div>
    </div>
  );
}

export default Investment;
