import styles from './units-filters.module.scss';
import { useSearchParams } from 'react-router-dom';
import { getSelectedLabels } from './filters-utils';
import { FiltersEnums, UnitFilters } from '@orascom/api-interfaces';
import { useTranslation } from 'react-i18next';
import useClearSearchParams from './use-clear-search-params';

const ClearFilter = ({
  selectedLabels,
  filterLabel,
}: {
  selectedLabels: string;
  filterLabel: string;
}) => {
  const [_, setSearchParams] = useSearchParams();

  const handleClearFilter = (label: string) => {
    setSearchParams(
      (searchParams) => {
        searchParams.delete(label);
        if (label === FiltersEnums.MIN_PRICE) {
          searchParams.delete(FiltersEnums.MAX_PRICE);
          searchParams.delete(FiltersEnums.CURRENCY);
        }
        return searchParams;
      },
      {
        replace: true,
      }
    );
  };
  return (
    <div className={styles['item']} key={filterLabel}>
      {selectedLabels}
      <button
        className={styles['clear']}
        onClick={() => handleClearFilter(filterLabel)}
      >
        X
      </button>
    </div>
  );
};

const ClearFilters = ({
  expanded,
  unitFilters,
}: {
  expanded: boolean;
  unitFilters: UnitFilters;
}) => {
  const { t } = useTranslation();
  const { searchParams, params, onClearAllFilters } = useClearSearchParams();

  if (!params.length) return null;

  return (
    <div
      className={`${styles['clear-filters']} ${
        expanded ? styles['clear-filters--expanded'] : ''
      }`}
    >
      <div className={`${styles['clear-filters__container']} container`}>
        <div className={styles['filters']}>
          {params.map((param) => {
            const label = param.label;

            let selectedLabels = getSelectedLabels(
              label,
              unitFilters,
              searchParams,
              t
            );

            // if the label is not in the filters, return null
            if (!selectedLabels) return null;

            if (label === 'built_up_areas') selectedLabels += ' ㎡';
            if (label === 'bedrooms') selectedLabels += ' bedrooms';
            if (label === 'bathrooms') selectedLabels += ' bathrooms';

            return (
              <ClearFilter
                filterLabel={label}
                selectedLabels={selectedLabels}
                key={label}
              />
            );
          })}
        </div>
        <button
          className={styles['clear-all-filters']}
          onClick={onClearAllFilters}
        >
          {t('clearFilters')}
        </button>
      </div>
    </div>
  );
};

export default ClearFilters;
