import { ChangeEvent, InputHTMLAttributes, RefObject, forwardRef } from 'react';
import { debounce } from '../debounce/debounce';
import { useTranslation } from 'react-i18next';

const formatNumWithCommas = (val: number): string => {
  // add comma to every 3 digits
  return val.toLocaleString('en-US');
};

const removeCommas = (val: string): string => {
  return val.replace(/,/g, '');
};

type ReactInput = InputHTMLAttributes<HTMLInputElement>;
interface NumberInputProps
  extends Omit<ReactInput, 'onChange' | 'value' | 'id'> {
  onChange: (value: number) => void;
  value?: number;
  currency: string;
  label: string;
  id: string;
}

const PriceTextInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (
    { onChange, value, currency, id, label, ...inputProps }: NumberInputProps,
    ref
  ) => {
    const debouncedOnChange = debounce((val: number) => onChange(val), 1000);
    const { t } = useTranslation();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value) return;

      // remove all commas from the input value
      const rawValue = removeCommas(e.target.value);

      const inputRef = ref as RefObject<HTMLInputElement>;
      if (!inputRef?.current) return;

      // make sure the input value is a number
      if (!/^\d*$/.test(rawValue)) {
        inputRef.current.value = '';
        return;
      }

      inputRef.current.value = formatNumWithCommas(parseInt(rawValue));

      debouncedOnChange(parseInt(rawValue));
    };

    return (
      <>
        <label className="min-max__label" htmlFor={id}>
          {label}
        </label>
        <div className="min-max__input">
          <input
            type="text"
            id={id}
            ref={ref}
            onChange={handleChange}
            inputMode="numeric"
            pattern="[0-9\s]{13,19}"
            {...inputProps}
          />
          <span className="min-max__input-currency">{t(currency)}</span>
        </div>
      </>
    );
  }
);

export default PriceTextInput;
