import { UnitConstructionUpdate } from '../../../definitions/interfaces/pre-delivery.interface';
import { useTranslation } from 'react-i18next';
import GallerySlider from '../../common/gallery-slider/gallery-slider';
import styles from './unit-construction-updates.module.scss';

/* eslint-disable-next-line */
export interface UnitConstructionUpdatesProps {
  constructionUpdates: UnitConstructionUpdate[];
}

export function UnitConstructionUpdates(
  props: Readonly<UnitConstructionUpdatesProps>
) {
  const { t } = useTranslation();
  return (
    <div className={styles['unit-construction-updates']}>
      <div className={styles['background']} />
      <h1 className="orascom__title">{t('constructionUpdates')}</h1>
      <h2 className="orascom__sub-title">{t('recentPhotosOfUnits')}</h2>
      {props.constructionUpdates?.length ? (
        <div className={styles['gallery']}>
          <GallerySlider pagination>
            {props.constructionUpdates.map((constructionUpdate) => (
              <div
                className={styles['gallery-content']}
                key={constructionUpdate.id}
              >
                <div className={styles['gallery-info']}>
                  <p>
                    {new Date(
                      constructionUpdate.date * 1000
                    ).toLocaleDateString('en-GB', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </p>
                  <h3>{constructionUpdate.title}</h3>
                </div>
                <img
                  src={constructionUpdate.image}
                  alt="gallery"
                  loading="lazy"
                />
              </div>
            ))}
          </GallerySlider>
        </div>
      ) : null}
    </div>
  );
}

export default UnitConstructionUpdates;
