/* eslint-disable @nx/enforce-module-boundaries */
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FAQInterface } from '../../definitions/interfaces/home.interface';
import { Home as HomeUtilities } from '../../utils/home.utils';
import parse from 'html-react-parser';
import styles from './faqs.module.scss';
import { GoldButton } from '../../components/common/button/button';
import { ROUTES } from '../../api/routes';
import { Accordion, Loader } from '@orascom/common-components';
import { Helmet } from 'react-helmet-async';
import getDocTitle from '../../utils/getDocTitle';
import { analyticsGetInTouchCustomEvent } from '../../utils/analytics-events';
import { CommonEventParameters } from '@orascom/utils';
import { USER_CONTEXT } from '../../contexts/user-context';

const faqDocTitle = getDocTitle('FAQs');
export function FAQs() {
  const { t } = useTranslation();
  const UserContext = useContext(USER_CONTEXT);
  const [faqs, setFaqs] = useState<FAQInterface[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    HomeUtilities.getFAQs()
      .then((res) => setFaqs(res.faqs))
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  const analyticsEventParams: CommonEventParameters = {
    userId: UserContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Shopper',
    pageName: location.pathname,
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title>{faqDocTitle}</title>
        <meta property="og:title" content={faqDocTitle} />
        <meta name="description" content={t('faqsSubtitle')} />
        <meta property="og:description" content={t('faqsSubtitle')} />
      </Helmet>
      <div className="container">
        <h1 className={styles['title']}>{t('faqsTitle')}</h1>
        <p className={styles['sub-title']}>{t('faqsSubtitle')}</p>
        <ul className={styles['faqs-list']}>
          {faqs.map((faq) => (
            <li key={faq.id}>
              <Accordion title={faq.question}>{parse(faq.answer)}</Accordion>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles['reach-us']}>
        <h4>{t('stillHaveQuestions')}</h4>
        <p>{t('howWeCanHelp')}</p>
        <GoldButton
          asLink
          to={ROUTES.ContactUs.path}
          onClick={() => {
            analyticsGetInTouchCustomEvent(analyticsEventParams);
          }}
        >
          {t('getInTouch')}
        </GoldButton>
      </div>
    </>
  );
}

export default FAQs;
