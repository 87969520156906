import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { FilterSelect } from '../select/filter-select';
import { Props } from 'react-select';
import { FilterOption, UnitFilters } from '@orascom/api-interfaces';
import { extendMultiSearchParams, getSelectedOptions } from './filters-utils';
import { useEffect, useState } from 'react';

interface MultiFilterDropdownProps extends Props<FilterOption, true> {
  filterOptions?: FilterOption[];
  filterKey: keyof UnitFilters | 'sort_by';
  labelTranslationKey?: string;
  transformLabel?: (label: string, value: number) => string;
  showLabel?: boolean;
  containerClassName?: string;
}
const filters = [
  'countries',
  'destinations',
  'projects',
  'unit_types',
  'built_up_areas',
  'bedrooms',
  'bathrooms',
  'finishing_status',
  'tags',
  'delivery_dates',
];

export const MultiFilterDropdown = ({
  filterOptions = [],
  filterKey,
  labelTranslationKey,
  transformLabel,
  isLoading,
  isDisabled,
  showLabel = true,
  containerClassName,
  ...props
}: MultiFilterDropdownProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[]>([]);

  useEffect(() => {
    setSelectedOptions(
      getSelectedOptions(filterKey, filterOptions, searchParams) ?? []
    );
  }, [searchParams, isLoading]);

  return (
    <div className={containerClassName ?? 'input-wrapper'}>
      {showLabel && (
        <label htmlFor={filterKey}>{t(labelTranslationKey ?? filterKey)}</label>
      )}

      <FilterSelect
        id={filterKey}
        isMulti={true}
        isClearable={false}
        options={filterOptions}
        value={selectedOptions}
        isLoading={isLoading}
        isDisabled={isDisabled || isLoading || !filterOptions?.length}
        placeholder={t('selectPlaceholder')}
        getOptionValue={(option) => option.id.toString()}
        getOptionLabel={(option) => option.name}
        onChange={(val) => {
          // delete any search params that has index bigger than the current one
          const currentIndex = filters.indexOf(filterKey);

          for (let i = currentIndex + 1; i < filters.length; i++) {
            searchParams.delete(filters[i]);
          }
          // sync the selected options with the url params
          extendMultiSearchParams(filterKey, val, setSearchParams);
        }}
        components={{
          // separate multi values with a comma

          MultiValue: ({ data, index }) => {
            // NOSONAR
            if (!selectedOptions?.length) return null;

            const isLastItem = index === selectedOptions.length - 1;
            const separator = isLastItem ? '' : ',';

            return (
              <>
                <div className="select-multi__multi-value">{data.name} </div>
                <span style={{ marginInline: '4px' }}>{separator}</span>
              </>
            );
          },
        }}
        {...props}
      />
    </div>
  );
};

interface SingleFilterDropdownProps extends Props<FilterOption, false> {
  filterOptions: FilterOption[];
  filterKey: keyof UnitFilters | 'sort_by';
  labelTranslationKey?: string;
  transformLabel?: (label: string, value: number) => string;
  wrapperClassName?: string;
}

export const SingleFilterDropdown = ({
  filterOptions,
  filterKey,
  labelTranslationKey,
  transformLabel,
  isLoading,
  wrapperClassName,
  ...props
}: SingleFilterDropdownProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedOption, setSelectedOption] = useState<FilterOption | null>(
    null
  );

  useEffect(() => {
    const selected = getSelectedOptions(filterKey, filterOptions, searchParams);
    setSelectedOption(selected ? selected[0] : null);
  }, [searchParams]);

  return (
    <div className={`input-wrapper ${wrapperClassName}`}>
      <label htmlFor={filterKey}>{t(labelTranslationKey ?? filterKey)}</label>

      <FilterSelect
        id={filterKey}
        options={filterOptions}
        value={selectedOption}
        isLoading={isLoading}
        placeholder={t('selectPlaceholder')}
        getOptionValue={(option) => option.id.toString()}
        getOptionLabel={(option) => option.name}
        onChange={(val) => {
          // sync the selected option with the URL params
          if (val) {
            searchParams.set(filterKey, val.id.toString());
          } else {
            searchParams.delete(filterKey);
          }
          setSearchParams(searchParams, { replace: true });
          setSelectedOption(val as FilterOption);
        }}
        {...props}
      />
    </div>
  );
};
